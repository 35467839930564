import { useEffect, useState } from "react"
import { H1, QuestTitle, UnderQuestTitle } from '../Styles/Texts'
import { Quest, setQuest, setAnswers, setAnswerTranslation, setSelectionnable, Answer, setOrder, setNumberOfAnswer, setbackgroundSRC, setStrengthAnswer, setPriorityAnswers } from '../Constants/QuestsConstants'
import { QuestsViewContainer, ProgressBarContainer, ProgressBar, QuestTitleContainer, QuestContainerBubbles, Curtain, NextQuestionContainer } from "../Styles/Containers"
import { QuestButton, QuestBubble, RoundQuestButton, NextQuestion, PPButton, MainButton, HelpSkinButton } from "../Styles/Buttons"
import { BackgroundImage } from '../Styles/Images'
import { useDispatch, useSelector } from "react-redux"
import { MySkinDiagState } from "../type"
import * as ActionTypes from '../Constants/ActionsTypes'
import { useTranslation } from "react-i18next"
import { CSSTransition } from 'react-transition-group'
import { launchStepAnimation, setClickAnimation } from "../Styles/Animations"
import HelpSkinView from './HelpSkinView'

import arrow from '../Assets/Icons/Chevron.svg'

import PPComponent, { PPContainer, PPNumberRound, PriorityContainer } from '../Components/PPComponent'
import { runHeightMachine, sendAnalytics, analyseToAnswer } from "../Constants/Utilitaries"
import i18n from "../i18nextInit"
import { isDesktop } from "react-device-detect"

const QuestsView = () => {
    const [questStep, setQuestStep] = useState(Quest.preoccupationsPrincipales) //pp
    const [answerButtons, setAnswerButtons] = useState<any[]>([])
    const [questText, setQuestText] = useState<any>([])
    const [selected, setSelected] = useState<Array<any>>([])
    //eslint-disable-next-line
    const [selectedByDefault, setSelectedByDefault] = useState<any>([])
    const [backgroundImage, setBackgroundImage] = useState<any>(null)

    const [answer, setAnswer] = useState<any[]>([])
    const [answersContainer, setAnswersContainer] = useState<any>([]);
    //eslint-disable-next-line
    const [strengthAnswer, setStrengthAnswerState] = useState<Array<any>>([])
    const [priorityAnswer, setPriorityAnswerState] = useState<Array<any>>([])
    const [inProp, setInProp] = useState<boolean>(false)
    const [curtain, displayCurtain] = useState<any>(null)
    const [helpProp, setHelpProp] = useState<boolean>(false)
    
    //eslint-disable-next-line
    const analyses = useSelector((state: MySkinDiagState) => state.session.analyses)

    const dispatch = useDispatch()
    const { t } = useTranslation()

    const displayHelpPopUp = () => {
        if (helpProp) {
            return (<div style={{ width: "100vw", height: "100vh", top: 0, position: "fixed", zIndex: 10 }}>
                <HelpSkinView key={0} setOptIn={setHelpProp} />
            </div>)
        } else {
            return <div />
        }
    }

    const nextStep = (ans: any, e: any) => {

        if (setSelectionnable(questStep)) {

            if (selected.length >= setNumberOfAnswer(questStep)) {
                let array: Array<number> = selected
                array.pop()
                //setSelected(array)
                setSelected((arr: Array<Answer>) => [...arr, ans])
            } else {
                setSelected((arr: Array<Answer>) => [...arr, ans])
            }
        }
        else {

            sendAnalytics("question_" + questStep)
            // Disable Buttn after click
            if (document.getElementById("QUEST_CONTAINER")) {
                let children: HTMLButtonElement[] = document.getElementById("QUEST_CONTAINER")!.children as any
                for (let i = 0; i < children.length; i++) {
                    children[i].disabled = true
                }
            }

            if (document.getElementById("PDvisageYeux")) {

                let visageYeux: HTMLButtonElement = document.getElementById("PDvisageYeux") as any
                visageYeux!.style.pointerEvents = "none"
                visageYeux.disabled = true
            }

            if (document.getElementById("PDspecifiqueVisage")) {

                let specifiqueVisage: HTMLButtonElement = document.getElementById("PDspecifiqueVisage") as any
                specifiqueVisage!.style.pointerEvents = "none"
                specifiqueVisage.disabled = true
            }


            setClickAnimation(questStep, ans)


            setTimeout(() => {
                setInProp(false)
            }, 1500);

            setSelected((arr: Array<Answer>) => [...arr, ans])

            setTimeout(() => {
                setAnswer((arr: Array<Answer>) => [...arr, ans])
                let quest: number = questStep
                quest++
                //setAnswerInProp(false)
                setQuestStep(quest)
            }, 2000);
        }
    }

    const nextStepWithMultipleChoices = (e: any) => {
        questStep === Quest.preoccupationsPrincipales && displayCurtain(<Curtain />)
        questStep === Quest.preoccupationsPrincipales && setTimeout(() => {
            document.getElementById("APP")!.style.backgroundColor = "#E2E2E2"
        }, 1000);
        //document.getElementById("NEXT_QUESTION")!.style.animation = "Unfading 1s"

        //prevent multiple click
        if (document.getElementById("NEXT_QUESTION")) {
            let button = document.getElementById("NEXT_QUESTION")
            button!.style.pointerEvents = "none"
        }

        sendAnalytics("question_" + questStep)

        setTimeout(() => {
            setInProp(false)
            if (questStep === Quest.contourYeux) {
                document.getElementById("NEXT_QUESTION")!.style.animation = "Unfading 1s"
            }
        }, 1000);

        setTimeout(() => {
            //setAnswerInProp(false)
            //setInProp(false)
            e.target.blur() //desactive le hover
            let quest: number = questStep
            quest++
            console.warn(selected)
            setAnswer((arr: Array<Answer>) => [...arr, selected])
            //setSelected([])
            setQuestStep(quest)
        }, 1500);
    }

    //gère l'UI du systeme de selection
    useEffect(() => {
        let flag: boolean = false
        if (document.getElementById('QUEST_CONTAINER') && (questStep === Quest.contourYeux || questStep === Quest.preoccupationsPrincipales || questStep === Quest.exposomes)) {
            let childs = document.getElementById('QUEST_CONTAINER')!.children

            if (selected.length > setNumberOfAnswer(questStep)) { // setNumberOfAnswer(questStep)
                let array: Array<number> = [...selected]
                array.shift()
                //setSelected(array)
                setSelected(array)
            }

            for (let i = 0; i < selected.length; i++) {
                for (let x = 0; x < selected.length; x++) {
                    if (selected[i] === selected[x] && i !== x) {
                        //let newArray: Array<any> = selected.filter((value: any, index: any, self:any) => { return self.indexOf(value) === index}) //pour  avoir un distinct
                        //check si on a deja cliqué
                        let newArray: Array<any> = selected.filter((e: any) => e !== selected[i])
                        flag = true
                        setSelected(newArray)
                    }
                }
            }

            if (selected.length > 0 && (questStep === Quest.exposomes) && !flag) {
                //setSelectedByDefault([])
                setClickAnimation(questStep, selected[selected.length - 1])
            }

            for (let i = 0; i < childs.length; i++) {
                if (!childs[i].getAttribute("id")) {
                    break
                }
                if (selected.includes(childs[i].getAttribute("id")) || (selectedByDefault.includes(childs[i].getAttribute("id")) && selected.length === 0)) {

                    if (questStep === Quest.exposomes) {

                        document.getElementById(childs[i].getAttribute("id")!)!.style.backgroundColor = 'white'
                        document.getElementById(childs[i].getAttribute("id")!)!.style.color = 'black'
                    }
                    else {
                        document.getElementById(childs[i].getAttribute("id")!)!.style.backgroundColor = ''
                        document.getElementById(childs[i].getAttribute("id")!)!.style.color = ''
                    }

                    if (questStep === Quest.preoccupationsPrincipales || questStep === Quest.contourYeux) {
                        document.getElementById(childs[i].getAttribute("id")!)!.style.border = 'none'
                        document.getElementById(childs[i].getAttribute("id")!)!.style.backgroundColor = 'white'
                        document.getElementById(childs[i].getAttribute("id")!)!.style.color = 'black'
                    }

                    if (questStep === Quest.preoccupationsPrincipales) {
                        if (selected.length >= 2) {
                            document.getElementById("PPNEXT_BUTTON")!.style.backgroundColor = "black"
                        } else {
                            document.getElementById("PPNEXT_BUTTON")!.style.backgroundColor = "white"
                        }
                    }

                }
                else {
                    if (questStep === Quest.preoccupationsPrincipales) {
                        document.getElementById("NumberRound_" + childs[i].getAttribute("id")!)!.style.opacity = "0"
                    }
                    document.getElementById(childs[i].getAttribute("id")!)!.style.backgroundColor = ''
                    document.getElementById(childs[i].getAttribute("id")!)!.style.color = ''
                }

                if (selected[selected.length - 1] !== childs[i].getAttribute("id") && (questStep === Quest.contourYeux || questStep === Quest.exposomes)) {
                    document.getElementById(childs[i].getAttribute("id")!)!.style.transform = ''
                }
            }

            // Gère l'ajout des Ronds avec les numéros d'ordre
            if (questStep === Quest.preoccupationsPrincipales) {

                for (let i = 0; i < selected.length; i++) {
                    document.getElementById("NumberRound_" + selected[i])!.style.opacity = "1"
                    document.getElementById("NumberRound_" + selected[i])!.innerHTML = (i + 1).toString()
                }

                if (selected.length >= 2) {
                    document.getElementById("PPNEXT_BUTTON")!.style.backgroundColor = "black"
                    document.getElementById("PPNEXT_BUTTON")!.style.pointerEvents = "all"
                } else {
                    document.getElementById("PPNEXT_BUTTON")!.style.backgroundColor = "rgba(0,0,0,0.3)"
                    document.getElementById("PPNEXT_BUTTON")!.style.pointerEvents = "none"
                }
            }
        }
        //eslint-disable-next-line
    }, [selected, selectedByDefault])

    useEffect(() => {

        //enable question button click
        if (document.getElementById("NEXT_QUESTION")) {
            let button = document.getElementById("NEXT_QUESTION")
            button!.style.pointerEvents = "all"
            button!.style.opacity = "0.5 !important"
        }

        //eslint-disable-next-line
        if (document.getElementById("NEXT_QUESTION")) {
            document.getElementById("NEXT_QUESTION")!.style.animation = ''
            document.getElementById("NEXT_QUESTION")!.style.opacity = ''
        }

        if (document.getElementById("UNDER_TEXT")) {
            document.getElementById("UNDER_TEXT")!.style.animation = ''
        }

        //eslint-disable-next-line
        let trick = document.getElementById("APP")!.offsetHeight //trick pour restart l'animation
        setBackgroundImage(<BackgroundImage questStep={questStep} picture={setbackgroundSRC(questStep)} />)
        setInProp(true)
        if (questStep === Object.keys(Quest).length / 2 - 1) {
            let url = new URL(window.location.href)
            if (url.searchParams.get("withUI") === "false") {
                dispatch({ type: ActionTypes.SET_STEP, value: 4 })
            } else {
                setTimeout(() => {
                    document.getElementById("APP")!.style.backgroundColor = "white"
                }, 500);
                dispatch({ type: ActionTypes.SET_STEP, value: 5 })
            }
            dispatch({ type: ActionTypes.SET_QUESTION, value: answer })
        }
        if (setSelectionnable(questStep) || setOrder(questStep)) {
            //window.enableScrolling()
        }
        else {
            //window.disableScrolling()
        }
        let staticAnswers = setAnswers(questStep)
        let _sortedStaticAnswers: string[] = []

        let sortedAnalysis = [...analyses].sort((a: any, b: any) => {
            return b.score - a.score
        })

        let elementTab: any = []
        let key: number = 0

        const checkIfStrengthDisplayed = (index: number, length: number, eyesConcerns: boolean = false): boolean => {
            if (window.ASIAN_LANGS.includes(i18n.language)) {
                if (index === length - 1) return true
                if (index === length - 2 && !eyesConcerns) return true
            }

            return false
        }

        const checkIfPriorityDisplayed = (index: number, eyesConcerns: boolean = false): boolean => {

            if (index === 0) return true
            if (index === 1 && !eyesConcerns) return true

            return false
        }

        switch (questStep) {
            case Quest.exposomes:
            case Quest.manqueVitalite:
            case Quest.routineNuit:
            case Quest.preferenceDemaquillage:
            case Quest.peau:
                staticAnswers.forEach((e) => {
                    elementTab.push(
                        <QuestBubbleComponent isSelected={selectedByDefault.includes(e)} questStep={questStep} idAnswer={key} e={e} key={key} />
                    )
                    key++
                })

                break;
            case Quest.preferenceDemaquillage2:
                staticAnswers.forEach((e) => {
                    elementTab.push(
                        <QuestButtonComponent idAnswer={key} id={e} e={e} key={key} />
                    )
                    key++
                })
                break;
            case Quest.contourYeux:
                let eyeAnalysis = [...sortedAnalysis].filter((e: any) => e.name === "DARK_CIRCLES" || e.name === "EYES_BAGS" || e.name === "LOSS_OF_FIRMNESS" || e.name === "WRINKLES")
                _sortedStaticAnswers = []

                eyeAnalysis.forEach((analyse) => {
                    _sortedStaticAnswers.push(analyseToAnswer(analyse.name, true))
                })

                _sortedStaticAnswers.splice(2, 0, "CYridules")

                _sortedStaticAnswers.forEach((e, index, array) => {
                    elementTab.push(
                        <PPButton onClick={(event) => nextStep(e, event)} isSelected={priorityAnswer.includes(e)} id={e} key={key}>{setAnswerTranslation(e, t)}
                            {checkIfStrengthDisplayed(index, array.length, true) && <PriorityContainer>
                                {t('questionView.strength')} {window.ASIAN_LANGS.includes(i18n.language) && (array.length - index)}
                            </PriorityContainer>}

                            {checkIfPriorityDisplayed(index, true) && <PriorityContainer>
                                {t('questionView.priority')} {index + 1}
                            </PriorityContainer>}
                        </PPButton>
                    )
                    key++
                })

                break;

            case Quest.preoccupationsPrincipales:

                let ppAnalysis = [...sortedAnalysis].filter((e: any) => e.name === "SHININESS" || e.name === "WRINKLES" || e.name === "DARK_SPOTS" || e.name === "LOSS_OF_FIRMNESS" || e.name === "RADIANCE" || e.name === "ROUGHNESS")

                ppAnalysis.forEach((analyse) => {
                    _sortedStaticAnswers.push(analyseToAnswer(analyse.name))
                })

                // On ajoute les préoccupations qui n'ont pas de score au "milieu"
                _sortedStaticAnswers.splice(3, 0, "PPtracesResiduelles")
                _sortedStaticAnswers.splice(3, 0, "PPsensibilite")
                _sortedStaticAnswers.splice(3, 0, "PPdeshydratation")
                _sortedStaticAnswers.splice(3, 0, "PPimperfections")

                if (window.ASIAN_LANGS.includes(i18n.language)) {
                    _sortedStaticAnswers.splice(3, 0, "PPpores")
                } else {
                    _sortedStaticAnswers.splice(3, 0, "PPsignesFatigue")
                }

                _sortedStaticAnswers.forEach((e, index, array) => {
                    elementTab.push(
                        <PPButton onClick={(event) => nextStep(e, event)} isSelected={priorityAnswer.includes(e)} id={e} key={key}>{setAnswerTranslation(e, t)}
                            {checkIfStrengthDisplayed(index, array.length) && <PriorityContainer>
                                {t('questionView.strength')} {window.ASIAN_LANGS.includes(i18n.language) && (array.length - index)}
                            </PriorityContainer>}

                            {checkIfPriorityDisplayed(index) && <PriorityContainer>
                                {t('questionView.priority')} {index + 1}
                            </PriorityContainer>}
                            <PPNumberRound id={"NumberRound_" + e}>
                            </PPNumberRound>
                        </PPButton>
                    )
                    key++
                })

                break;
        }

        setAnswerButtons(elementTab)

        //eslint-disable-next-line
    }, [questStep, selectedByDefault, strengthAnswer, priorityAnswer])

    useEffect(() => {

        setTimeout(() => {
            let containerTab: any = []
            if (questStep === Quest.preferenceDemaquillage2) {
                return;
            }
            else if (questStep === Quest.preoccupationsPrincipales) {
                containerTab.push(<PPComponent nextStep={nextStepForPPHandler} answers={answerButtons} key={20}></PPComponent>)
                setAnswersContainer(containerTab)
            } else
                if (questStep === Quest.contourYeux) {
                    containerTab.push(<PPContainer eyeContainer={true} enabled={true} id="QUEST_CONTAINER" key={20}>{answerButtons}</PPContainer>)
                    setAnswersContainer(containerTab)
                } else {
                    containerTab.push(<QuestContainerBubbles top={document.getElementById("TITLE_CONTAINER")!.getBoundingClientRect().height} id="QUEST_CONTAINER" questStep={questStep} key={20}>{answerButtons}</QuestContainerBubbles>)
                    setAnswersContainer(containerTab)
                }
        }, 200);

        //eslint-disable-next-line
    }, [answerButtons])

    //vide les questions selectionnees a chaque question et calcule les reponses recommandées
    useEffect(() => {
        console.log(answer)
        let questTab: any = []
        let keyQuest: number = 0
        setQuestText([])
        displayCurtain(null)
        questTab.push(<QuestTitleComponent key={100} keyQuest={keyQuest} />)
        keyQuest++
        setQuestText(questTab)

        // Cas pour l'Asie car il n'y a pas de démaquillant
        if (questStep === Quest.preferenceDemaquillage2 && window.ASIAN_LANGS.includes(i18n.language)) {
            let quest = questStep
            quest += 2
            answer.push('tweak')
            answer.push('tweak')
            setQuestStep(quest)
        }

        if (questStep === Quest.manqueVitalite
            && !answer.flat().includes(Answer.PPperteFermete)
            && !answer.flat().includes(Answer.PPrides)
            && !answer.flat().includes(Answer.CYrides)
            && !answer.flat().includes(Answer.CYrelachement)) {
            let quest = questStep
            quest += 2
            answer.push('tweak')
            answer.push('tweak')
            setQuestStep(quest)
        }

        if (questStep === Quest.routineNuit && answer.flat().includes(Answer.MVoui)) {
            let quest = questStep
            quest++
            answer.push(Answer.SDNnon)
            setQuestStep(quest)
        }
        if (questStep !== Quest.contourYeux) {
            setSelected([])
        } else {
            setAnswer((arr: Array<Answer>) => [...arr, selected])
            sendAnalytics("question_" + 1)
            setSelected([])
        }
        setAnswersContainer([])
        //setRecommandedAnswerState(setRecommendedAnswer(questStep, analyses))

        setInProp(true)
        //eslint-disable-next-line
    }, [questStep])

    useEffect(() => {
        if (questStep === Quest.contourYeux || questStep === Quest.preoccupationsPrincipales) {
            setStrengthAnswerState(setStrengthAnswer(questStep, analyses))
            setPriorityAnswerState(setPriorityAnswers(questStep, analyses))
        }
    }, [analyses, questStep]) //j'ai changé ici.

    useEffect(() => {

        setTimeout(() => {
            runHeightMachine()
        }, 300)

        let data2 = {
            messageType: "toggle_fullscreen",
            value: true
        };

        console.log("[QUESTVIEW] Full screen true !")
        window.parent.postMessage(data2, "*")

        let dataBis = {
            messageType: "scroll_top",
        };
        console.log(dataBis)
        window.parent.postMessage(dataBis, "*")

        dispatch({ type: ActionTypes.SET_QUESTION, value: [] })

    }, [dispatch])

    const QuestBubbleComponent = (props: any) => {
        useEffect(() => {
            if (strengthAnswer.includes(props.e)) {
                setClickAnimation(questStep, props.e)
            }
        }, [props.e])

        return (
            <QuestBubble dangerouslySetInnerHTML={{ __html: setAnswerTranslation(props.e, t) }} questStep={props.questStep} id={props.e} idAnswer={props.idAnswer} onClick={(event) => nextStep(props.e, event)} isSelected={props.isSelected}>

            </QuestBubble>
        )
    }

    const QuestTitleComponent = (props: any) => {
        useEffect(() => {
            // Lance L'animation du titre (avec la bar et le petit texte)
            document.getElementById("APP")!.style.minHeight = "100%"
            document.getElementById("APP")!.style.position = ""
            launchStepAnimation()
        }, [])

        const questNumberForAsia = (questStep: Quest) => {
            switch (questStep) {
                case Quest.manqueVitalite:
                    return 4
                case Quest.routineNuit:
                    return 5
                case Quest.exposomes:
                    return 6
                default:
                    return questStep
            }
        }

        return (
            <QuestTitleContainer id="TITLE_CONTAINER" questStep={questStep} >
                <ProgressBarContainer id={"PROGRESS_CONTAINER"}>
                    <H1 style={{ margin: '8px' }}>{window.ASIAN_LANGS.includes(i18n.language) ? questNumberForAsia(questStep) : questStep}</H1>
                    <ProgressBar id="PROGRESS_BAR" />
                    <H1 style={{ margin: '8px', fontWeight: 300 }}>{window.ASIAN_LANGS.includes(i18n.language) ? 6 : 8}</H1>
                </ProgressBarContainer>
                <QuestTitle questStep={questStep} key={props.keyQuest}>{setQuest(questStep, t)}</QuestTitle>
                {(questStep === Quest.contourYeux || questStep === Quest.preoccupationsPrincipales) && <UnderQuestTitle questStep={questStep} id="UNDER_TEXT">{questStep === Quest.contourYeux ? t("questionView.question.AITextCY") : t("questionView.question.AIText")}</UnderQuestTitle>}
                {questStep === Quest.exposomes && <UnderQuestTitle questStep={questStep} id="UNDER_TEXT">{t("questionView.question.AITextExposomes")}</UnderQuestTitle>}

            </QuestTitleContainer>
        )
    }

    const QuestButtonComponent = (props: any) => {
        return (
            <QuestButton id={props.e} idAnswer={props.idAnswer} onClick={(event) => nextStep(props.e, event)} >
                {props.idAnswer === 0 && <RoundQuestButton id={"RQ" + props.e} idAnswer={props.idAnswer} >
                    <img style={{ transform: window.RTL_LANGS.includes(i18n.language) ? "" : "rotate(90deg)", paddingBottom: "2px", filter: "brightness(0)", height: '7px' }} src={arrow} alt="Arrow" />
                </RoundQuestButton>}
                {setAnswerTranslation(props.e, t)}
                {props.idAnswer === 1 && <RoundQuestButton id={"RQ" + props.e} idAnswer={props.idAnswer} >
                    <img style={{ transform: window.RTL_LANGS.includes(i18n.language) ? "" : "rotate(90deg)", paddingBottom: "2px", filter: "brightness(0)", height: '7px' }} src={arrow} alt="Arrow" />
                </RoundQuestButton>}
            </QuestButton>
        )
    }

    useEffect(() => {
        if (!selected.includes("femme")) {
            let selectedItem = "femme"
            setAnswer((arr: Array<Answer>) => [...arr, selectedItem])
        }

        // eslint-disable-next-line
    }, [])

    const setReady = () => {
        if (questStep === Quest.preoccupationsPrincipales) {
            if (selected.length === 2) {
                return true
            } else {
                return false
            }
        }
        if (selected.length === setNumberOfAnswer(questStep)) {
            return true
        }
        if (selectedByDefault.length === setNumberOfAnswer(questStep) && selected.length === 0) {
            return true
        }

        return false
    }

    const backStep = () => {
        setSelected([])
        displayCurtain(<Curtain />)
        setTimeout(() => {
            let answerTab = answer
            let quest: number = questStep
            if (questStep === Quest.exposomes) {
                if (answer.includes(Answer.SDNnon) && answer.includes(Answer.MVoui)) {
                    quest -= 2
                    answerTab.pop()
                }
                else if (answer.includes(Answer.SDNnon) || answer.includes(Answer.SDNoui)) {
                    quest--
                } else if (answer.includes(Answer.MVnon) || answer.includes(Answer.MVoui)) {
                    quest -= 2
                    answerTab.pop()
                } else {
                    quest -= 3
                    answerTab.pop()
                }
            } else {
                quest--
            }
            answerTab.pop()
            setAnswer(answerTab)

            setTimeout(() => {
                setQuestStep(quest)
            }, 500);
        }, 1000);
    }

    const asiaBackStep = () => {
        setSelected([])
        displayCurtain(<Curtain />)
        setTimeout(() => {
            let answerTab = answer
            let quest: number = questStep
            if (questStep === Quest.exposomes) {
                if (answer.includes(Answer.SDNnon) && answer.includes(Answer.MVoui)) {
                    quest -= 2
                    answerTab.pop()
                }
                else if (answer.includes(Answer.SDNnon) || answer.includes(Answer.SDNoui)) {
                    quest--
                } else {
                    quest = Quest.peau
                    answerTab.pop()
                    answerTab.pop()
                    answerTab.pop()
                    answerTab.pop()
                }
            }
            else if (questStep === Quest.manqueVitalite) {
                quest = Quest.peau
                answer.pop()
            }
            else if (questStep === Quest.routineNuit) {
                quest = Quest.manqueVitalite
            }
            else {
                quest--
            }
            answerTab.pop()
            setAnswer(answerTab)

            setTimeout(() => {
                setQuestStep(quest)
            }, 500);
        }, 1000);
    }

    const nextStepForPPHandler = () => {
        //sendAnalytics("question_" + 1)

        //prevent multiple click
        if (document.getElementById("NEXT_QUESTION")) {
            let button = document.getElementById("NEXT_QUESTION")
            button!.style.pointerEvents = "none"
            document.getElementById("NEXT_QUESTION")!.style.animation = "Unfading 1s"
        }

        questStep === Quest.preoccupationsPrincipales && setTimeout(() => {
            document.getElementById("APP")!.style.backgroundColor = "#E2E2E2"
        }, 500);
        //document.getElementById("NEXT_QUESTION")!.style.animation = "Unfading 1s"

        setInProp(false)

        setTimeout(() => {
            let quest: number = questStep
            quest++

            setQuestStep(quest)
        }, 500);
        return

    }

    return (
        <CSSTransition style={{ overflow: questStep === Quest.preoccupationsPrincipales && !isDesktop ? "auto" : "" }} in={inProp} classNames="fadeIn" addEndListener={() => null}>
            <QuestsViewContainer>

                {questText}
                {answersContainer}
                {answersContainer.length === 0 && answerButtons}

                {questStep !== Quest.preoccupationsPrincipales && <NextQuestionContainer id="NEXT_QUESTION">
                    <MainButton onClick={() => window.ASIAN_LANGS.includes(i18n.language) ? asiaBackStep() : backStep()} style={{ width: '42px', margin: '0px 4px' }}>
                        <img style={{ transform: window.RTL_LANGS.includes(i18n.language) ? "rotate(90deg)" : "rotate(-90deg)", width: "18px", height: "18px", paddingBottom: "2px" }} src={arrow} alt="Retour" />
                    </MainButton>
                    {questStep === Quest.peau && <HelpSkinButton style={{ fontSize: window.ASIAN_LANGS.includes(i18n.language) ? "18px" : '' }} onClick={() => setHelpProp(true)}>
                        {t('questionView.help', 'Besoin d\'aide ?')}
                    </HelpSkinButton>}
                    {setSelectionnable(questStep) && questStep !== Quest.exposomes && <NextQuestion onClick={(e) => nextStepWithMultipleChoices(e)} isReady={setReady()}>{t('questionView.next', 'Question suivante').toUpperCase()}</NextQuestion>}
                    {setSelectionnable(questStep) && questStep === Quest.exposomes && <NextQuestion onClick={(e) => nextStepWithMultipleChoices(e)} isReady={true}>{t('questionView.seeResults').toUpperCase()}</NextQuestion>}
                </NextQuestionContainer>}
                {curtain}
                {backgroundImage}
                {displayHelpPopUp()}

            </QuestsViewContainer>
        </CSSTransition>
    )
}

const QuestsViewComponent = () => {
    return <QuestsView />
}

export default QuestsViewComponent