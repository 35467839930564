import { useSelector } from "react-redux";
import { MySkinDiagState } from '../type';
import OnBoardingComponent from "./OnBoardingView";
import WaitingViewComponent from './WaitingView';
import QuestsViewComponent from './QuestsView';
import AnalyseMaker from './AnalysesMaker'
import OldQuestView from '../Components/OldQuestsView'
import NewCameraView from './CameraView'
import RecoViewComponent from './ResultView/RecoView/RecoView'
import ResultsViewComponent from "./ResultView/ResultsView";
import DesktopViewComponent from './DesktopView'
import TryOnComponent from './Try-On/TryOnIndex'
import VideoViewComponent from './VideoTutoView'
import ScoreViewComponent from './ScoreView'
import CameraErrorComponent from './CameraPermView'
import { PlayGroundTest } from "./Try-On/PlayGroundTest";
import OTSTSCErrorComponent from "./OTSTSCErrorView"
import NewHomePageComponent from "./NewHomePage";

const ViewDispatcher = () => {
    const step = useSelector((state: MySkinDiagState) => state.session.step)

    switch (step) {
        case -1:
            return (NewHomePageComponent())
        case 0:
            return (OnBoardingComponent())
        case 1:
            return (NewCameraView())
        case 2:
            return (WaitingViewComponent())
        case 21:
            return (ScoreViewComponent())
        case 3:
            return (QuestsViewComponent())
        case 5:
            return (ResultsViewComponent())
        case 51:
            return (RecoViewComponent())
        case 6:
            return (AnalyseMaker())
        case 7:
            return (OldQuestView())
        case 8:
            return (DesktopViewComponent())
        case 10:
            return (TryOnComponent())
        case 11:
            return (VideoViewComponent())
        case 12:
            return (CameraErrorComponent())
        case 13: 
            return (PlayGroundTest())
        case 14:
            return OTSTSCErrorComponent()
        default:
            return (OnBoardingComponent())
    }
}

export default ViewDispatcher