import styled from 'styled-components'
import { H4, ParagraphText } from '../Styles/Texts'
import { BridgeButtonsContainer, ComponentContainer } from '../Styles/Containers'
import { MySkinDiagState } from '../type';
import { useTranslation } from 'react-i18next'
import { CloseButton, MainButton } from '../Styles/Buttons'
import { SisleyFlower, WaitingBarEmpty, WaitingBarFilling, OKIcon, FaceIcon } from '../Styles/Images'
import { useSelector, useDispatch } from 'react-redux'
import * as Actiontypes from '../Constants/ActionsTypes';
import { useEffect, useRef } from 'react';
import { CSSTransition } from 'react-transition-group'
import { useState } from 'react'

import SisleyIcon from '../Assets/Icons/SISLEY.svg'
import closeIcon from '../Assets/Icons/Close_B.svg'
import FACE_ICON from '../Assets/gif/Face_scan.gif'
import ConfirmPopUp from './ConfirmPopUp';
import { sendAnalytics } from '../Constants/Utilitaries';
import i18n from '../i18nextInit';
import { setBridgeValue } from '../API/firebase/database';

const WaitingText = styled(ParagraphText)`
    position: relative;
    font: normal normal 500 16px/24px Playfair;
    padding: 32px 24px 24px;

    @media (min-width: 768px) {
        font-size: 18px;
        padding: 42px 24px 28px;
    }
`;

const WaitingView = () => {
    const bridge = useSelector((state: MySkinDiagState) => state.session.bridge)
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const analyses = useSelector((state: MySkinDiagState) => state.session.analyses)
    //eslint-disable-next-line
    const [inProp, setInProp] = useState<boolean>(false)
    // const [loadingProp, setLoadingProp] = useState<boolean>(false)
    // const [okProp, setOkProp] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [isDesktopWaiting, setDesktopWaiting] = useState<boolean>(false)
    const [isBridgeButtonContainerActive, setBridgeButtonContainerActive] = useState<boolean>(false)

    const [isShowingBridgeButton, setIsShowingBridgeButton] = useState<boolean>(false)
    const [isButtonsShown, showButtons] = useState<boolean>(false)
    const [closeIn, setCloseIn] = useState<boolean>(false)
    const waitingTextRef = useRef<any>(null)
    const container = useRef<HTMLDivElement>(null)
    const animTimeOut = useRef<any>(null)

    const timeout1 = useRef<any>(null)
    const timeout2 = useRef<any>(null)
    const timeout3 = useRef<any>(null)

    useEffect(() => {
        setInProp(true)
        container.current!.style.backgroundColor = "#fcfcff"

        animTimeOut.current = setTimeout(() => {
            document.getElementById("LOADING")!.style.opacity = "0"
            setTimeout(() => {
                document.getElementById("APP")!.style.justifyContent = "center"

                setIsLoading(false)
                // document.getElementById("LOADING_OK")!.style.opacity = "1"

                setTimeout(() => {
                    if (bridge) {
                        if (analyses.length > 0) {
                            setIsShowingBridgeButton(true)
                            
                        }
                    }
                    setTimeout(() => {
                        setBridgeButtonContainerActive(true)
                    }, 100);

                    showButtons(true)

                }, 700);

            }, 500);
        }, 120000);

        return (() => {
            document.getElementById("APP")!.style.justifyContent = ""
            clearTimeout(animTimeOut.current)
        })
        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        timeout1.current = setTimeout(() => {
            waitingTextRef.current.innerHTML = t('waitingView.content2', 'Votre routine sera adaptée non seulement à l\'analyse de l\'IA, mais aussi à vos préférences personnelles.')
        }, 3000);

        timeout2.current = setTimeout(() => {
            waitingTextRef.current.innerHTML = t('waitingView.content3', 'Votre routine sera adaptée non seulement à l\'analyse de l\'IA, mais aussi à vos préférences personnelles.')
        }, 6000);

        timeout3.current = setTimeout(() => {
            waitingTextRef.current.innerHTML = t('waitingView.content1', 'Votre routine sera adaptée non seulement à l\'analyse de l\'IA, mais aussi à vos préférences personnelles.')
        }, 9000);

        return () => {
            clearTimeout(timeout1.current)
            clearTimeout(timeout2.current)
            clearTimeout(timeout3.current)
        }
    }, [t])

    useEffect(() => {

        if (analyses.length > 2) {
            clearTimeout(animTimeOut.current)

            clearTimeout(timeout1.current)
            clearTimeout(timeout2.current)
            clearTimeout(timeout3.current)
            container.current!.style.backgroundColor = "initial"

            waitingTextRef.current.innerHTML = ""

            document.getElementById("LOADING")!.style.opacity = "0"
            setTimeout(() => {
                document.getElementById("APP")!.style.justifyContent = "center"

                setIsLoading(false)
                // document.getElementById("LOADING_OK")!.style.opacity = "1"

                setTimeout(() => {
                    console.warn(bridge)
                    if (bridge) {
                        if (analyses.length > 0) {
                            setIsShowingBridgeButton(true)
                        }
                    }
                    
                    setTimeout(() => {
                        setBridgeButtonContainerActive(true)
                    }, 100);

                    showButtons(true)

                }, 700);

            }, 700);
        }

        if (bridge) {
            if (analyses.length > 0) {
                setIsShowingBridgeButton(true)
            }
        }

        return (() => {
            document.getElementById("APP")!.style.justifyContent = ""
        })

        //eslint-disable-next-line
    }, [analyses])

    const goToQuests = () => {
        setInProp(false)
        sendAnalytics("start_2pic")

        setTimeout(() => {
            dispatch({ type: Actiontypes.SET_STEP, value: 21 })
        }, 500);
    }

    const WaitingContentText = styled.p`
        margin: 0px;
        font: normal normal 500 13px/23px Helvetica;
        padding: 24px;
        text-align: center;
        animation: WaitingText 3s infinite;
        animation-delay: 1.5s;
        min-height: 130px;

        @media (min-width: 768px) {
            padding: 24px 72px;
            min-height: 120px;
            font-size: 16px;
        }
    `

    const displayClosePopUp = () => {
        if (closeIn) {
            return (<div style={{ width: "100vw", height: "100vh", top: 0, position: "fixed", zIndex: 10 }}>
                <ConfirmPopUp key={0} setOptIn={setCloseIn} />
            </div>)
        } else {
            return <div />
        }
    }

    return (
        <CSSTransition in={inProp} classNames="fadeIn" addEndListener={() => null}>
            <ComponentContainer ref={container} style={{ position: "fixed" }}>
                <CloseButton onClick={() => setCloseIn(true)} src={closeIcon} alt="close">
                </CloseButton>

                {isLoading ?
                    <LoadingContainer id="LOADING">
                        <FaceIcon src={FACE_ICON} />
                        <WaitingText style={{ fontSize: i18n.language === "tw_TW" ? "20px" : '' }}>
                            {t('waitingView.analysing', 'Votre visage est en cours d\'analyses')}
                        </WaitingText>
                        <WaitingBarEmpty>
                            <WaitingBarFilling time={analyses.length > 2 ? 3 : 15} />
                        </WaitingBarEmpty>
                        <WaitingContentText style={{ fontSize: i18n.language === "tw_TW" ? "20px" : '' }} ref={waitingTextRef}>
                            {t('waitingView.content1', 'Une fois l\'analyse terminée, nous vous poserons quelques questions pour en savoir plus sur vos habitudes et vos préoccupations en matière de soins de la peau.')}
                        </WaitingContentText>
                        <SisleyFlower />

                    </LoadingContainer>
                    : !isDesktopWaiting ?
                        <LoadingOKContainer id="LOADING_OK">
                            <OKIcon />
                            <WaitingText style={{ color: "#648A82", font: "normal normal 500 11px/12px Helvetica Bold", fontSize: i18n.language === "tw_TW" ? "14px" : '', textTransform: "uppercase" }}>{t('waitingView.perfect', 'VOTRE PHOTO EST PARFAITE !')}</WaitingText>

                            <H4 style={{ margin: "0px" }}>{t('waitingView.continueQuest', 'Complétez le questionnaire pour que nous puissions répondre au mieux aux besoins de votre peau.')}</H4>
                            <H4>{t('waitingView.completeQuests', 'Complétez le questionnaire pour que nous puissions répondre au mieux aux besoins de votre peau.')}</H4>
                        </LoadingOKContainer> :
                        <div id="DESKTOP_OK" style={{ transition: "opacity 0.7s", opacity: 0, display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <img alt="Sisley Icon" style={{ width: "150px", marginBottom: "12px" }} src={SisleyIcon} />
                            <OKIcon />
                            <H4>{t('waitingView.dataOnPC', 'Vos données ont été transmises sur votre PC, vous pouvez fermer cette page.')}</H4>
                        </div>
                }

                {isButtonsShown &&
                    <BridgeButtonsContainer isActive={isBridgeButtonContainerActive}>
                        {isShowingBridgeButton && <MainButton onClick={() => {
                            setDesktopWaiting(true)
                            setIsLoading(false)

                            setTimeout(() => {
                                document.getElementById("DESKTOP_OK")!.style.opacity = "1"
                                setBridgeButtonContainerActive(false)
                            }, 700);

                            setBridgeValue(bridge, {
                                status: 'DONE',
                                analysisResults: analyses,
                            })
                        }}
                            style={{ opacity: 0, margin: '12px', backgroundColor: 'white', border: 'black 1px solid', color: 'black', animation: "Fading 0.7s Forwards" }}>{t('waitingView.onDesktop', 'POURSUIVRE SUR ORDINATEUR').toUpperCase()}</MainButton>}
                        <MainButton style={{ fontSize: i18n.language === "tw_TW" ? "14px" : '12px' }} onClick={() => goToQuests()}>{isShowingBridgeButton ? t('waitingView.onMobile', 'POURSUIVRE SUR MOBILE').toUpperCase() : t('waitingView.continue', 'POURSUIVRE').toUpperCase()}</MainButton>
                    </BridgeButtonsContainer>
                }

                {displayClosePopUp()}
            </ComponentContainer>
        </CSSTransition >
    )
}


const LoadingContainer = styled.div`
    display: flex;
    position: relative;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    transition: all 0.7s;
    height: 100vh;

    @media (min-width: 768px){
        
    }
`

const LoadingOKContainer = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    transition: all 0.7s;

    @media (min-width: 768px){
        margin-bottom: 200px;
    }
`

const WaitingViewComponent = () => {
    return <WaitingView />
}

export default WaitingViewComponent