import { beautyKit2Loader, getImageCapture } from './BeautyKit2Loader.js'
import { Layer, Shapetype, Effect } from './Types'

declare global {
    interface HTMLCanvasElement {
        captureStream(frameRate?: number): MediaStream;
    }

    interface Window {
        dataLayer: Record<string, any>[];
    }

}

class BeautyKit2 {

    private _bk2Module: any = null
    _bk2CPP: any = null

    resolution = this.resByDeviceCategory();
    score = 0;
    framerate = (this.resolution === 720) ? 1000 / 12 : 1000 / 10;
    private _drawingLoop: ReturnType<typeof setInterval> | null = null
    private _isWASMReady = 0; // 0: not rea
    private _videoTrack: MediaStreamTrack | null = null

    // constructor(video:string,rendering:string){
    //     this._videoCanvas = video
    //     this._renderingCanvas = rendering
    // }

    resByDeviceCategory() {
        var screenRes = window.devicePixelRatio * window.devicePixelRatio * window.screen.availHeight * window.screen.availWidth;
        var ratio = window.screen.availHeight / window.screen.availWidth
        if (["iPad", "iPad Simulator", "MacIntel"].includes(navigator.platform)) {
            if (window.innerHeight > 2300 || window.innerWidth > 1000) {
                return 720;
            }
            else {
                return 480;
            }
        }
        if ((ratio > 2 && ["iPhone", "iPhone Simulator"].includes(navigator.platform)) || (screenRes > 2500000 && window.innerHeight > 700) || window.innerWidth > 768) {
            return 720;
        }
        else {
            if (screenRes > 1400000) {
                return 480;
            }
            else {
                return 360;
            }
        }
    }

    load() {
        return new Promise<void>((resolve, reject) => {
            console.log(((window.devicePixelRatio > 2.8 && window.innerWidth > 380) || window.innerWidth > 768) ? "Preset High" : "Preset Low");
            if (this._isWASMReady !== 0) {
                if (this._bk2Module) {
                    console.log(`[BK2] Already Setup!`)
                    resolve()
                } else {
                    console.error(`[BK2] Already Setup but module not working`)
                    return
                }

            }
            console.log(`[BK2] Start BeautyKit 2 v2`)
            const imgcapt = document.createElement("script");
            imgcapt.src = "./image-capture.js";
            imgcapt.async = true;

            document.body.appendChild(imgcapt);

            const script = document.createElement("script");
            script.src = "./BeautyKit2.js";
            script.async = true;

            document.body.appendChild(script);

            // document.body.appendChild(script);
            imgcapt.onload = () => {
                console.log(`[BK2] Image Capture Loaded as Class `)
                script.onload = () => {
                    console.log(`[BK2] BeautyKit 2 Loaded `)
                    beautyKit2Loader().then((module) => {
                        console.log(`[BK2] BeautyKit 2 Fully Loaded `)
                        this._bk2Module = module

                        console.log(`[BK2] BeautyKit 2 Module Loaded `)


                        const ppixels = this._bk2Module._malloc(this.resolution * this.resolution * 4);
                        this._bk2Module.pixels = new Uint8ClampedArray(this._bk2Module.HEAPU8.buffer, ppixels, this.resolution * this.resolution * 4);
                        this._isWASMReady = 1
                        resolve()
                        //resolve()
                        //this.start()
                    })
                        .catch((error: any) => {
                            console.error(`[BK2] BeautyKit 2 Load FAILED!`)
                            reject(error)
                        });
                }
            }


        })

    }



    initCamera(facingMode: Boolean) {
        return new Promise((resolve, reject) => {
            const constraints = {
                video: {
                    // video resolution : doesn't seem to work under a width of 1000px
                    width: { ideal: this.resolution },
                    height: { ideal: this.resolution },
                    facingMode: facingMode ? "user" : "environment",
                },
                audio: false
            }

            navigator.mediaDevices.getUserMedia(constraints)
                .then(mediaStream => {
                    console.log(`[BK2] Camera Ok `)
                    if (this._videoTrack === null) {
                        const track = mediaStream.getVideoTracks()[0];

                        // track.addEventListener("mute", (event) => {

                        //         console.warn("Muted Changed"

                        // )}, false);
                        this._videoTrack = track
                    }

                    let imageCapture = getImageCapture(this._videoTrack)

                    resolve(imageCapture)

                })
                .catch((error: any) => {
                    console.error("[BK2] Camera Access Failed")
                    console.error(error)
                    reject(error)
                });
        })
    }

    /*stop(){
        return new Promise<void>((resolve,reject) => {
        clearInterval(this._drawingLoop!)
        //this._bk2CPP = null
        
        var constraints = { video: true }; 
console.log("STOP CAMERA")
        navigator.mediaDevices.getUserMedia(constraints)
        .then(function(mediaStream) {
          mediaStream.getVideoTracks().forEach(function(track) {
              track.stop();
              console.log("STOPING CAMERA " + track.id)
              resolve()
          });
        })
        .catch(function(err) { console.log(err.name + ": " + err.message); reject();});
    })
    }*/

    update(videoCanvas: string, renderingCanvas: string, facingMode: Boolean) {
        this._bk2CPP.setCanvasSize(this.resolution, this.resolution);
        this._bk2CPP.setFrameSize(this.resolution, this.resolution);
        return new Promise<void>((resolve, reject) => {
            // canvas to draw the camera's frame and get its image data 
            let cameraCanvas = document.getElementById(videoCanvas) as HTMLCanvasElement;
            if (!cameraCanvas) {
                reject()
            }

            let ctx = cameraCanvas!.getContext('2d');
            this.initCamera(facingMode)
                .then((imageCapture: any) => {


                    // #canvas2 is the css selector pointing to the canvas BeautyKit will use to draw the result
                    this._bk2CPP.initialize(renderingCanvas)
                    // loop
                    this._drawingLoop = setInterval(() => {
                        if (!(imageCapture && imageCapture.track && (imageCapture.track.readyState !== 'live' || !imageCapture.track.enabled || imageCapture.track.muted))) {
                            imageCapture.grabFrame(this.resolution)
                                .then((bmp: CanvasImageSource) => {
                                    try {
                                        /*let img = new Image();
                                        img.onload = function () {
                                            const iw = img.width;
                                            const ih = img.height;
                                            const scale = Math.min((720 / iw), (600 / ih));
                                            const iwScaled = iw * scale;
                                            const ihScaled = ih * scale;
                                            console.log(iwScaled + ' ' + ihScaled);
                                            (<HTMLCanvasElement>cameraCanvas).width = iwScaled;
                                            (<HTMLCanvasElement>cameraCanvas).height = ihScaled;
                                            ctx!.drawImage(img, 0, 0, iwScaled, ihScaled);
                                        }
                                         img.src = FavIcon;*/
                                        // draw the camera's image to the canvas to get its pixel's data
                                        ctx!.drawImage(bmp, 0, 0);

                                        const imageData = ctx!.getImageData(0, 0, this.resolution, this.resolution).data;
                                        //Save pixel data to preallocated buffer
                                        this._bk2Module.pixels = imageData;
                                    } catch (error) {
                                        this.handle_error(error)
                                    }
                                    // apply the new frame
                                    this._bk2CPP.setFrame();
                                    // detect face
                                    this.score = this._bk2CPP.detect();
                                    // draw the result on #canvas2
                                    this._bk2CPP.draw(1.0);
                                })
                                .catch(this.handle_error);
                        }
                    }, this.framerate);
                    resolve()
                })
                //.catch(this.handle_error);
                .catch((error: any) => {
                    console.error("[BK2] Start Failed")
                    reject(error)
                });

        })
    }
    stop() {
        return new Promise<void>((resolve, reject) => {
            if (this._videoTrack) {
                this._videoTrack.enabled = false
                this._videoTrack.stop()
                this._videoTrack = null
            }
            clearInterval(this._drawingLoop!)
            this._bk2CPP = null
            resolve()
        })
    }

    /**
     *   Beauty kit plugged on camera's video stream
     **/
    start(videoCanvas: string, renderingCanvas: string, facingMode: Boolean) {
        if (this._bk2CPP) {
            this.stop()
        }

        // BeautyKit initialization
        this._bk2CPP = new this._bk2Module.BeautyKit();
        // console.log(`[BK2] Create BeautyKit 2 aaa: ${this._bk2CPP.version()} `)
        this._bk2CPP.setCanvasSize(this.resolution, this.resolution);
        this._bk2CPP.setFrameSize(this.resolution, this.resolution);
        return new Promise<void>((resolve, reject) => {
            // canvas to draw the camera's frame and get its image data 
            let cameraCanvas = document.getElementById(videoCanvas) as HTMLCanvasElement;
            if (!cameraCanvas) {
                reject()
            }

            let ctx = cameraCanvas!.getContext('2d');
            this.initCamera(facingMode)
                .then((imageCapture: any) => {

                    // #canvas2 is the css selector pointing to the canvas BeautyKit will use to draw the result
                    this._bk2CPP.initialize(renderingCanvas)
                    // loop
                    this._drawingLoop = setInterval(() => {
                        if (!(imageCapture && imageCapture.track && (imageCapture.track.readyState !== 'live' || !imageCapture.track.enabled || imageCapture.track.muted))) {

                            imageCapture.grabFrame(this.resolution)
                                .then((bmp: CanvasImageSource) => {
                                    try {
                                        // draw the camera's image to the canvas to get its pixel's data
                                        ctx!.drawImage(bmp, 0, 0);

                                        const imageData = ctx!.getImageData(0, 0, this.resolution, this.resolution).data;
                                        //Save pixel data to preallocated buffer
                                        this._bk2Module.pixels = imageData;
                                    } catch (error) {
                                        this.handle_error(error)
                                    }
                                    // apply the new frame
                                    this._bk2CPP.setFrame();
                                    // detect face
                                    this.score = this._bk2CPP.detect();
                                    //console.log(this.score)
                                    // draw the result on #canvas2
                                    this._bk2CPP.draw(1.0);
                                })
                                .catch(this.handle_error);
                        }
                    }, this.framerate);
                    resolve()
                })
                //.catch(this.handle_error);
                .catch((error: any) => {
                    console.error("[BK2] Start Failed")
                    reject(error)
                });

        })
    }

    setFramerate(f: number) {
        this.framerate = 1000 / f
    }
    handle_error(error: any) {
        // In case the error come from WASM module
        if (typeof error === "number") {
            console.error(this._bk2Module.getExceptionMessage(error));
        } else {
            console.error(error);
        }
    }

    setShinyValue(value: number) {
        console.log("CHNGEMENT VALUE SHINY : " + value);
        this._bk2CPP.changeShinyEffect(1 - value);
    }

    setLayers(layers: Layer[]) {
        if (!this._bk2CPP) {
            console.warn("[BK2]Not ready please wait")
            return
        }
        var vectors = new this._bk2Module.VectorARLayer();
        console.log("[BK2]layers will Update")

        console.log(layers)

        layers.forEach(lay => {
            console.log("Loading element")
            console.log(lay)
            let path = "/shapes/" + lay.shape
            vectors.emplace_back(0,
                lay.shapeType,
                lay.r,
                lay.g,
                lay.b,
                lay.a,
                lay.effect,
                path, false);
        });
        // Beautifyer
        // vectors.emplace_back(0, BK2.ShapeType.EYELINER.value, 0, 0, 0, 1, BK2.Effect.MATT.value, "/shapes/eyeliner_top and outer bottom.png", false)
        // vectors.emplace_back(0, BK2.ShapeType.LIPSTICK.value, 0, 0, 1, 1, BK2.Effect.MATT.value, "/shapes/lipstick.png", false);
        //vectors.emplace_back(0, this._bk2Module.ShapeType.SKIN_BEAUTIFY.value, 1, 1, 1, 1, this._bk2Module.Effect.MATT.value, "/shapes/29032018_clarte_shape6.png", false);
        console.log("[BK2] Update layers with:")

        console.log(vectors)

        this._bk2CPP.setLayers(vectors.getObject());
    }
}

export {
    BeautyKit2,
    Layer,
    Shapetype,
    Effect
} 
