import { BubbleContainer, BubbleText, ButtonIcon, ChartContainer, ColorBar, ConcernListText, EmptyBar, ListContainer, ListingContainer, NextButton, NextButtonContainer, RadarContainer, ScoreButtonContainer, ScoreCompContainer, ScoreSwitchButton, ScoreText, ScoreTextContainer, Title, TitleContainer, TwinView, BackIcon, BackIconBorder, ScoreIntro, IntroContainer, OTSTCWaterMarkContainer } from "../Styles/ScoreStyle"
import { useTranslation } from "react-i18next"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { MySkinDiagState } from "../type"
import * as  ActionType from '../Constants/ActionsTypes'
import { analyseToAnswer, downloadImagesForOTSTC, runHeightMachine, sendAnalytics } from "../Constants/Utilitaries"

import Chart from 'react-apexcharts'
import { BubbleContent, Bubble } from "../Styles/ScoreStyle"

// import SCAN from '../Assets/Icons/SCAN_.svg'
import MENU from '../Assets/Icons/Menu.svg'
import CHART from '../Assets/Icons/CHART.svg'
import CHEVRON from '../Assets/Icons/Chevron.svg'

import { setAnswerTranslation } from "../Constants/QuestsConstants"
import { isDesktop, isTablet } from "react-device-detect"

import waterMark from '../Assets/Icons/OTSTCImage.png'
import { OTSTCmark } from "../Styles/Images"
import { OTSTCButton } from "../Styles/Buttons"
import i18n from "../i18nextInit"

const ScoreView = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const [isRadarViewDisplayed, displayRadarView] = useState<boolean>(true)
    const [listCells, setListCells] = useState<Array<any>>([])
    const [isRadarDisplayed, displayRadar] = useState<boolean>(false)
    const [isWaterMarkDisplayed, displayWaterMark] = useState<boolean>(false)

    const analyses = useSelector((state: MySkinDiagState) => state.session.analyses)
    const questions = useSelector((state: MySkinDiagState) => state.session.questions)

    const onSwitch = () => {
        if (isRadarViewDisplayed) {
            displayRadarView(false)
            launchListAnim()
        } else {
            displayRadarView(true)
        }
    }

    useEffect(() => {
        if (new URL(window.location.href).searchParams.get("otstcMode")) {

            downloadImagesForOTSTC(window.otstcTest)
        }
    }, [])

    const enabledAnalysis = [
        "WRINKLES", // Rides OK
        "DARK_SPOTS", // Taches
        "ROUGHNESS", // Grain de peau irrégulier OK
        "SHININESS", // Brillance OK
        "RADIANCE", // Manque d'éclat OK
        "DARK_CIRCLES", // Cernes OK
        "EYES_BAGS", // Poches OK
        "LOSS_OF_FIRMNESS", // Perte de fermeté OK
        window.isQA && "ACNE" // imperfections OK
    ]



    useEffect(() => {
        let _listCells: Array<any> = []
        let key: number = 0
        let _analyses = [...analyses]

        _analyses.sort((a: any, b: any) => {
            return b.score - a.score
        }).forEach((analyse: any) => {
            if (enabledAnalysis.includes(analyse.name)) {
                _listCells.push(<ListContainer rank={key} key={key}>
                    <div style={{ display: "flex", flexDirection: "column", flex: 9, position: "relative" }}>
                        <ConcernListText rank={key}>
                            {setAnswerTranslation(analyseToAnswer(analyse.name), t)}
                        </ConcernListText>
                        <EmptyBar />
                        <ColorBar id={"BAR_" + analyse.name} rank={key} width={0} />
                    </div>
                    <ScoreTextContainer>
                        <ScoreText rank={key}>
                            {Math.trunc(analyse.score * 100)}%
                        </ScoreText>
                    </ScoreTextContainer>
                </ListContainer>)
                key++
            }

            window.scrollTo(0, 0)

            let data = {
                messageType: "toggle_fullscreen",
                value: true
            };

            runHeightMachine(false, "RADAR_CONTAINER")

            console.log("[Score View] Full screen true !")
            window.parent.postMessage(data, "*")

            setTimeout(() => {
                displayRadar(true)
            }, 100);
        });

        setListCells(_listCells)

        // eslint-disable-next-line
    }, [analyses])

    useEffect(() => {
        //SI on est sur desktop, activer l'animation direct.
        if (isDesktop && !isTablet) {
            launchListAnim()
        }
        //eslint-disable-next-line
    }, [listCells])

    const launchListAnim = () => {
        let _analyses = [...analyses]
        _analyses.sort((a: any, b: any) => {
            return b.score - a.score
        }).forEach((analyse: any) => {
            if (document.getElementById("BAR_" + analyse.name)) {
                document.getElementById("BAR_" + analyse.name)!.style.width = Math.trunc(analyse.score * 100) + "%"
            }
        })
    }

    return <ScoreCompContainer id="RADAR_CONTAINER" isRadarView={isRadarViewDisplayed}>

        {questions.length > 2 && <>
            <BackIconBorder />
            <BackIcon onClick={() => dispatch({ type: ActionType.SET_STEP, value: 5 })}>
                <img style={{ width: "12px", paddingBottom: "2px", transform: window.RTL_LANGS.includes(i18n.language) ? "rotate(90deg)" : "rotate(-90deg)" }} alt="Back to Result" src={CHEVRON} />
            </BackIcon>
        </>}

        <TitleContainer>
            <Title>
                {t("scoreView.title", "Your photo analysis")}
            </Title>
        </TitleContainer>

        <IntroContainer>
            <ScoreIntro>
                {t("scoreView.intro")}
            </ScoreIntro>
        </IntroContainer>


        <ScoreButtonContainer>

            <ScoreSwitchButton onClick={onSwitch} isSelected={isRadarViewDisplayed}>
                <ButtonIcon src={CHART} />
                {t("scoreView.diagram", "Diagram")}
            </ScoreSwitchButton>

            <ScoreSwitchButton onClick={onSwitch} isSelected={!isRadarViewDisplayed}>
                <ButtonIcon style={{ paddingTop: "2px" }} src={MENU} />
                {t("scoreView.list", "List")}
            </ScoreSwitchButton>

        </ScoreButtonContainer>

        <TwinView $isRadarViewDisplayed={isRadarViewDisplayed}>
            <RadarContainer>
                {isRadarDisplayed && <RadarView />}
            </RadarContainer>

            <ListingContainer>
                {listCells}
            </ListingContainer>
            <OTSTCWaterMarkContainer onClick={() => displayWaterMark(!isWaterMarkDisplayed)} isWMDisplayed={isWaterMarkDisplayed}>
                <OTSTCButton>i</OTSTCButton>
                {isWaterMarkDisplayed && <OTSTCmark src={waterMark} />}
            </OTSTCWaterMarkContainer>

        </TwinView>

        {questions.length === 0 && <NextButtonContainer isRadarView={isRadarViewDisplayed} id="BUTTON">
            <NextButton onClick={() => {
                dispatch({ type: ActionType.SET_STEP, value: 3 })
                sendAnalytics("scores_continue")

                window.dataLayer.push({
                    event: 'events',
                    eventProps: {
                        category: "Diag",
                        action: "click",
                        label: "scores_continue"
                    }
                })
            }}>
                {t("confirmPopUp.continue", "Continuer")}
            </NextButton>
        </NextButtonContainer>}

    </ScoreCompContainer>
}

const RadarView = () => {
    const analyses = useSelector((state: MySkinDiagState) => state.session.analyses)
    const [concernBubbles, setConcernBubbles] = useState<any>([])

    const enabledAnalysis = [
        "WRINKLES", // Rides OK
        "DARK_SPOTS", // Taches
        "ROUGHNESS", // Grain de peau irrégulier OK
        "SHININESS", // Brillance OK
        "RADIANCE", // Manque d'éclat OK
        "DARK_CIRCLES", // Cernes OK
        "EYES_BAGS", // Poches OK
        "LOSS_OF_FIRMNESS", // Perte de fermeté OK
        window.isQA && "ACNE" // imperfections OK
    ]

    let isTablet = window.innerWidth > 767
    let isDesktop = window.innerWidth > 1224

    const setData = () => {
        let data: Array<any> = []
        analyses.forEach((e: any) => {
            if (enabledAnalysis.includes(e.name)) {
                data.push(Math.min(e.score * 90, 90) + 10)
            }
        })

        return data
    }

    const [chartState] = useState<any>({

        options: {

            fill: {
                opacity: 0.7,
                colors: ['#B9B394']
            },
            chart: {
                type: 'radar',
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: true,
                    type: 'x',
                    autoScaleYaxis: false,
                    zoomedArea: {
                        fill: {
                            color: '#90CAF9',
                            opacity: 0.4
                        },
                        stroke: {
                            color: '#0D47A1',
                            opacity: 0.4,
                            width: 1
                        }
                    }
                },
            },
            markers: {
                size: 0
            },
            yaxis: {
                show: false,
                min: 0,
                max: 100,
                tickAmount: 5
            },
            xaxis: {
                show: false,
                categories: ['', '', '', '', '', '', '', '', '', '', '', '', '']
            },
            plotOptions: {
                size: 3,
                radar: {
                    polygons: {
                        strokeColor: 'green',
                        strokeWidth: 0,
                        fill: {
                            colors: ['#FBFBFB', "#F2F2F2"]
                        },
                        width: 0,
                        connectorColors: 'rgba(0,0,0,0)'
                    }
                }
            },
            stroke: {
                show: false
            }
        },
        series: [
            {
                name: "Radar Series 1",
                data: setData()
            }],
    })

    const setRadius = (): number => {
        if (isDesktop) {
            console.warn(window.innerHeight * 0.22)
            //return Math.max(Math.min(window.innerHeight * 0.22, 180), 310)
            return 180
        } else if (isTablet) {
            return (window.innerWidth * 0.66 - 64) / 2
        } else {
            return window.innerWidth / 2 - 42
        }
    }

    useEffect(() => {
        let factorPI = 0
        let radius = setRadius()
        let biggerAnalyses: Array<string> = []

        console.warn(analyses)

        let _analyses: Array<any> = [...analyses].filter((e: any) => enabledAnalysis
            .includes(e.name))
            .sort((a: any, b: any) => { return b.score - a.score })

        biggerAnalyses.push(_analyses[0].name)
        biggerAnalyses.push(_analyses[1].name)
        console.warn(biggerAnalyses)

        let tab: Array<any> = []

        analyses.forEach((analyse: any) => {
            if (enabledAnalysis.includes(analyse.name)) {
                tab.push(<ConcernBubble isBetterScore={biggerAnalyses.includes(analyse.name)} key={analyse.name} factorPI={factorPI} radius={radius} score={analyse.score} name={analyse.name} />)
                factorPI++
            }
        });
        setConcernBubbles(tab)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const setSize = (): number => {
        if (isDesktop) {
            return 400 // 420
        }
        else if (isTablet) {
            return window.innerWidth * 0.66
        } else {
            return window.innerWidth - 32
        }
    }

    // 300 et 320
    return (
        <ChartContainer>
            <Chart options={chartState.options} series={chartState.series} type="radar" width={setSize()} height={setSize()}>
            </Chart>
            <BubbleContainer size={setSize()}>
                {concernBubbles}
            </BubbleContainer>
        </ChartContainer>

    )
}

interface BubbleProps {
    factorPI: number,
    radius: number,
    score: number,
    name: string,
    isBetterScore: boolean
}

const ConcernBubble = (props: BubbleProps) => {
    const { t } = useTranslation()

    return (
        <BubbleContent radius={props.radius} factorPI={props.factorPI} numberOfQuests={window.isQA ? 9 : 8} >

            <BubbleText isBetterScore={props.isBetterScore}>
                {setAnswerTranslation(analyseToAnswer(props.name), t)}
            </BubbleText>
            <Bubble isBetterScore={props.isBetterScore}>
                {Math.trunc(props.score * 100)}
            </Bubble>
        </BubbleContent>
    )
}

const ScoreViewComponent = () => {
    return <ScoreView />
}

export default ScoreViewComponent