import { useEffect, useRef, useState } from 'react'
import * as BK2 from './BeautyKit2'
import { Loader, TryOnContainer } from '../../Styles/VTO/VTOContainers'
import TryOnCameraComponent from './TryOnCamera'
import TryOnUIComponent from './TryOnUI'
//import { LoadingText } from '../../Styles/VTO/VTOTexts'
import LoadingFlower from '../../Assets/gif/LoadingFlower.gif'
//import loadingIcon from '../../Assets/Icons/LoadingIcon.png'
import { runHeightMachine, sendAnalytics } from '../../Constants/Utilitaries'
import { isDesktop, isTablet } from 'react-device-detect'
import LandscapePage from '../LandscapePage'
import TryOnDesktopComponent from './TryOnDesktopPage'
import PlayGroundUI from './PlaygroundUI'
import { PlayGroundState } from './PlaygroundConstants'
import PrivateNavigationPopUp from './NewPrivateNavPopUp'
import { LightTryOnClass } from '../../Constants/TryOnProduct'
import { useSelector } from 'react-redux'
import { MySkinDiagState } from '../../type'
import VTOonboarding from './VTOonboarding'
import { AnimatePresence } from 'framer-motion'

const beautyKit2 = new BK2.BeautyKit2() //"camera","#rendering"


export const getPlayGroundMode = () => {
    let url = new URL(window.location.href)
    if (url.searchParams.get("data")) {
        let data = JSON.parse(atob(url.searchParams.get("data")!.replace(" ", "+")))
        if (!data["pid"] && data["mode"] === "try-on") {
            return true
        }
    }

    return false
}

//Ici on handle l'objet contenant les produits, leur sku, leur couleur et si ils sont actifs 
function UIView(props: any) {
    const [isBK2Ready, setIsBK2Ready] = useState(false)
    const [isLandscape, setLandscape] = useState(false)
    const [isDesktopViewDisplayed, displayDesktopView] = useState<boolean>(false)
    const [inPage, setInPage] = useState<boolean>(true)
    const [score, setScore] = useState<number>(6)
    const [isAuthorized, setAuthorization] = useState<boolean>(false)

    const isPlaygroundMode = useRef<boolean | null>(null)
    const [isOvalEnabled, enableOval] = useState<any>([])
    const [playgroundState, setPlayGroundState] = useState<PlayGroundState>(PlayGroundState.chooseCategory)
    const [actualTryOnProduct, setActualTryOnProduct] = useState<LightTryOnClass | undefined>()
    const [isShadesBackButtonDisplayed, displayShadesBackButton] = useState<boolean>(true)
    const [privateNavigationFlag, setPrivateNavigationFlag] = useState<boolean>(false)
    const isPrivateNavigation = useSelector((state: MySkinDiagState) => state.session.isPrivateNavigation)
    const [isOnboardDisplayed, displayOnboard] = useState<boolean>(false)

    useEffect(() => {
        if (isPrivateNavigation) {
            setPrivateNavigationFlag(true)
            setAuthorization(false)
            displayOnboard(false)
        } else if (!privateNavigationFlag) {
            displayOnboard(true)
            setAuthorization(false)
        }
        //eslint-disable-next-line
    }, [isPrivateNavigation])

    useEffect(() => {
        if (isPlaygroundMode.current === null) {
            isPlaygroundMode.current = getPlayGroundMode()

            // if (isPlaygroundMode.current) {
            //     window.dataLayer.push({
            //         event: 'vto_playground_start'
            //     });
            // }

        }

        if (getPlayGroundMode()) {
            sendAnalytics("start", "vto_playground")
        } else {
            //sendAnalytics("start", "TryOn")
        }

        window.scrollTo(0, 0)

        setTimeout(() => {
            runHeightMachine(false, "TRY_ON")
        }, 300);

        (!isDesktop || isTablet) && setLandscape(window.innerWidth > window.innerHeight)
        isDesktop && !isTablet && displayDesktopView(true)

        const resize = () => {
            if (!isDesktop || isTablet) {
                setLandscape(window.innerWidth > window.innerHeight)
            }

            if (isDesktop && !isTablet) {
                displayDesktopView(true)
                setAuthorization(false)
            }
        }
        window.addEventListener('resize', resize)

        document.addEventListener("visibilitychange", event => {
            if (document.visibilityState === "visible") {
                setInPage(true)
            } else {
                beautyKit2.stop()
                setInPage(false)
                console.warn("Make-up engine shut down.")
            }
        })

        return () => {
            window.removeEventListener("resize", resize)
        }

    }, []);

    // useEffect(() => {
    //     if (isPlaygroundMode.current && !isOnboardDisplayed) {
    //         setAuthorization(true)
    //     }
    // }, [isPlaygroundMode, isOnboardDisplayed])

    useEffect(() => {
        beautyKit2.load().then(() => {
            console.log("Makeup Ready")
            setIsBK2Ready(true) 
        })
    }, [])

    useEffect(() => {
        let interval: any

        if (isBK2Ready && isAuthorized) {
            setTimeout(() => {
                interval = setInterval(() => {
                    setScore(beautyKit2.score)
                }, 1000)
            }, 1000);
        }

        return () => { window.clearInterval(interval) }
    }, [isBK2Ready, isAuthorized])

    return (<TryOnContainer id="TRY_ON">
        {(isBK2Ready && inPage && isAuthorized && !isDesktopViewDisplayed) ? <TryOnCameraComponent playgroundMode={isPlaygroundMode} isOvalEnabled={isOvalEnabled} playGroundState={playgroundState} score={score} beautyKit2={beautyKit2} /> :
            <>
                {!isDesktopViewDisplayed && <Loader src={LoadingFlower} />}
            </>}

        <AnimatePresence>
            {
                isPrivateNavigation && !isAuthorized && (!isDesktop || isTablet) && <PrivateNavigationPopUp setAuthorized={setAuthorization} />
            }
        </AnimatePresence>

        {
            !privateNavigationFlag && isOnboardDisplayed && (!isDesktop || isTablet) && <VTOonboarding setAuthorized={setAuthorization} displayOnBoard={displayOnboard} />
        }

        {!isDesktopViewDisplayed && !isPlaygroundMode.current && <TryOnUIComponent setAuthorized={setAuthorization} score={score} resolution={beautyKit2.resolution} />}
        {isPlaygroundMode.current && !isDesktopViewDisplayed && isAuthorized && !isOnboardDisplayed && <PlayGroundUI isShadesBackButtonDisplayed={isShadesBackButtonDisplayed} displayShadesBackButton={displayShadesBackButton} actualTryOnProduct={actualTryOnProduct} setActualTryOnProduct={setActualTryOnProduct} playGroundState={playgroundState} setPlayGroundState={setPlayGroundState} enableOval={enableOval} />}
        {isDesktopViewDisplayed && <TryOnDesktopComponent />}
        {isLandscape && <LandscapePage />}
    </TryOnContainer>)
}

function TryOnComponent() {

    return (
        <div>
            <UIView beautyKit2={beautyKit2} />
        </div>

    )

}

export default TryOnComponent