import i18n from "../../../i18nextInit"

export const handleProductsText = (t: Function, count: number) => {

    switch (i18n.language) {
        case "pl_PL":
            if (count === 1) {
                return count + " " + t("tryOn.product")
            } else if (count < 5) {
                return count + " " + t("tryOn.products_few")
            } else {
                return count + " " + t("tryOn.products_many")
            }
        case "cs_CZ":
            if (count === 1) {
                return count + " " + t("tryOn.product")
            } else if (count < 7) {
                return count + " " + t("tryOn.products_few")
            } else {
                return count + " " + t("tryOn.products_many")
            }
        default:
            if (count === 1) {
                return count + " " + t("tryOn.product")
            } else {
                return count + " " + t("tryOn.products")
            }
    }


}