
enum Shapetype {
    SKIN_DETECTOR=0,
    EYEBAG_LEFT,
    EYEBAG_RIGHT,
    HOMOGENEOUS_LEFT,
    HOMOGENEOUS_RIGHT,
    SKIN_BEAUTIFY,
    BENEFITS_PORE,
    BENEFITS_WRINKLES,
    BENEFITS_RADIANT,
    FOUNDATION,
    POWDER,
    CONCEALER,
    FOUNDATION_LIGHT,
    SCULTING,
    BLUSH,
    EYESHADOW,
    EYELINER, //16
    KHOL,
    MASCARA,
    LIPLINER,
    LIPSTICK, //20
    LIPGLOSS,
    LIPLINER_CLOSED,
    LIPSTICK_CLOSED,
    LIPGLOSS_CLOSED,
    HAIR = 100,
    INNER_EYES = 200,
    LENS,
    ANALYZE_EFFECT= 500
}
enum Effect {
    MATT=2,
    GLOSS=0,
    PEARLY=4,
    GLITTER=5,
    SATIN=7,
    SHINY=1,
    METALLIC=3,
    VELVETY=9,
    GLOWY=8,
    NORMAL=10,
    IRIDESCENT=11,
    HIGHLIGHTER=12,
    EFFECT13 = 13,
    EFFECT14 = 14,
    NORMALBIS = 15,
    MATPOWDER = 16,
    MOIRE = 17

}

class Layer  {
    shape : string // Shape path with the PNG without the shape/
    effect : Effect
    shapeType : Shapetype
    //color : [number, number,number] // RGB Color 
    r : number
    g : number
    b : number
    a : number 
    shineLevel : number
    blurLevel : number
    name: string
    sku: string
    constructor(shape:string,effect:Effect,shapeType:Shapetype,color:[number, number,number], opacity:number, blurLevel: number = 0, shineLevel: number = 0, name: string = "", sku: string){
        this.shape = shape
        this.effect = effect
        this.shapeType = shapeType
        this.r = color[0]
        this.g = color[1]
        this.b = color[2]
        this.a = opacity
        this.blurLevel = blurLevel
        this.shineLevel = shineLevel   
        this.name = name    
        this.sku = sku     
    }
}

export {
    Layer,
    Shapetype,
    Effect
}