import { useEffect, useState } from "react";
import i18n from "../i18nextInit";
import { getVideoLanguage, sendAnalytics, VideoFixedTuto } from "../Constants/Utilitaries";

import { CloseButton, MainButton } from "../Styles/Buttons";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import * as ActionTypes from '../Constants/ActionsTypes'
import ConfirmPopUp from "./ConfirmPopUp";
import closeIcon from '../Assets/Icons/Close_B.svg'
const blackLinesLangs = ["nl_BE", "nl_NL", "de_DE", "es_ES", "de_AT", "de_CH", "cs_CZ", "da_DK", "it_IT", "pt_PT", "pl_PL", "ja_JP", "ko_KR"]

const VideoViewTuto = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const [closeIn, setCloseIn] = useState<boolean>(false)

    useEffect(() => {
        let data = {
            messageType: "toggle_fullscreen",
            value: true
        };

        let dataBis = {
            messageType: "content_height",
            height: "2000"
        }


        let dataTier = {
            messageType: "scroll_top",
        };
        console.log(dataTier)

        console.log("[TUTO VIDEO] Full screen !")
        window.parent.postMessage(data, "*")
        window.parent.postMessage(dataBis, "*")
        window.parent.postMessage(dataTier, "*")

        window.scrollTo(0, 0)
    }, [])

    const displayClosePopUp = () => {
        if (closeIn) {
          return (<div style={{ width: "100vw", height: "100vh", top: 0, position: "fixed", zIndex: 15 }}>
            <ConfirmPopUp key={0} setOptIn={setCloseIn} />
          </div>)
        } else {
          return <div />
        }
      }

    return (<>
        <VideoFixedTuto blackLines={blackLinesLangs} src={getVideoLanguage()}>
        </VideoFixedTuto>
        <CloseButton style={{ zIndex: 15 }} src={closeIcon} alt="close" onClick={() => setCloseIn(true)}>
          </CloseButton>
        <MainButton
            onClick={() => {
                dispatch({ type: ActionTypes.SET_STEP, value: 1 })
                sendAnalytics("tutorial_video_ok")
                window.dataLayer.push({
                    event: 'events',
                    eventProps: {
                        category: "Diag",
                        action: "click",
                        label: "tutorial_video_ok"
                    }
                  })}
            }
            style={{
                font: "normal normal 500 12px/24px Helvetica Bold",
                fontSize: i18n.language === "tw_TW" ? "14px" : "12px",
                position: "fixed",
                bottom: "18px",
                zIndex: 10,
                width: "initial",
                paddingRight: "24px",
                paddingLeft: "24px",
                border: blackLinesLangs.includes(i18n.language) ? "solid 1px white" : ""
            }}>
            {t("introView.understand", "I understand")}
        </MainButton>
        {displayClosePopUp()}
    </>

    )
}

const VideoTutoComponent = () => {
    return <VideoViewTuto />
}

export default VideoTutoComponent
