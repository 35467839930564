/* eslint no-undef: 0 */  // --> OFF
const beautyKit2Loader = async () => {
    return new Promise((resolve) => {
        createMyModule(/* optional default settings */).then(function(BK2Module) {
            console.log(`[BK2] BeautyKit 2 Module Available `)
            resolve(BK2Module)
        })
    })
}

function getImageCapture(track){
    return new ImageCapture(track)
}
export  {beautyKit2Loader,getImageCapture};