// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getDatabase } from "firebase/database";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBHhfI18lazkzKmG6dZ8UYXUbYW_cDO2Z4",
    authDomain: "sisley-bridge.firebaseapp.com",
    databaseURL: "https://sisley-bridge-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "sisley-bridge",
    storageBucket: "sisley-bridge.appspot.com",
    messagingSenderId: "279267474510",
    appId: "1:279267474510:web:b56ab21afcd1b49d80571f"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const db = getDatabase(app);


export {db};