export class TryOnProductClass {
    sku: string
    name: string
    imgURL: string
    isActive: boolean
    price: number
    promo: number
    shadeColor: string
    shadeName: string
    description: string
    introduction: string
    type: TryOnType
    variants: Array<TryOnProductClass>
    actualVariant: string
    stock: number
    isVTOEnable: boolean
    isLiner: boolean
    isKholHigh: boolean
    parentSku: string
    parentName: string
    makeUpCategory?: string

    constructor(_sku: string, _name: string, _stock: number, _isVTOEnable: boolean, _imgURL: string, _shadeColor: string, _shadeName: string, _isActive: boolean, _description: string, _price: number, _promo: number, _introduction: string, _type: TryOnType, _variants: Array<TryOnProductClass>, _actualVariant: string = "", _isLiner: boolean = false, _parentSku: string = "" , _parentName: string = "", _makeUpCategory: any = "") {
        this.sku = _sku
        this.name = _name
        this.stock = _stock
        this.isVTOEnable = _isVTOEnable
        this.imgURL = _imgURL
        this.isActive = _isActive
        this.shadeColor = _shadeColor
        this.shadeName = _shadeName
        this.description = _description
        this.price = _price
        this.promo = _promo
        this.introduction = _introduction
        this.type = _type
        this.variants = _variants
        this.actualVariant = _actualVariant
        this.isLiner = _isLiner
        this.isKholHigh = false
        this.parentSku  = _parentSku
        this.parentName = _parentName
        this.makeUpCategory = _makeUpCategory
    }

    addVariant = (product: TryOnProductClass) => {
        this.variants?.push(product)
    }
}

export type LightTryOnClass = {
    sku: string,
    category: string,
    imageURL: string,
    parentSku: string
}

export enum TryOnType {
    bundle = "bundle",
    variant = "variant",
    master = "master",
    look = "look",
    item = "item",
    error = "error"
}