
export interface Dictionary<T> {
    [Key: string]: T;
}

export enum CBSKError {

    // Error
    no_photo = "CBSKERR_NO_PHOTO",
    no_face = "CBSKERR_NO_FACE",
    no_biometrics = "CBSKERR_NO_BIOMETRIE",
    no_analyse = "CBSKERR_ANALYSE_BAD_FORMAT",
    // Warnings
    too_far = "CBSKWARN_TOO_FAR",
    too_close = "CBSKWARN_TOO_CLOSE",
    high_light = "CBSKWARN_HIGHT_LIGHT",
    low_light = "CBSKWARN_LOW_LIGHT",
    too_left = "CBSKWARN_TOO_LEFT",
    too_right = "CBSKWARN_TOO_RIGHT",
    too_up = "CBSKWARN_TOO_UP",
    too_down = "CBSKWARN_TOO_DOWN",
}

export enum AnalyseStep {
    start = "START_QUALITY_ESTIMATION",
    // warningCalculation,
    // estimateBiometrie,
    analysingFace = "WARNING_OK_ANALYSING_FACE",
    biometricsDone = "BIOMETRICS_DONE",
    done = "ANALYSE_DONE"
}

export interface Range {
    min: number
    max: number
}

export enum CBSKEndPoint {
    warnings = "/warnings",
    biometrics = "/biometrics",
    analyze = "/analyze",
    effect = "/effect",
}


export interface SkinDiagApiConfig {
    url: string
    apiKey?: string,
    warnings: Dictionary<Range>
}

export interface Point {
    x: number,
    y: number
}
export interface Biometrics {
    zones: [[[number, number]]]
    contour: [[number, number]]
}
export function defaultCBSKConfig() {
    let lightning: Range = { min: 0.2, max: 1.0 }
    let offset: Range = { min: 0.28, max: 1.0 }
    let rotation: Range = { min: 0.4, max: 1.0 }
    let cameraAngle: Range = { min: 0.4, max: 1.0 }
    let config: SkinDiagApiConfig = {
        url: "https://api-sisley-prod.otstc.net", // https://apiprod.otstc.net/demo
        apiKey: "740b6fcdf59ea00e01f89b7f31c107a92qe2e7mmUWxmAITuRRazMLeJNTCZwtiI7fOwq9Q/mtlvDov3mrR25p29b/oYfyZc",
        warnings: {
            "Lighting": lightning,
            "Offset": offset,
            "Rotation": rotation,
            "Camera angle": cameraAngle,
        }
    }

    return config
}

export function preProdCBSKConfig() {
    let lightning: Range = { min: 0.2, max: 1.0 }
    let offset: Range = { min: 0.25, max: 1.0 }
    let rotation: Range = { min: 0.4, max: 1.0 }
    let cameraAngle: Range = { min: 0.0, max: 1.0 }

    let preProdConfig: SkinDiagApiConfig = {
        url: "https://api-sisley-preprod.otstc.net", // https://apiprod.otstc.net/demo
        apiKey: "3bfd4249c0c57c3fe4366bd18e39b4b6k3YHzIxnlYPhenp9spzUPycef3C3hdbYtRAQNOJujqYanz9GZ8lgpA1rbHTEhXgj",
        warnings: {
            "Lighting": lightning,
            "Offset": offset,
            "Rotation": rotation,
            "Camera angle": cameraAngle,
        }
    }

    return preProdConfig
}

