import styled from "styled-components";
import { MainButton } from "./Buttons";
import i18n from "../i18nextInit";

export const ScoreCompContainer = styled.div<{ isRadarView: boolean }>`
    width: 100%;

    @media (max-width: 768px){
        height: ${props => props.isRadarView ? "900px" : ""};
    }

    @media (min-width: 1224px) {
        max-width: 1200px;
        margin-bottom: 64px;
    }
`

export const Title = styled.p`
    margin: 0px;
    font: normal normal normal 24px/30px Playfair;
    color: black;
    text-align: center;


    @media (min-width: 1224px){
        font: normal normal 300 32px/40px Playfair;
    }
`

export const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 48px;
    padding: 24px 42px;
    position: relative;
`

const handleLeft = (isRadarViewDisplayed: boolean) => {
    if (window.RTL_LANGS.includes(i18n.language)) {
        return isRadarViewDisplayed ? "0%" : "100%"
    } else {
        return isRadarViewDisplayed ? "0%" : "-100%"
    }
}

export const TwinView = styled.div<{ $isRadarViewDisplayed: boolean }>`
    width: 200%;
    position: relative;
    display: flex;
    margin: 24px 0px;
    padding-bottom: 32px;
    overflow: auto;
    transition: all 0.3s;
    left: ${props => handleLeft(props.$isRadarViewDisplayed)};

    @media (min-width: 1224px){
        width: 100%;
        flex-direction: row-reverse;
        overflow: initial;
    }

`

export const RadarContainer = styled.div`
    width: 50%;
    min-height: 300px;
`

export const ListingContainer = styled.div`
    width: 50%;
    min-height: 300px;
`

export const ScoreButtonContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 0px 4% 24px;

    @media (min-width: 1224px){
        display: none;
    }
`

export const ScoreSwitchButton = styled.button<{ isSelected: boolean }>`
    background-color: ${props => props.isSelected ? "#F7F7F7" : ""};
    border: ${props => !props.isSelected ? "solid 0.5px #E0E0E0" : "none"};
    border-radius: 24px;
    font: normal normal 13px/18px Playfair;
    color: black;
    margin: 0px;
    padding: 12px 12px;
    max-width: 200px;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 12px;
    gap: 6px;

    @media (min-width: 768px){
        padding: 16px 12px;
    }
`

export const ListContainer = styled.div<{ rank: number }>`
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    box-sizing: border-box;
    padding: 4px 12%;
    margin-bottom: ${props => props.rank === 1 ? "24px" : ""};

    @media (min-width: 768px) and (max-width: 1224px){
        padding: 4px 20%;
    }
`

export const ListCell = styled.div`
    display: flex;
    width: 100%;
`

export const ConcernListText = styled.p<{ rank: number }>`
    font: ${props => props.rank < 2 ? "normal normal 300 13px/40px Playfair" : "normal normal 300 11px/40px Helvetica Light"};
    color: black;
    margin: 0px;

    @media (min-width: 768px) {
        font-size: ${props => props.rank < 2 ? "16px" : "12px"};
    }

`

export const EmptyBar = styled.div`
    width: 100%;
    height: 0.5px;
    background-color: #707070;
    opacity: 0.2;
`

export const ScoreText = styled.p<{ rank: number }>`
    font: ${props => props.rank < 2 ? "normal normal 500 12px/24px Hevetica" : "normal normal 500 10px/24px Hevetica Light"};
    flex: 1;
    margin: 0px;
    text-align: right;
    opacity: ${props => props.rank < 2 ? 1 : 0.7};

    @media (min-width: 768px) {
        font-size: ${props => props.rank < 2 ? "13px" : "11px"};
    }
`

export const ScoreTextContainer = styled.div`
    flex: 1;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    max-width: 64px;
    min-width: 32px;
`

export const ColorBar = styled.div<{ width: number, rank: number }>`
    width: ${props => props.width}%;
    transition: width 1.5s;
    height: 2px;
    background-color: ${props => props.rank < 2 ? "#99D3E4" : "#BABABA"};
    position: absolute;
    bottom: -0.5px;
    left: ${() => window.RTL_LANGS.includes(i18n.language) ? "" : "0px"};
    right: ${() => window.RTL_LANGS.includes(i18n.language) ? "0px" : ""};
`

export const NextButtonContainer = styled.div<{ isRadarView: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 32px;
    z-index: 30;

    @media (min-width: 768px) and (max-width: 1224px) {
        position: fixed;
        bottom: 0px;
    }

    @media (max-width: 768px) {
        position: ${props => props.isRadarView ? "fixed" : "relative"};
        bottom: ${props => props.isRadarView ? "0px" : ""};
    }

    @media (min-width: 1224px) {
        margin-top: 42px;
    }
`

export const NextButton = styled(MainButton)`
    font: normal normal 500 13px/14px Helvetica Bold;
    text-transform: uppercase;
    max-width: 300px;
`
// le -18 sert à soustraire la moitié de la widthde la bulle, pour être au milieu
/* eslint-disable no-useless-concat */
export const BubbleContent = styled.div<{ radius: number, factorPI: number, numberOfQuests: number }>`
    position: absolute;
    
    top: ${props => `calc(calc(50% - 12px) - ${props.radius * Math.cos((props.numberOfQuests - props.factorPI) * 2 * Math.PI / props.numberOfQuests)}px - 18px)`};
    left: ${props => `calc(calc(50%) - ${props.radius * Math.sin((props.numberOfQuests - props.factorPI) * 2 * Math.PI / props.numberOfQuests)}px - 18px)`};

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (min-width: 768px){
        top: ${props => `calc(calc(50% - 12px) - ${props.radius * Math.cos((props.numberOfQuests - props.factorPI) * 2 * Math.PI / props.numberOfQuests)}px - 25px)`};
        left: ${props => `calc(calc(50%) - ${props.radius * Math.sin((props.numberOfQuests - props.factorPI) * 2 * Math.PI / props.numberOfQuests)}px - 25px)`};
    }
`

export const Bubble = styled.div<{ isBetterScore: boolean }>`
    width: 36px;
    height: 36px;

    background-color: ${props => props.isBetterScore ? "#99D3E4" : "white"};
    color: ${props => props.isBetterScore ? "white" : "black"};
    z-index : 4;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #FBFBFB;
    font: normal normal 500 10px/24px Helvetica;

    @media (min-width: 768px){
        width: 50px;
        height: 50px;
        font-size: 16px;
    }
`

export const BubbleText = styled.p<{ isBetterScore: boolean }>`
    font: normal normal 500 11px/17px Playfair;
    border-radius: 24px;
    background-color: ${props => props.isBetterScore ? "#99D3E4" : ""};
    color: ${props => props.isBetterScore ? "white" : "black"};
    position: absolute;
    bottom: 42px;
    margin: 0px;
    padding: 6px 12px;
    white-space: nowrap;

    @media (min-width: 768px){
        bottom: 54px;
        font-size: 16px;
    }

    @media (max-width: 768px){
        white-space: initial;
        text-align: center;
        width: 72px;
    }
`

export const IconContainer = styled.div`
    width: 100%;
    margin-top: 42px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (min-width: 1224px){
        margin-top: 72px;
    }

    @media (min-width: 768px) and  (max-width: 1224px){
        margin-top: 110px;
    }
`

export const Icon = styled.img`
    width: 48px;
`

export const ButtonIcon = styled.img`
    width: 12px;
`

export const ChartContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 32px;
    flex-direction: column;

    @media (min-width: 1224px){
        height: 100%;
    }
`
//left: 7%;
//top: 0px;
export const BubbleContainer = styled.div<{ size: number }>`
    position: absolute;
    height: ${props => props.size}px;
    width: ${props => props.size}px;
    display: flex;
    overflow: visible;
`

export const BackIcon = styled.button`
    width: 44px;
    height: 44px;
    position: fixed;
    margin: 0px;
    left: ${() => window.RTL_LANGS.includes(i18n.language) ? "" : "24px"};
    right: ${() => window.RTL_LANGS.includes(i18n.language) ? "24px" : ""};
    top: 24px;
    z-index: 10;
    cursor: pointer;
    border-radius: 24px;
    filter: brightness(0);

    &:hover {
        filter: brightness(1);
        background-color: black;
    }
`

export const BackIconBorder = styled.div`
    width: 42px;
    height: 42px;
    position: fixed;
    left: ${() => window.RTL_LANGS.includes(i18n.language) ? "" : "24px"};
    right: ${() => window.RTL_LANGS.includes(i18n.language) ? "24px" : ""};
    top: 24px;
    border-radius: 24px;
    border: 0.5px solid #CACACA;
`

export const ScoreIntro = styled.p`
    font: normal normal 300 14px/19px Helvetica;
    max-width: 600px;
    text-align: center;
    margin: 0px;

    @media (orientation: landscape){
        font-size: 18px;
    }
`

export const IntroContainer = styled.div`
    margin: 0px 24px 36px;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const OTSTCWaterMarkContainer = styled.div<{ isWMDisplayed: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 12px;
    position: fixed;
    background-color: white;
    bottom: 12px;
    left: 8px;
    z-index: 10;
    -webkit-tap-highlight-color: transparent;

    @media (min-width: 1224px){
        left: 8px;
    }
`