import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import Webcam from 'react-webcam';
import { ResizeObserver } from 'resize-observer';
import styled from 'styled-components';
import * as ActionTypes from '../Constants/ActionsTypes';
import * as ActionsTypes from '../Constants/ActionsTypes';
import { CloseButton, OTSTCButton } from '../Styles/Buttons';
import { CameraPanel, CameraTextPanel, CameraViewComponent, Flash, OTSTCWaterMarkContainer, WarningContainer } from '../Styles/Containers';
import { OKWarning, OTSTCmark } from '../Styles/Images';
import { CameraText, CameraWarningText } from '../Styles/Texts';
import { MySkinDiagState } from '../type';

import closeIcon from '../Assets/Icons/Close_B.svg';
import ConfirmPopUp from './ConfirmPopUp';

import { FaceLandmarksDetectionResults, faceDetection } from '../A3Camera/faceLandmarksDetection';
import { ConfigDetection } from '../A3Camera/type';
import waterMark from '../Assets/Icons/OTSTCImage.png';
import skinDiagDispatcher from './SkinDiagApi';
import { sendAnalytics, translateError } from '../Constants/Utilitaries';
import { isTablet } from 'react-device-detect';
//import skinDiagDispatcher from './SkinDiagApi'
//import fakeImg from '../Assets/Images Produits/fakeBigImg.png' A mettre dans le dossier en cas de nécessité, trop grosse.
import type { } from 'redux-thunk/extend-redux'

const defaultConfigDetection: ConfigDetection = {
	xMin: 0.45,
	xMax: 0.7,
	yMin: 0.55, // 0.5
	yMax: 0.7, // 0.65
	zMin: isTablet ? 0.28 : 0.5,
	zMax: isTablet ? 0.34 : 0.7,
	roll: 30,
};

export const NAVSIZE: number = 60;

const NewCameraView = () => {
	const webcamRef = useRef(null);
	const [oval, setOval] = useState<any>([]);
	const { model, error } = useSelector((state: MySkinDiagState) => state.session);
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const [inProp, setInProp] = useState<boolean>(false);
	const [closeIn, setCloseIn] = useState<boolean>(false);
	const [isWaterMarkDisplayed, displayWaterMark] = useState<boolean>(false);
	const timer = useRef<number>(0);
	const intervalRef = useRef<any>(null);
	const [isWebcamReady, setIsWebcamReady] = useState<boolean>(false);
	const [warning, setWarning] = useState<string | null>(t('cameraView.pleaseWait', 'Veuillez patienter') + '...');
	const [waitingCounter, setWaitingCounter] = useState<number>(0);
	const isPhotoOk = useRef<boolean>(false);
	const [detectionRes, setDetectionRes] = useState<FaceLandmarksDetectionResults>({
		status: {
			good: false,
			loading: true,
			modelError: false,
			noFace: false,
			badPositionBottom: false,
			badPositionLeft: false,
			badPositionRight: false,
			badPositionTop: false,
			badDistanceFar: false,
			badDistanceClose: false,
			badOrientation: false,
			badBrightness: false,
		},
	});

	const resizeObserver = new ResizeObserver((entries: any) => {
		for (let entry of entries) {
			// ... entry has the full info about your element dimensions
			console.log(entry);
			createOval();
		}
	});

	let isTablet = false;

	const videoConstaints = {
		facingMode: 'user',
		height: { ideal: 2250, max: 2250 },
		width: { ideal: 3000, max: 3000 },
	};

	useEffect(() => {
		setInProp(true);
		//reset l'experience
		dispatch({ type: ActionsTypes.FAKE_RESET });
		timer.current = Date.now();
		let url = new URL(window.location.href);
		if (url.searchParams.get('bridgeID')) {
			dispatch({ type: ActionsTypes.SET_ONBOARD_DISPLAYED, value: false });
			document.getElementById('CAMERA')!.style.position = 'absolute';
			document.getElementById('APP')!.style.position = 'absolute';
		}

		//window.disableScrolling()
		//eslint-disable-next-line
	}, [dispatch]);

	const createOval = () => {
		let ovalHeight: number;
		let ovalWidth: number;
		let factor: number;
		factor = window.outerHeight / window.outerWidth > 2 ? 2.5 : 2;
		ovalHeight = isTablet ? window.innerHeight * 0.6 : window.innerHeight * 0.5;
		ovalWidth = isTablet ? window.innerHeight * 0.408 : window.innerHeight * 0.34;

		const Oval = styled.div<{ step: number }>`
			position: fixed;
			display: block;
			transition: 0.5s all;
			bottom: ${(window.innerHeight - ovalHeight - NAVSIZE) / factor}px;
			height: ${ovalHeight}px;
			width: ${ovalWidth}px;
			z-index: 3;
			border-radius: ${ovalWidth}px / ${ovalHeight}px;
			border-width: 2px;
			border-style: ${(props) => (props.step === 2 ? 'dashed' : 'solid')};
			border-color: white;
			clip-path: ${(props) => props.step === 1 && 'polygon(0 83%, 100% 83%, 100% 100%, 0 100%)'};
			clip-path: ${(props) => props.step === 2 && 'polygon(0 30%, 100% 30%, 100% 60%, 0 60%);'};
			clip-path: ${(props) => props.step === 3 && 'polygon(0 0, 100% 0, 100% 20%, 0 20%)'};
		`;

		let tab = [];
		tab.push(<Oval key={0} step={1} id={'OVAL'} />);
		tab.push(<Oval key={1} step={2} id={'OVAL_2'} />);
		tab.push(<Oval key={2} step={3} id={'OVAL_3'} />);

		setOval(tab);
	};

	useEffect(() => {
		if (document.getElementById('CAMERA')) {
			resizeObserver.observe(document.getElementById('CAMERA')!);
		}

		return () => {
			// clean
			//resizeObserver.unobserve(document.getElementById('CAMERA')!);
		};
		//eslint-disable-next-line
	}, []);

	useEffect(() => {
		const back = () => {
			window.clearInterval(window.id);
			window.clearInterval(window.textAnim);
		};

		let data = {
			messageType: 'toggle_fullscreen',
			value: true,
		};

		console.log('[CAMERA] Full screen !');
		window.parent.postMessage(data, '*');

		return () => {
			back();
		};
		//eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (!model || !isWebcamReady || !webcamRef.current || isPhotoOk.current) {
			return;
		}

		setTimeout(() => {
			createOval();
		}, 1000);

		intervalRef.current = setInterval(() => {
			faceDetection(webcamRef.current, defaultConfigDetection, model, false).then((res: any) => {
				setDetectionRes(res);
			});
		}, 500);

		return () => {
			clearInterval(intervalRef.current);
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isWebcamReady, model, webcamRef, isPhotoOk.current]);

	useEffect(() => {
		if (error.length > 0) {
			return
		}

		if (detectionRes.status?.modelError) {
			setWarning(t('cameraView.pleaseWait', 'Veuillez patienter') + '...');
			setWaitingCounter(0);
			return;
		}
		if (detectionRes.status?.loading) {
			setWarning(t('cameraView.pleaseWait', 'Veuillez patienter') + '...');
			setWaitingCounter(0);
			return;
		}

		if (detectionRes.status?.good) {
			setWarning(null);
			setWaitingCounter((prev) => prev + 1);
			return;
		}

		if (detectionRes.status?.noFace) {
			setWarning(t('cameraView.too_left', 'Place your face '));
			setWaitingCounter(0);
			return;
		}

		if (detectionRes.status?.badDistanceFar) {
			setWarning(t('cameraView.far', 'Vous êtes trop près.'));
			setWaitingCounter(0);
			return;
		}
		if (detectionRes.status?.badDistanceClose) {
			setWarning(t('cameraView.close', 'Plus loin'));
			setWaitingCounter(0);
			return;
		}
		if (detectionRes.status?.badPositionLeft) {
			setWarning(t('cameraView.too_left', 'Place your face '));
			setWaitingCounter(0);
			return;
		}
		if (detectionRes.status?.badPositionRight) {
			setWarning(t('cameraView.too_right', 'Place your face in the oval'));
			setWaitingCounter(0);
			return;
		}
		if (detectionRes.status?.badPositionBottom) {
			setWarning(t('cameraView.too_down', 'Place your face in the oval.'));
			setWaitingCounter(0);
			return;
		}
		if (detectionRes.status?.badPositionTop) {
			setWarning(t('cameraView.too_high', 'Place your face in the oval.'));
			setWaitingCounter(0);
			return;
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [detectionRes.status, error]);

	useEffect(() => {
		if (error.length > 0) {
			setWarning(translateError(error[0], t))

			setTimeout(() => {
				dispatch({ type: ActionTypes.ANALYSE_ERROR, value: [] })
			}, 3000);
		}
	}, [error, dispatch, t])

	useEffect(() => {
		if (isPhotoOk.current) return;
		if (waitingCounter >= 5) {
			// displayFlash(true);
			if (detectionRes.image) {
				isPhotoOk.current = true;
				// onPictureTaken(detectionRes.image);
				document.getElementById('FLASH')!.style.animation = 'Fading 0.2s forwards';
				setTimeout(() => {
					fetch(detectionRes.image)
						.then((res) => res.blob())
						.then((blob) => {
							const action = { type: ActionTypes.ANALYSE_PHOTO, value: URL.createObjectURL(blob) };
							setInProp(false);
							dispatch(action);
							dispatch(skinDiagDispatcher);
							sendAnalytics('picture_taken_time', 'Diag', Math.floor((Date.now() - timer.current) / 1000));

							window.dataLayer.push({
								event: 'events',
								eventProps: {
									category: 'Diag',
									action: 'click',
									label: 'picture_taken_time',
									value: Math.floor((Date.now() - timer.current) / 1000),
								},
							});
							// downloadImagesForOTSTC(blob)
						});
				}, 200);

			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [waitingCounter]);

	const handleErrorCamera = () => {
		dispatch({ type: ActionsTypes.SET_STEP, value: 12 });
	};


	if (window.innerWidth / window.innerHeight > 3 / 4) {
		isTablet = true;
	}

	const displayClosePopUp = () => {
		if (closeIn) {
			return (
				<div style={{ width: '100vw', height: '100vh', top: 0, position: 'fixed', zIndex: 10 }}>
					<ConfirmPopUp key={0} setOptIn={setCloseIn} />
				</div>
			);
		} else {
			return <div />;
		}
	}

	return (
		<CSSTransition in={inProp} key={1} classNames='swipeToRight' addEndListener={() => null}>
			<CameraViewComponent id={'CAMERA'}>
				<CloseButton style={{ top: '20px' }} src={closeIcon} alt='close' onClick={() => setCloseIn(true)}></CloseButton>

				<CameraTextPanel show={warning !== null} isTablet={isTablet} id='WARN_PANEL'>
					<CameraText id={'CAM_TEXT'}>{warning}</CameraText>
				</CameraTextPanel>
				<Webcam
					id='CAMERA'
					audio={false}
					ref={webcamRef}
					videoConstraints={videoConstaints}
					onUserMedia={() => setIsWebcamReady(true)}
					onUserMediaError={handleErrorCamera}
					height={window.innerHeight - NAVSIZE} // -NAVSIZE
					width={(window.outerHeight * 4) / 3}
					//style={{ position: 'fixed', height: window.innerHeight - NAVSIZE, width: window.outerHeight * 3 / 4, top: 0, left: (window.innerWidth - window.outerHeight * 3 / 4) / 2 }}
					mirrored={true}
					screenshotFormat='image/jpeg'
					autoPlay={true}
					screenshotQuality={0.96}
					forceScreenshotSourceSize={true}
					imageSmoothing={false}
					playsInline
					muted
					style={{
						transition: 'all 0.4s',
						position: 'absolute',
						height: isTablet ? ((window.outerHeight - 90) * 4) / 3 : window.innerHeight - NAVSIZE, // -Navsize
						width: isTablet ? window.outerWidth : (window.outerHeight * 3) / 4,
						left: isTablet ? 0 : (window.innerWidth - (window.outerHeight * 3) / 4) / 2,
						bottom: isTablet ? '' : 0,
					}}
				/>

				{oval}
				<CameraPanel id='CAM_PANEL'>
					<WarningContainer>
						<CameraWarningText id='DISTANCE_TEXT'>{t('cameraView.distance', 'Distance')}</CameraWarningText>
						<OKWarning step={detectionRes.status?.loading ? 0 : detectionRes.status?.noFace || detectionRes.status?.badDistanceClose || detectionRes.status?.badDistanceFar ? 1 : 2} />
					</WarningContainer>

					<WarningContainer>
						<CameraWarningText id='POSITION_TEXT'>{t('cameraView.position', 'Positionnement')}</CameraWarningText>
						<OKWarning step={detectionRes.status?.loading ? 0 : detectionRes.status?.noFace || detectionRes.status?.badPositionBottom || detectionRes.status?.badPositionTop || detectionRes.status?.badPositionLeft || detectionRes.status?.badPositionRight ? 1 : 2} />
					</WarningContainer>

					<WarningContainer>
						<CameraWarningText id='ORIENTATION_TEXT'>{t('cameraView.orientation', 'Orientation')}</CameraWarningText>
						<OKWarning step={detectionRes.status?.loading ? 0 : detectionRes.status?.noFace || detectionRes.status?.badOrientation ? 1 : 2} />
					</WarningContainer>
				</CameraPanel>
				{displayClosePopUp()}

				<Flash id='FLASH' />
				<OTSTCWaterMarkContainer onClick={() => displayWaterMark(!isWaterMarkDisplayed)} isWMDisplayed={isWaterMarkDisplayed}>
					<OTSTCButton>i</OTSTCButton>
					{isWaterMarkDisplayed && <OTSTCmark src={waterMark} />}
				</OTSTCWaterMarkContainer>
			</CameraViewComponent>
		</CSSTransition>
	);
};

const NewCameraComponent = () => {
	return <NewCameraView />;
};

export default NewCameraComponent;
