import { keyframes } from 'styled-components'
import { fadeInUpBig } from 'react-animations'
import { Answer, Quest } from '../Constants/QuestsConstants';

export const FadeInUp = keyframes`${fadeInUpBig}`;

export const launchStepAnimation = () => {
    document.getElementById("PROGRESS_BAR")!.style.animation = "none"
    document.getElementById("PROGRESS_CONTAINER")!.style.animation = "none"
    //eslint-disable-next-line
    let trick = document.getElementById("PROGRESS_BAR")!.offsetHeight //trick pour restart l'animation
    document.getElementById("PROGRESS_BAR")!.style.animation = "BarFilling2 1.5s forwards 2s"
    document.getElementById("PROGRESS_CONTAINER")!.style.animation = "Fading 1.5s forwards 2s"
}

export const MoveBubblesOnClick = (id: string) => {
    //eslint-disable-next-line
    let trick = document.getElementById("APP")!.offsetHeight //trick pour restart l'animation
    switch (id) {
        case "normal":
            document.getElementById("grasse")!.style.animation = 'none'
            document.getElementById("grasse")!.style.opacity = "1"
            document.getElementById("grasse")!.style.transition = "1s"
            //eslint-disable-next-line
            let trick = document.getElementById("grasse")!.offsetHeight //trick pour restart l'animation
            document.getElementById("grasse")!.style.transform = "translateY(15px)"

            document.getElementById("seche")!.style.animation = 'none'
            document.getElementById("seche")!.style.opacity = "1"
            document.getElementById("seche")!.style.transition = "1s"
            //eslint-disable-next-line
            let trick2 = document.getElementById("seche")!.offsetHeight //trick pour restart l'animation
            document.getElementById("seche")!.style.transform = "translateX(-15px)"
            break;

        case "seche":
            document.getElementById("grasse")!.style.animation = 'none'
            document.getElementById("grasse")!.style.opacity = "1"
            document.getElementById("grasse")!.style.transition = "1s"
            //eslint-disable-next-line
            let trick3 = document.getElementById("grasse")!.offsetHeight //trick pour restart l'animation
            document.getElementById("grasse")!.style.transform = "translateY(15px)"

            document.getElementById("normal")!.style.animation = 'none'
            document.getElementById("normal")!.style.opacity = "1"
            document.getElementById("normal")!.style.transition = "1s"
            //eslint-disable-next-line
            let trick4 = document.getElementById("normal")!.offsetHeight //trick pour restart l'animation
            document.getElementById("normal")!.style.transform = "translate(15px, -15px)"
            break;
        case "grasse":
            document.getElementById("normal")!.style.animation = 'none'
            document.getElementById("normal")!.style.opacity = "1"
            document.getElementById("normal")!.style.transition = "1s"
            //eslint-disable-next-line
            let trick5 = document.getElementById("normal")!.offsetHeight //trick pour restart l'animation
            document.getElementById("normal")!.style.transform = "translate(15px, -15px)"

            document.getElementById("seche")!.style.animation = 'none'
            document.getElementById("seche")!.style.opacity = "1"
            document.getElementById("seche")!.style.transition = "1s"
            //eslint-disable-next-line
            let trick6 = document.getElementById("seche")!.offsetHeight //trick pour restart l'animation
            document.getElementById("seche")!.style.transform = "translateX(-15px)"
            break;
        case Answer.sansrincage:
            document.getElementById(Answer.avecRincage)!.style.animation = 'none'
            document.getElementById(Answer.avecRincage)!.style.opacity = "1"
            document.getElementById(Answer.avecRincage)!.style.transition = "1s"
            document.getElementById(Answer.sansrincage)!.style.transition = "1s"
            //eslint-disable-next-line
            let trick7 = document.getElementById("APP")!.offsetHeight //trick pour restart l'animation
            document.getElementById(Answer.avecRincage)!.style.transform = "translate(-15px, 15px)"
            break;
        case Answer.avecRincage:
            document.getElementById(Answer.sansrincage)!.style.animation = 'none'
            document.getElementById(Answer.sansrincage)!.style.opacity = "1"
            document.getElementById(Answer.sansrincage)!.style.transition = "1s"
            //eslint-disable-next-line
            let trick8 = document.getElementById("APP")!.offsetHeight //trick pour restart l'animation
            document.getElementById(Answer.sansrincage)!.style.transform = "translate(15px, -15px)"
            break;
        case Answer.CYrelachement:
            document.getElementById(Answer.CYpoche)!.style.animation = 'none'
            document.getElementById(Answer.CYpoche)!.style.opacity = "1"
            document.getElementById(Answer.CYpoche)!.style.transition = "1s"
            document.getElementById(Answer.CYpoche)!.style.transform = "translate(-10px, -10px)"

            document.getElementById(Answer.CYcernes)!.style.animation = 'none'
            document.getElementById(Answer.CYcernes)!.style.opacity = "1"
            document.getElementById(Answer.CYcernes)!.style.transition = "1s"
            document.getElementById(Answer.CYcernes)!.style.transform = "translate(10px, -10px)"

            document.getElementById(Answer.CYrides)!.style.animation = 'none'
            document.getElementById(Answer.CYrides)!.style.opacity = "1"
            document.getElementById(Answer.CYrides)!.style.transition = "1s"
            document.getElementById(Answer.CYrides)!.style.transform = "translate(-10px, 10px)"

            document.getElementById(Answer.CYridules)!.style.animation = 'none'
            document.getElementById(Answer.CYridules)!.style.opacity = "1"
            document.getElementById(Answer.CYridules)!.style.transition = "1s"
            document.getElementById(Answer.CYridules)!.style.transform = "translate(10px, 10px)"
            break;
        case Answer.CYpoche:
            document.getElementById(Answer.CYrelachement)!.style.animation = 'none'
            document.getElementById(Answer.CYrelachement)!.style.opacity = "1"
            document.getElementById(Answer.CYrelachement)!.style.transition = "1s"
            document.getElementById(Answer.CYrelachement)!.style.transform = "translate(5px, 5px)"

            document.getElementById(Answer.CYridules)!.style.animation = 'none'
            document.getElementById(Answer.CYridules)!.style.opacity = "1"
            document.getElementById(Answer.CYridules)!.style.transition = "1s"
            document.getElementById(Answer.CYridules)!.style.transform = "translate(5px, 5px)"
            break;
        case Answer.CYcernes:
            document.getElementById(Answer.CYrelachement)!.style.animation = 'none'
            document.getElementById(Answer.CYrelachement)!.style.opacity = "1"
            document.getElementById(Answer.CYrelachement)!.style.transition = "1s"
            document.getElementById(Answer.CYrelachement)!.style.transform = "translate(-5px, 5px)"

            document.getElementById(Answer.CYrides)!.style.animation = 'none'
            document.getElementById(Answer.CYrides)!.style.opacity = "1"
            document.getElementById(Answer.CYrides)!.style.transition = "1s"
            document.getElementById(Answer.CYrides)!.style.transform = "translate(-5px, 5px)"
            break;
        case Answer.CYrides:
            document.getElementById(Answer.CYrelachement)!.style.animation = 'none'
            document.getElementById(Answer.CYrelachement)!.style.opacity = "1"
            document.getElementById(Answer.CYrelachement)!.style.transition = "1s"
            document.getElementById(Answer.CYrelachement)!.style.transform = "translate(5px, -5px)"

            document.getElementById(Answer.CYcernes)!.style.animation = 'none'
            document.getElementById(Answer.CYcernes)!.style.opacity = "1"
            document.getElementById(Answer.CYcernes)!.style.transition = "1s"
            document.getElementById(Answer.CYcernes)!.style.transform = "translate(5px, -5px)"
            break;
        case Answer.CYridules:
            document.getElementById(Answer.CYrelachement)!.style.animation = 'none'
            document.getElementById(Answer.CYrelachement)!.style.opacity = "1"
            document.getElementById(Answer.CYrelachement)!.style.transition = "1s"
            document.getElementById(Answer.CYrelachement)!.style.transform = "translate(-5px, -5px)"

            document.getElementById(Answer.CYpoche)!.style.animation = 'none'
            document.getElementById(Answer.CYpoche)!.style.opacity = "1"
            document.getElementById(Answer.CYpoche)!.style.transition = "1s"
            document.getElementById(Answer.CYpoche)!.style.transform = "translate(-5px, -5px)"
            break;
        case Answer.MVoui:
            document.getElementById(Answer.MVnon)!.style.animation = 'none'
            document.getElementById(Answer.MVnon)!.style.opacity = "1"
            document.getElementById(Answer.MVnon)!.style.transition = "1s"
            //eslint-disable-next-line
            let trick12 = document.getElementById("APP")!.offsetHeight //trick pour restart l'animation
            document.getElementById(Answer.MVnon)!.style.transform = "scale(0.7)"
            break;
        case Answer.MVnon:
            document.getElementById(Answer.MVoui)!.style.animation = 'none'
            document.getElementById(Answer.MVoui)!.style.opacity = "1"
            document.getElementById(Answer.MVoui)!.style.transition = "1s"
            //eslint-disable-next-line
            let trick13 = document.getElementById("APP")!.offsetHeight //trick pour restart l'animation
            document.getElementById(Answer.MVoui)!.style.transform = "scale(0.7)"
            break;
        case Answer.SDNoui:
            document.getElementById(Answer.SDNnon)!.style.animation = 'none'
            document.getElementById(Answer.SDNnon)!.style.opacity = "1"
            document.getElementById(Answer.SDNnon)!.style.transition = "1s"
            //eslint-disable-next-line
            let trick14 = document.getElementById("APP")!.offsetHeight //trick pour restart l'animation
            document.getElementById(Answer.SDNnon)!.style.transform = "scale(0.7)"
            break;
        case Answer.SDNnon:
            document.getElementById(Answer.SDNoui)!.style.animation = 'none'
            document.getElementById(Answer.SDNoui)!.style.opacity = "1"
            document.getElementById(Answer.SDNoui)!.style.transition = "1s"
            //eslint-disable-next-line
            let trick15 = document.getElementById("APP")!.offsetHeight //trick pour restart l'animation
            document.getElementById(Answer.SDNoui)!.style.transform = "scale(0.7)"
            break;
        case Answer.pollution:
            document.getElementById(Answer.stress)!.style.animation = 'none'
            document.getElementById(Answer.stress)!.style.opacity = "1"
            document.getElementById(Answer.stress)!.style.transition = "1s"
            //eslint-disable-next-line
            let trick16 = document.getElementById("APP")!.offsetHeight //trick pour restart l'animation
            document.getElementById(Answer.stress)!.style.transform = "translate(-10px, -10px)"

            document.getElementById(Answer.ecrans)!.style.animation = 'none'
            document.getElementById(Answer.ecrans)!.style.opacity = "1"
            document.getElementById(Answer.ecrans)!.style.transition = "1s"
            //eslint-disable-next-line
            let trick17 = document.getElementById("APP")!.offsetHeight //trick pour restart l'animation
            document.getElementById(Answer.ecrans)!.style.transform = "translate(-10px, 10px)"

            document.getElementById(Answer.manqueSommeil)!.style.animation = 'none'
            document.getElementById(Answer.manqueSommeil)!.style.opacity = "1"
            document.getElementById(Answer.manqueSommeil)!.style.transition = "1s"
            //eslint-disable-next-line
            let trick18 = document.getElementById("APP")!.offsetHeight //trick pour restart l'animation
            document.getElementById(Answer.manqueSommeil)!.style.transform = "translate(10px, -10px)"
            break;
        case Answer.manqueSommeil:
            document.getElementById(Answer.stress)!.style.animation = 'none'
            document.getElementById(Answer.stress)!.style.opacity = "1"
            document.getElementById(Answer.stress)!.style.transition = "1s"
            //eslint-disable-next-line
            let trick19 = document.getElementById("APP")!.offsetHeight //trick pour restart l'animation
            document.getElementById(Answer.stress)!.style.transform = "translate(-15px, -5px)"

            document.getElementById(Answer.ecrans)!.style.animation = 'none'
            document.getElementById(Answer.ecrans)!.style.opacity = "1"
            document.getElementById(Answer.ecrans)!.style.transition = "1s"
            //eslint-disable-next-line
            let trick20 = document.getElementById("APP")!.offsetHeight //trick pour restart l'animation
            document.getElementById(Answer.ecrans)!.style.transform = "translate(-10px, 10px)"

            document.getElementById(Answer.pollution)!.style.animation = 'none'
            document.getElementById(Answer.pollution)!.style.opacity = "1"
            document.getElementById(Answer.pollution)!.style.transition = "1s"
            //eslint-disable-next-line
            let trick21 = document.getElementById("APP")!.offsetHeight //trick pour restart l'animation
            document.getElementById(Answer.pollution)!.style.transform = "translate(10px, 10px)"
            break;
        case Answer.stress:
            document.getElementById(Answer.ecrans)!.style.animation = 'none'
            document.getElementById(Answer.ecrans)!.style.opacity = "1"
            document.getElementById(Answer.ecrans)!.style.transition = "1s"
            //eslint-disable-next-line
            let trick22 = document.getElementById("APP")!.offsetHeight //trick pour restart l'animation
            document.getElementById(Answer.ecrans)!.style.transform = "translate(-10px, 10px)"

            document.getElementById(Answer.pollution)!.style.animation = 'none'
            document.getElementById(Answer.pollution)!.style.opacity = "1"
            document.getElementById(Answer.pollution)!.style.transition = "1s"
            //eslint-disable-next-line
            let trick23 = document.getElementById("APP")!.offsetHeight //trick pour restart l'animation
            document.getElementById(Answer.pollution)!.style.transform = "translate(10px, 10px)"

            document.getElementById(Answer.manqueSommeil)!.style.animation = 'none'
            document.getElementById(Answer.manqueSommeil)!.style.opacity = "1"
            document.getElementById(Answer.manqueSommeil)!.style.transition = "1s"
            //eslint-disable-next-line
            let trick24 = document.getElementById("APP")!.offsetHeight //trick pour restart l'animation
            document.getElementById(Answer.manqueSommeil)!.style.transform = "translate(10px, -10px)"
            break;
        case Answer.ecrans:
            document.getElementById(Answer.pollution)!.style.animation = 'none'
            document.getElementById(Answer.pollution)!.style.opacity = "1"
            document.getElementById(Answer.pollution)!.style.transition = "1s"
            //eslint-disable-next-line
            let trick25 = document.getElementById("APP")!.offsetHeight //trick pour restart l'animation
            document.getElementById(Answer.pollution)!.style.transform = "translate(15px, 5px)"

            document.getElementById(Answer.manqueSommeil)!.style.animation = 'none'
            document.getElementById(Answer.manqueSommeil)!.style.opacity = "1"
            document.getElementById(Answer.manqueSommeil)!.style.transition = "1s"
            //eslint-disable-next-line
            let trick26 = document.getElementById("APP")!.offsetHeight //trick pour restart l'animation
            document.getElementById(Answer.manqueSommeil)!.style.transform = "translate(10px, -10px)"

            document.getElementById(Answer.stress)!.style.animation = 'none'
            document.getElementById(Answer.stress)!.style.opacity = "1"
            document.getElementById(Answer.stress)!.style.transition = "1s"
            //eslint-disable-next-line
            let trick27 = document.getElementById("APP")!.offsetHeight //trick pour restart l'animation
            document.getElementById(Answer.stress)!.style.transform = "translate(-15px, -5px)"
            break;
        default:
            break;

    }

}

export const MoveBubblesOnClickForDesktop = (id: string) => {
    //eslint-disable-next-line
    let trick = document.getElementById("APP")!.offsetHeight //trick pour restart l'animation
    switch (id) {
        case "normal":
            document.getElementById("grasse")!.style.animation = 'none'
            document.getElementById("grasse")!.style.opacity = "1"
            document.getElementById("grasse")!.style.transition = "1s"
            //eslint-disable-next-line
            let trick = document.getElementById("grasse")!.offsetHeight //trick pour restart l'animation
            document.getElementById("grasse")!.style.transform = "translateX(15px)"

            document.getElementById("seche")!.style.animation = 'none'
            document.getElementById("seche")!.style.opacity = "1"
            document.getElementById("seche")!.style.transition = "1s"
            //eslint-disable-next-line
            let trick2 = document.getElementById("seche")!.offsetHeight //trick pour restart l'animation
            document.getElementById("seche")!.style.transform = "translateX(-15px)"
            break;

        case "seche":
            document.getElementById("grasse")!.style.animation = 'none'
            document.getElementById("grasse")!.style.opacity = "1"
            document.getElementById("grasse")!.style.transition = "1s"
            //eslint-disable-next-line
            let trick3 = document.getElementById("grasse")!.offsetHeight //trick pour restart l'animation
            document.getElementById("grasse")!.style.transform = "translateX(15px)"

            document.getElementById("normal")!.style.animation = 'none'
            document.getElementById("normal")!.style.opacity = "1"
            document.getElementById("normal")!.style.transition = "1s"
            //eslint-disable-next-line
            let trick4 = document.getElementById("normal")!.offsetHeight //trick pour restart l'animation
            document.getElementById("normal")!.style.transform = "translateX(15px)"
            break;
        case "grasse":
            document.getElementById("normal")!.style.animation = 'none'
            document.getElementById("normal")!.style.opacity = "1"
            document.getElementById("normal")!.style.transition = "1s"
            //eslint-disable-next-line
            let trick5 = document.getElementById("normal")!.offsetHeight //trick pour restart l'animation
            document.getElementById("normal")!.style.transform = "translateX(-15px)"

            document.getElementById("seche")!.style.animation = 'none'
            document.getElementById("seche")!.style.opacity = "1"
            document.getElementById("seche")!.style.transition = "1s"
            //eslint-disable-next-line
            let trick6 = document.getElementById("seche")!.offsetHeight //trick pour restart l'animation
            document.getElementById("seche")!.style.transform = "translateX(-15px)"
            break;
        case Answer.sansrincage:
            document.getElementById(Answer.avecRincage)!.style.animation = 'none'
            document.getElementById(Answer.avecRincage)!.style.opacity = "1"
            document.getElementById(Answer.avecRincage)!.style.transition = "1s"
            document.getElementById(Answer.sansrincage)!.style.transition = "1s"
            //eslint-disable-next-line
            let trick7 = document.getElementById("APP")!.offsetHeight //trick pour restart l'animation
            document.getElementById(Answer.avecRincage)!.style.transform = "translateX(-15px)"
            break;
        case Answer.avecRincage:
            document.getElementById(Answer.sansrincage)!.style.animation = 'none'
            document.getElementById(Answer.sansrincage)!.style.opacity = "1"
            document.getElementById(Answer.sansrincage)!.style.transition = "1s"
            //eslint-disable-next-line
            let trick8 = document.getElementById("APP")!.offsetHeight //trick pour restart l'animation
            document.getElementById(Answer.sansrincage)!.style.transform = "translateX(15px)"
            break;
        case Answer.CYrelachement:
            document.getElementById(Answer.CYpoche)!.style.animation = 'none'
            document.getElementById(Answer.CYpoche)!.style.opacity = "1"
            document.getElementById(Answer.CYpoche)!.style.transition = "1s"
            document.getElementById(Answer.CYpoche)!.style.transform = "translateX(-10px)"

            document.getElementById(Answer.CYcernes)!.style.animation = 'none'
            document.getElementById(Answer.CYcernes)!.style.opacity = "1"
            document.getElementById(Answer.CYcernes)!.style.transition = "1s"
            document.getElementById(Answer.CYcernes)!.style.transform = "translateX(-10px)"

            document.getElementById(Answer.CYrides)!.style.animation = 'none'
            document.getElementById(Answer.CYrides)!.style.opacity = "1"
            document.getElementById(Answer.CYrides)!.style.transition = "1s"
            document.getElementById(Answer.CYrides)!.style.transform = "translateX(10px)"

            document.getElementById(Answer.CYridules)!.style.animation = 'none'
            document.getElementById(Answer.CYridules)!.style.opacity = "1"
            document.getElementById(Answer.CYridules)!.style.transition = "1s"
            document.getElementById(Answer.CYridules)!.style.transform = "translateX(10px)"
            break;
        case Answer.CYpoche:
            document.getElementById(Answer.CYrelachement)!.style.animation = 'none'
            document.getElementById(Answer.CYrelachement)!.style.opacity = "1"
            document.getElementById(Answer.CYrelachement)!.style.transition = "1s"
            document.getElementById(Answer.CYrelachement)!.style.transform = "translateX(10px)"

            document.getElementById(Answer.CYrelachement)!.style.animation = 'none'
            document.getElementById(Answer.CYrelachement)!.style.opacity = "1"
            document.getElementById(Answer.CYrelachement)!.style.transition = "1s"
            document.getElementById(Answer.CYrelachement)!.style.transform = "translateX(10px)"
            break;
        case Answer.CYcernes:
            document.getElementById(Answer.CYrelachement)!.style.animation = 'none'
            document.getElementById(Answer.CYrelachement)!.style.opacity = "1"
            document.getElementById(Answer.CYrelachement)!.style.transition = "1s"
            document.getElementById(Answer.CYrelachement)!.style.transform = "translateX(10px)"

            document.getElementById(Answer.CYpoche)!.style.animation = 'none'
            document.getElementById(Answer.CYpoche)!.style.opacity = "1"
            document.getElementById(Answer.CYpoche)!.style.transition = "1s"
            document.getElementById(Answer.CYpoche)!.style.transform = "translateX(-10px)"
            break;
        case Answer.CYrides:
            document.getElementById(Answer.CYrelachement)!.style.animation = 'none'
            document.getElementById(Answer.CYrelachement)!.style.opacity = "1"
            document.getElementById(Answer.CYrelachement)!.style.transition = "1s"
            document.getElementById(Answer.CYrelachement)!.style.transform = "translateX(-10px)"

            document.getElementById(Answer.CYridules)!.style.animation = 'none'
            document.getElementById(Answer.CYridules)!.style.opacity = "1"
            document.getElementById(Answer.CYridules)!.style.transition = "1s"
            document.getElementById(Answer.CYridules)!.style.transform = "translateX(10px)"
            break;
        case Answer.CYridules:
            document.getElementById(Answer.CYrelachement)!.style.animation = 'none'
            document.getElementById(Answer.CYrelachement)!.style.opacity = "1"
            document.getElementById(Answer.CYrelachement)!.style.transition = "1s"
            document.getElementById(Answer.CYrelachement)!.style.transform = "translateX(-10px)"

            document.getElementById(Answer.CYrides)!.style.animation = 'none'
            document.getElementById(Answer.CYrides)!.style.opacity = "1"
            document.getElementById(Answer.CYrides)!.style.transition = "1s"
            document.getElementById(Answer.CYrides)!.style.transform = "translateX(-10px)"
            break;
        case Answer.MVoui:
            document.getElementById(Answer.MVnon)!.style.animation = 'none'
            document.getElementById(Answer.MVnon)!.style.opacity = "1"
            document.getElementById(Answer.MVnon)!.style.transition = "1s"
            //eslint-disable-next-line
            let trick12 = document.getElementById("APP")!.offsetHeight //trick pour restart l'animation
            document.getElementById(Answer.MVnon)!.style.transform = "scale(0.7)"
            break;
        case Answer.MVnon:
            document.getElementById(Answer.MVoui)!.style.animation = 'none'
            document.getElementById(Answer.MVoui)!.style.opacity = "1"
            document.getElementById(Answer.MVoui)!.style.transition = "1s"
            //eslint-disable-next-line
            let trick13 = document.getElementById("APP")!.offsetHeight //trick pour restart l'animation
            document.getElementById(Answer.MVoui)!.style.transform = "scale(0.7)"
            break;
        case Answer.SDNoui:
            document.getElementById(Answer.SDNnon)!.style.animation = 'none'
            document.getElementById(Answer.SDNnon)!.style.opacity = "1"
            document.getElementById(Answer.SDNnon)!.style.transition = "1s"
            //eslint-disable-next-line
            let trick14 = document.getElementById("APP")!.offsetHeight //trick pour restart l'animation
            document.getElementById(Answer.SDNnon)!.style.transform = "scale(0.7)"
            break;
        case Answer.SDNnon:
            document.getElementById(Answer.SDNoui)!.style.animation = 'none'
            document.getElementById(Answer.SDNoui)!.style.opacity = "1"
            document.getElementById(Answer.SDNoui)!.style.transition = "1s"
            //eslint-disable-next-line
            let trick15 = document.getElementById("APP")!.offsetHeight //trick pour restart l'animation
            document.getElementById(Answer.SDNoui)!.style.transform = "scale(0.7)"
            break;
        case Answer.pollution:
            document.getElementById(Answer.stress)!.style.animation = 'none'
            document.getElementById(Answer.stress)!.style.opacity = "1"
            document.getElementById(Answer.stress)!.style.transition = "1s"
            //eslint-disable-next-line
            let trick16 = document.getElementById("APP")!.offsetHeight //trick pour restart l'animation
            document.getElementById(Answer.stress)!.style.transform = "translateX(10px)"

            document.getElementById(Answer.ecrans)!.style.animation = 'none'
            document.getElementById(Answer.ecrans)!.style.opacity = "1"
            document.getElementById(Answer.ecrans)!.style.transition = "1s"
            //eslint-disable-next-line
            let trick17 = document.getElementById("APP")!.offsetHeight //trick pour restart l'animation
            document.getElementById(Answer.ecrans)!.style.transform = "translateX(10px)"

            break;
        case Answer.manqueSommeil:
            document.getElementById(Answer.stress)!.style.animation = 'none'
            document.getElementById(Answer.stress)!.style.opacity = "1"
            document.getElementById(Answer.stress)!.style.transition = "1s"
            //eslint-disable-next-line
            let trick19 = document.getElementById("APP")!.offsetHeight //trick pour restart l'animation
            document.getElementById(Answer.stress)!.style.transform = "translateX(-10px)"

            document.getElementById(Answer.ecrans)!.style.animation = 'none'
            document.getElementById(Answer.ecrans)!.style.opacity = "1"
            document.getElementById(Answer.ecrans)!.style.transition = "1s"
            //eslint-disable-next-line
            let trick20 = document.getElementById("APP")!.offsetHeight //trick pour restart l'animation
            document.getElementById(Answer.ecrans)!.style.transform = "translateX(-10px)"

            break;
        case Answer.stress:
            document.getElementById(Answer.ecrans)!.style.animation = 'none'
            document.getElementById(Answer.ecrans)!.style.opacity = "1"
            document.getElementById(Answer.ecrans)!.style.transition = "1s"
            //eslint-disable-next-line
            let trick22 = document.getElementById("APP")!.offsetHeight //trick pour restart l'animation
            document.getElementById(Answer.ecrans)!.style.transform = "translateX(-10px)"

            document.getElementById(Answer.manqueSommeil)!.style.animation = 'none'
            document.getElementById(Answer.manqueSommeil)!.style.opacity = "1"
            document.getElementById(Answer.manqueSommeil)!.style.transition = "1s"
            //eslint-disable-next-line
            let trick24 = document.getElementById("APP")!.offsetHeight //trick pour restart l'animation
            document.getElementById(Answer.manqueSommeil)!.style.transform = "translateX(10px)"
            break;
        case Answer.ecrans:
            document.getElementById(Answer.pollution)!.style.animation = 'none'
            document.getElementById(Answer.pollution)!.style.opacity = "1"
            document.getElementById(Answer.pollution)!.style.transition = "1s"
            //eslint-disable-next-line
            let trick25 = document.getElementById("APP")!.offsetHeight //trick pour restart l'animation
            document.getElementById(Answer.pollution)!.style.transform = "translateX(-10px)"

            document.getElementById(Answer.stress)!.style.animation = 'none'
            document.getElementById(Answer.stress)!.style.opacity = "1"
            document.getElementById(Answer.stress)!.style.transition = "1s"
            //eslint-disable-next-line
            let trick27 = document.getElementById("APP")!.offsetHeight //trick pour restart l'animation
            document.getElementById(Answer.stress)!.style.transform = "translateX(10px)"
            break;
        default:
            break;

    }

}

export const setClickAnimation = (questStep: number, ans: string, triggerAnimation: boolean = true) => {
    if (document.getElementById(ans)) {
        switch (questStep) {
            case Quest.contourYeux:
            case Quest.preferenceDemaquillage:
            case Quest.peau:
                document.getElementById(ans)!.style.backgroundColor = '#E2E2E2'
                document.getElementById(ans)!.style.opacity = '1'
                document.getElementById(ans)!.style.animation = 'none'
                document.getElementById(ans)!.style.zIndex = '10'

                if(triggerAnimation) {
                    document.getElementById("NEXT_QUESTION")!.style.animation = "Unfading 1s"
                }

                setTimeout(() => {
                    document.getElementById(ans)!.style.transform = 'scale(1.2)'
                    document.getElementById(ans)!.style.border = 'none'
                    window.innerWidth < 1224 ? MoveBubblesOnClick(ans) : MoveBubblesOnClickForDesktop(ans)
                }, 200);

                if (questStep !== Quest.contourYeux) {
                    setTimeout(() => {
                        document.getElementById(ans)!.style.transform = 'scale(15)'
                        document.getElementById(ans)!.style.color = '#E2E2E2'
                        document.getElementById(ans)!.innerHTML = ''
                        document.getElementById(ans)!.style.zIndex = '10'

                        setTimeout(() => {
                            document.getElementById("APP")!.style.backgroundColor = "#E2E2E2"
                        }, 500);
                    }, 1000);
                }
                break;
            case Quest.preferenceDemaquillage2:
                document.getElementById("RQ" + ans)!.style.opacity = '1'
                document.getElementById("RQ" + ans)!.style.animation = 'none'
                document.getElementById("RQ" + ans)!.style.transition = '1s'
                document.getElementById("RQ" + ans)!.innerHTML = ''
                //document.getElementById(ans)!.style.zIndex = "2"
                //eslint-disable-next-line
                let trick = document.getElementById("PROGRESS_BAR")!.offsetHeight //trick pour restart l'animation
                document.getElementById("RQ" + ans)!.style.transform = 'scale(2)'
                document.getElementById("NEXT_QUESTION")!.style.animation = "Unfading 1s"


                setTimeout(() => {
                    document.getElementById("RQ" + ans)!.style.transform = 'scale(100)'
                    document.getElementById("RQ" + ans)!.style.color = 'white'
                    document.getElementById(ans)!.style.zIndex = '10'
                    setTimeout(() => {
                        document.getElementById("APP")!.style.backgroundColor = "white"
                    }, 500);
                }, 1000);
                break;
            case Quest.exposomes:
                document.getElementById(ans)!.style.backgroundColor = 'white'
                document.getElementById(ans)!.style.opacity = '1'
                document.getElementById(ans)!.style.animation = 'none'

                setTimeout(() => {
                    document.getElementById(ans)!.style.transform = 'scale(1.2)'
                    document.getElementById(ans)!.style.border = 'none'
                    window.innerWidth < 1224 ? MoveBubblesOnClick(ans) : MoveBubblesOnClickForDesktop(ans)
                }, 200);
                break;
            case Quest.manqueVitalite:
                document.getElementById(ans)!.style.backgroundColor = 'white'
                document.getElementById(ans)!.style.opacity = '1'
                document.getElementById(ans)!.style.animation = 'none'
                document.getElementById("NEXT_QUESTION")!.style.animation = "Unfading 1s"

                setTimeout(() => {
                    document.getElementById(ans)!.style.transform = 'scale(1.2)'
                    document.getElementById(ans)!.style.border = 'none'
                    window.innerWidth < 1224 ? MoveBubblesOnClick(ans) : MoveBubblesOnClickForDesktop(ans)
                }, 200);

                setTimeout(() => {
                    document.getElementById(ans)!.style.transform = 'scale(15)'
                    document.getElementById(ans)!.style.color = 'white'
                    document.getElementById(ans)!.innerHTML = ''
                    document.getElementById(ans)!.style.zIndex = '10'
                    setTimeout(() => {
                        document.getElementById("APP")!.style.backgroundColor = "white"
                    }, 500);
                }, 1000);
                break;
            case Quest.routineNuit:
                document.getElementById(ans)!.style.backgroundColor = '#E2E2E2'
                document.getElementById(ans)!.style.opacity = '1'
                document.getElementById(ans)!.style.animation = 'none'
                document.getElementById("NEXT_QUESTION")!.style.animation = "Unfading 1s"


                setTimeout(() => {
                    document.getElementById(ans)!.style.transform = 'scale(1.2)'
                    document.getElementById(ans)!.style.border = 'none'
                    window.innerWidth < 1224 ? MoveBubblesOnClick(ans) : MoveBubblesOnClickForDesktop(ans)
                }, 200);

                setTimeout(() => {
                    document.getElementById(ans)!.style.transform = 'scale(15)'
                    document.getElementById(ans)!.style.color = '#E2E2E2'
                    document.getElementById(ans)!.innerHTML = ''
                    document.getElementById(ans)!.style.zIndex = '10'
                    setTimeout(() => {
                        document.getElementById("APP")!.style.backgroundColor = "#E2E2E2"
                    }, 500);
                }, 1000);
                break;
            default:
                break;
        }
    }

}

export const setClickAnimationForCY = (selected: Array<any>) => {
    let element = document.getElementById(selected[selected.length - 1])
    setTimeout(() => {
        element!.style.transform = 'scale(15)'
        element!.style.color = '#E2E2E2'
        element!.innerHTML = ''
        element!.style.zIndex = '5'
        document.getElementById("NEXT_QUESTION")!.style.animation = "Unfading 1s"

        setTimeout(() => {
            document.getElementById("APP")!.style.backgroundColor = "white"
            if(document.getElementById("UNDER_TEXT")){
                document.getElementById("UNDER_TEXT")!.style.animation = 'none'
            }
        }, 500);
    }, 200);
}