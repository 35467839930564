import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { OptInContainer } from '../Styles/Containers'
import { BlackCurtain } from '../Styles/Images'

import CloseImg from '../Assets/Icons/Quitter.svg'
import { MainButton } from '../Styles/Buttons'
import { useDispatch, useSelector } from 'react-redux'
import * as ActionsTypes from '../Constants/ActionsTypes'
import { MySkinDiagState } from '../type'
import { PanelContainer } from './HelpSkinView'

const ConfirmPopUp = (props: any) => {

    const [closed, setClosed] = useState<boolean>(true)
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const session = useSelector((state: MySkinDiagState) => state.session)

    //eslint-disable-next-line
    const step = useSelector((state: MySkinDiagState) => state.session.step)

    useEffect(() => {
        setClosed(false)
    }, [])

    const close = () => {
        setClosed(true)
        if(step === 5 || step === 51 || step === 2 || step === 1 || step === 11){
            let data = {
                messageType: "toggle_fullscreen",
                value: false
            };
    
            console.log("[QUIT] Full screen false !")
            window.parent.postMessage(data, "*")
            window.parent.postMessage(data, "*")
        }
        

        if (document.getElementById("BLACK_CURTAIN")) {
            document.getElementById("BLACK_CURTAIN")!.style.opacity = "0"
        }

        if (window.innerWidth > 768) {
            props.setOptIn(false)
        } else {
            setTimeout(() => {
                props.setOptIn(false)
            }, 300);
        }
    }

    useEffect(() => {
        let data = {
            messageType: "toggle_fullscreen",
            value: true
        };

        console.log("[QUIT] Full screen true !")
        window.parent.postMessage(data, "*")

    }, [])

    const retry = () => {
        close()
        dispatch({type: ActionsTypes.SET_STEP, value: -1})
        dispatch({type: ActionsTypes.RESET_EXPERIENCE})
        try {
            sessionStorage.clear()
            console.warn(sessionStorage)
        } catch (error) {
            console.error("Error with sessionStorage")
        }
    }

    const shouldDisplayConfirmText = (): boolean => {
        if(session.step === 2 && session.bridge.status !== "NONE"){

            return false
        }

        return true
    }

    return (
        <PanelContainer>
            <BlackCurtain onClick={() => close()} id="BLACK_CURTAIN" isForDetails={false} isActive={true} />

            <OptInContainer style={{ display: "flex", alignItems: "center", padding: "*%" }} isHelp={false} isClosed={closed}>
                <img style={{ width: "64px", marginTop: "32px" }} alt="close" src={CloseImg} />
                <ConfirmTitle style={{marginBottom: session.step === 2 && session.bridge.status !== "NONE" ? "32px" : ""}}>
                    {t('confirmPopUp.title', "Êtes-vous sûr de vouloir quitter l’expérience ?")}
                </ConfirmTitle>

                { shouldDisplayConfirmText() && <ConfirmContent>
                    {t('confirmPopUp.content', "Votre parcours et vos données ne seront pas sauvegardés.")}
                </ConfirmContent>}

                <MainButton onClick={() => retry()} style={{ fontSize: "12px", textTransform: "uppercase", width: "100%" }}>
                    {t('confirmPopUp.leave', "Quitter")}
                </MainButton>

                <LeaveText onClick={() => close()}>
                    {t('confirmPopUp.continue', "Continuer")}
                </LeaveText>
            </OptInContainer>
        </PanelContainer>)
}

const ConfirmTitle = styled.p`
margin: 32px 0px 0px;
font: normal normal 500 13px/18px Helvetica Bold;
text-align: center;
`

const ConfirmContent = styled.p`
margin: 0px 0px 32px;
font: normal normal 500 13px/18px Helvetica;
text-align: center;
`

const LeaveText = styled.p`
cursor: pointer;
margin: 16px 0px 0px;
font: normal normal 500 12px/21px Helvetica Bold;
text-decoration: underline;
`

export default ConfirmPopUp