import styled from 'styled-components'
import { CloseButton, MainButton } from '../Styles/Buttons'
import closeIcon from '../Assets/Icons/Close_B.svg';
import { useEffect, useState } from 'react';
import ConfirmPopUp from './ConfirmPopUp';
import smiley from '../Assets/Icons/BadSmiley.png'
import { useTranslation } from 'react-i18next';
import { AnimatePresence, motion } from 'framer-motion';
import { useDispatch } from 'react-redux';
import * as actionType from '../Constants/ActionsTypes'

const OTSTSCErrorView = () => {
    const [isClosePopUpOpen, openClosePopUp] = useState<boolean>(false)
    const { t } = useTranslation()
    const SECOND_TO_WAIT = 10
    const [isRetryReady, setRetryReady] = useState<boolean>(false)
    const dispatch = useDispatch()

    const displayClosePopUp = () => {
        if (isClosePopUpOpen) {
            return (<div style={{ width: "100vw", height: "100vh", top: 0, position: "fixed", zIndex: 10 }}>
                <ConfirmPopUp key={0} setOptIn={openClosePopUp} />
            </div>)
        } else {
            return <div />
        }
    }

    const retry = () => {
        dispatch({type: actionType.SET_STEP, value: -1})
        dispatch({type: actionType.RESET_EXPERIENCE})
        try {
            sessionStorage.clear()
            console.warn(sessionStorage)
        } catch (error) {
            console.error("Error with sessionStorage")
        }
    }

    useEffect(() => {
        setTimeout(() => {
            setRetryReady(true)
        }, SECOND_TO_WAIT * 1000);
    }, [])

    return (
        <PageContainer>
            <CloseButton style={{ top: '20px' }} src={closeIcon} alt='close' onClick={() => openClosePopUp(true)}></CloseButton>

            {displayClosePopUp()}

            <img style={{ width: "38px" }} alt="Bad smiley" src={smiley} />

            <ErrorText>
                {t("waitingView.otstcMessage")}
            </ErrorText>

            <ButtonContainer>
                <AnimatePresence>
                    {!isRetryReady && <LoadingText
                        exit={{ opacity: 0, transition: { duration: 0.5 } }}>
                        {t("waitingView.otstcLoading")}
                    </LoadingText>}
                </AnimatePresence>

                <RetryButton $isReady={isRetryReady} onClick={() => retry()}>
                    {t("waitingView.retry")}
                </RetryButton>
            </ButtonContainer>


        </PageContainer>
    )
}

const PageContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100svh;
    width: 100%;
    flex-direction: column;
    padding: 14px;
    box-sizing: border-box;
`

const ButtonContainer = styled.div`
    position: fixed;
    flex-direction: column;
    align-items: center;
    display: flex;
    bottom: 32px;
    gap: 8px;
    width: 100%;
`

const LoadingText = styled(motion.p)`
    font: normal normal 300 13px/24px Helvetica;
    color: #313131;
    margin: 0px;
    animation: blinking 2s infinite;
`

const ErrorText = styled.p`
    font: normal normal 400 16px/24px Helvetica;
    color: #313131;
    text-align: center;
`

const RetryButton = styled(MainButton) <{ $isReady: boolean }>`
    text-transform: uppercase;
    background-color: ${props => props.$isReady ? "black" : "#EBEBEB"};
    pointer-events: ${props => props.$isReady ? "initial" : "none"};
    transition: all 0.3s;
`

const OTSTCErrorComponent = () => {
    return <OTSTSCErrorView />
}

export default OTSTCErrorComponent