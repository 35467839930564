import styled, { createGlobalStyle } from 'styled-components';
import PlayFair from '../Assets/Fonts/PlayfairDisplay-Regular.ttf'
import HelevticaLight from '../Assets/Fonts/HelveticaNeueLTW05-35Thin.woff'
import Helvetica from '../Assets/Fonts/HelveticaNeueLTW05-45Light.woff'
import HelveticaBold from '../Assets/Fonts/HelveticaNeueLTW05-75Bold.woff'
import PlayFairBold from '../Assets/Fonts/PlayfairDisplay-VariableFont_wght.ttf'
import { Quest } from '../Constants/QuestsConstants';
import i18n from '../i18nextInit';

export const GlobalStyle = createGlobalStyle`
    @font-face {
        font-family: 'Playfair';
        src: url(${PlayFair}) format("truetype");
    }

    @font-face {
        font-family: 'Helvetica Light';
        src: url(${HelevticaLight}) format("truetype");
    }

    @font-face {
        font-family: 'Helvetica';
        src: url(${Helvetica}) format("woff");
    }

    @font-face {
        font-family: 'Helvetica Bold';
        src: url(${HelveticaBold}) format("woff");
    }

    @font-face {
        font-family: 'Playfair Bold';
        src: url(${PlayFairBold}) format("truetype");
    }
`;

export const H1 = styled.p`
    font: normal normal 500 12px/23px Helvetica Bold;
    font-size: ${i18n.language === "tw_TW" ? "14px" : "12px"};
    text-align: center;
    padding: 0px 24px;
    margin: 0;
`;

export const H2 = styled.p`
    text-align: center;
    font: normal normal normal 20px/28px Playfair;
    color: #000000;
    margin: 0;
    padding-left: 12px;
    padding-right: 12px;
`;

export const H3 = styled.p`
    font: normal normal 500 11px/23px Helvetica Bold;
`;

export const RoutineText = styled.p`
    font: normal normal 500 13px/18px Helvetica Bold;
    font-size: ${i18n.language === "tw_TW" ? "14px" : "13px"};
    margin: 0px;
`

export const SecurityText = styled(H2)`
    padding: 0px;
    padding-bottom: 64px;
    font: normal normal 900 40px/48px Playfair;
    text-align: start;

    @media(min-width: 1224px) {
        padding-bottom: 36px;
        padding-top: 4px;
    }

    @media (min-width:768px) and (max-width: 1224px) {
        padding-bottom: 32px;
    }

    @media (max-width: 768px) {
        font: normal normal 900 30px/38px Playfair;
        padding-bottom: 36px;
    }
`

export const LittleRoutineText = styled.p`
    font: normal normal 300 10px/18px Helvetica;
    font-size: ${i18n.language === "tw_TW" ? "12px" : "10px"};
    text-transform: uppercase;
    margin: 0px;
`

export const H4 = styled.p`
    font: normal normal 300 16px/24px Playfair;
    text-align: center;
    padding: 12px 24px;

    @media (min-width: 768px) {
        padding: 0px 72px;
    }
`

export const H5 = styled.p`
    font: normal normal 300 40px/40px Playfair;
    width: calc(100% - 24px);
    margin: 0px;
`

export const BigText = styled.p`
    font: normal normal 700 40px/48px Playfair Bold;
    text-align: center;
    max-width: 70%;
`

export const ParagraphText = styled.p`
    text-align: start;
    font: normal normal 300 16px/24px Helvetica;
    font-size: ${i18n.language === "tw_TW" ? "18px" : "16px"};
    line-height: ${i18n.language === "tw_TW" ? "26px" : "24px"};
    letter-spacing: 0px;
    color: #1D1D1B;
    opacity: 1;
    margin: 0;
    padding-bottom: 24px;
`;

export const ParagraphTitle = styled.p`
    text-align: start;
    font: normal normal 500 13px/21px Helvetica Bold;
    letter-spacing: 0px;
    color: #1D1D1B;
    opacity: 1;
    padding-bottom: 12px;
`;

export const CameraText = styled.p`
    position: relative;
    z-index: 5;
    font: normal normal 500 16px/18px Helvetica Bold;
    font-size: ${i18n.language === "tw_TW" ? "18px" : "16px"};
`;

export const CameraWarningText = styled.p`
    font: normal normal normal 11px/34px Helvetica;
    font-size: ${i18n.language === "tw_TW" ? "14px" : "11px"};
    text-transform: uppercase;
    margin: 0;
`

export const QuestTitle = styled(H1)<{questStep: number}>`
    font: normal normal normal ${props => (props.questStep === Quest.contourYeux || props.questStep === Quest.preoccupationsPrincipales || props.questStep === Quest.manqueVitalite || props.questStep === Quest.exposomes) ? '24px/31px' : '30px/38px'} Playfair;
    padding: 0 12px;

    @media (min-width: 768px) {
        padding: 0px 64px;
        font: normal normal normal ${props => (props.questStep === Quest.contourYeux || props.questStep === Quest.preoccupationsPrincipales || props.questStep === Quest.manqueVitalite || props.questStep === Quest.exposomes) ? '38px/38px' : '38px/38px'} Playfair;
    }
`

export const UnderQuestTitle = styled(H4)<{questStep: number}>`
    animation: Fading 1.5s Forwards 3s;
    opacity: 0;
    z-index: 1;
    transition: opacity 0.4s;
    color: white;
    font: normal normal 300 14px/19px Helvetica;

    @media (max-width: 768px) {
        padding: 0px 24px;
    }

    @media (min-width: 768px) {
        font-size: 16px;
    }

    @media (min-width: 1224px) {
        max-width: 600px;
    }
`

export const BigNumber = styled.div`
    font: normal normal normal 80px/35px Playfair;
    color: black;
    margin: 0px;
    display: flex;
`

export const YourPreoccupationTitle = styled(H2)`
    font: normal normal normal 15px/21px Playfair;
    text-align: start;
    text-transform: uppercase;
    padding: 0px;

    @media (min-width: 1224px) {
        font: normal normal normal 26px/34px Playfair;
    }
`

export const RoutineTextIntro = styled.p`
    font: normal normal 400 20px/26px Helvetica;
    text-align: start;
    margin-bottom: 32px;
    padding: 0px 12px;
    box-sizing: border-box;

    @media(min-width: 1224px) {
        font: normal normal 400 26px/34px Helvetica;
        padding: 0px 0px 12px;
        max-width: 570px;
    }
`

export const ProductDetailText = styled(H1)`
    font: normal normal 300 13px/21px Helvetica;
    text-align: start;
    padding: 4px 12px 12px 12px;
    margin-bottom: 80px;

    @media(min-width: 1224px) {
        margin: 0px;
    }
`

export const DropDownContentText = styled.p`
    font: normal normal 300 16px/24px Helvetica;
    margin: 0px;
`

export const AdviceBoldText = styled.p`
    font: normal normal 500 17px/24px Helvetica Bold;
    margin: 0px;
    padding-top: 1px;
    text-transform: uppercase;
`

export const SkinTypeText = styled.p`
    font: normal normal 500 36px/48px Playfair;
    line-height: ${i18n.language === "tw_TW" ? "42px" : ""};
    color: #1B1919;
    bottom: 0px;
    margin: 0px;
    margin-bottom: 8px;

    @media (min-width: 768px) {
        margin-bottom: 32px;
    }

    @media (min-width: 1224px){
        font-size: 46px;
    }
`

export const SpanForRecommandedAnswer = styled.p`
    font: normal normal 500 8px/8px Helvetica Bold;
    text-transform: uppercase;
    margin: 2px 0px 0px 4px;
`

export const CatText = styled(H2)`
    text-transform: uppercase;
    font: normal normal 300 16px/20px Playfair;
    padding-top: 4px;
    text-align: center;

    @media (min-width: 1224px){
        text-align: start;
        padding: 8px 0px;
    }
`

export const TitleProduct = styled(H2)`
    font: normal normal 500 13px/20px Helvetica Bold;
    text-align: start;
    padding: 12px;

    @media (min-width: 768px) and (max-width: 1224px) {
        font-size: 16px;
    }

    @media (min-width: 1224px) {
        padding: 0px;
        height: 42px;
    }
`

export const YourRoutine = styled.p`
    font: normal normal 500 40px/42px Playfair;
    margin: 24px 0px 12px;
    width: 100%;

    @media (max-width: 1224px){
        padding: 0px 12px;
        box-sizing: border-box;
        margin-top: 0px;
    }
`

