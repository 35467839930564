import { TFunction } from "i18next"
import { Answer } from "./QuestsConstants"

export class Tag {
    color: string
    code: string
    starCode: string

    constructor(_code: string) {
        this.color = setColor(_code)
        this.code = _code
        this.starCode = this.setStarCode(_code)
    }

    setStarCode = (code: string): string => {
        switch (code) {
            case "adoucissant":
                return "TAG_1"
            case "PPgrainDePeau":
                return "TAG_2"
            case "antiAge":
                return "TAG_3"
            case "CYcernes":
                return "TAG_4"
            case "PPimperfections":
                return "TAG_5"
            case "CYpoches":
                return "TAG_6"
            case "pollution":
                return "TAG_7"
            case "CYrelachement":
                return "TAG_8"
            case "CYrides":
            case "PPrides":
                return "TAG_9"
            case "PPtaches":
            case "PPtracesResiduelles":
                return "TAG_10"
            case "PPsensibilite":
                return "TAG_11"
            case "bouclier":
                return "TAG_12"
            case "confort":
                return "TAG_13"
            case "defatiguant":
                return "TAG_14"
            case "PPmanqueEclat":
                return "TAG_15"
            case "PPsignesFatigue":
                return "TAG_16"
            case "exfoliant":
                return "TAG_17"
            case "PPperteFermete":
                return "TAG_18"
            case "PPdeshydratation":
                return "TAG_19"
            case "liftant":
                return "TAG_20"
            case "lissant":
                return "TAG_21"
            case "PPbrillance":
                return "TAG_22"
            case "nutrition":
                return "TAG_23"
            case "grasse":
                return "TAG_24"
            case "normal":
                return "TAG_25"
            case "seche":
                return "TAG_26"
            case "PPpores":
                return "TAG_27"
            case "preparateur":
                return "TAG_28"
            case "protection":
                return "TAG_29"
            case "purifiant":
                return "TAG_30"
            case "regenerant":
                return "TAG_31"
            case "reparateur":
                return "TAG_32"
            case "PPridules":
                return "TAG_33"
            case "SDNoui":
                return "TAG_34"
            case "teintUnifie":
                return "TAG_35"
            case "tonifiant":
                return "TAG_36"
            case "PDvisageYeux":
                return "TAG_37"
            case "vitalite":
                return "TAG_38"
            default:
                return "No Tag assiociated to" + code
        }
    }
}

const setColor = (code: string): string => {
    switch (code) {
        case "CYpoches":
        case "CYcernes":
        case "PPmanqueEclat":
            return "#B2658B"
        case "CYrides":
        case 'CYridules':
        case "CYrelachement":
        case "PPridules":
        case "PPrides":
        case "PPperteFermete":
        case "vitalite":
        case "SDNoui":
        case "lissant":
        case "antiAge":
            return "#BAAA82"
        case "PPgrainDePeau":
        case "PPimperfections":
        case "PPbrillance":
        case "purifiant":
        case "grasse":
            return "#B6C992"
        case "PPtaches":
        case "teintUnifie":
        case "normal":
        case "PDvisageYeux":
            return "#B6C0D1"
        case "PPsensibilite":
        case "reparateur":
        case "PPdeshydratation":
        case "PPsignesFatigue":
        case "defatiguant":
        case "seche":
        case "nutrition":
        case "pollution":
            return "#B0CDD1"
        default:
            return "gray"
    }
}

export const setTagTranslations = (code: string, t: TFunction): string => {
    switch (code) {
        case "seche":
            return t('results.tags.seche', "peau sèche")
        case "normal":
            return t('results.tags.normal', "peau normale")
        case "grasse":
            return t('results.tags.grasse', "peau mixte à grasse")
        case "confort":
            return t("results.tags.confort", "confort")
        case "nutrition":
            return t("results.tags.nutrition", "nutrition")
        case "antiAge":
            return t("results.tags.antiAge", "anti-Age")
        case "lissant":
            return t("results.tags.lissant", "lissant")
        case "CYcernes":
            return t("results.tags.cernes", "anti-cernes")
        case "CYrides":
            return t("results.tags.antiRides", "anti-rides")
        case "CYridules":
        case "PPridules":
            return t("results.tags.ridules", "repulpant")
        case "CYpoches":
            return t("results.tags.poches", "anti-poches")
        case "CYrelachement":
            return t("results.tags.relachement", "anti-relachement")
        case "PPsignesFatigue":
            return t("results.tags.signeFatigue", "energisant")
        case "SDNoui":
            return t("results.tags.soinDeNuit", "soin de nuit")
        case "PPperteFermete":
            return t("results.tags.perteFermete", "fermeté")
        case "PPimperfections":
            return t("results.tags.imperfections", "anti-imperfections")
        case "PPtaches":
        case "PPtracesResiduelles":
            return t("results.tags.taches", "anti-taches")
        case "PPdeshydratation":
            return t("results.tags.hydratant", "hydratant")
        case "teintUnifie":
            return t("results.tags.teintUnifie", "teint unifié")
        case "purifiant":
            return t("results.tags.purifiant", "purifiant")
        case "PPgrainDePeau":
            return t("results.tags.grainDePeau", "affine le grain de peau")
        case "PPmanqueEclat":
            return t("results.tags.eclat", "eclat")
        case "PPrides":
            return t("results.tags.rides", "anti-rides")
        case "PPsensibilite":
            return t('results.tags.sensibilite', "apaisant")
        case "pollution":
            return t("results.tags.pollution", "anti-pollution")
        case "PPbrillance":
            return t("results.tags.brillance", "matifiant")
        case "bouclier":
            return t("results.tags.bouclier", "bouclier")
        case "protection":
            return t("results.tags.protection", "protection")
        case "reparateur":
            return t("results.tags.reparateur", "reparateur")
        case "defatiguant":
            return t("results.tags.defatiguant", "defatiguant")
        case "exfoliant":
            return t("results.tags.exfoliant", "exfoliant")
        case "liftant":
            return t("results.tags.lifant", "liftant")
        case "adoucissant":
            return t("results.tags.adouscissant", "adoucissant")
        case "preparateur":
            return t("results.tags.preparateur", "preparateur")
        case "regenerant":
            return t("results.tags.regenerant", "régénérant")
        case "vitalite":
            return t("results.tags.revitalisant", "vitalite")
        case "revitalisant":
            return t("results.tags.revitalisant", "revitalisant")
        case "PPpores":
            return t("results.tags.pores", "pore smoothing")
        case "PDvisageYeux":
            return t("results.tags.PDvisageYeux", "Visage et yeux")
        case "tonifiant":
            return t("results.tags.tonifiant", "tonifiant")
        default:
            return code
    }
}

export const setTagCode = (code: string): string => {
    switch (code) {
        case "antiAge":
            return Answer.PPperteFermete
        case "lissant":
            return Answer.CYridules
        case "purifiant":
            return Answer.PPbrillance
        case "defatiguant":
            return Answer.PPsigneFatigue
        default:
            return code
    }
}

export class Tags {
    tags: Array<Tag>

    constructor(sku: string, file: any, questions: Array<any>) {
        this.tags = this.tagParser(file, sku, questions)
    }

    tagParser = (file: any, productSku: string, questions: Array<any>): Array<Tag> => {
        let resultArray: Array<Tag> = []
        if (!file[productSku]) {
            console.log("No Tags for this sku: " + productSku)
            return []
        }

        for (const [key, values] of Object.entries(file[productSku])) {
            if (key === "always") {
                if (values instanceof Array) {
                    values.forEach((tag: string) => {
                        resultArray.push(new Tag(tag))
                    });
                }
            }

            if (key === "selected") {
                if (values instanceof Array) {
                    values.forEach((tag: string) => {
                        if (questions.includes(setTagCode(tag))) {
                            resultArray.push(new Tag(tag))
                        }
                    })
                }
            }

            if (key === "other") {
                if (values instanceof Array) {
                    values.forEach((tag: string) => {
                        if (resultArray.length === 0) {
                            resultArray.push(new Tag(tag))
                        }

                    })
                }
            }
        }

        return resultArray
    }
}