import styled from 'styled-components'
import arrowBack from '../Assets/Icons/arrow_back.svg'
import close from '../Assets/Icons/ICONClose.svg'
import lockIcon from '../Assets/Icons/LockIcon.png'
import loadingIcon from '../Assets/Icons/LoadingIcon.png'
import okIcon from '../Assets/Icons/OKIcon.svg'
import sisleyFlower from '../Assets/Icons/LogoSisley.svg'
import { Quest } from '../Constants/QuestsConstants'

export const IMG = styled.div`
    border-image: none;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat; 
`;

export const LockIcon = styled(IMG)`
    width: 28px;
    height: 40px;
    background-image : url(${lockIcon});
`

export const BackIcon = styled(IMG) <{ step: number }>`
    display: ${props => (props.step === 3 || props.step === 2) && 'none'};
    position: fixed;
    left: 24px;
    width: 20px;
    height: 22px;
    background-image : url(${arrowBack});
    filter: invert(100%) brightness(0);
`;

export const BackQuestIcon = styled(IMG) <{ step: number }>`
    position: fixed;
    top: 20px;
    left: 24px;
    width: 20px;
    height: 22px;
    background-image : url(${arrowBack});
    filter: invert(100%) brightness(0);
    z-index: 4;
`;

export const QuestIcon = styled(IMG) <{ url: any, isSelected: any }>`
    width: 100%;
    height: 100%;
    filter: ${props => props.isSelected ? 'invert(0)' : 'invert(1)'}; 
    background-image: url(${props => props.url})
`;

export const NextArrow = styled(IMG) <{ isPointingRight: boolean, isDisplayed: boolean }>`
    position: absolute;
    // display: ${props => !props.isDisplayed && 'none'};
    top: 80px;
    width: 40px;
    height: 40px;
    left: ${props => !props.isPointingRight && '0px'};
    right: ${props => props.isPointingRight && '0px'};
    background-image : url(${arrowBack});
    transform: ${props => props.isPointingRight && 'rotate(180deg)'};
    filter: invert(100%) brightness(0);
`

export const CloseIcon = styled(IMG)`
    height: 14px;
    width: 14px;
    background-image: url(${close});
    border-radius: 24px;
`

export const ImgContainer = styled.div`
    width: 80px;
    height: 80px;
    display: flex;
    padding: 6px;
    margin-left: 18px;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
`

export const OKWarning = styled(IMG) <{ step: number }>`
    width:28px;
    height: 28px;
    display: ${props => props.step === 0 ? "none" : "flex"};
    background-image: ${props => props.step === 2 ? `url(${okIcon})` : `url(${loadingIcon})`};
    animation: ${props => props.step === 1 ? "rotating 1s linear infinite" : "none"};
`

export const SisleyFlower = styled(IMG)`
    background-image : url(${sisleyFlower});
    width: 80px;
    height: 80px;
    position: fixed;
    bottom: 72px;

    @media (min-width: 768px){
        bottom: 84px;
    }
`

export const FaceIcon = styled.img`
    width: 154px;

    @media (min-width: 768px){
        width: 210px;
    }
`

export const WaitingBarEmpty = styled.div`
    width: 200px;
    height: 3px;
    background-color: #F6F6F6;
`

export const WaitingBarFilling = styled.div<{time: number}>`
    position: absolute;
    height: 3px;
    width: 30%;
    background-color: #5D8C82;
    animation: barFilling ${props => props.time}s linear forwards;
`

export const ProductForWaiting = styled.div`
    height: 300px;
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: center;
    animation: ChangeImage 6s Forwards;

    @media (min-width: 768px) {
        width: 85%;
        height: 500px;
    }
`

export const OKIcon = styled(IMG)`
    background-image: url(${okIcon});
    width: 48px;
    height: 48px;
`

export const BackgroundImage = styled(IMG) <{ picture: string, questStep: Quest }>`
    background-image: url(${props => props.picture});
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-size: cover;
    bottom: 0px;
    z-index: 0;
    animation: FadingBackground 4s Forwards;

    @media (max-width: 768px){
        background-position: ${props => props.questStep === 3 ? "center" : ""};
    }
`

export const StepIcon = styled.div`
    width: 32px;
    height: 32px;
    border-radius: 24px;
    background-color: black;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font: normal normal 500 10px/20px Helvetica Bold;
`

export const BlackRound = styled.div`
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    border-radius: 100px; 
`

export const BlackCurtain = styled.div<{ isActive: boolean, isForDetails: boolean }>`
    width: ${props => props.isActive && props.isForDetails ? 'calc(100vw - 464px)' : '100vw'};
    height: 100vh;
    background-color: rgb(40,40,40, 0.4);
    opacity: ${props => props.isActive ? 0.5 : 0};
    position: fixed;
    left: 0;
    top: 0;
    z-index: 10;
`

export const SkinTypeImage = styled.img`
    width: 100%;
    max-height: 350px;
    object-fit: cover;

    @media (min-width: 1224px) {
        width: 100vw;
        max-height: 450px;
        position: absolute;
        left: calc((100vw - 1200px) / -2);
    }

    @media(max-width: 1224px) {
        object-position: bottom;
    }

    @media (min-width: 2000px){
        max-height: 750px;
        max-width: 2000px;
        left: calc((100vw - 1000px) / -4);
    }
`

export const ImageCellProduct = styled.img`
    width: 100%;
    margin-top: -50px;
    object-fit: contain;

    @media (min-width: 768px){
        width: 30%;
        height: 30%;
    }

    @media (min-width: 1224px){
        width: 100%;
        height: auto;
    }
`

export const Line = styled.div`
    width: 100%;
    height: 0.5px;
    background-color: black;
    margin-bottom: 42px;
    margin-top: 24px;
    opacity: 0.25;

    @media (min-width: 768px){
        display: none;
    }
`

export const OTSTCmark = styled.img`
    height: 12px;
`




