import { FaceLandmarksDetector, SupportedModels, createDetector } from '@tensorflow-models/face-landmarks-detection';
import '@tensorflow/tfjs-backend-webgl';
import { NAVSIZE } from './../Components/CameraView';
import { ConfigDetection, MESH_ANNOTATIONS } from './type';


export const loadFaceLandmarksDetectionModel = async (): Promise<FaceLandmarksDetector> => {
    return createDetector(SupportedModels.MediaPipeFaceMesh, {
        runtime: 'mediapipe',
        solutionPath: 'https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh',
        refineLandmarks: true,
    })

};

export const faceDetection = async (webcam: any, configDetection: ConfigDetection, model?: FaceLandmarksDetector, debug?: boolean): Promise<FaceLandmarksDetectionResults | undefined> => {
    let status: FaceLandmarksDetectionStatus = {
        good: true,
        loading: false,
        modelError: false,
        noFace: false,
        badPositionBottom: false,
        badPositionLeft: false,
        badPositionRight: false,
        badPositionTop: false,
        badDistanceFar: false,
        badDistanceClose: false,
        badOrientation: false,
        badBrightness: false,
    };

    if (!model) {
        return {
            status: {
                loading: true,
                modelError: true,
            },
        };
    }

    if (!webcam) {
        return {
            status: {
                loading: true,
            },
        };
    }
    const video = webcam.video;
    const { videoHeight, videoWidth }: { videoHeight: number, videoWidth: number } = video;

    const predictions = await model.estimateFaces(video);


    if (predictions.length === 0) {
        return {
            status: {
                noFace: true,
                good: false,
            },
        };
    }

    const keypoints = predictions[0].keypoints;
    const annotations = Object.entries(MESH_ANNOTATIONS).reduce((acc: any, [key, value]) => {
        acc[key] = value.map((pos: number) => Object.values(keypoints[pos]).slice(0, 3));
        return acc;
    }, {});
    const scalesMesh = Object.values(keypoints).map(k => Object.values(k).slice(0, 3))

    let res: FaceLandmarksDetectionResults = {
        status: positionDetection(annotations, status, configDetection, videoWidth, videoHeight),
    };

    if (res.status?.good) {
        let image = webcam.getScreenshot();
        res = {
            ...res,
            predictions: [{
                annotations: annotations,
                scaledMesh: scalesMesh,
                faceInViewConfidence: 1
            }],
            image: image,
        };
    }

    return res;
};

const positionDetection = (annotations: any, status: FaceLandmarksDetectionStatus, configDetection: ConfigDetection, imgWidth: number, imgHeight: number): FaceLandmarksDetectionStatus => {
    const heightRatio = imgHeight / (window.innerHeight - NAVSIZE);

    if (annotations.length === 0) {
        status.noFace = true;
        status.good = false;
    }

    const cheeksDistance = getDistance(annotations.leftCheek[0], annotations.rightCheek[0]);

    if (cheeksDistance < window.outerHeight * 4 / 3 * (configDetection.zMin)) {
        status.badDistanceFar = true;
        status.good = false;
    }

    if (cheeksDistance > window.outerHeight * 4 / 3 * (configDetection.zMax)) {
        status.badDistanceClose = true;
        status.good = false;
    }


    if (annotations.noseBottom[0][0] < imgWidth * (configDetection.xMin)) {
        status.badPositionLeft = true;
        status.good = false;
    }

    if (annotations.noseBottom[0][0] > imgWidth * (configDetection.xMax)) {
        status.badPositionRight = true;
        status.good = false;
    }

    if ((annotations.noseBottom[0][1] / heightRatio) < (window.innerHeight - NAVSIZE) * (configDetection.yMin)) {
        status.badPositionTop = true;
        status.good = false;
    }

    if ((annotations.noseBottom[0][1] / heightRatio) > (window.innerHeight - NAVSIZE) * (configDetection.yMax)) {
        status.badPositionBottom = true;
        status.good = false;
    }

    if (Math.abs(annotations.rightEyeUpper0[4][1] - annotations.leftEyeUpper0[4][1]) > configDetection.roll) {
        status.badOrientation = true;
        status.good = false;
    }

    return status;
};

// Compute the height of the rectangle that represents one area of the face
export const computeHeight = (x1: number, y1: number, x2: number, y2: number) => {
    if (y1 < y2) {
        return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
    } else return -Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
};

// Compute the width of the rectangle that represents one area of the face
export const computeWidth = (x1: number, y1: number, x2: number, y2: number) => {
    if (x1 < x2) {
        return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
    } else return -Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
};

export const getDistance = (a: Array<any>, b: Array<any>): number => {
    return Math.sqrt((b[0] - a[0]) * (b[0] - a[0]) + (b[1] - a[1]) * (b[1] - a[1]));
};

export const setWarningText = (detectionRes: FaceLandmarksDetectionResults): string => {
    if (!detectionRes) {
        return "Loading"
    }
    if (detectionRes.status?.loading) {
        return 'Loading';
    }

    if (detectionRes.status?.good) {
        return 'good';
    }

    if (detectionRes.status?.noFace) {
        return "No Face"
    }

    if (detectionRes.status?.badDistanceFar) {
        return 'Far';
    }
    if (detectionRes.status?.badDistanceClose) {
        return 'Close';
    }
    if (detectionRes.status?.badPositionLeft) {
        return 'Left';
    }
    if (detectionRes.status?.badPositionRight) {
        return 'Right';
    }
    if (detectionRes.status?.badPositionBottom) {
        return 'Low';
    }
    if (detectionRes.status?.badPositionTop) {
        return "High";
    }
    if (detectionRes.status?.badBrightness) {
        return 'Bad light';
    }
    if (detectionRes.status?.badOrientation) {
        return 'Bad orientation';
    }

    else return 'err'
}

export type FaceLandmarksDetectionStatus = {
    loading?: boolean;
    noFace?: boolean;
    modelError?: boolean;
    good?: boolean;
    taken?: boolean;
    badPositionLeft?: boolean;
    badPositionRight?: boolean;
    badPositionTop?: boolean;
    badPositionBottom?: boolean;
    badDistanceFar?: boolean;
    badDistanceClose?: boolean;
    badOrientation?: boolean;
    badBrightness?: boolean;
};

export type FaceLandmarksDetectionResults = {
    status?: FaceLandmarksDetectionStatus;
    predictions?: any;
    image?: any;
};
