import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { CloseButton } from '../Styles/Buttons'
import { OptInContainer } from '../Styles/Containers'
import { BlackCurtain } from '../Styles/Images'
import closeIcon from '../Assets/Icons/Close_B.svg'

const HelpSkinPopUp = (props: any) => {


    const [closed, setClosed] = useState<boolean>(true)
    const { t } = useTranslation()

    useEffect(() => {
        setClosed(false)
    }, [])

    const close = () => {

        setClosed(true)
        if (document.getElementById("BLACK_CURTAIN")) {
            document.getElementById("BLACK_CURTAIN")!.style.opacity = "0"
        }

        if (window.innerWidth > 768) {
            props.setOptIn(false)
        } else {
            setTimeout(() => {
                props.setOptIn(false)
            }, 300);
        }
    }

    return (
        <PanelContainer>
            <BlackCurtain onClick={() => close()} id="BLACK_CURTAIN" isForDetails={false} isActive={true} />

            <OptInContainer isHelp isClosed={closed}>

                <CloseButton style={{ position: "absolute", }} src={closeIcon} alt="close" onClick={() => close()}>
                </CloseButton>

                <HelpTitle>{t('questionView.helpTitle', 'Déterminez votre type de peau')}</HelpTitle>

                <HelpH1>{t('questionView.helpDryTitle', 'Sèche')}</HelpH1>
                <HelpH2>{t('questionView.helpDryText', 'Sèche : Votre peau manque de confort et à tendance à peler. Vous ressentez des sensations de picotement et tiraillement au quotidien.')}</HelpH2>

                <HelpH1>{t('questionView.helpNormalTitle', 'Normale')}</HelpH1>
                <HelpH2>{t('questionView.helpNormalText', 'Normale : Votre peau est confortable et ne présente ni tiraillements, ni brillances.')}</HelpH2>

                <HelpH1>{t('questionView.helpOilyTitle', 'Mixte à grasse')}</HelpH1>
                <HelpH2>{t('questionView.helpOilyText', 'Mixte à grasse : Votre peau à tendance à briller, en particulier sur la zone T. ')}</HelpH2>

            </OptInContainer>
        </PanelContainer>)
}

const HelpTitle = styled.p`
        margin: 0px;
        font: normal normal 500 26px/26px Playfair;
    `

const HelpH1 = styled.p`
        margin: 24px 0px 12px;
        font: normal normal 500 20px/26px Playfair;
    `

const HelpH2 = styled.p`
        margin: 0px;
        font: normal normal 500 13px/21px Helvetica;
    `

export const PanelContainer = styled.div`
    width: 100%;
    height: 100%;

    @media (min-width: 768px){
        display: flex;
        justify-content: center;
        align-items: center;
        left: 0px;
    }
`

export default HelpSkinPopUp