import { ContactTextContainer, FooterContainer } from '../../Styles/Containers'
import { useTranslation } from 'react-i18next'
import { BlackRound } from '../../Styles/Images'

import PhoneIcon from '../../Assets/Icons/phone.svg'
import { H1 } from '../../Styles/Texts'
import { WhiteButton } from '../../Styles/Buttons'
import { useSelector } from 'react-redux'
import { MySkinDiagState } from '../../type'
import styled from 'styled-components'
import i18n from '../../i18nextInit'

const FooterView = (props: any) => {
    const { t } = useTranslation()
    const isSignedIn = useSelector((store: MySkinDiagState) => store.session.isSignedIn)
    return (
        <FooterContainer id={props.isShortRoutine ? "FOOTER_VIEW" : ""}>
            {window.innerWidth < 768 && !window.MAIL_DISABLED_LANGS.includes(i18n.language) &&
                <WhiteButton disabled={isSignedIn}
                    onClick={() => props.optinHandler()}
                    style={{
                        font: 'normal normal 500 12px/15px Helvetica Bold',
                        textTransform: 'uppercase',
                        marginBottom: props.isDesktop ? '' : '24px',
                        color: isSignedIn ? "gray" : ""
                    }}>{t('results.getByMailCTA', "Recevoir mes résultats par email")}
                </WhiteButton>}

            <Contact />

            {!props.isDesktop &&
                <ButtonContainer isInReco={props.isInReco}>
                    <WhiteButton
                        onClick={() => props.retry(true)}
                        style={{
                            font: 'normal normal 500 12px/15px Helvetica Bold',
                            textTransform: 'uppercase',
                            width: window.innerWidth > 768 ? "47%" : props.isInReco ? "calc(100% - 24px)" : "100%",
                            marginBottom: props.isDesktop ? '' : '24px',
                        }}>{t('results.retry', 'Recommencer le diagnostic')}
                    </WhiteButton>

                    {window.innerWidth > 768 && !window.MAIL_DISABLED_LANGS.includes(i18n.language) &&
                        <WhiteButton disabled={isSignedIn}
                            onClick={() => props.optinHandler()}
                            style={{
                                font: 'normal normal 500 12px/15px Helvetica Bold',
                                textTransform: 'uppercase',
                                marginBottom: props.isDesktop ? '' : '16px',
                                color: isSignedIn ? "gray" : "",
                                width: window.innerWidth > 768 ? "47%" : "100%"
                            }}>{t('results.getByMailCTA', "Recevoir mes résultats par email")}
                        </WhiteButton>}
                </ButtonContainer>}

            {props.isDesktop && <div style={{ maxWidth: "570px", display: "flex", width: "100%", columnGap: "24px", justifyContent: "center" }}>
                <WhiteButton
                    onClick={() => props.retry(true)}
                    style={{
                        font: 'normal normal 500 10px/15px Helvetica Bold',
                        fontSize: i18n.language === "tw_TW" ? "12px" : "10px",
                        textTransform: 'uppercase',
                        marginBottom: props.isDesktop ? '' : '16px',

                    }}>{t('results.retry', 'Recommencer le diagnostic')}
                </WhiteButton>
                {!window.MAIL_DISABLED_LANGS.includes(i18n.language) && <WhiteButton disabled={isSignedIn}
                    onClick={() => props.optinHandler()}
                    style={{
                        font: 'normal normal 500 10px/15px Helvetica Bold',
                        fontSize: i18n.language === "tw_TW" ? "12px" : "10px",
                        textTransform: 'uppercase',
                        marginBottom: props.isDesktop ? '' : '16px',
                        color: isSignedIn ? "gray" : ""

                    }}>{t('results.getByMailCTA', "Recevoir mes résultats par email")}
                </WhiteButton>}
            </div>}

        </FooterContainer>
    )
}

const ButtonContainer = styled.div<{ isInReco: boolean }>`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    padding: ${props => props.isInReco ? "24px 0px" : "24px 12px 12px"};
    justify-content: ${props => props.isInReco ? "start" : "center"};

    @media (min-width: 768px){
        justify-content: space-between;
        padding: 24px 0px 12px;
    }
`

const Contact = () => {
    const { t } = useTranslation()

    return (
        <ContactContainer>
            <div style={{ flex: 1, maxWidth: "64px", alignItems: 'center', justifyContent: 'center' }}>
                <BlackRound>
                    <img alt={"Contact Icon"} src={PhoneIcon} style={{ width: '16px', height: '16px' }} />
                </BlackRound>
            </div>

            <ContactTextContainer>
                <H1 style={{ padding: '0px', textAlign: 'start', lineHeight: '16px', paddingBottom: '4px' }}>{t('results.needHelp', 'Pour encore plus de conseils personnalisés, nos experts beauté se tiennent à votre disposition. Bénéficiez d\'un accompagnement sur-mesure et découvrez tous les secrets d\'application des soins Sisley.')}<br /> <span onClick={() => window.open(t("results.contactLink"), "_BLANK")} style={{ textDecoration: "underline", font: "normal normal 500 12px/23px Helvetica", cursor: "pointer" }}>{t('results.contactUs', 'Prendre rendez-vous')}</span></H1>
            </ContactTextContainer>
        </ContactContainer>
    )
}

const ContactContainer = styled.div`
    width: calc(100% - 24px);
    max-width: 570px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;

    @media (min-width: 768px) and (max-width: 1224px){
        width: 100%;
        max-width: initial;
    }
`

export default FooterView