import videoMoodMob from '../Assets/VIDEOS/MOB_mood_work.mp4'
import videoMoodDesk from '../Assets/VIDEOS/220208_Mood_Desktop.mp4'
import * as ActionsTypes from '../Constants/ActionsTypes'

import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { runHeightMachine, sendAnalytics } from "../Constants/Utilitaries"

import { BigNumber, BeginButton, CarouselButton, CarouselContainer, CarouselPoint, CarouselPointsContainer, Container, ContentContainer, Intro, Item, ItemText, ItemTitle, SisleySkinExpertContainer, SisleyText, SkinExpertText, Title, Video, VideoContainer } from "../Styles/Intro/NewIntro"
import { useEffect, useRef, useState } from 'react'
import i18n from '../i18nextInit'

export const HomePage = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const carouselRef = useRef<HTMLDivElement>(null)
    const pointsRef = useRef<HTMLDivElement>(null)
    const stepRef = useRef<number>(1)
    const [step, setStep] = useState<number>(0)
    const [stateInterval, setStateInterval] = useState<any>(null)
    const [isPhoneMode, setTabletMode] = useState<boolean>(window.innerWidth < window.innerHeight)

    const beginSkinDiag = () => {
        sendAnalytics("start_1")
        dispatch({ type: ActionsTypes.SET_STEP, value: 0 })
    }

    const animateCarousel = () => {
        let _interval = setInterval(() => {
            setStep(stepRef.current)
            switch (stepRef.current) {
                case 0:
                    carouselRef.current!.scrollLeft = 0
                    stepRef.current++
                    break;
                case 1:
                    carouselRef.current!.scrollLeft = carouselRef.current!.clientWidth * (window.RTL_LANGS.includes(i18n.language) ? -1 : 1)
                    stepRef.current++
                    break
                case 2:
                    carouselRef.current!.scrollLeft = 2 * carouselRef.current!.clientWidth * (window.RTL_LANGS.includes(i18n.language) ? -1 : 1)
                    stepRef.current = 0
                    break
                default:
                    break;
            }
        }, 7000)

        setStateInterval(_interval)
    }

    const handleCarouselClick = () => {
        window.clearInterval(stateInterval)

        setStep(stepRef.current)
        switch (stepRef.current) {
            case 0:
                carouselRef.current!.scrollLeft = 0
                stepRef.current++
                break;
            case 1:
                carouselRef.current!.scrollLeft = carouselRef.current!.clientWidth * (window.RTL_LANGS.includes(i18n.language) ? -1 : 1)
                stepRef.current++
                break
            case 2:
                carouselRef.current!.scrollLeft = 2 * carouselRef.current!.clientWidth * (window.RTL_LANGS.includes(i18n.language) ? -1 : 1)
                stepRef.current = 0
                break
            default:
                break;
        }

        animateCarousel()
    }

    useEffect(() => {
        runHeightMachine(false, "ONBOARD")

        let dataBis = {
            messageType: "scroll_top",
        };
        console.log(dataBis)
        window.parent.postMessage(dataBis, "*")
        
        let timeOut = setTimeout(() => {
            animateCarousel()
        }, 1000);

        const resize = () => {
            if (window.innerWidth > window.innerHeight) {
                setTabletMode(false)
            } else {
                setTabletMode(true)
            }
        }

        window.addEventListener("resize", resize)
        dispatch({type: ActionsTypes.RESET_EXPERIENCE})

        return () => { 
            window.removeEventListener("resize", resize)
            window.clearTimeout(timeOut)
        }
        //eslint-disable-next-line
    }, [])

    useEffect(() => {

        return () => {
            window.clearInterval(stateInterval)
        }

    }, [stateInterval])

    return (<Container id="ONBOARD">

        {!isPhoneMode && <SisleySkinExpertContainer height={pointsRef.current ? pointsRef.current.getBoundingClientRect().top + 45 : undefined}>
            <SisleyText>Sisley</SisleyText>
            <SkinExpertText>SkinExpert</SkinExpertText>
        </SisleySkinExpertContainer>}

        <VideoContainer>
            {isPhoneMode && <SisleySkinExpertContainer>
                <SisleyText>Sisley</SisleyText>
                <SkinExpertText>SkinExpert</SkinExpertText>
            </SisleySkinExpertContainer>}
            <Video
                onLoad={(e) => e.currentTarget.play()}
                muted={true}
                loop={true}
                autoPlay={true}
                playsInline={true}>
                <source type={"video/mp4"} src={isPhoneMode ? videoMoodDesk : videoMoodMob} />
            </Video>
        </VideoContainer>
        <ContentContainer>
            <Title dangerouslySetInnerHTML={{ __html: t('introView.yourSkinDiagDesk', 'VOTRE DIAGNOSTIC DE PEAU') }} />
            <Intro>
                {t("introView.textInButton", "Découvrez la routine de soin parfaitement adaptée à votre peau et à vos besoins.")}
            </Intro>

            <BeginButton onClick={() => beginSkinDiag()}>
                {t("introView.beginDiag", "Commencer le diagnostic")}
            </BeginButton>

            <CarouselContainer ref={carouselRef}>
                <Item>
                    <ItemTitle>{t('introView.title1', 'Démarrez l’analyse de peau')}</ItemTitle>
                    <ItemText>{t('introView.content1', 'Prenez un selfie et lancez l’analyse de peau ; grâce à l’intelligence artificielle, nous pourrons déterminer l’état et les besoins de votre peau.')}</ItemText>
                    <BigNumber>01</BigNumber>
                </Item>

                <Item>
                    <ItemTitle>{t('introView.title2', 'Renseignez vos préoccupations beauté')}</ItemTitle>
                    <ItemText>{t('introView.content2', 'Remplissez le questionnaire pour que nous puissions au mieux comprendre vos besoins et ainsi vous apporter des conseils personnalisés.')}</ItemText>
                    <BigNumber>02</BigNumber>
                </Item>

                <Item>
                    <ItemTitle>{t('introView.title3')}</ItemTitle>
                    <ItemText>{t('introView.content3')}</ItemText>
                    <BigNumber>03</BigNumber>
                </Item>
            </CarouselContainer>

            <CarouselButton top={carouselRef.current ? carouselRef.current.offsetTop : undefined} height={carouselRef.current ? carouselRef.current.clientHeight : undefined} onClick={() => handleCarouselClick()} />

            <CarouselPointsContainer onClick={() => handleCarouselClick()} ref={pointsRef}>
                <CarouselPoint selected={step === 0} />
                <CarouselPoint selected={step === 1} />
                <CarouselPoint selected={step === 2} />
            </CarouselPointsContainer>
        </ContentContainer>


    </Container>)
}

export const NewHomePageComponent = () => {
    return <HomePage />
}

export default NewHomePageComponent