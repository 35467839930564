import * as ActionTypes from '../Constants/ActionsTypes'
import { Answer } from '../Constants/QuestsConstants'
import { TryOnProductClass } from '../Constants/TryOnProduct'
import { MySkinDiagState } from '../type'

const initialState: MySkinDiagState = {
    session: {
        step: -1,
        isOnBoardDisplayed: true,
        error: [],
        isSignedIn: false,
        imageUser: "",
        isPrivateNavigation: false,
        analyses: [
            { name: "WRINKLES", score: 0.3952355714285714 },
            { name: "WRINKLES_UNDER_EYE", score: 0.5552355714285714 },
            { name: "DARK_SPOTS", score: 0.544971, image: "https://s3.eu-west-1.amazonaws.com/otstc.photos/ab…22796695&Signature=nHQrsAAJz896qB63TB2s7AT5WJQ%3D" },
            { name: "SHININESS", score: 0.865034, image: "https://s3.eu-west-1.amazonaws.com/otstc.photos/ab…796695&Signature=Xf6kxvvX1aTRyM0H%2B929CZVQpTQ%3D" },
            { name: "RADIANCE", score: 0.443158, image: "https://s3.eu-west-1.amazonaws.com/otstc.photos/ab…796695&Signature=Jt%2FeKLoL9EUaDUaREOIAJzfiw98%3D" },
            { name: "BLACK_SPOTS", score: 0.5, image: "https://s3.eu-west-1.amazonaws.com/otstc.photos/ab…&Signature=%2FeGZm%2FTORc%2FNzp4M%2Fbf0cgQ3SwA%3D" },
            { name: "ACNE", score: 0.75, image: "https://s3.eu-west-1.amazonaws.com/otstc.photos/ab…22796695&Signature=49TYD4Xp8k94WsJslO4aQQsehLg%3D" },
            { name: "HOLLOW_CHEEKS", score: 0.6 },
            { name: "HYDRATION", score: 0 },
            { name: "LOSS_OF_FIRMNESS", score: 0.4 },
            { name: "DARK_CIRCLES", score: 0.9 },
            { name: "EYES_BAGS", score: 0.2 },
            { name: "ROUGHNESS", score: 0.9 },
        ],
        analyseStep: 0,
        questions: ["femme", [Answer.PPrides, Answer.PPperteFermete], [Answer.CYpoche], Answer.normal, Answer.specifiqueVisage, Answer.avecRincage, Answer.MVnon, Answer.SDNoui, Answer.stress],
        cheek: "",
        actualConcern: null,
        positions: [],
        panier: [],
        playGroundCart: [],
        currentPGProductToModify: null,
        products: [],
        reco: [],
        bridge: null,
        clientData: { bearer: "", basketID: "" },
        tryOnProducts: [],
        isExpand: false // dégueu mais en cours de résolution, test
    }
}

const enabledConcerns = [
    "WRINKLES",
    "WRINKLES_UNDER_EYE",
    "EYES_BAGS",
    "DARK_CIRCLES",
    "LOSS_OF_FIRMNESS",
    "SHININESS",
    "RADIANCE",
    "REDNESS",
    "ACNE",
    "PORES",
    "DARK_SPOTS",
    "TEAR_THROUGH",
    "HOLLOW_CHEEKS",
    "LOWER_FACE_PTOSIS",
    "BLACK_SPOTS",
    "HYDRATION",
    "ROUGHNESS"
]

const reducer = (state = initialState, action: any): MySkinDiagState => {
    let nextstate
    let _tryOnProducts: TryOnProductClass[] = []

    switch (action.type) {

        case ActionTypes.UPDATE_CURRENT_PG_PRODUCT:
            nextstate = {
                session: {
                    ...state.session,
                    currentPGProductToModify: action.value
                }
            }

            return nextstate

        case ActionTypes.UPDATE_PLAYGROUND_CART:

            let _updated_cart = [...state.session.playGroundCart]

            if (action.value.length) {
                _updated_cart = action.value
            } else {
                _updated_cart.push(action.value)
            }


            nextstate = {
                session: {
                    ...state.session,
                    playGroundCart: _updated_cart
                }
            }
            console.warn("[CART]")
            console.warn(nextstate.session.playGroundCart)
            return nextstate

        case ActionTypes.REMOVE_PLAYGROUND_CART_PRODUCT:

            let _cart: TryOnProductClass[] = [...state.session.playGroundCart]

            if (!action.value.sku) {
                return state
            }

            _cart = _cart.filter(product => product.parentSku !== action.value.parentSku)

            nextstate = {
                session: {
                    ...state.session,
                    playGroundCart: _cart
                }
            }

            console.warn("[REDUCER] CArt: ")
            console.warn(nextstate.session.playGroundCart)
            return nextstate

        case ActionTypes.SET_EXPAND:
            nextstate = {
                session: {
                    ...state.session,
                    isExpand: action.value
                }
            }
            return nextstate

        case ActionTypes.SET_PRIVATE_MODE:
            nextstate = {
                session: {
                    ...state.session,
                    isPrivateNavigation: action.value
                }
            }

            return nextstate

        case ActionTypes.SET_SIGNED_IN:
            nextstate = {
                session: {
                    ...state.session,
                    isSignedIn: action.value
                }
            }

            return nextstate

        case ActionTypes.REMOVE_TRYON_PRODUCT:
            _tryOnProducts = [...state.session.tryOnProducts]

            if (action.value === null) {
                return state
            }

            let _productToRemove: TryOnProductClass | undefined = _tryOnProducts.find(product => product.parentSku === action.value.parentSku)
            console.warn("[REMOVE_TRY_ON]")
            console.warn(_productToRemove)
            if (_productToRemove && _productToRemove.actualVariant !== "NoShade") {
                const index = _tryOnProducts.indexOf(_productToRemove, 0);
                if (index > -1) {
                    _tryOnProducts.splice(index, 1);
                }
            }
            //_tryOnProducts = _tryOnProducts.filter(product => product.parentSku !== action.value.parentSku)

            nextstate = {
                session: {
                    ...state.session,
                    tryOnProducts: _tryOnProducts
                }
            }

            return nextstate

        case ActionTypes.NEW_UPDATE_TRY_ON_PRODUCT:

            _tryOnProducts = [...state.session.tryOnProducts]
            let _tryOnProductToUpdate: TryOnProductClass = action.value
            let productFound: boolean = false

            if (action.value.length) {
                _tryOnProducts = action.value
                productFound = true
            } else {
                for (let i = 0; i < _tryOnProducts.length; i++) {
                    if (_tryOnProducts[i].parentSku === _tryOnProductToUpdate.parentSku) {
                        _tryOnProducts[i].actualVariant = _tryOnProductToUpdate.actualVariant
                        _tryOnProducts[i].sku = _tryOnProductToUpdate.sku
                        _tryOnProducts[i].name = _tryOnProductToUpdate.name
                        _tryOnProducts[i].shadeColor = _tryOnProductToUpdate.shadeColor
                        _tryOnProducts[i].shadeName = _tryOnProductToUpdate.shadeName
                        _tryOnProducts[i].imgURL = _tryOnProductToUpdate.imgURL
                        _tryOnProducts[i].description = _tryOnProductToUpdate.description
                        _tryOnProducts[i].introduction = _tryOnProductToUpdate.introduction
                        _tryOnProducts[i].isActive = _tryOnProductToUpdate.isActive
                        _tryOnProducts[i].isVTOEnable = _tryOnProductToUpdate.isVTOEnable
                        _tryOnProducts[i].makeUpCategory = _tryOnProductToUpdate.makeUpCategory
                        _tryOnProducts[i].price = _tryOnProductToUpdate.price
                        _tryOnProducts[i].promo = _tryOnProductToUpdate.promo
                        _tryOnProducts[i].stock = _tryOnProductToUpdate.stock
                        _tryOnProducts[i].isLiner = _tryOnProductToUpdate.isLiner

                        productFound = true
                    }
                 }
            }

            if (!productFound) {
                console.warn("Adding product")
                _tryOnProducts.push(_tryOnProductToUpdate)
            }

            nextstate = {
                session: {
                    ...state.session,
                    tryOnProducts: _tryOnProducts
                }
            }
            console.warn("[TRY ON PRODUCTS]")
            console.warn(nextstate.session.tryOnProducts)
            return nextstate

        case ActionTypes.UPDATE_TRYON_PRODUCTS:
            let tryOnProducts: Array<TryOnProductClass> = [...state.session.tryOnProducts]

            if (action.value === null) {
                return state
            }

            if (action.value.length) {
                tryOnProducts = action.value
            } else if (!tryOnProducts.find((e: any) => e.sku === action.value.sku)) {
                tryOnProducts.push(action.value)
            } else {
                tryOnProducts.find((e: any) => e.sku === action.value.sku)!.isActive = action.value.isActive
            }

            if (!action.value.sku && !action.value.length) {
                return state
            }

            nextstate = {
                session: {
                    ...state.session,
                    tryOnProducts: tryOnProducts
                }
            }
            console.warn("[TRY ON PRODUCTS]")
            console.warn(nextstate.session.tryOnProducts)
            return nextstate

        case ActionTypes.CREATE_NEW_VARIANT:

            let __tryOnProducts: Array<TryOnProductClass> = state.session.tryOnProducts
            __tryOnProducts[0].variants.push(action.value)
            __tryOnProducts[0].variants = __tryOnProducts[0].variants.sort((a, b) => a.name.localeCompare(b.name))

            console.warn(__tryOnProducts[0])

            nextstate = {
                session: {
                    ...state.session,
                    tryOnProducts: __tryOnProducts
                }
            }

            return nextstate

        case ActionTypes.UPDATE_TRYON_VARIANTS:
            //cherche le sku dans les variants de tous les produits et l'update dès que trouver
            _tryOnProducts = [...state.session.tryOnProducts]

            _tryOnProducts.forEach((tryOnprodut: TryOnProductClass) => {
                if (tryOnprodut.variants.length > 0) {
                    for (let i = 0; i < tryOnprodut.variants.length; i++) {
                        if (tryOnprodut.variants[i].sku === action.value.sku) {
                            tryOnprodut.variants[i] = action.value
                        }
                    }
                }
            });

            nextstate = {
                session: {
                    ...state.session,
                    tryOnProducts: _tryOnProducts
                }
            }

            return nextstate

        case ActionTypes.SET_CLIENT_DATA:
            nextstate = {
                session: {
                    ...state.session,
                    clientData: action.value
                }
            }
            console.log("[REDUCER] ClientDATA: ")
            console.log(nextstate.session.clientData)
            return nextstate

        case ActionTypes.SET_BRIDGE:
            nextstate = {
                session: {
                    ...state.session,
                    bridge: action.value
                }
            }
            return nextstate

        case ActionTypes.SET_ACTUAL_CONCERN:
            nextstate = {
                session: {
                    ...state.session,
                    actualConcern: action.value
                }
            }
            return nextstate

        case ActionTypes.SET_ONBOARD_DISPLAYED:
            nextstate = {
                session: {
                    ...state.session,
                    isOnBoardDisplayed: action.value
                }
            }
            return nextstate

        case ActionTypes.RESET_EXPERIENCE:
            console.warn("RESET")
            nextstate = {
                session: {
                    ...state.session,
                    analyses: [],
                    cheek: '',
                    questions: [],
                    products: [],
                    panier: [],
                    imageUser: '',
                    reco: [],
                    bridge: ""
                }
            }
            return nextstate

        case ActionTypes.FAKE_RESET:
            console.log("[REDUCER] Reset experience.")
            nextstate = {
                session: {
                    ...state.session,
                    cheek: '',
                    questions: [],
                    analyses: [],
                    panier: [],
                    imageUser: ''
                }
            }
            return nextstate

        case 'SET_POSITIONS':
            nextstate = {
                session: {
                    ...state.session,
                    positions: action.value
                }
            }
            return nextstate

        case ActionTypes.SET_STEP:
            nextstate = {
                session: {
                    ...state.session,
                    step: action.value
                }
            }
            return nextstate

        case ActionTypes.ANALYSE_PHOTO:
            nextstate = {
                session: {
                    ...state.session,
                    step: 2,
                    imageUser: action.value
                }
            }

            console.warn(nextstate.session.imageUser)
            return nextstate

        case ActionTypes.ANALYSE_ERROR:
            nextstate = {
                session: {
                    ...state.session,
                    step: 1,
                    imageUser: null,
                    error: action.value
                }
            }
            return nextstate

        case ActionTypes.ANALYSE_UPDATE:
            nextstate = {
                session: {
                    ...state.session,
                    analyseStep: action.value,
                    //step: 2
                }
            }
            return nextstate

        case ActionTypes.ANALYSE_BIOMETRICS_DONE:
            if (state.session.step === 2)
                nextstate = {
                    session: {
                        ...state.session,
                        biometrics: action.value,
                    }
                }
            return nextstate || state

        case ActionTypes.ANALYSE_DONE:
            let tab: Array<any> = []
            console.log("[REDUCER] ANALYSE DONE")
            action.value.forEach((element: any) => {
                if (enabledConcerns.includes(element.name)) {
                    tab.push(element)
                }
            });
            //let eyesBags = tab.find((e: any) => e.name === 'EYES_BAGS')
            //let darkCircles = tab.find((e: any) => e.name === 'DARK_CIRCLES')
            let tearthrough = tab.find((e: any) => e.name === 'TEAR_THROUGH')
            let hollowCheeks = tab.find((e: any) => e.name === 'HOLLOW_CHEEKS')
            let lowerFace = tab.find((e: any) => e.name === 'LOWER_FACE_PTOSIS')

            let blackSpot = {
                name: "BLACK_SPOTS",
                score: tab.find((e: any) => e.name === 'ACNE').score
            }

            tab.push(blackSpot)

            if (tearthrough.score > hollowCheeks.score && tearthrough.score > lowerFace.score) {
                tab = tab.filter(function (e) {
                    return e.name !== 'HOLLOW_CHEEKS'
                })
                tab = tab.filter(function (e) {
                    return e.name !== 'LOWER_FACE_PTOSIS'
                })
            }
            else if (hollowCheeks.score > tearthrough.score && hollowCheeks.score > lowerFace.score) {
                tab = tab.filter(function (e) {
                    return e.name !== 'TEAR_THROUGH'
                })
                tab = tab.filter(function (e) {
                    return e.name !== 'LOWER_FACE_PTOSIS'
                })
            }
            else {
                tab = tab.filter(function (e) {
                    return e.name !== 'TEAR_THROUGH'
                })
                tab = tab.filter(function (e) {
                    return e.name !== 'HOLLOW_CHEEKS'
                })
            }

            //modify score
            let radiance = tab.find((e: any) => e.name === "RADIANCE")
            if (radiance) {
                if (radiance.score < 0.6 && radiance.score > 0.3) {
                    radiance.score *= 2 / 4.5
                }
            }

            let eyesBags = tab.find((e: any) => e.name === 'EYES_BAGS')
            if (eyesBags) {
                if (eyesBags.score < 0.7 && eyesBags.score > 0.1) {
                    eyesBags.score *= 1.5 / 2.5
                }
            }

            let darkCircles = tab.find((e: any) => e.name === "DARK_CIRCLES")
            if (darkCircles) {
                if (darkCircles.score < 4.5 && darkCircles.score > 1.5) {
                    darkCircles *= 2 / 2.7
                }
            }

            let shininess = tab.find((e: any) => e.name === "SHININESS")
            if (shininess) {
                if (shininess.score < 0.3 && shininess > 0.05) {
                    shininess *= 2.5 / 3
                }
            }

            let roughness = tab.find((e: any) => e.name === "ROUGHNESS")
            if (roughness) {
                if (roughness.score < 0.6 && roughness > 0.15) {
                    roughness *= 2 / 4.5
                }
            }

            console.warn(tab)

            nextstate = {
                session: {
                    ...state.session,
                    analyses: tab,
                }
            }

            return nextstate || state

        case ActionTypes.SET_ANALYSE:
            nextstate = {
                session: {
                    ...state.session,
                    analyses: action.value,
                }
            }
            return nextstate || state

        case ActionTypes.UPDATE_ONE_PRODUCT:

            let currentProducts = state.session.products

            if (currentProducts.find((product: any) => product.sku === action.value["sku"]) && action.value.sku !== "NOTHING IN CATEGORY") {
                let baseProduct = currentProducts.find((product: any) => product.sku === action.value["sku"])
                Object.assign(baseProduct, action.value)
                //console.warn("Produit modifié")
            }
            else {
                currentProducts.push(action.value)
                //console.warn("Produit ajouté")
            }

            nextstate = {
                session: {
                    ...state.session,
                    products: currentProducts,
                }
            }

            return nextstate

        case ActionTypes.SET_QUESTION:
            nextstate = {
                session: {
                    ...state.session,
                    questions: action.value.flat()
                }
            }
            return nextstate

        case ActionTypes.UPDATE_PANIER:

            let panier: Array<string> = state.session.panier


            if (Array.isArray(action.value)) {
                action.value.forEach((sku: string) => {
                    panier.push(sku)
                });
            } else {
                panier.push(action.value)
            }

            nextstate = {
                session: {
                    ...state.session,
                    panier: panier
                }
            }
            console.log("[REDUCER] Panier: ")
            console.log(nextstate.session.panier)
            return nextstate

        case ActionTypes.SET_RECO:
            console.warn("STATE:")
            console.warn(action.value)

            nextstate = {
                session: {
                    ...state.session,
                    reco: action.value
                }
            }
            return nextstate
            
        case ActionTypes.ANALYSE_FAKE:
            nextstate = {
                session: {
                    ...state.session,
                    analyses: action.value
                }
            }
            return nextstate
        case ActionTypes.UPDATE_MODEL:
            nextstate = {
                session: {
                    ...state.session,
                    model: action.value,
                },
            };
            return nextstate;
        default:
            return state
    }
}

export default reducer

