import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import i18n from "../i18nextInit"
import { MainButton } from "../Styles/Buttons"

interface PPProps {
    answers: Array<any>,
    nextStep: any
}

const PPContainerComponent = ({ answers, nextStep }: PPProps) => {

    //const questions = useSelector((state: MySkinDiagState) => state.session.questions)
    const [enabled, setEnableButton] = useState<boolean>(false)
    const { t } = useTranslation()

    const container = useRef<any>(null)

    useEffect(() => {
        document.getElementById("TITLE_CONTAINER")!.style.position = "absolute"
        const timeOut = setTimeout(() => {
            document.getElementById("UNDER_TEXT")!.style.animation = "inital"
            document.getElementById("UNDER_TEXT")!.style.opacity = "1"
            setEnableButton(true)
        }, 3500);

        return () => {
            window.clearTimeout(timeOut)
        }
    }, [])

    return (
        <PPContainer eyeContainer={false} id="QUEST_CONTAINER" enabled={enabled} ref={container}>
            {answers}
            <PPLiner />
            <PPNextButtonContainer>
                <PPNextButton onClick={nextStep} id={"PPNEXT_BUTTON"}>
                    {t("questionView.next", "Next")}
                </PPNextButton>
            </PPNextButtonContainer>
        </PPContainer>
    )
}


// STYLES

export const PPContainer = styled.div<{ enabled: boolean, eyeContainer: boolean }>`
display: flex;
position: absolute;
pointer-events: ${props => props.enabled ? "all" : "none"};
opacity: 0;
top: 45%;
z-index: 1;
width: 100%;
flex-wrap: wrap;
box-sizing: border-box;
transition: all 0.5s;
animation: Fading 1s Forwards 2s;

@media (min-width: 768px) and (max-width: 1224px){
    top: "30%";
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(40%, 1fr));
    column-gap: 32px;
    row-gap: 12px;
    padding: 0px 24px;
}

@media (min-width: 1224px){
    top: 45%;
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(30%, 1fr));
    column-gap: 32px;
    row-gap: 12px;
    width: intial;
    max-width: 1200px;
}

@media (min-width: 1224px) and (max-height: 650px){
    row-gap: 6px;
    top: ${props => props.eyeContainer ? "50%" : ""};
}
`

const PPLiner = styled.div`
    height: 0px;
    background-color: white;
    opacity: 0.45;
    width: 100%;
    position: relative;
    margin: 24px 0px 64px;

    @media (min-width: 768px){
        display: none;
    }
`

export const PPNumberRound = styled.div`
    opacity: 0;
    transition: all 0.3s;
    background-color: #EDEDED;
    color: black;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    right: 8px;
    border-radius: 24px;
    padding-bottom: 4px;
    box-sizing: border-box;
`

export const PriorityContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #10B5F2;
    border-radius: 24px;
    color: white;
    opacity: 0;
    animation: 0.7s Fading 1s forwards;
    position: absolute;
    opacity: 0;
    top: ${i18n.language === "tw_TW" ? "-10.5px" : "-7px"};
    left: ${() => window.RTL_LANGS.includes(i18n.language) ? "" : "16px"};
    right: ${() => window.RTL_LANGS.includes(i18n.language) ? "16px" : ""};
    font: normal normal 300 8px/8px Helvetica Bold;
    font-size: ${i18n.language === "tw_TW" ? "12px" : ""};
    padding: ${i18n.language === "tw_TW" ? "6px 10px" : "2px 8px"};
    text-transform: uppercase;
`

export const PPNextButton = styled(MainButton)`
    position: relative;
    width: 100%;
    margin: 0px 4% 24px;
    background-color: rgba(0,0,0,0.3);
    pointer-events: none;
    font-size: ${i18n.language === "tw_TW" ? "14px" : "12px"};
    text-transform: uppercase;

    @media (min-width: 768px) {
        max-width: 300px;
    }

    @media (min-width: 1224px) and (max-height: 650px){
    margin-bottom: 12px;
}
`

export const PPNextButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    flex: 1;
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    
`

/* pointer-events: ${props => !props.isReady ? 'none' : ''};
color: ${props => !props.isReady ? 'white' : 'white'};
background-color: ${props => !props.isReady ? 'rgba(0,0,0,0.3)' : 'black'}; */

export default PPContainerComponent