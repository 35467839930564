import { useEffect, useRef } from "react"
import { useDispatch } from "react-redux"
import { IntroVideo, VideoTuto } from "../Styles/Intro/Intro"
import * as ActionTypes from '../Constants/ActionsTypes'
import { Answer } from '../Constants/QuestsConstants'
import i18n from "../i18nextInit"
import { Tags } from "./Tags"
import { isAndroid, isTablet, isMobile } from 'react-device-detect'
import Compressor from 'compressorjs';
import { cropSku } from "../Components/Try-On/PlaygroundConstants"
import mobInstructionsFR from '../Assets/VIDEOS/MOB_instructions fr.mp4'
import mobInstructionsEN from '../Assets/VIDEOS/MOB_instructions en.mp4'
import mobInstructionsTW from '../Assets/VIDEOS/MOB_instructions tw.mp4'
import mobInstructionsNL from '../Assets/VIDEOS/MOB_instructions nl.mp4'
import mobInstructionsNLBE from '../Assets/VIDEOS/MOB_instructions nl_be.mp4'
import mobInstructionsDE from '../Assets/VIDEOS/MOB_instructions de.mp4'
import mobInstructionsES from '../Assets/VIDEOS/MOB_instructions es.mp4'
import mobInstructionsCZ from '../Assets/VIDEOS/MOB_instructions cz.mp4'
import mobInstructionsDEAT from '../Assets/VIDEOS/MOB_instructions de_at.mp4'
import mobInstructionsDK from '../Assets/VIDEOS/MOB_instructions dk.mp4'
import mobInstructionsIT from '../Assets/VIDEOS/MOB_instructions it.mp4'
import mobInstructionsPL from '../Assets/VIDEOS/MOB_instructions pl.mp4'
import mobInstructionsPT from '../Assets/VIDEOS/MOB_instructions pt.mp4'
import mobInstructionsJP from '../Assets/VIDEOS/MOB_instructions jp.mp4'
import mobInstructionsKR from '../Assets/VIDEOS/MOB_instructions kr.mp4'






import tabInstructionsFR from '../Assets/VIDEOS/TAB_instructions fr.mp4'
import tabInstructionsEN from '../Assets/VIDEOS/TAB_instructions en.mp4'
import tabInstructionsTW from '../Assets/VIDEOS/TAB_instructions tw.mp4'
import { CBSKError } from "../Components/SkinDiagApi/defaults"

export const getVideoLanguage = () => {
    switch (i18n.language) {
        case "fr_CH":
        case "fr_BE":
        case "fr_FR":
            return window.innerWidth < 768 ? mobInstructionsFR : tabInstructionsFR
        case "tw_TW":
            return window.innerWidth < 768 ? mobInstructionsTW : tabInstructionsTW
        case "nl_NL":
            return mobInstructionsNL
        case "nl_BE":
            return mobInstructionsNLBE
        case "de_CH":
        case "de_DE":
            return mobInstructionsDE
        case "es_ES":
            return mobInstructionsES
        case "it_IT":
            return mobInstructionsIT
        case "pl_PL":
            return mobInstructionsPL
        case "pt_PT":
            return mobInstructionsPT
        case "cs_CZ":
            return mobInstructionsCZ
        case "da_DK":
            return mobInstructionsDK
        case "de_AT":
            return mobInstructionsDEAT
        case "ja_JP":
            return mobInstructionsJP
        case "ko_KR":
            return mobInstructionsKR
        default:
            return window.innerWidth < 768 ? mobInstructionsEN : tabInstructionsEN
    }
}

export const closeIFrame = () => {
    let data = {
        messageType: "close",
    }

    let dataBis = {
        messagetype: "toggle_fullscreen",
        value: false
    }

    window.parent.postMessage(dataBis, '*')
    window.parent.postMessage(data, '*')
    console.log(data)
    console.log(dataBis)
}

export const setIframeHeight = () => {
    let data = {
        messageType: "content_height",
        height: document.getElementById("APP")!.getBoundingClientRect().height
    }
    console.log(data)
    window.parent.postMessage(data, '*')
}

export const castConcernNameToLowerCase = (key: string): string => {
    switch (key) {
        case "WRINKLES":
            return "rides"
        case "WRINKLES_UNDER_EYES":
        case "ridesYeux":
            return "ridesYeux"
        case "EYES_BAGS":
            return "poches"
        case "LOWER_FACE_PTOSIS":
            return "ptose"
        case "HOLLOW_CHEEKS":
            return "ptose"
        case "TEAR_THROUGH":
            return "ptose"
        case "DARK_SPOTS":
            return "taches"
        case "DARK_CIRCLES":
            return "cernes"
        case "BLACK_SPOTS":
            return "taches"
        case "HYDRATATION":
            return "hydration"
        case "LOSS_OF_FIRMNESS":
            return "fermete"
        case "SHININESS":
            return "brillance"
        default:
            return key.toLowerCase()
    }
}

export const analyseToAnswer = (analyseName: string, eyesAnalysis: boolean = false): Answer => {
    switch (analyseName) {
        case 'WRINKLES':
            return eyesAnalysis ? Answer.CYrides : Answer.PPrides
        case "WRINKLES_UNDER_EYE":
            return Answer.CYrides
        case "DARK_SPOTS":
            return Answer.PPtaches
        case "SHININESS":
            return Answer.PPbrillance
        case "RADIANCE":
            return Answer.PPmanqueEclat
        case "DARK_CIRCLES":
            return Answer.CYcernes
        case "ACNE":
            return Answer.PPimperfections
        case "ROUGHNESS":
            return Answer.PPgrainDePeau
        case "LOSS_OF_FIRMNESS":
            return eyesAnalysis ? Answer.CYrelachement : Answer.PPperteFermete
        case "EYES_BAGS":
            return Answer.CYpoche

        default:
            return Answer.problem
    }
}

export const starProductParser = (starResult: any) => {

    let productObject = {
        sku: "",
        name: "",
        capacity: "",
        imageURL: "",
        application: "",
        description: "",
        introduction: ""
    }

    if (!starResult) {
        return productObject
    }

    if (!starResult["Response"]) {
        console.error("No such product in Local STAR Base")
        return productObject
    }

    let product = starResult["Response"]["Product"]

    if (!product) {
        return;
    } if (!product["Header"]) {
        return;
    } if (!product["Header"]["ProductId"]) {
        return;
    }

    productObject.sku = product["Header"]["ProductId"]
    productObject.capacity = product["ProductInfo"]["Capacity"]

    if (product["Medias"]) {
        if (product["Medias"].length > 0) {
            let media = product["Medias"].find((obj: any) => obj["Key"] === "01_S") // 01_S
            if (media) {
                productObject.imageURL = media["Value"]
            }
        }
    }

    if (product["ProductInfo"]) {
        productObject.name = product["ProductInfo"]["ProductName"]
    }

    if (product["Translations"].length > 0) {
        let translations: any = product["Translations"][0]["Translations"]

        if (translations) {
            if (translations["PDTN"]) {
                productObject.name = translations["PDTN"]
            }
            if (translations["APPL"]) {
                productObject.application = translations["APPL"]
            }
            if (translations["DESC"]) {
                productObject.description = translations["DESC"]
            }
            if (translations["INTRO"]) {
                productObject.introduction = translations["INTRO"]
            }
        }
    }

    return productObject
}

export const StarFeeder = async (bearer: string) => {
    let myHeaders = new Headers();
    myHeaders.append("Authorization", "Google " + bearer);
    myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');
    const langs: string[] = ["en_ZA", "en_CA", "fr_CA", "ar_SA", "en_SA", "es_MX", "pt_BR", "en_HK", "zh_HK"]

    let skuMap: any


    skuMap = require('../Assets/skuMap.json')

    let skusArray: Array<string> = []

    for (const obj in skuMap) {
        skusArray.push(obj)
    }

    //évite les doublons
    //skusArray = skusArray.flat().filter((v, i, a) => a.indexOf(v) === i).slice(50)
    skusArray = skusArray.flat().filter((v, i, a) => a.indexOf(v) === i)
    console.warn("Téléchargement des produits STAR en cours...")
    console.warn(skusArray)

    let array: Array<any> = []
    for (let j = 0; j < langs.length; j++) {
        for (let i = 0; i < skusArray.length; i++) {
            console.warn("downloading " + skusArray[i] + " for " + langs[j])
            await fetch("https://app-we-prd-star-api.azurefd.net/v1/Products/" + skusArray[i] + "?locale=" + langs[j] + "&withExtendedAttributes=true", {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            })
                .then(response => response.json())
                // eslint-disable-next-line
                .then(result => {
                    array.push(starProductParser(result))
                    if (array.length === skusArray.length) {
                        console.log(array)
                        if (langs[j] === "zh_TW") {
                            langs[j] = "tw_TW"
                        }
                        downloadObjectAsJson(array, "STAR_PRODUCTS_" + langs[j])
                        array = []
                    }
                })
                .catch(error => console.error('error', error));
        }
    }

}

export const StarFeederMakeUp = async (bearer: string, isMakeUp: boolean = false) => {
    let myHeaders = new Headers();
    myHeaders.append("Authorization", "Google " + bearer);
    myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');

    let skuMap: any

    skuMap = require('../Components/Try-On/BeautyKit2/myStoreUpdated.json')

    let skusArray: Array<string> = []

    skuMap.forEach((product: any) => {
        skusArray.push(cropSku(product.sku))
    })

    //évite les doublons
    //skusArray = skusArray.flat().filter((v, i, a) => a.indexOf(v) === i).slice(50)
    skusArray = skusArray.flat().filter((v, i, a) => a.indexOf(v) === i)
    console.warn("Téléchargement des produits STAR en cours...")
    console.warn(skusArray)

    let array: Array<any> = []
    for (let i = 0; i < skusArray.length; i++) {
        console.warn("downloading " + skusArray[i] + " for make up")
        await fetch("https://app-we-prd-star-api.azurefd.net/v1/Products/" + skusArray[i] + "?locale=fr_FR&withExtendedAttributes=true", {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        })
            .then(response => response.json())
            // eslint-disable-next-line
            .then(result => {
                array.push(result)
                if (array.length === skusArray.length) {
                    console.log(array)
                    downloadObjectAsJson(parseMakeUpResult(array), "STAR_PRODUCTS_MAKE_UP")
                    array = []
                }
            })
            .catch(error => console.error('error', error));
    }

}

export const parseMakeUpResult = (array: any[]): any[] => {
    let parsedArray: any[] = []

    array.forEach(product => {
        if (!product.Response.Product) {
            console.error("issue with " + product)
            return
        }

        if (!product.Response.Product.Header) {
            console.error("issue with SKU " + product)
            return
        }

        if (!product.Response.Product.ProductInfo) {
            console.error("issue with name " + product)
            return
        }

        if (!product.Response.Product.Medias.find((media: any) => media.Key === "01_S")) {
            console.error("issue with image " + product)
            return
        }

        parsedArray.push({
            sku: product.Response.Product.Header.SKU,
            name: product.Response.Product.ProductInfo.ProductName,
            imageURL: product.Response.Product.Medias.find((media: any) => media.Key === "01_S").Value
        })
    });

    return parsedArray
}

export function downloadObjectAsJson(exportObj: Object, exportName: string) {
    var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(exportObj));
    var downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute("href", dataStr);
    downloadAnchorNode.setAttribute("download", exportName + ".json");
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
}

export const checkIsMaxQuantity = (sku: string, panier: Array<string>): boolean => {
    return panier.filter((e: string) => e === sku).length >= 4
}

export const VideoFixedIntro = (props: any) => {
    const refVideo = useRef<any>(null);

    useEffect(() => {
        if (!refVideo.current) {
            return;
        }

        //open bug since 2017 that you cannot set muted in video element https://github.com/facebook/react/issues/10389
        refVideo.current!.defaultMuted = true;
        refVideo.current!.muted = true;
        refVideo.current!.playsInline = true
        refVideo.current!.play()
    }, [props.src]);

    return (
        <IntroVideo
            ref={refVideo}
            loop
            src={props.src}
            playsInline //FIX iOS black screen
        >
            <source type="video/mp4" src={props.src} />
        </IntroVideo>
    );
}

export const VideoFixedTuto = (props: any) => {
    const refVideo = useRef<any>(null);
    const dispatch = useDispatch()

    useEffect(() => {
        if (!refVideo.current) {
            return;
        }

        //open bug since 2017 that you cannot set muted in video element https://github.com/facebook/react/issues/10389
        refVideo.current!.defaultMuted = true;
        refVideo.current!.muted = true;
        refVideo.current!.playsInline = true
        refVideo.current!.play()
    }, [props.src]);

    return (
        <VideoTuto
            $blackLines={props.blackLines.includes(i18n.language)}
            style={{
                position: isAndroid ? "fixed" : "relative"
            }}
            onEnded={() => dispatch({ type: ActionTypes.SET_STEP, value: 1 })}
            ref={refVideo}
            loop
            src={props.src}
            playsInline //FIX iOS black screen
        >
            <source type="video/mp4" src={props.src} />
        </VideoTuto>
    );
}

export const runHeightMachine = (isFast: boolean = false, objectName: string = "APP", offset: number = 0) => {
    let updateHeightOnce = setInterval(() => {
        if (document.getElementById(objectName)) {
            let data = {
                messageType: "content_height",
                height: document.getElementById(objectName)!.offsetHeight + offset + 1
            }
            window.parent.postMessage(data, '*')
        } else {
            return;
        }

    }, isFast ? 100 : 300);

    setTimeout(() => {
        window.clearInterval(updateHeightOnce)
    }, isFast ? 300 : 3000);
}

export const mobileCheck = function () {
    return isTablet || isMobile
};

const setStoreID = (): string => {
    switch (i18n.language) {
        case "fr_FR":
            return "W0000001526"
        case "en_US":
            return "W0000001849"
        default:
            return "W0000001526"
    }
}

const setStage = (): string => {
    let url = new URL(window.location.href)
    let stage: string = "int"
    if (url.searchParams.get("data")) {
        let data = JSON.parse(atob(url.searchParams.get("data")!.replace(" ", "+")))
        if (data["options"]) {
            let parentURL: string = data["options"]["currentUrl"]
            if (parentURL) {
                if (parentURL.includes("dev")) {
                    stage = "pre"
                }
                else if (parentURL.includes("staging")) {
                    stage = "int"
                } else if (parentURL.includes("sisley-paris") && !parentURL.includes("preprod")) {
                    stage = "prd"
                }
            }

        }
    }
    return stage
}

export const sendMailtoSTAR = (analyses: any, questions: Array<any>, products: Array<any>, firstName: string, lastName: string, email: string, countryCode: string, phone: string = "", setSendStatus: any, sendStatus: number) => {
    const channels: Array<any> = []
    channels.push({
        "OutboundChannelTypeCode": "EMAIL",
        "RawValue": email
    })

    if (phone !== "") {
        channels.push({
            "OutboundChannelTypeCode": "MOB",
            "RawValue": phone
        })
    }

    fetch("https://kq8wlmru7h.execute-api.eu-west-3.amazonaws.com/Dev/mail", {
        method: "POST",
        body: JSON.stringify({
            "firstName": firstName,
            "lastName": lastName,
            "shortCountryCode": countryCode.slice(3),
            "longCountryCode": countryCode === "tw_TW" ? "zh_TW" : countryCode,
            "channel": channels,
            "time": new Date().toISOString(),
            "storeID": setStoreID(),
            "stage": setStage()
        }),

    })
        .then(res => res.json())
        .then(res => JSON.parse(res.body))
        //.then(res => console.log(res))
        .then((res: any) => sendDiagToSTAR(res, res.Response.Customer.Header.CustomerId, analyses, questions, products, setSendStatus, sendStatus))
        .catch(err => {
            console.error(err)
            setSendStatus(3)
        })
}

export const sendDiagToSTAR = (res: any, customerID: string, analyses: any, questions: Array<any>, products: Array<any>, setSendStatus: any, sendStatus: number) => {
    console.log(res)

    if (res.Response.Star.Technical.APIStatusCode === "IN_0001") {
        fetch("https://kq8wlmru7h.execute-api.eu-west-3.amazonaws.com/Dev/update", {
            method: "PUT",
            body: JSON.stringify({
                "customerID": customerID,
                "time": new Date().toISOString(),
                "stage": setStage()
            })
        })
            .then(res => res.json())
            .then(res => console.log(res.body))
            .then(res => {
                if (sendStatus !== 3) {
                    setSendStatus(1)
                }
            })
            .catch(err => {
                console.error(err)
                setSendStatus(3)
            })
    }

    const setSkinValue = (): string => {
        switch (questions.flat()[4]) {
            case Answer.seche:
                return "1"
            case Answer.normal:
                return "2"
            case Answer.grasse:
                return "3"
            default:
                return "1"
        }
    }


    let raw = {
        "customerID": customerID,
        "storeID": setStoreID(),
        "questions": [
            {
                "QuestionCode": "SK_TYPE",
                "AnswerValue": setSkinValue()
            }
        ],
        "criterias": generateCriterias(analyses, questions),
        "reco": generateReco(products, questions),
        "countryCode": i18n.language.slice(3),
        "stage": setStage()
    }

    console.warn(raw)

    fetch("https://kq8wlmru7h.execute-api.eu-west-3.amazonaws.com/Dev/diag", {
        method: "POST",
        body: JSON.stringify(raw),
    })
        .then(res => res.json())
        .then(res => console.log(res.body))
        .then(res => {
            if (sendStatus !== 3) {
                setSendStatus(2)
            }
        })
        .catch(err => {
            console.error(err)
            setSendStatus(3)
        })
}

const generateCriterias = (analyses: any, questions: Array<any>) => {
    let criteriasArray: Array<any> = []

    const setCriteriaName = (name: string) => {
        switch (name) {
            case "WRINKLES":
                return "SK_WRINKLES"
            case "WRINKLES_UNDER_EYE":
                return "EYE_WRINKLE"
            case "EYES_BAGS":
                return "EYE_BAGS"
            case "DARK_SPOTS":
                return "SK_SPOTS"
            case "DARK_CIRCLES":
                return "EYE_CIRCLES"
            case "SHININESS":
                return "SK_SHINE"
            case "RADIANCE":
                return "SK_RADIANCE"
            case "PORES":
                return "SK_PORES"
            case "LOSS_OF_FIRMNESS":
                return "SK_FIRM"
            case "ROUGHNESS":
                return "SK_TEXTURE"
            case "ACNE":
                return "SK_ACNE"
            case "HOLLOW_CHEEKS":
            case "LOWER_FACE_PTOSIS":
            case "TEAR_THROUGH":
                return "EYE_LOOSE"
            default:
                return "ERROR"
        }
    }

    const criteriaNameToQuest = (starName: string): Answer => {
        switch (starName) {
            case "SK_SENSITIVITY":
                return Answer.PPsensibilite
            case "EYE_BAGS":
                return Answer.CYpoche
            case "EYE_CIRCLES":
                return Answer.CYcernes
            case "EYE_WRINKLE":
                return Answer.CYrides
            case "EYE_LOOSE":
                return Answer.CYrelachement
            case "SK_WRINKLES":
                return Answer.PPrides
            case "SK_FIRM":
                return Answer.PPperteFermete
            case "SK_RADIANCE":
                return Answer.PPmanqueEclat
            case "SK_TEXTURE":
                return Answer.PPgrainDePeau
            case "SK_ACNE":
                return Answer.PPimperfections
            case "SK_SHINE":
                return Answer.PPbrillance
            case "SK_SPOTS":
                return Answer.PPtaches
            case "SK_SCARS":
                return Answer.PPtracesResiduelles
            case "SK_PORES":
                return Answer.PPpores
            case "SK_HYDRA":
                return Answer.PPdeshydratation
            case "SK_TIRED":
                return Answer.PPsigneFatigue
            case "SK_POLLUT":
                return Answer.pollution
            case "SK_SCREEN":
                return Answer.ecrans
            case "SK_SLEEP":
                return Answer.manqueSommeil
            case "SK_STRESS":
                return Answer.stress
            default:
                return Answer.problem
        }
    }

    const checkIsMainCriteria = (starName: string): boolean => {
        if (starName === "SK_WRINKLES") {
            if (questions.includes(Answer.PPrides || questions.includes(Answer.CYrides))) {
                return true
            }
        }

        if (starName === "EYE_WRINKLE") {
            if (questions.includes(Answer.CYridules)) {
                return true
            }
        }

        if (questions.includes(criteriaNameToQuest(starName))) {
            return true
        } else {
            return false
        }
    }

    let noScoreArray: Array<string> = ["SK_SENSITIVITY", "SK_HYDRA", "SK_TIRED", "SK_POLLUT", "SK_SCREEN", "SK_STRESS", "SK_SLEEP", "SK_ACNE"]

    noScoreArray.forEach((name: string) => {
        criteriasArray.push({
            "CriteriaCode": name,
            "Value": 0,
            "isMainCriteria": checkIsMainCriteria(name)
        })
    })
    analyses.forEach((analyse: any) => {
        if (setCriteriaName(analyse.name) !== "ERROR" && setCriteriaName(analyse.name) !== "SK_ACNE") {
            criteriasArray.push({
                "CriteriaCode": setCriteriaName(analyse.name),
                "Value": Math.trunc((analyse.score * 100)),
                "isMainCriteria": checkIsMainCriteria(setCriteriaName(analyse.name))
            })
        }
    });

    criteriasArray.push({
        "CriteriaCode": "SK_SCARS",
        "Value": criteriasArray.find((e: any) => e.CriteriaCode === "SK_SPOTS").Value,
        "isMainCriteria": checkIsMainCriteria("SK_SCARS")
    })

    criteriasArray.find((e: any) => e.CriteriaCode === "EYE_WRINKLE").Value = criteriasArray.find((e: any) => e.CriteriaCode === "SK_WRINKLES").Value

    // Cas si l'utlisateur a choisi rides visage et rides yeux. (pareil pour relachement)
    if (checkIsMainCriteria("SK_WRINKLES") && checkIsMainCriteria("EYE_WRINKLE")) {
        criteriasArray.find((e: any) => e.CriteriaCode === "EYE_WRINKLE").isMainCriteria = false
    }

    if (checkIsMainCriteria("SK_FIRM") && checkIsMainCriteria("EYE_LOOSE")) {
        criteriasArray.find((e: any) => e.CriteriaCode === "EYE_LOOSE").isMainCriteria = false
    }

    return criteriasArray
}

const generateReco = (products: Array<any>, questions: Array<any>) => {
    let recos: Array<any> = []
    let count: number = 1
    let tagFile = require("../Assets/tags.json")

    products.forEach(product => {
        if (product.sku !== "NOTHING IN CATEGORY") {
            let tags = new Tags(product.sku, tagFile, questions)
            let tagArray: Array<string> = []

            tags.tags.forEach(tag => {
                tagArray.push(tag.starCode)
            });

            if (product.sku === "108310" && (i18n.language === "fr_FR" || i18n.language === "en_GB" || i18n.language === "en_US")) {
                product.sku = "108311"
            }

            recos.push({
                "ProductId": product.sku,
                "ProductOrder": count,
                "PrescriptionLanguageCode": i18n.language.slice(3),
                "TagList": tagArray.join('**')
            })
            count++
        }
    });

    return recos
}

export const setCurrencyTuple = (): [string, string] => {
    switch (i18n.language) {
        case "da_DK":
            return ["da-DK", "DKK"]
        case "fr_CH":
            return ["fr-CH", "CHF"]
        case "en_CA":
        case "fr_CA":
            return ["fr-CA", "CAD"]
        case "en_MEA":
        case "ar_MEA":
            return ["ar-AE", "AED"]
        case "en_SA":
        case "ar_SA":
            return ["en-SA", "SAR"]
        case "en_ZA":
            return ["en-ZA", "ZAR"]
        case "pt_BR":
            return ["pt-BR", "BRL"]
        case "es_MX":
            return ["es-MX", "MXN"]
        case "zh_HK":
        case "en_HK":
            return ["en-HK", "HKD"]
        case "de_CH":
            return ["de-CH", "CHF"]
        case "en_GB":
            return ["en-GB", "GBP"]
        case "pl_PL":
            return ["pl-PL", "PLN"]
        case "ja_JP":
            return ["ja-JP", "JPY"]
        case "ko_KR":
            return ["ko-KR", "KRW"]
        case "fr_FR":
            return ["fr-FR", "EUR"]
        case "en_US":
            return ["en-US", "USD"]
        case "tw_TW":
            return ["en-US", "TWD"]
        case "en_AU":
            return ["en-AU", "AUD"]
        case "en_SG":
            return ["en-SG", "SGD"]
        case "en_MY":
            return ["en-MY", "MYR"]
        case "cs_CZ":
            return ["cs-CZ", "CZK"]
        default:
            return ["fr-FR", "EUR"]
    }
}

export const handleFormatPrice = (price: any) => {
    let formatedPrice: string = Intl.NumberFormat(setCurrencyTuple()[0], { style: 'currency', currency: setCurrencyTuple()[1] }).format(price)

    if (i18n.language === "tw_TW") {
        return formatedPrice.slice(0, 3) + " " + formatedPrice.slice(3)
    }

    if (i18n.language === "en_SG") {
        return "S" + formatedPrice.slice(0, 1) + " " + formatedPrice.slice(1)
    }

    if (i18n.language === "ko_KR") {
        return price.toLocaleString('ko-KR', { maximumFractionDigits: 2 }) + " 원"
    }

    return formatedPrice
}

export const sendAnalytics = (label: string, category: string = "Diag", photo_time?: number) => {
    let analyticsData: Record<string, string> = {
        messageType: "analytics_event",
        event_category: category,
        event_action: "clic",
        event_label: label
    }

    if (photo_time) {
        analyticsData.photo_time = photo_time.toString()
    }

    console.log("[ANALYTICS]")
    console.log(analyticsData)
    window.parent.postMessage(analyticsData, "*")
}

export const analysisNameToConcernKey = (analyseName: string, isEyeAnalysis: boolean = false): Answer => {
    if (isEyeAnalysis && analyseName === "LOSS_OF_FIRMNESS") {
        return Answer.CYrelachement
    }

    if (isEyeAnalysis && analyseName === "WRINKLES") {
        return Answer.CYrides
    }

    switch (analyseName) {
        case "WRINKLES":
            return Answer.PPrides
        case "RADIANCE":
            return Answer.PPmanqueEclat
        case "SHININESS":
            return Answer.PPbrillance
        case "HYDRATATION":
            return Answer.PPdeshydratation
        case "ROUGHNESS":
            return Answer.PPgrainDePeau
        case "ACNE":
            return Answer.PPimperfections
        case "WRINKLES_UNDER_EYE":
            return Answer.CYrides
        case "DARK_SPOTS":
            return Answer.PPtaches
        case "BLACK_SPOTS":
            return Answer.PPtracesResiduelles
        case "LOSS_OF_FIRMNESS":
            return Answer.PPperteFermete
        case "EYES_BAGS":
            return Answer.CYpoche
        case "DARK_CIRCLES":
            return Answer.CYcernes
        default:
            return Answer.problem
    }
}

function preventDefault(e: any) {
    e.preventDefault();
}

let wheelOpt = { passive: false };
let wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';

// call this to Disable
export function disableScroll() {
    document.getElementById("APP")!.addEventListener('DOMMouseScroll', preventDefault, false); // older FF
    document.getElementById("APP")!.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
    document.getElementById("APP")!.addEventListener('touchmove', preventDefault, wheelOpt); // mobile

    document.getElementById("PRODUCT_DETAIL")?.addEventListener("touchmove", function (e) {
        e.stopPropagation()
        e.stopImmediatePropagation()
    }, wheelOpt)

    document.getElementById("PRODUCT_DETAIL")?.addEventListener(wheelEvent, function (e) {
        e.stopPropagation()
        e.stopImmediatePropagation()
    }, wheelOpt)
}

// call this to Enable
export function enableScroll() {
    document.getElementById("APP")!.removeEventListener('DOMMouseScroll', preventDefault, false);
    document.getElementById("APP")!.removeEventListener("wheel", preventDefault)
    document.getElementById("APP")!.removeEventListener('touchmove', preventDefault);
}

export function downloadBase64File(contentType: string, base64Data: any) {

    const linkSource = base64Data;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = "Face_Image";
    downloadLink.click();
}

export function isOnProd() {
    let siteURL = new URL(window.location.href)
    if (siteURL.searchParams.get("data")) {
        let data = JSON.parse(atob(siteURL.searchParams.get("data")!.replace(" ", "+")))
        if (data["options"]) {
            let parentURL = data["options"]["currentUrl"]
            if (parentURL) {
                if (parentURL.includes("staging")) {
                    return false
                } else if (parentURL.includes("sisley-paris")) {
                    return true
                }
            }
        }
    }
    return false
}

export function downloadImagesForOTSTC(image: any) {
    new Compressor(image, {
        quality: 1,
        success(result) {
            let a = document.createElement("a")
            a.download = "Face_Image_0Per.jpeg"
            a.href = URL.createObjectURL(result)
            document.body.appendChild(a)
            a.click()
        }
    })

    // setTimeout(() => {
    //     new Compressor(image, {
    //         quality: 0.98,
    //         success(result) {
    //             let a = document.createElement("a")
    //             a.download = "Face_Image_2Per.jpeg"
    //             a.href = URL.createObjectURL(result)
    //             document.body.appendChild(a)
    //             a.click()
    //         }
    //     })
    // }, 3000);

    // setTimeout(() => {
    //     new Compressor(image, {
    //         quality: 0.96,
    //         success(result) {
    //             let a = document.createElement("a")
    //             a.download = "Face_Image_4Per.jpeg"
    //             a.href = URL.createObjectURL(result)
    //             document.body.appendChild(a)
    //             a.click()
    //         }
    //     })
    // }, 6000);


    // setTimeout(() => {
    //     new Compressor(image, {
    //         quality: 0.94,
    //         success(result) {
    //             let a = document.createElement("a")
    //             a.download = "Face_Image_6Per.jpeg"
    //             a.href = URL.createObjectURL(result)
    //             document.body.appendChild(a)
    //             a.click()
    //         }
    //     })
    // }, 9000);

}

export function getChromeVersion() {
    var raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);

    return raw ? parseInt(raw[2], 10) : false;
}

export const translateError = (error: CBSKError, t: Function) => {
    switch (error) {
        case CBSKError.low_light:
            return t("cameraView.error_low_light")
        case CBSKError.no_face:
            return t("cameraView.no_face")
        default:
            return t("cameraView.no_face")
    }
}