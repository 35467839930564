import { onValue, ref, remove, set } from "firebase/database";
import { db } from "./firebase";
import { Bridge } from "../../type";

export const listenBridge = (bridgeId: string, callback: Function) => {
    return onValue(ref(db, `/bridge/${bridgeId}`), (snapshot) => {
        const data: Bridge = snapshot.val();
        callback(data);
    });
};


export const setBridgeValue = (bridgeId: string, value: Bridge) => {
    return set(ref(db, `/bridge/${bridgeId}`), value);
};

export const deleteBridgeValue = (bridgeId: string) => {
    return remove(ref(db, `/bridge/${bridgeId}`));
};