import lips from '../../Assets/playGround/lips.png'
import complexion from '../../Assets/playGround/complexion.png'
import eyes from '../../Assets/playGround/eyes.png'
import lipStick from '../../Assets/playGround/lipStick.png'
import concealer from '../../Assets/playGround/concealer.png'
import blush from '../../Assets/playGround/blush.png'
import bronzer from '../../Assets/playGround/bronzer.png'
import penLip from '../../Assets/playGround/penLip.png'
import eyeliner from '../../Assets/playGround/eyeliner.png'
import foundation from '../../Assets/playGround/foundation.png'
import gloss from '../../Assets/playGround/gloss.png'
import mascara from '../../Assets/playGround/mascara.png'
import eyeShadow from '../../Assets/playGround/eyeShadow.png'
import powder from '../../Assets/playGround/powder.png'
import { downloadObjectAsJson } from '../../Constants/Utilitaries'
import SisleyAPI from '../../API/SisleyAPI'

export enum PlayGroundState {
    none,
    chooseCategory,
    chooseUnderCategory,
    chooseProduct,
    chooseShade
}

export enum PGCategory {
    foundation = "foundation",
    eyes = 'eyes',
    lips = "lips"
}

export enum PGUnderCategory {
    concealer = "concealers",
    blush = "blushs",
    bronzers = "bronzers",
    foundations = "foundations",
    powders = "powders",
    eyeShadows = "eyeShadows",
    eyeLiners = "eyeliners",
    mascaras = "mascaras",
    glosses = "glosses",
    lipSticks = "lipSticks",
    penLips = "penLips"
}

export const getImageURLFromStarFile = (starFile: any[], sku: string): string => {
    if (!sku) {
        console.warn("NO SKU FOR " + sku)
        return ""
    }

    for (let i = 0; i < starFile.length; i++) {
        if (starFile[i].sku === sku) {
            return starFile[i].imageURL
        }
    }
    console.warn("no image found  for" + sku)
    return ""
}

export const cropSku = (sku: string): string => {
    if (sku.length === 7) {
        return sku.slice(0, -1)
    } else if (sku.length === 8) {
        return sku.slice(1, -1)
    } else if (sku.length === 12) {
        return sku.slice(6, -1)
    } else if (sku.length === 13) {
        return sku.slice(6, -1)
    } else {
        return sku
    }
}

export const getPlayGroundProducts = (): PlayGroundAllProductsType => {
    let _MUProductsFile = require("./BeautyKit2/myStoreUpdated.json")
    let starFile = require("../../Assets/STAR_PRODUCTS_MAKE_UP.json")

    let _playGroundProducts: PlayGroundAllProductsType = {
        foundation: {
            foundations: [],
            blushs: [],
            bronzers: [],
            concealers: [],
            powders: []
        },
        eyes: {
            eyeShadows: [],
            eyeliners: [],
            mascaras: []
        },
        lips: {
            lipSticks: [],
            penLips: [],
            glosses: [],
        }
    }

    _MUProductsFile.forEach((jsonProduct: any) => {
        let croppedSku = cropSku(jsonProduct["sku"])

        if (jsonProduct["category"] === "Anti-cernes") {
            _playGroundProducts.foundation.concealers.push({ name: jsonProduct["name"], sku: croppedSku, parentSku: jsonProduct["parentSku"], parentName: jsonProduct["parentName"], imageURL: getImageURLFromStarFile(starFile, croppedSku) })
        }

        if (jsonProduct["category"] === "Blush") {
            _playGroundProducts.foundation.blushs.push({ name: jsonProduct["name"], sku: croppedSku, parentSku: jsonProduct["parentSku"], parentName: jsonProduct["parentName"], imageURL: getImageURLFromStarFile(starFile, croppedSku) })
        }

        if (jsonProduct["category"] === "Bronzer") {
            _playGroundProducts.foundation.bronzers.push({ name: jsonProduct["name"], sku: croppedSku, parentSku: jsonProduct["parentSku"], parentName: jsonProduct["parentName"], imageURL: getImageURLFromStarFile(starFile, croppedSku) })
        }

        if (jsonProduct["category"] === "Fond de teint") {
            _playGroundProducts.foundation.foundations.push({ name: jsonProduct["name"], sku: croppedSku, parentSku: jsonProduct["parentSku"], parentName: jsonProduct["parentName"], imageURL: getImageURLFromStarFile(starFile, croppedSku) })
        }

        if (jsonProduct["category"] === "Poudre") {
            _playGroundProducts.foundation.powders.push({ name: jsonProduct["name"], sku: croppedSku, parentSku: jsonProduct["parentSku"], parentName: jsonProduct["parentName"], imageURL: getImageURLFromStarFile(starFile, croppedSku) })
        }

        if (jsonProduct["category"] === "Ombre à paupières") {
            _playGroundProducts.eyes.eyeShadows.push({ name: jsonProduct["name"], sku: croppedSku, parentSku: jsonProduct["parentSku"], parentName: jsonProduct["parentName"], imageURL: getImageURLFromStarFile(starFile, croppedSku) })
        }

        if (jsonProduct["category"] === "Mascara") {
            _playGroundProducts.eyes.mascaras.push({ name: jsonProduct["name"], sku: croppedSku, parentSku: jsonProduct["parentSku"], parentName: jsonProduct["parentName"], imageURL: getImageURLFromStarFile(starFile, croppedSku) })
        }

        if (jsonProduct["category"] === "Eyeliner / Crayon") {
            _playGroundProducts.eyes.eyeliners.push({ name: jsonProduct["name"], sku: croppedSku, parentSku: jsonProduct["parentSku"], parentName: jsonProduct["parentName"], imageURL: getImageURLFromStarFile(starFile, croppedSku) })
        }

        if (jsonProduct["category"] === "Rouge à lèvres") {
            _playGroundProducts.lips.lipSticks.push({ name: jsonProduct["name"], sku: croppedSku, parentSku: jsonProduct["parentSku"], parentName: jsonProduct["parentName"], imageURL: getImageURLFromStarFile(starFile, croppedSku) })
        }

        if (jsonProduct["category"] === "Crayon à lèvres") {
            _playGroundProducts.lips.penLips.push({ name: jsonProduct["name"], sku: croppedSku, parentSku: jsonProduct["parentSku"], parentName: jsonProduct["parentName"], imageURL: getImageURLFromStarFile(starFile, croppedSku) })
        }

        if (jsonProduct["category"] === "Gloss") {
            _playGroundProducts.lips.glosses.push({ name: jsonProduct["name"], sku: croppedSku, parentSku: jsonProduct["parentSku"], parentName: jsonProduct["parentName"], imageURL: getImageURLFromStarFile(starFile, croppedSku) })
        }
    });

    _playGroundProducts.eyes.eyeShadows = _playGroundProducts.eyes.eyeShadows.filter(function (this: any, item) {
        let key = `${item.parentSku}`
        return !this.has(key) && this.add(key)
    }, new Set())

    _playGroundProducts.eyes.eyeliners = _playGroundProducts.eyes.eyeliners.filter(function (this: any, item) {
        let key = `${item.parentSku}`
        return !this.has(key) && this.add(key)
    }, new Set())

    _playGroundProducts.eyes.mascaras = _playGroundProducts.eyes.mascaras.filter(function (this: any, item) {
        let key = `${item.parentSku}`
        return !this.has(key) && this.add(key)
    }, new Set())

    _playGroundProducts.foundation.blushs = _playGroundProducts.foundation.blushs.filter(function (this: any, item) {
        let key = `${item.parentSku}`
        return !this.has(key) && this.add(key)
    }, new Set())

    _playGroundProducts.foundation.bronzers = _playGroundProducts.foundation.bronzers.filter(function (this: any, item) {
        let key = `${item.parentSku}`
        return !this.has(key) && this.add(key)
    }, new Set())

    _playGroundProducts.foundation.concealers = _playGroundProducts.foundation.concealers.filter(function (this: any, item) {
        let key = `${item.parentSku}`
        return !this.has(key) && this.add(key)
    }, new Set())

    _playGroundProducts.foundation.foundations = _playGroundProducts.foundation.foundations.filter(function (this: any, item) {
        let key = `${item.parentSku}`
        return !this.has(key) && this.add(key)
    }, new Set())

    _playGroundProducts.foundation.powders = _playGroundProducts.foundation.powders.filter(function (this: any, item) {
        let key = `${item.parentSku}`
        return !this.has(key) && this.add(key)
    }, new Set())

    _playGroundProducts.lips.glosses = _playGroundProducts.lips.glosses.filter(function (this: any, item) {
        let key = `${item.parentSku}`
        return !this.has(key) && this.add(key)
    }, new Set())

    _playGroundProducts.lips.lipSticks = _playGroundProducts.lips.lipSticks.filter(function (this: any, item) {
        let key = `${item.parentSku}`
        return !this.has(key) && this.add(key)
    }, new Set())

    _playGroundProducts.lips.penLips = _playGroundProducts.lips.penLips.filter(function (this: any, item) {
        let key = `${item.parentSku}`
        return !this.has(key) && this.add(key)
    }, new Set())

    console.warn(_playGroundProducts)
    return _playGroundProducts

}

export type PlayGroundAllProductsType = {
    foundation: {
        concealers: PlayGroundProduct[],
        blushs: PlayGroundProduct[],
        bronzers: PlayGroundProduct[],
        foundations: PlayGroundProduct[],
        powders: PlayGroundProduct[]
    },
    eyes: {
        eyeShadows: PlayGroundProduct[],
        mascaras: PlayGroundProduct[],
        eyeliners: PlayGroundProduct[]
    },
    lips: {
        glosses: PlayGroundProduct[],
        lipSticks: PlayGroundProduct[],
        penLips: PlayGroundProduct[],
    }

}

export const countProductsForCategories = (category: PGCategory, products: PlayGroundAllProductsType): number => {
    if (category === PGCategory.foundation) {
        return countProductsForUnderCategories(PGUnderCategory.blush, products) + countProductsForUnderCategories(PGUnderCategory.bronzers, products) + countProductsForUnderCategories(PGUnderCategory.foundations, products) + countProductsForUnderCategories(PGUnderCategory.concealer, products) + countProductsForUnderCategories(PGUnderCategory.powders, products)
    }

    if (category === PGCategory.eyes) {
        return countProductsForUnderCategories(PGUnderCategory.eyeLiners, products) + countProductsForUnderCategories(PGUnderCategory.eyeShadows, products) + countProductsForUnderCategories(PGUnderCategory.mascaras, products)
    }

    if (category === PGCategory.lips) {
        return countProductsForUnderCategories(PGUnderCategory.glosses, products) + countProductsForUnderCategories(PGUnderCategory.penLips, products) + countProductsForUnderCategories(PGUnderCategory.lipSticks, products)
    }

    return 0
}

export const countProductsForUnderCategories = (underCategory: PGUnderCategory, products: PlayGroundAllProductsType): number => {
    let counter: number = 0

    if (underCategory === PGUnderCategory.concealer) {
        products.foundation.concealers.forEach(concealer => {
            if(concealer.isEnable && concealer.parentName){
                counter++
            }
        });
        return counter
    }

    if (underCategory === PGUnderCategory.bronzers) {
        products.foundation.bronzers.forEach(bronzer => {
            if(bronzer.isEnable && bronzer.parentName){
                counter++
            }
        });
        return counter
    }

    if (underCategory === PGUnderCategory.foundations) {
        products.foundation.foundations.forEach(foundation => {
            if(foundation.isEnable && foundation.parentName){
                counter++
            }
        });
        return counter
    }

    if (underCategory === PGUnderCategory.powders) {
        products.foundation.powders.forEach(powder => {
            if(powder.isEnable && powder.parentName){
                counter++
            }
        });
        return counter
    }

    if (underCategory === PGUnderCategory.blush) {
        products.foundation.blushs.forEach(blush => {
            if(blush.isEnable && blush.parentName){
                counter++
            }
        });
        return counter
    }

    if (underCategory === PGUnderCategory.eyeLiners) {
        products.eyes.eyeliners.forEach(eyeLiner => {
            if (eyeLiner.isEnable && eyeLiner.parentName) {
                counter++
            }
        });
        return counter
    }

    if (underCategory === PGUnderCategory.eyeShadows) {
        products.eyes.eyeShadows.forEach(eyeShadow => {
            if (eyeShadow.isEnable && eyeShadow.parentName) {
                counter++
            }
        });
        return counter
    }

    if (underCategory === PGUnderCategory.mascaras) {
        products.eyes.mascaras.forEach(mascaraProduct => {
            if (mascaraProduct.isEnable && mascaraProduct.parentName) {
                counter++
            }
        });
        return counter
    }

    if (underCategory === PGUnderCategory.glosses) {
        products.lips.glosses.forEach(gloss => {
            if (gloss.isEnable && gloss.parentName) {
                counter++
            }
        });
        return counter
    }

    if (underCategory === PGUnderCategory.lipSticks) {
        products.lips.lipSticks.forEach(lipStick => {
            if (lipStick.isEnable && lipStick.parentName) {
                counter++
            }
        });
        return counter
    }

    if (underCategory === PGUnderCategory.penLips) {
        products.lips.penLips.forEach(penLip => {
            if (penLip.isEnable && penLip.parentName) {
                counter++
            }
        });
        return counter
    }

    return 0
}

export type PlayGroundProduct = {
    name: string,
    sku: string,
    parentSku: string,
    parentName: string,
    imageURL: string,
    isEnable?: boolean
}


export const setCategoriesSrc = (category: PGCategory | PGUnderCategory): string => {
    switch (category) {
        case PGCategory.eyes:
            return eyes
        case PGCategory.foundation:
            return complexion
        case PGCategory.lips:
            return lips
        case PGUnderCategory.blush:
            return blush
        case PGUnderCategory.bronzers:
            return bronzer
        case PGUnderCategory.concealer:
            return concealer
        case PGUnderCategory.eyeLiners:
            return eyeliner
        case PGUnderCategory.eyeShadows:
            return eyeShadow
        case PGUnderCategory.foundations:
            return foundation
        case PGUnderCategory.glosses:
            return gloss
        case PGUnderCategory.lipSticks:
            return lipStick
        case PGUnderCategory.mascaras:
            return mascara
        case PGUnderCategory.penLips:
            return penLip
        case PGUnderCategory.powders:
            return powder
        default:
            return ""
    }
}

export const displayProductNameWithLimiter = (name: string, limiter: number = 27): string => {

    if (name.length > limiter) {
        return name.slice(0, limiter) + "..."
    }

    return name
}

export const findInPGProducts = (PGProducts: any, sku: string): PlayGroundProduct | null => {
    for (const category in PGProducts) {
        for (const subCategory in PGProducts[category]) {
            let subCategProducts: PlayGroundProduct[] = PGProducts[category][subCategory] as PlayGroundProduct[]

            for (let i = 0; i < subCategProducts.length; i++) {
                if (subCategProducts[i].sku === sku) {
                    return subCategProducts[i]
                }
            }
        }
    }

    return null
}

export const downloadMakeUpProducts = async (bearer?: string) => {
    const makeUpProducts: any[] = require("../../Components/Try-On/BeautyKit2/myStoreUpdated.json")
    let products: { sku: string, name: string, imageURL?: string }[] = []

    makeUpProducts.forEach((product) => {
        products.push({ sku: cropSku(product.sku), name: product.name })
    })

    bearer = "ya29.a0AWY7Ckk-feDQyWQycu3saUQlTKxvxyVP2xt1iHNuVE9CiMRmlq_V5ei1zenziDSri3Fc2DnKO58Sb7ICd6wzqwZIN3-0IhEm0P4bXloggQxP8B4YSgJm3CcTSHm_uJ2FjTQGQ5DA63qM8PrLzz5yTwOp5qMR-msaCgYKAfUSARMSFQG1tDrpVFuArR2Am0n_S4AE5Epepg0166"

    let myHeaders = new Headers();
    myHeaders.append("Authorization", "Google " + bearer);
    myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');


    for (let i = 0; i < products.length; i++) {
        let result = await fetch("https://app-we-prd-star-api.azurefd.net/v1/Products/" + products[i].sku + "?locale=fr_FR&withExtendedAttributes=true", {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        })
            .then(response => response.json())
            .then(result => {
                return result
            })
            .catch(error => console.error('error', error));

        if (result) {
            let medias: any[] = result.Response.Product.Medias
            if (medias.length > 0) {
                let imageObject = medias.find((media) => media.Key === "01_S")
                if (imageObject) {
                    products[i].imageURL = imageObject.Value
                }
            }
        }
        console.warn(i / products.length * 100 + "%")
    }
    downloadObjectAsJson(products, "STAR_PRODUCTS_MAKE_UP")
}

export const checkProductsAvailability = async (products: PlayGroundAllProductsType): Promise<PlayGroundAllProductsType> => {
    const sisleyAPI = new SisleyAPI()

    let eyeShadows = products.eyes.eyeShadows
    for (let i = 0; i < eyeShadows.length; i++) {
        const tuple: [boolean, string] = await sisleyAPI.getAvailibiltyAndParentNameProduct(eyeShadows[i].parentSku)
        eyeShadows[i].isEnable = tuple[0]
        eyeShadows[i].parentName = tuple[1]
    }

    let eyeLiners = products.eyes.eyeliners
    for (let i = 0; i < eyeLiners.length; i++) {
        const tuple: [boolean, string] = await sisleyAPI.getAvailibiltyAndParentNameProduct(eyeLiners[i].parentSku)
        eyeLiners[i].isEnable = tuple[0]
        eyeLiners[i].parentName = tuple[1]
    }

    let eyeMascaras = products.eyes.mascaras
    for (let i = 0; i < eyeMascaras.length; i++) {
        const tuple: [boolean, string] = await sisleyAPI.getAvailibiltyAndParentNameProduct(eyeMascaras[i].parentSku)
        eyeMascaras[i].isEnable = tuple[0]
        eyeMascaras[i].parentName = tuple[1]
    }

    let blushs = products.foundation.blushs
    for (let i = 0; i < eyeShadows.length; i++) {
        const tuple: [boolean, string] = await sisleyAPI.getAvailibiltyAndParentNameProduct(blushs[i].parentSku)
        blushs[i].isEnable = tuple[0]
        blushs[i].parentName = tuple[1]
    }

    let bronzers = products.foundation.bronzers
    for (let i = 0; i < bronzers.length; i++) {
        const tuple: [boolean, string] = await sisleyAPI.getAvailibiltyAndParentNameProduct(bronzers[i].parentSku)
        bronzers[i].isEnable = tuple[0]
        bronzers[i].parentName = tuple[1]
    }

    let concealers = products.foundation.concealers
    for (let i = 0; i < concealers.length; i++) {
        const tuple: [boolean, string] = await sisleyAPI.getAvailibiltyAndParentNameProduct(concealers[i].parentSku)
        concealers[i].isEnable = tuple[0]
        concealers[i].parentName = tuple[1]
    }

    let foundations = products.foundation.foundations
    for (let i = 0; i < foundations.length; i++) {
        const tuple: [boolean, string] = await sisleyAPI.getAvailibiltyAndParentNameProduct(foundations[i].parentSku)
        foundations[i].isEnable = tuple[0]
        foundations[i].parentName = tuple[1]
    }

    let powders = products.foundation.powders
    for (let i = 0; i < powders.length; i++) {
        const tuple: [boolean, string] = await sisleyAPI.getAvailibiltyAndParentNameProduct(powders[i].parentSku)
        powders[i].isEnable = tuple[0]
        powders[i].parentName = tuple[1]
    }

    let glosses = products.lips.glosses
    for (let i = 0; i < glosses.length; i++) {
        const tuple: [boolean, string] = await sisleyAPI.getAvailibiltyAndParentNameProduct(glosses[i].parentSku)
        glosses[i].isEnable = tuple[0]
        glosses[i].parentName = tuple[1]
    }

    let lipSticks = products.lips.lipSticks
    for (let i = 0; i < lipSticks.length; i++) {
        const tuple: [boolean, string] = await sisleyAPI.getAvailibiltyAndParentNameProduct(lipSticks[i].parentSku)
        lipSticks[i].isEnable = tuple[0]
        lipSticks[i].parentName = tuple[1]
    }

    let penLips = products.lips.penLips
    for (let i = 0; i < penLips.length; i++) {
        const tuple: [boolean, string] = await sisleyAPI.getAvailibiltyAndParentNameProduct(penLips[i].parentSku)
        penLips[i].isEnable = tuple[0]
        penLips[i].parentName = tuple[1]
    }

    return products
}