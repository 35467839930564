import { Base64 } from 'js-base64'
import { QRCodeSVG } from 'qrcode.react'
import { DesktopPageContainer, Scan } from '../../Styles/VTO/VTOContainers'
import { DesktopTitle, DesktopText, QRCodeContainer, TopLeftBorder, TopRightBorder, BottomLeftBorder, BottomRightBorder } from '../../Styles/VTO/VTOTexts'
import { useTranslation } from 'react-i18next'
import { TryOnCloseButton } from '../../Styles/VTO/VTOButtons'
import { closeIFrame } from '../../Constants/Utilitaries'
import reactStringReplace from 'react-string-replace'
import logo from '../../Assets/Icons/Logo_White_Background.png'
import { getPlayGroundMode } from './TryOnIndex'

const TryOnDesktopPage = () => {
    const { t } = useTranslation()

    const createLink = (): string => {
        let link: string = ""

        let url = new URL(window.location.href)
        if (url.searchParams.get("data")) {
            let urlString = Base64.atob(url.searchParams.get("data")!.replace(" ", "+"))
            let data = JSON.parse(urlString)
            if (data["options"]) {
                if (data["options"]["currentUrl"]) {
                    link = data["options"]["currentUrl"]
                }
            }
        }
        return link + "#vto"
    }

    return (<DesktopPageContainer>
        <TryOnCloseButton onClick={() => closeIFrame()} />
        <DesktopTitle>
            {getPlayGroundMode() ? t("playGround.qrCodeTitle") : t("tryOn.desktopTitle")}
        </DesktopTitle>

        <DesktopText>
            {reactStringReplace(t("tryOn.desktopText"), t("tryOn.highlightedText"), (match, i): any => {
                return <span style={{ fontWeight: 900 }}>{match}</span>
            })}
        </DesktopText>

        <QRCodeContainer>
            <TopLeftBorder />
            <TopRightBorder />
            <img alt="Sisley Logo" style={{ width: "24px", position: "absolute" }} src={logo} />
            <QRCodeSVG value={createLink()} style={{ width: "128px", height: "128px", padding: "0px" }} />
            <BottomLeftBorder />
            <BottomRightBorder />
            <Scan />
        </QRCodeContainer>
    </DesktopPageContainer>)
}

const TryOnDesktopComponent = () => {
    return <TryOnDesktopPage />
}

export default TryOnDesktopComponent