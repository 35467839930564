import { useTranslation } from "react-i18next"
import styled from "styled-components"

import { MainButton } from "../../Styles/Buttons"
import { useEffect, useRef } from "react"

interface BadQualityProps {
    displayPopUp: Function
}

const BadQualityPopUp = (props: BadQualityProps) => {
    const { t } = useTranslation()
    const containerRef = useRef<HTMLDivElement>(null)

    const close = () => {
        containerRef.current!.style.bottom = "-100%"
        setTimeout(() => {
            props.displayPopUp(false)
        }, 700);
    }

    useEffect(() => {
        let timeOut = setTimeout(() => {
            containerRef.current!.style.bottom = "0%"
            console.warn("DISPLAYED")
        }, 2000);

        return () => window.clearTimeout(timeOut)
    }, [])

    return (
        <QualityContainer ref={containerRef} id="BadQualityPopUp">

            <QualityText>
                {t("tryOn.qualityError", "Bad Quality")}
            </QualityText>

            <BadQualityButtonContainer>
                <QUalityText onClick={() => close()}>
                    {t("tryOn.understand", "J'ai compris")}
                </QUalityText>
            </BadQualityButtonContainer>

        </QualityContainer>
    )
}

const QualityContainer = styled.div`
    width: 100%;
    height: initial;
    bottom: -100%;
    display: flex;
    position: fixed;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: white;
    z-index: 50;
    max-height: 80%;
    overflow: auto;

    @media (orientation: portrait){
        border-top-left-radius: 24px;
        border-top-right-radius: 24px;
        padding: 16px;
        box-sizing: border-box;
        transition: bottom 0.7s;
        margin: 0px;
        min-height: 224px;
    }

    @media (orientation: landscape){
        width: 100vw;
        min-height: 100vh;        
        position: absolute;
        bottom: 0;
        left: 0;
    }
`
const QualityText = styled.p`
    font: normal normal 500 20px/24px Playfair;
    color: black;
    width: 80%;

    @media (orientation: portrait){
        margin: 0px;
        margin: 24px 0px 12px;
        width: 100%;
    }
`

const QUalityText = styled(MainButton)`
    letter-spacing: 1px;
    font: normal normal 500 12px/15px Helvetica Bold;
    padding: 12px 24px;
    width: 100%;
    text-transform: uppercase;
    margin-top: 16px;
    margin-bottom: 8px;

    @media (orientation: landscape){
        width: initial;
    }

    &:hover {
        border: solid 1px #CACACA;
        background-color: white;
        color: black;
    }
`

const BadQualityButtonContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (orientation: landscape){
        width: 80%;
        justify-content: left;
    }
`

export default BadQualityPopUp