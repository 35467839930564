import { useDispatch, useSelector } from "react-redux"
import * as ActionType from '../Constants/ActionsTypes'
import { AgreeButton, OTSTCInputButton } from '../Styles/Buttons'
import { OnboardingButtonContainer, OnboardingContainer, ParagraphContainer } from '../Styles/Containers'
import { LockIcon } from '../Styles/Images'
import { H1, ParagraphText, SecurityText } from '../Styles/Texts'
import { useTranslation } from 'react-i18next'
import { useEffect, useRef } from "react"
import '../Assets/animations.css'
import { CSSTransition } from 'react-transition-group'
import { useState } from 'react'
import AnimateHeight from "react-animate-height"
import reactStringReplace from 'react-string-replace'
import { mobileCheck, runHeightMachine, sendAnalytics } from "../Constants/Utilitaries"
import skinDiagDispatcher from './SkinDiagApi';
import { MySkinDiagState } from "../type"
import i18n from "../i18nextInit"

const OnBoardingView = (props: any) => {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const [inProp, setInProp] = useState<boolean>(false)
    const [warnings, setWarnings] = useState<any>([])
    const [isExpended, setExpended] = useState<boolean>(false)
    const error: any[] = useSelector((state: MySkinDiagState) => state.session.error)
    const moreInformationText = useRef<any>(null)

    useEffect(() => {
        setTimeout(() => {
            runHeightMachine(true, "ON_BOARDING")
        }, 500);

        window.scrollTo(0, 0)

        let dataBis = {
            messageType: "scroll_top",
        };
        console.log(dataBis)
        window.parent.postMessage(dataBis, "*")
    }, [])

    // enable/disable transitions
    useEffect(() => {
        setInProp(true)
        // eslint-disable-next-line
        let warningsTab: Array<any> = []
        let key = 0
        for (let i = 1; i < 7; i++) {
            if (i18n.exists('onboardView.warningsTexts' + i)) {
                warningsTab.push(<ParagraphContainer key={key + i}>
                    <ParagraphText>
                        {reactStringReplace(t('onboardView.warningsTexts' + i, ""), t("onboardView.textToLink1"), (match, i): any => {
                            return <span key={10} onClick={() => window.open(t("onboardView.href1"), "_blank")} style={{ textDecoration: "underline", cursor: "pointer" }}>{t("onboardView.textToLink1")}</span>
                        })}
                    </ParagraphText>
                </ParagraphContainer>)
            }
        }

        setWarnings(warningsTab)

        //eslint-disable-next-line
    }, [dispatch])

    useEffect(() => {
        if (error.length > 0) {
            alert(error)
        }
    }, [error])

    const onOTSTCButtonChange = (event: any) => {

        const action = { type: ActionType.ANALYSE_PHOTO, value: URL.createObjectURL(event.target.files[0]) };
        dispatch(action)
        window.otstcTest = event.target.files[0]
        // downloadImagesForOTSTC(event.target.files[0])
        dispatch(skinDiagDispatcher);
    }

    const nextStep = () => {
        let url = new URL(window.location.href)

        if (new URL(window.location.href).searchParams.get("otstcMode") === "true") {
            document.getElementById("OTSTC_BUTTON")!.click()
            return
        }

        sendAnalytics("legal_notice_ok")

        window.dataLayer.push({
            event: 'events',
            eventProps: {
                category: "Diag",
                action: "click",
                label: "legal_notice_ok"
            }
        })

        if (url.searchParams.get("withUI") === "false") {

            setTimeout(() => {
                dispatch({ type: ActionType.SET_STEP, value: 6 })
            }, 300);
        }

        else if (!mobileCheck()) {
            setTimeout(() => {
                dispatch({ type: ActionType.SET_STEP, value: 8 })
            }, 300);
        }
        else {
            let dataBis = {
                messageType: "content_height",
                height: "2000"
            }
            window.parent.postMessage(dataBis, "*")

            setInProp(false)
            setTimeout(() => {
                dispatch({ type: ActionType.SET_STEP, value: 11 })
            }, 300);
        }
    }

    const expandText = () => {
        runHeightMachine(true, "ON_BOARDING")
        setTimeout(() => {
            runHeightMachine(true, "ON_BOARDING")
        }, 500);
        moreInformationText!.current.style.display = "none"
        setExpended(true)
    }

    return (
        <CSSTransition in={inProp} key={1} classNames="swipeToRight" addEndListener={() => null}>
            <OnboardingContainer id="ON_BOARDING">

                <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                    <LockIcon style={{ margin: '12px 0px' }} />
                    <H1 style={{ padding: '12px 0px 0px 0px', font: "normal normal 500 13px/21px Helvetica Bold" }}> {t('onboardView.security', 'Sécurité')}</H1>
                </div>

                <SecurityText> {t('onboardView.permission', 'Nous veillons à la sécurité de vos données')}</SecurityText>

                <ParagraphContainer>
                    <ParagraphText>
                        {t("onboardView.intro", "L'outil SisleySkin Expert analyse une photographie de votre visage afin de vous fournir des conseils personnalisés et vous recommander une routine de produits adaptés à vos résultats d'analyses. Votre photographie ne sera pas conservée et sera immédiatement supprimée après l'analyse.")}
                    </ParagraphText>
                    <H1 ref={moreInformationText} onClick={() => expandText()} style={{ textDecoration: "underline", textAlign: "start", padding: "0px", marginBottom: "24px", cursor: "pointer" }}>
                        {t("onboardView.moreInformation", "En savoir plus")}
                    </H1>
                </ParagraphContainer>
                <AnimateHeight duration={500} height={isExpended ? "auto" : 0}>
                    {warnings}
                </AnimateHeight>
                <OnboardingButtonContainer>
                    <OTSTCInputButton id={"OTSTC_BUTTON"} type={"file"} capture={"user"} onChange={(e) => onOTSTCButtonChange(e)} />
                    <AgreeButton style={{ textTransform: 'uppercase' }} onClick={() => nextStep()}>{t('onboardView.agree', 'Je suis d\'accord')}</AgreeButton>
                </OnboardingButtonContainer>
            </OnboardingContainer>
        </CSSTransition>

    )
}

const OnBoardingComponent = () => {
    return (
        <OnBoardingView />
    )
}

export default OnBoardingComponent