// Core modules imports are same as usual
import { Pagination } from 'swiper';
import { Swiper, SwiperRef, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import './swiper.css'

import "swiper/css/pagination";
import { useEffect, useRef, useState } from 'react';
import { AddToCartContainer, NoShadeIcon, PGProductCellContainer, PGProductCellImg, PGProductCellText, PGShadeText, RoundBar, SelectedShadeRound, ShadeCarouselContainer, ShadeRound, SwiperCtnr } from '../../../Styles/VTO/VTOContainers';

import NoShade from '../../../Assets/Icons/NoShade.png'
import { useTranslation } from 'react-i18next';
import { AddToCartButton, PGShadeClose, PGShadeCloseContainer } from '../../../Styles/VTO/VTOButtons';
import { AnimatePresence } from 'framer-motion';
import { displayProductNameWithLimiter, PGUnderCategory, PlayGroundState } from '../PlaygroundConstants';
import SisleyAPI from '../../../API/SisleyAPI';
import { useDispatch, useSelector } from 'react-redux';
import { MySkinDiagState } from '../../../type';
import { TryOnProductClass } from '../../../Constants/TryOnProduct';
import * as actionTypes from '../../../Constants/ActionsTypes'
import ReplacePopUP from './ReplacePopUp'
import loadingFlower from '../../../Assets/gif/LoadingFlower.gif'
import i18n from '../../../i18nextInit';
import { sendAnalytics } from '../../../Constants/Utilitaries';

interface ShadeSelectorProps {
    selectedSku: string,
    selectedParentSku: string,
    setPlayGroundState: Function,
    setActualTryOnProduct: Function,
    setCategory: Function,
    setUnderCategory: Function,
    afterBtnHandler: Function,
    undercategory: PGUnderCategory,
    isShadesBackButtonDisplayed: boolean,
    displayShadesBackButton: Function,
    setCartPanelWide: Function
}

const handleShadeColor = (category: PGUnderCategory, shadeColor?: string) => {
    if (!shadeColor) {
        switch (category) {
            case PGUnderCategory.blush:
            case PGUnderCategory.bronzers:
            case PGUnderCategory.foundations:
            case PGUnderCategory.powders:
            case PGUnderCategory.concealer:
                return "#c48455 "
            default:
                return "black"
        }
    }

    return shadeColor
}

export const ShadeSelector = (props: ShadeSelectorProps) => {

    const [shades, setShades] = useState<any[]>([])
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const tryOnProducts: TryOnProductClass[] = useSelector((state: MySkinDiagState) => state.session.tryOnProducts)
    const swiperRef = useRef<SwiperRef>(null)
    const playGroundCart: TryOnProductClass[] = useSelector((state: MySkinDiagState) => state.session.playGroundCart)
    const [isPopUpDisplayed, displayReplacePopUp] = useState<boolean>(false)
    const shadeGenerationFlag = useRef<number>(0)

    useEffect(() => {
        // Au lancement de la vue, on récupère le sku passé pour aller récupérer les infos du produit si on n'est pas en mode edition.
        console.warn(props.isShadesBackButtonDisplayed)
        if (props.isShadesBackButtonDisplayed) {
            const sisleyAPI = new SisleyAPI()
            sisleyAPI.getMasterProducts(props.selectedParentSku, dispatch, true, props.undercategory)
        }

        //eslint-disable-next-line
    }, [props.displayShadesBackButton])

    useEffect(() => {
        // puis on génère les teintes asociées (avec la teinte vide qui est générée directement dans SisleyAPI.ts en tant que variant) du produit passé en paramètre
        let actualproduct: TryOnProductClass | undefined = tryOnProducts.find(product => product.parentSku === props.selectedParentSku)

        setTimeout(() => {
            if (actualproduct && shadeGenerationFlag.current < 2) {
                // on active tous les produits dès qu'on arrive sur cette vue.
                props.afterBtnHandler()
                let _shades = []
                let key: number = 0

                // Teinte vide.
                _shades.push(<SwiperSlide>
                    <NoShadeIcon key={key} src={NoShade} />
                </SwiperSlide>)
                key++

                // Génère une teinte pour chaque variants
                actualproduct.variants.forEach((product: TryOnProductClass) => {
                    if (product.sku !== "NoShade") {
                        _shades.push(<SwiperSlide>
                            <ShadeRound key={key} color={handleShadeColor(props.undercategory, product.shadeColor)} isLittle={false} />
                            {(!product.isVTOEnable || product.stock === 0) && <RoundBar />}
                        </SwiperSlide>)
                        key++
                    }
                })

                setShades(_shades)
                shadeGenerationFlag.current++
            }
        }, 1000);

        //eslint-disable-next-line
    }, [tryOnProducts, shadeGenerationFlag])

    useEffect(() => {
        // On envoi l'utilisateur vers la shade sélectionné si on est en mode Edition, sinon on envoie à la première teinte.
        if (shades.length > 0) {
            if (!props.isShadesBackButtonDisplayed) {
                let index: number = getActualTryOnProduct()!.variants.findIndex(product => product.sku === props.selectedSku)
                // console.warn(getActualTryOnProduct()!.variants.find(product => product.sku === props.selectedSku))
                // Ici on et bon (mais on a pas les variants ?)

                if (index > -1) {
                    swiperRef.current!.swiper.slideTo(index, 200)
                    console.warn(tryOnProducts.find(product => product.parentSku === props.selectedParentSku)?.variants[index])
                    // setTimeout(() => {
                    //     dispatch({ type: actionTypes.NEW_UPDATE_TRY_ON_PRODUCT, value: tryOnProducts.find(product => product.parentSku === props.selectedParentSku)?.variants[index] })
                    // }, 200);
                }
            } else {
                swiperRef.current!.swiper.slideTo(1, 200)
            }

        }
        //eslint-disable-next-line
    }, [shades, props.selectedParentSku])

    const onShadeChangeHandler = (e: any) => {
        let _product: TryOnProductClass = tryOnProducts.find(product => product.parentSku === props.selectedParentSku)?.variants[e.activeIndex]!
        if (!_product) {
            return
        }

        if (_product.makeUpCategory === PGUnderCategory.eyeLiners) {
            _product.isLiner = true
        }
        dispatch({ type: actionTypes.NEW_UPDATE_TRY_ON_PRODUCT, value: _product })
        //dispatch({ type: actionTypes.NEW_UPDATE_TRY_ON_PRODUCT, value: tryOnProducts.find(product => product.parentSku === props.selectedParentSku)?.variants[e.activeIndex] })
    }

    const getActualTryOnProduct = (): TryOnProductClass | undefined => {
        let _tryOnProduct: TryOnProductClass = tryOnProducts.find(product => product.parentSku === props.selectedParentSku)!

        if (!_tryOnProduct) {
            return undefined
        }
        return _tryOnProduct
    }

    const handleShadeName = () => {
        let _product = getActualTryOnProduct()

        if (!_product) {
            return t("playGround.noShade")
        }
        if (_product!.shadeName === "NoShade") {
            return t("playGround.noShade")
        }

        return _product.shadeName
    }

    const handleImage = () => {
        let _product: TryOnProductClass | undefined = getActualTryOnProduct()

        if (!_product || _product.sku === "NoShade" || !_product.imgURL) {
            return getActualTryOnProduct()!.variants[0].imgURL
        }

        return _product.imgURL
    }

    const handleButtonDisabled = () => {
        let _product: TryOnProductClass | undefined = getActualTryOnProduct()

        if (!_product || _product.stock === 0 || !_product.isVTOEnable || _product.sku === "NoShade") {
            return true
        }

        return false
    }

    const handleClose = () => {
        props.setPlayGroundState(PlayGroundState.chooseProduct)
        dispatch({ type: actionTypes.REMOVE_TRYON_PRODUCT, value: getActualTryOnProduct() })
        props.setActualTryOnProduct(undefined)

        // Ici en cas d'annulation de mise au panier pour un produit d'une même catégorie, comme on a supprimé le produit de tryonProducts, il faut le rajouter.
        for (let i = 0; i < playGroundCart.length; i++) {
            if (playGroundCart[i].makeUpCategory === props.undercategory ||
                (playGroundCart[i].makeUpCategory === PGUnderCategory.lipSticks && props.undercategory === PGUnderCategory.glosses) ||
                (playGroundCart[i].makeUpCategory === PGUnderCategory.glosses && props.undercategory === PGUnderCategory.lipSticks)) {
                dispatch({ type: actionTypes.NEW_UPDATE_TRY_ON_PRODUCT, value: playGroundCart[i] })
                break
            }
        }
    }

    const handleAddToLook = () => {

        // Checker ici si un produit de même type existe dans le cart. 
        // S'il existe, supprimer le produit dans le cart AVANT de faire l'ajout du produit actuel.

        for (let i = 0; i < playGroundCart.length; i++) {
            if (playGroundCart[i].makeUpCategory === props.undercategory ||
                (playGroundCart[i].makeUpCategory === PGUnderCategory.glosses && props.undercategory === PGUnderCategory.lipSticks) ||
                (playGroundCart[i].makeUpCategory === PGUnderCategory.lipSticks && props.undercategory === PGUnderCategory.glosses)) {
                displayReplacePopUp(true)
                props.displayShadesBackButton(true)
                props.setCartPanelWide(true)
                return
            }
        }

        dispatch({ type: actionTypes.UPDATE_PLAYGROUND_CART, value: getActualTryOnProduct() })
        props.setPlayGroundState(PlayGroundState.chooseCategory)
        props.setCategory(null)
        props.setUnderCategory(null)
        props.setActualTryOnProduct(undefined)
        props.displayShadesBackButton(true)
        props.setCartPanelWide(true)

        sendAnalytics("add_to_look", "vto_playground")
    }

    const handleStock = (): boolean => {
        let _product = getActualTryOnProduct()

        if (!_product) {
            return false
        }

        if (_product.stock === 0) {
            return true
        }

        return false
    }

    const handleVTODisabled = (): boolean => {
        let _product = getActualTryOnProduct()

        if (!_product) {
            return false
        }

        if (i18n.language === "en_MY" || i18n.language === "en_SG") {
            if (_product.stock > 0) {
                return false
            }
        }

        if (!_product.isVTOEnable && _product.stock > 0) {
            return true
        }

        return false
    }

    const setInRed = () => {
        let _product = getActualTryOnProduct()

        if (!_product) {
            return false
        }
        if (_product.shadeName === "NoShade") {
            return false
        }

        if (_product.stock === 0 || !_product.isVTOEnable) {
            return true
        }

        return false
    }

    return (
        <ShadeCarouselContainer isMonoProduct={true} playground>

            <AnimatePresence>
                {getActualTryOnProduct() && <PGProductCellContainer
                    style={{ top: "-66px" }}
                    initial={{ y: 10, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: 10, opacity: 0 }}
                    transition={{ duration: 0.25 }}
                    $isShadeMode>
                    <PGProductCellImg src={handleImage()} />
                    <PGProductCellText>
                        {displayProductNameWithLimiter(getActualTryOnProduct()!.parentName)}
                    </PGProductCellText>
                </PGProductCellContainer>}
            </AnimatePresence>

            <SwiperCtnr>
                {shades.length === 0 && <img alt="Shades Loader" src={loadingFlower} style={{ width: "64px", position: "absolute" }} />}

                {shades.length > 0 && <SelectedShadeRound $isOOS={setInRed()} style={{ top: "0px", left: "initial" }} playground isMonoProduct={true} />}
                {props.isShadesBackButtonDisplayed && <PGShadeCloseContainer style={{ top: "initial" }}>
                    <PGShadeClose onClick={() => handleClose()} />
                </PGShadeCloseContainer>}

                <Swiper
                    slidesPerView={'auto'}
                    slideToClickedSlide={true}
                    centeredSlides={true}
                    spaceBetween={12}
                    ref={swiperRef}
                    onSlideChange={(e) => onShadeChangeHandler(e)}
                    modules={[Pagination]}
                >
                    {shades}
                </Swiper>
            </SwiperCtnr>

            {shades.length > 0 && <PGShadeText>{handleShadeName()}
                {handleStock() && <span style={{ color: "#C53131" }}> - {t("tryOn.outOfStock")}</span>}
                {handleVTODisabled() && <span style={{ color: "#C53131" }}> - {t("tryOn.vtoDisabled")}</span>}
            </PGShadeText>}

            <AddToCartContainer
                key="BUTTON_CONTAINER"
                playground
                $isLook={true}
                style={{ marginTop: "initial" }}>
                <AddToCartButton isTwist={false} isExtended={true} onClick={() => handleAddToLook()} noProduct={handleButtonDisabled()}>
                    {t("playGround.addToLook")}
                </AddToCartButton>
            </AddToCartContainer>

            <AnimatePresence>
                {isPopUpDisplayed && <ReplacePopUP
                    currentTryOnProduct={getActualTryOnProduct()!}
                    displayPopUp={displayReplacePopUp}
                    setActualProduct={props.setActualTryOnProduct}
                    setPlaygroundState={props.setPlayGroundState}
                    setUnderCategory={props.setUnderCategory}
                    underCategory={props.undercategory}
                />}
            </AnimatePresence>

        </ShadeCarouselContainer>

    )
}

export default ShadeSelector