import { useTranslation } from "react-i18next"
import styled from "styled-components"

import { TryOnCloseButton } from "../../Styles/VTO/VTOButtons"
import i18n from "../../i18nextInit"
import { closeIFrame } from "../../Constants/Utilitaries"

const ChromePopUp = () => {
    const { t } = useTranslation()

    // J'utilise les mêmes balises que pour la pop up de navigation
    return (
        <PrivateNavigationContainer>
            {<PrivateNavigationClose onClick={() => closeIFrame()} />}

            <PrivateNavigationText>
                {t("tryOn.chromeError")}
            </PrivateNavigationText>

        </PrivateNavigationContainer>
    )
}

const PrivateNavigationContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    position: absolute;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: white;
    z-index: 100;
    box-shadow: 0px 3px 15px #00000029;

    @media (orientation: portrait){
        border-top-left-radius: 24px;
        border-top-right-radius: 24px;
        padding: 16px;
        box-sizing: border-box;
        margin: 0px;
        min-height: 200px;
    }

    @media (orientation: landscape){
        width: 100vw;
        min-height: 100vh;        
        position: absolute;
        bottom: 0;
        left: 0;
    }
`

const PrivateNavigationText = styled.p`
    width: 384px;
    font: normal normal 500 13px/21px Helvetica light;
    font-size: ${i18n.language === "tw_TW" ? "14px" : ""};

    color: black;
    text-align: center;

    @media (orientation: portrait){
        width: 100%;
    }
`

const PrivateNavigationClose = styled(TryOnCloseButton)`
    @media (orientation: portrait){
        position: absolute;
    }
`

export default ChromePopUp