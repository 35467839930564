import { useTranslation } from "react-i18next"
import styled from "styled-components"

import { TryOnCloseButton } from "../../Styles/VTO/VTOButtons"
import { isTablet } from "react-device-detect"
import { MainButton } from "../../Styles/Buttons"
import i18n from "../../i18nextInit"
import { motion } from "framer-motion"

interface PrivateNavigationPopUpProps {
    setAuthorized: Function
}

const PrivateNavigationPopUp = (props: PrivateNavigationPopUpProps) => {
    const { t } = useTranslation()

    const close = () => {
        props.setAuthorized(true)
    }

    return (
        <PrivateNavigationContainer
            initial={{ y: "100%" }}
            animate={{ y: 0 }}
            exit={{ y: "100%" }}
            transition={{ duration: 0.25 }}
            key="PRIVATE_NAV_POPUP">
            {isTablet && <PrivateNavigationClose onClick={() => close()} />}

            <PrivateNavigationTitle>
                {t("privateNavigation.title", "Navigation privée")}
            </PrivateNavigationTitle>

            <PrivateNavigationText>
                {t("privateNavigation.text", "Vous êtes actuellement sur une fenêtre de navigation privée. Pour que l’outil d’Essai Virtuel fonctionne correctement et que vous puissiez ajouter des produits à votre panier, nous vous conseillons d’utiliser une fenêtre de navigation classique.")}
            </PrivateNavigationText>

            {!isTablet && <PrivateNavigationButton onClick={() => close()}>
                {t("tryOn.understand", "J'ai compris")}
            </PrivateNavigationButton>}
        </PrivateNavigationContainer>
    )
}

const PrivateNavigationContainer = styled(motion.div)`
    width: 100vw;
    display: flex;
    position: fixed;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: white;
    z-index: 100;
    bottom: 0px;
    left: 0px;

    @media (orientation: portrait){
        border-top-left-radius: 24px;
        border-top-right-radius: 24px;
        padding: 16px;
        box-sizing: border-box;
        margin: 0px;
        min-height: 200px;
    }

    @media (orientation: landscape){
        width: 100vw;
        min-height: 100vh;        
        position: absolute;
        bottom: 0;
        left: 0;
    }
`

const PrivateNavigationTitle = styled.p`
    font: normal normal 500 20px/24px Playfair;
    font-size: ${i18n.language === "tw_TW" ? "22px" : ""};
    color: black;

    @media (orientation: portrait){
        margin: 0px;
    }
`

const PrivateNavigationText = styled.p`
    width: 384px;
    font: normal normal 500 13px/21px Helvetica light;
    font-size: ${i18n.language === "tw_TW" ? "14px" : ""};

    color: black;
    text-align: center;

    @media (orientation: portrait){
        width: 100%;
    }
`

const PrivateNavigationClose = styled(TryOnCloseButton)`
    @media (orientation: portrait){
        position: absolute;
    }
`

const PrivateNavigationButton = styled(MainButton)`
    letter-spacing: 1px;
    font: normal normal 500 12px/15px Helvetica Bold;
    font-size: ${i18n.language === "tw_TW" ? "14px" : ""};
    padding: 12px 24px;
    width: 100%;
    text-transform: uppercase;
    margin-top: 16px;

    @media (orientation: landscape){
        width: initial;
    }

    &:hover {
        border: solid 1px #CACACA;
        background-color: white;
        color: black;
    }
`

export default PrivateNavigationPopUp