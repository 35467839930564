import styled from "styled-components";
import i18n from "../../i18nextInit";
import { MainButton } from "../Buttons";

export const ColoredBackground = styled.div`
    width: 100vw;
    background-color: #FCE9D5;
    display: flex;
    flex-direction: column;
    position: relative;
    margin-top: -24px;
    padding-top: 24px;
    padding-bottom: 24px;

    @media (min-width: 768px) {
        align-items: center;
    }
`

export const YourSkinDiag = styled.p`
    font: normal normal normal 70px/70px Playfair;
    color: black;
    margin: 0px;
    word-break: break-word;
    text-transform: uppercase;

    @media (min-width: 768px) and (max-width: 1224px) {
        text-align: start;
        margin: 12px 0px 16px;
        max-width: 60%;
    }

    @media (min-width: 1224px) {
        text-align: center;
    }
`

export const Paragraph1 = styled.p`
    font: normal normal 300 16px/20px Helvetica;
    font-size: ${i18n.language === "tw_TW" ? "18px" : ""};
    line-height: ${i18n.language === "tw_TW" ? "24px" : ""};
    text-align: justify;
    margin-bottom: 32px;
    margin-top: 32px;
    color: black;

    @media (min-width: 768px) and (max-width: 1224px) {
        width: 60%;
        margin: 12px 0px;
    }

    @media (min-width: 1224px) {
        text-align: center;
    }
`

export const TextContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 54px;
    box-sizing: border-box;

    @media (min-width: 768px) {
        padding: initial;
        padding-right: 250px;
    }
`

export const TitleNumber = styled.p`
    font: normal normal 500 250px/188px Playfair;
    color: #ADDCFF;
    margin: 24px;

    @media (min-width: 768px) {
        display: none;
    }
`

export const TitleNumberDesktop = styled.p`
    font: normal normal 500 250px/188px Playfair;
    color: #EDEDED;
    margin: 24px;

    @media (max-width: 768px) {
        display: none;
    }
`

export const Paragraph2 = styled.p<{isCentered: boolean}>`
    font: normal normal 400 22px/34px playfair;
    font-size: ${i18n.language === "tw_TW" ? "24px" : ""};
    line-height: ${props => !props.isCentered ? "28px" : ""};
    width: 75%;
    margin: ${props => props.isCentered ? "0px" : "24px 0px 12px"};

    @media (min-width: 768px) {
        width: 100%;
        text-align: ${props => props.isCentered ? "center" : 'left'};
        font: ${props => !props.isCentered ? "normal normal 400 26px/28px playfair": ''};
        font-size: ${i18n.language === "tw_TW" ? "28px" : ""};
        margin: 12px;
        margin-left: ${props => !props.isCentered ? "0px" : ""}
    }

    @media (min-width: 768px) and (max-width: 1224px) {
        width: 100%;
        text-align: start;
        margin-left: initial;
    }
`

export const ContainerIntro = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    padding: 12px;
    width: 100%;
    box-sizing: border-box;
    
    @media (max-width: 768px) {
        padding: 4% 4% 0px;;
    }

    @media (min-width: 768px) {
        max-width: 1000px;
        padding: 24px;
    }

    @media (min-width: 1224px) {
        padding: 54px 54px 0px;
    }
`

export const CellIntroContainer = styled.div<{isFirstPosition: boolean, isRightPosition: boolean}>`
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;

    @media (min-width: 768px) {
        flex-direction: row;
        align-items: flex-end;
        margin: ${props => props.isFirstPosition ? "-48px 0px 48px 0px" : "48px 0px"};
        margin-Left: ${props => props.isRightPosition ? "150px" : ""};
        margin-Right: ${props => !props.isRightPosition ? "150px" : ""};
    }

    @media (min-width: 1224px) {
        width: 100%;
        justify-content: ${props => props.isRightPosition ? "flex-end" : "flex-start"};
        margin: ${props => props.isFirstPosition ? "-48px 0px 48px 0px" : "48px 0px"};
    }
`

export const SisleyImg = styled.img`
    margin: 0px;
    width: 180px;
    filter: brightness(0);

    @media (max-width: 768px){
        margin-top: 8px;
        margin-bottom: 8px;
    }

    @media (min-width: 768px) {
        width: 140px;
        margin-top: 24px;
    }

    @media (min-width:1224px){
        z-index: 2;
        position: absolute;
        left: 0px;
        top: 470px;
        width: 200px;
        filter: initial;
    }

    @media (min-width: 2000px){
        top: 770px;
    }
`

export const SkinExpertText = styled.p`
    font: normal normal 700 32px/30px Helvetica;
    color: black;
    margin: 0px;

    @media (max-width: 768px){
        margin-bottom: 8px;
    }

    @media (min-width: 768px) and (max-width: 1224px) {
        font: normal normal 700 23px/20px Helvetica;
    }

    @media (min-width:1224px){
        color: white;
        z-index: 2;
        position: absolute;
        left: 0px;
        top: 390px;
    }
`

export const CellIntroTextContainer = styled.div`
    display: flex;
    flex-direction: column;

    @media (max-width: 768px) {
        align-items: center;
    }

    @media (min-width: 768px) {
        max-width: 350px;
    }
`

export const IntroIMG = styled.img`
    margin: 48px 0px;
    width: 70%;

    @media (min-width: 768px) {
        width: 250px;
        margin: 0px;
    }
`

export const BeginButtonContainer = styled.div`
    position: relative;
    display: flex;
    width: 100vw;
    height: 64px;
    align-items: center;
    box-sizing: border-box;
    justify-content: center;
    padding: 0px 8.3%;
    z-index: 2;

    @media (min-width: 768px) {
        padding: 0px 24px;
        justify-content: center;
        margin-bottom: 64px;
    }

    @media (max-width: 768px){
        padding: 0px 4%;
    }
`

export const TextInBottom = styled.p`
    margin: 0px;
    font: normal normal 400 18px/28px playfair;

    @media (max-width: 768px) {
        display: none;
    }
`

export const BeginButton = styled(MainButton)`
    font: normal normal 500 12px/15px Helvetica Bold;
    font-size: ${i18n.language === "tw_TW" ? "14px" : ""};
    text-transform: uppercase;
    width: 100%;
    letter-spacing: 1px;

    @media (min-width: 768px) {
        width: initial;
        padding: 12px 42px;
    }
`

export const CellContainer = styled.div`
    display: flex;
    width: 100%;
    padding: 24px;
    box-sizing: border-box;
    margin-bottom: 24px;

    @media (max-width: 768px) {
        padding: 4%;
        height: 650px;
        align-items: flex-start;
    }

    @media (min-width: 1224px) {
        padding: 0px;
    }

    @media (min-width: 500px) and (max-width: 1224px) {
        height: 500px;
        padding: 0px 24px;
    }
`

export const NumbersContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    height: 100%;
    box-sizing: border-box;
    padding-top: 64px;
    justify-content: center;

    @media (min-width: 500px) {
        padding: 12px;
        padding-top: 150px;
        flex: initial;
        align-items: flex-end;
    }

    @media (min-width: 1224px) {
        flex: 1;
    }
`

export const NumberText = styled.div<{isActive: boolean}>`
    font: normal normal 300 100px/30px Playfair;
    color: ${props => props.isActive ? "#AC836A" : "#F4F4F4"};
    margin: 0px;
    transition: all 0.5s;
    pointer-events: none;

    @media (min-width: 768px) and (max-width: 1224px){
        font-size: 80px;
    }
`

export const MainText = styled.p`
    font: normal normal 300 12px/22px Helvetica;
    font-size: ${i18n.language === "tw_TW" ? "14px" : ""};
    margin: 0px;

    @media (min-width: 1224px) {
        font: normal normal 300 16px/24px Helvetica;
        font-size: ${i18n.language === "tw_TW" ? "18px" : ""};
    }
`

export const ImageAndTextContainer = styled.div<{isFadeOut: boolean}>`
    display: flex;
    flex-direction: column;
    flex: 4;
    opacity: ${props => props.isFadeOut ? 0 : 1};
    transition: opacity 0.3s;

    @media (min-width: 500px) {
        flex-direction: row;
        flex: 2;
    }

    @media (max-width: 500px){
        justify-content: flex-end;
        padding-right: 4%;
    }
`

export const NumberButton = styled.button`
    width: 90px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 12px;
    background-color: transparent;
    cursor: pointer;
    border: none;
    -webkit-tap-highlight-color: transparent;

    @media (min-width: 768px) and (max-width: 1224px){
        height: 60px;
    }
`

export const IntroVideo = styled.video`
    z-index: 1;
    width: 100vw;
    max-height: 600px;
    object-fit: cover;
    
    @media (max-width: 768px){
        border-bottom-left-radius: 24px;
        border-bottom-right-radius: 24px;
    }

    @media (min-width: 2000px){
        max-height: 900px;
    }
`

export const VideoTuto = styled.video<{$blackLines?: boolean}>`
    min-height: 100vh;
    width: 100vw;
    object-fit: cover;
    z-index: 1;
    position: fixed;
    left: 0px;
    top: 0px;

    @media (min-width: 768px) and (max-width: 1224px) {
        object-fit: contain;
    }

    @media (max-width: 768px){
        min-height: initial;
        height: ${props => props.$blackLines ? "80svh" : "100%"};
        top: ${props => props.$blackLines ? "10svh" : ""};
        width: 100%;
    }
`

export const StepImage = styled.img`
    object-fit: contain;

    @media (max-width: 500px){
        width: 100%;
        padding-right: 6%;
        box-sizing: border-box;
    }

    @media (max-width: 1224px) and (min-width: 500px) {
        max-width: 250px;
        width: 100%;
    }

    @media (min-width: 1224px){
        height: 474px;
    }
`

export const TextStepContainer = styled.div`
    display: flex;
    flex-direction: column;

    @media (min-width: 500px) {
        padding: 24px;
        justify-content: center;
    }
`

export const TextParagraphContainer = styled.div`
    width: 100%;
    margin: 46px 0px 24px;
    padding: 0px 12px;
    box-sizing: border-box;

    @media (max-width: 768px) {
        padding: 0px 4%
    }

    @media (min-width: 768px) {
        display: flex;
        justify-content: center;
        margin: 60px 0px;
    }

    @media (min-width: 768px) and (max-width: 1224px) {
        margin: 38px 0px 0px;
    }
`
