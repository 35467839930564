import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './App.css';
import ViewDispatcher from './Components/ViewDispatcher';
import i18n from './i18nextInit';
import { AppStyled, AppLandscape } from './Styles/Containers';
import { MySkinDiagState } from './type';
import { GlobalStyle } from './Styles/Texts';
import * as ActionTypes from './Constants/ActionsTypes';
import { getBearer } from './API/SalesForceAPI';
import { StarFeeder, StarFeederMakeUp } from './Constants/Utilitaries';
import { Base64 } from 'js-base64';
import { CloseButton } from './Styles/Buttons';
import closeIcon from './Assets/Icons/Close_B.svg';
import ConfirmPopUp from './Components/ConfirmPopUp';
import { isDesktop, isTablet } from 'react-device-detect';
import TagManager from 'react-gtm-module';
import { loadFaceLandmarksDetectionModel } from './A3Camera/faceLandmarksDetection';
import { Answer } from './Constants/QuestsConstants';

declare global {
	interface Window {
		id: any;
		textAnim: any;
		disableScrolling: () => void;
		enableScrolling: () => void;
		image: HTMLImageElement;
		bridgeInterval: any;
		stepInterval: any;
		isQA: boolean;
		RTL_LANGS: string[];
		ASIAN_LANGS: string[];
		MAIL_DISABLED_LANGS: string[];
	}
}

const App = () => {
	const [isLandscape, setLandscape] = useState(false);
	const step = useSelector((state: MySkinDiagState) => state.session.step);
	const [closeIn, setCloseIn] = useState<boolean>(false);
	const dispatch = useDispatch();
	window.RTL_LANGS = ["he_IL", "ar_MEA", "ar_SA"]
	window.ASIAN_LANGS = ["en_SG", "en_MY", "en_AU", "ja_JP", "ko_KR", "zh_TW", "tw_TW", "zh_HK", "en_HK"]
	window.MAIL_DISABLED_LANGS = ["cs_CZ", "da_DK", "it_IT", "nl_NL", "fr_CH", "de_CH", "pt_PT", "ko_KR", "de_DE", "de_AT"]

	const tagManagerArgs = {
		gtmId: 'GTM-NDL792M',
	};

	TagManager.initialize(tagManagerArgs);

	window.addEventListener('resize', () => {
		if (!isDesktop || isTablet) {
			setLandscape(window.innerWidth > window.innerHeight);
		}
	});

	window.history.scrollRestoration = 'manual';
	const stopScrolling = (e: Event) => {
		e.preventDefault();
	};

	window.disableScrolling = () => {
		document.getElementById('APP')!.addEventListener('touchmove', stopScrolling, false);
	};

	window.enableScrolling = () => {
		document.getElementById('APP')!.removeEventListener('touchmove', stopScrolling, false);
	};

	useEffect(() => {
		loadFaceLandmarksDetectionModel().then((model) => {
			dispatch({ type: ActionTypes.UPDATE_MODEL, value: model });
		});

		if (window.innerWidth < 768) {
			setLandscape(window.innerWidth > window.innerHeight);
		}

		try {
			if (sessionStorage.getItem('questions')) {
				console.log('storage found.');
				dispatch({ type: ActionTypes.SET_ANALYSE, value: JSON.parse(sessionStorage.getItem('analyses')!) });
				dispatch({ type: ActionTypes.SET_QUESTION, value: sessionStorage.getItem('questions')!.split(',') });
				dispatch({ type: ActionTypes.SET_STEP, value: 5 });
			}
		} catch (error) {
			console.error('Erreur avec session Storage');
			console.error(error);
		}

		let url = new URL(window.location.href);
		let data: any;

		if (url.searchParams.get('bearer')) {
			StarFeeder(url.searchParams.get('bearer')!)
			StarFeederMakeUp(url.searchParams.get('bearer')!)
		}

		if (url.searchParams.get('data')) {
			try {
				let urlString = Base64.atob(url.searchParams.get('data')!.replace(' ', '+'));
				data = JSON.parse(urlString);
				console.log(data);

				if (data['region']) {
					if (window.RTL_LANGS.includes(data['region'])) {
						document.body.style.direction = 'rtl'
					}
					switch (data['region']) {
						case 'tw_TW':
						case 'zh_TW':
							i18n.changeLanguage('tw_TW');
							break;
						case 'en_UK':
							i18n.changeLanguage('en_GB');
							break;
						default:
							i18n.changeLanguage(data['region']);
					}
				} else {
					console.warn('NO LANGUAGE PROVIDED IN DATA');
					i18n.changeLanguage('en_GB');
				}

				//check if user's coming from Desktop
				if (data['options']) {
					if (data['options']['currentUrl']) {
						let newUrl = new URL(data['options']['currentUrl']);
						let uuid = newUrl.searchParams.get('bridge');

						if (uuid) {
							dispatch({ type: ActionTypes.SET_BRIDGE, value: uuid });
							dispatch({ type: ActionTypes.SET_STEP, value: 1 });
						}
					}
				}

				// direct to results view
				if (data['goToResultView'] === true) {
					dispatch({ type: ActionTypes.SET_STEP, value: 5 });
				}

				//check if VTO
				if (data['mode'] === 'try-on') {
					dispatch({ type: ActionTypes.SET_STEP, value: 10 });
				}
			} catch (e) {
				console.error(e);
				console.warn('DATA UNREADABLE');
				i18n.changeLanguage('en_GB');
			}
		} else {
			i18n.changeLanguage('en_GB');
			// document.body.style.direction = 'rtl'
		}

		if (url.searchParams.get("debug") === "true") {
			dispatch({
				type: ActionTypes.SET_ANALYSE, value: [
					{ name: "WRINKLES", score: 0.3952355714285714 },
					{ name: "WRINKLES_UNDER_EYE", score: 0.5552355714285714 },
					{ name: "DARK_SPOTS", score: 0.544971, image: "https://s3.eu-west-1.amazonaws.com/otstc.photos/ab…22796695&Signature=nHQrsAAJz896qB63TB2s7AT5WJQ%3D" },
					{ name: "SHININESS", score: 0.865034, image: "https://s3.eu-west-1.amazonaws.com/otstc.photos/ab…796695&Signature=Xf6kxvvX1aTRyM0H%2B929CZVQpTQ%3D" },
					{ name: "RADIANCE", score: 0.443158, image: "https://s3.eu-west-1.amazonaws.com/otstc.photos/ab…796695&Signature=Jt%2FeKLoL9EUaDUaREOIAJzfiw98%3D" },
					{ name: "BLACK_SPOTS", score: 0.5, image: "https://s3.eu-west-1.amazonaws.com/otstc.photos/ab…&Signature=%2FeGZm%2FTORc%2FNzp4M%2Fbf0cgQ3SwA%3D" },
					{ name: "ACNE", score: 0.75, image: "https://s3.eu-west-1.amazonaws.com/otstc.photos/ab…22796695&Signature=49TYD4Xp8k94WsJslO4aQQsehLg%3D" },
					{ name: "HOLLOW_CHEEKS", score: 0.6 },
					{ name: "HYDRATION", score: 0 },
					{ name: "LOSS_OF_FIRMNESS", score: 0.4 },
					{ name: "DARK_CIRCLES", score: 0.9 },
					{ name: "EYES_BAGS", score: 0.2 },
					{ name: "ROUGHNESS", score: 0.9 },
				]
			})
			dispatch({ type: ActionTypes.SET_QUESTION, value: ["femme", [Answer.PPtracesResiduelles, Answer.PPimperfections], [Answer.CYpoche], Answer.seche, Answer.specifiqueVisage, Answer.avecRincage, Answer.MVoui, Answer.SDNoui, Answer.stress] })
			dispatch({ type: ActionTypes.SET_STEP, value: 5 })
		}

		let siteURL = new URL(window.location.href)

		window.isQA = true

		if (siteURL.searchParams.get("data")) {
			let data = JSON.parse(atob(siteURL.searchParams.get("data")!.replace(" ", "+")))
			if (data["options"]) {
				let parentURL = data["options"]["currentUrl"]
				if (parentURL) {
					if (parentURL.includes("staging") || parentURL.includes("dev-sis")) {
						window.isQA = true
					} else if (parentURL.includes("sisley-paris")) {
						window.isQA = false
					}
				}
			}
		}

		//eslint-disable-next-line
	}, []);

	useEffect(() => {
		let url = new URL(window.location.href);

		const bridgeId = url.searchParams.get('bridge');
		if (bridgeId) {
			dispatch({ type: ActionTypes.SET_BRIDGE, value: bridgeId });
			dispatch({ type: ActionTypes.SET_STEP, value: 1 });
		}

	}, [dispatch])

	useEffect(() => {
		setTimeout(() => {
			getBearer(dispatch, i18n.language);
		}, 500);
		//eslint-disable-next-line
	}, [i18n, dispatch]);

	const displayClosePopUp = () => {
		if (closeIn) {
			return (
				<div style={{ width: '100vw', height: '100vh', top: 0, position: 'fixed', zIndex: 10 }}>
					<ConfirmPopUp key={0} setOptIn={setCloseIn} />
				</div>
			);
		} else {
			return <div />;
		}
	};

	return (
		<div>
			{!isLandscape || step === 0 || step === 8 || step === 10 ? (
				<AppStyled id={'APP'} step={step}>
					<GlobalStyle />
					{step === 3 && <CloseButton style={{ zIndex: 15 }} src={closeIcon} alt='close' onClick={() => setCloseIn(true)}></CloseButton>}
					{displayClosePopUp()}
					<ViewDispatcher />
				</AppStyled>
			) : (
				<AppLandscape />
			)}
		</div>
	);
};

export default App;
