import { useTranslation } from "react-i18next"
import styled from "styled-components"
import switchIcon from '../Assets/Icons/switch-phone.svg' 

const LandscapePage = () => {
    const { t } = useTranslation()

    return (
        <LandscapeContainer>
            <img alt="tournez votre écran" style={{width: "64px"}} src={switchIcon}/>
            <LandscapeText>
                {t("introView.landscape", "Tournez votre appareil en mode portrait")}
            </LandscapeText>
        </LandscapeContainer>
    )
}

const LandscapeContainer = styled.div`
    width: 100vw;
    min-height: 100vh;
    z-index : 100;
    background-color: rgb(40,40,40);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
`

const LandscapeText = styled.p`
    font: normal normal 500 13px/24px Helvetica light;
    color: white;
`

export default LandscapePage