import * as BK2T from './Types'
import jsonMakeup from './myStoreUpdated.json'

function parse() : BK2T.Layer[] {
    let layers = Array<BK2T.Layer>()
    for (let makeup of jsonMakeup){
        layers.push(new BK2T.Layer(makeup.shape, makeup.effect, makeup.shapeType, [makeup.colorR/255, makeup.colorG/255, makeup.colorB/255], makeup.opacity/255, makeup.beautifierLevel, makeup.shineLevel ?? 0, makeup.name, makeup.sku))
    }
    return layers
}

export{parse}

