import { useEffect, useState } from "react"
import { H1 } from '../Styles/Texts'
import { Quest, setQuest, setAnswers, setAnswerTranslation, setSelectionnable, Answer, setOrder, setNumberOfAnswer } from '../Constants/QuestsConstants'
import { QuestsViewContainer, QuestContainer, ProgressBarEmpty, OldProgressBar, NextQuestContainer } from "../Styles/Containers"
import { OldQuestButton, MainButton, OrderIcon } from "../Styles/Buttons"
import { BackQuestIcon} from '../Styles/Images'
import { useDispatch, useSelector } from "react-redux"
import { MySkinDiagState } from "../type"
import * as ActionTypes from '../Constants/ActionsTypes'
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { CSSTransition } from 'react-transition-group'

const NextQuestButton = styled(MainButton)`
margin-top: 0;
`;

const QuestsView = () => {
    const [questStep, setQuestStep] = useState(Quest.peau)
    const [answerButtons, setAnswerButtons] = useState<any[]>([])
    const [selected, setSelected] = useState<any>([])
    const [answer, setAnswer] = useState<any>([])
    //eslint-disable-next-line
    const [recommandedAnswer, setRecommandedAnswerState] = useState<Array<any>>([])
    const [inProp] = useState<boolean>(false)
    const [answerInProp, setAnswerInProp] = useState<boolean>(false)
    const step = useSelector((state: MySkinDiagState) => state.session.step)
    //const analyses = useSelector((state: MySkinDiagState) => state.session.analyses)
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const nextStep = (ans: any, e: any) => {
        if (setSelectionnable(questStep)) {
            if (selected.includes(ans)) {
                setSelected(selected.filter((item: Answer) => item !== ans))
                e.target.blur() //desactive le hover
            } else {
                if (selected.length >= setNumberOfAnswer(questStep)) {
                    let array: Array<number> = selected
                    array.pop()
                    //setSelected(array)
                    setSelected((arr: Array<Answer>) => [...arr, ans])
                } else {
                    setSelected((arr: Array<Answer>) => [...arr, ans])
                }
            }
        } else if (setOrder(questStep)) {
            if (selected.includes(ans)) {
                setSelected(selected.filter((item: Answer) => item !== ans))
                e.target.blur() //desactive le hover
            } else {
                if (selected.length > 2) {
                    let array: Array<number> = selected
                    array.pop()
                    //setSelected(array)
                    setSelected((arr: Array<Answer>) => [...arr, ans])
                } else {
                    setSelected((arr: Array<Answer>) => [...arr, ans])
                }
            }
        }
        else {
            setAnswer((arr: Array<Answer>) => [...arr, ans])
            setSelected((arr: Array<Answer>) => [...arr, ans])

            setTimeout(() => {
                e.target.blur() //desactive le hover

                let quest: number = questStep
                quest++
                setAnswerInProp(false)
                //setInProp(false)
                setQuestStep(quest)
            }, 200);
        }
    }

    const nextStepWithMultipleChoices = (e: any) => {
        setTimeout(() => {
            setAnswerInProp(false)
            //setInProp(false)
            e.target.blur() //desactive le hover
            setSelected([])
            let quest: number = questStep
            quest++
            setAnswer((arr: Array<Answer>) => [...arr, selected])
            setQuestStep(quest)
        }, 200);
    }

    useEffect(() => {
        //window.scrollTo(0, 0)
        if (questStep === Object.keys(Quest).length / 2 - 1) {
            dispatch({ type: ActionTypes.SET_STEP, value: 4 })
            dispatch({ type: ActionTypes.SET_QUESTION, value: answer })
        }
        if (setSelectionnable(questStep) || setOrder(questStep)) {
            //window.enableScrolling()
        }
        else {
            //window.disableScrolling()
        }
        let tab = setAnswers(questStep)
        let elementTab: any = []
        let key: number = 0
        tab.forEach((e) => {
            elementTab.push(
                <QuestButtonComponent e={e} key={key} />
            )
            key++
        })
        setAnswerButtons(elementTab)
        //eslint-disable-next-line
    }, [questStep, selected])

    // vide les questions selectionnees a chaque question et calcule les reponses recommandées
    useEffect(() => {
        if (questStep === Quest.manqueVitalite && !answer.flat().includes("PPrides") && !answer.flat().includes('PPperteFermete')) {
            let quest = questStep
            quest++
            setQuestStep(quest)
        }

        if (questStep === Quest.routineNuit && !answer.flat().includes("PPrides") && !answer.flat().includes('PPperteFermete')) {
            let quest = questStep
            quest++
            setQuestStep(quest)
        }
        setSelected([])
        //setRecommandedAnswerState(setRecommendedAnswer(questStep, analyses))
        //setInProp(true)
        setAnswerInProp(true)
        //eslint-disable-next-line
    }, [questStep])

    const QuestButtonComponent = (props: any) => {

        return (
            <OldQuestButton style={{ fontFamily: 'none', borderRadius: '0' }} onClick={(event) => nextStep(props.e, event)} isSelected={selected.includes(props.e)}>
                {setAnswerTranslation(props.e, t)}
                {recommandedAnswer.includes(props.e) && <label style={{ fontSize: '10px', color: selected.includes(props.e) ? 'white' : 'green' }}> - Recommandé pour vous -</label>}
                {setOrder(questStep) && selected.includes(props.e) && <OrderIcon id={'ORDER'}>{selected.indexOf(props.e) + 1}</OrderIcon>}
            </OldQuestButton>
        )
    }

    const backStep = () => {
        setSelected([])
        if (questStep === 0) {
            dispatch({ type: ActionTypes.SET_STEP, value: step - 2 })
        } else {
            let quest: number = questStep
            quest--
            setQuestStep(quest)
            let answerTab = answer
            answerTab.pop()
            setAnswer(answerTab)
        }
    }

    useEffect(() => {
        if (!selected.includes("femme")) {
            let selectedItem = "femme"
            setAnswer((arr: Array<Answer>) => [...arr, selectedItem])
        }
        // eslint-disable-next-line
    }, [])

    return (
        <CSSTransition in={inProp} classNames="swipeToRight" addEndListener={() => null}>
            <QuestsViewContainer style={{ alignItems: 'stretch', overflowX: 'hidden' }}>
                <BackQuestIcon step={questStep} onClick={() => backStep()} />
                <ProgressBarEmpty />
                <OldProgressBar step={questStep < 12 ? questStep : 12} />
                <CSSTransition in={answerInProp} classNames="swipeToRight" addEndListener={() => null}>
                    <QuestContainer style={{ position: 'relative' }} className="scrollbar-hidden">
                        <H1 style={{ padding: '72px 24px 28px 24px', fontFamily: 'none', whiteSpace: 'pre-wrap' }}>{setQuest(questStep, t)}</H1>
                        {answerButtons}
                    </QuestContainer>
                </CSSTransition>
                <NextQuestContainer questStep={(setSelectionnable(questStep) && selected.length > 0) && (selected.length === setNumberOfAnswer(questStep))}>
                    <NextQuestButton style={{ fontFamily: 'none', borderRadius: '0' }} onClick={(e) => nextStepWithMultipleChoices(e)}>Suivant</NextQuestButton>
                </NextQuestContainer>
            </QuestsViewContainer>
        </CSSTransition>
    )
}

const OldQuestsViewComponent = () => {
    return <QuestsView />
}

export default OldQuestsViewComponent