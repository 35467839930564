import styled from 'styled-components'
import { motion } from 'framer-motion'
import i18n from '../../i18nextInit'

export const TryOnUIContainer = styled.div<{ isLook: boolean, isPrivateMode: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    min-height: 10%;
    width: 100vw;
    position: fixed;
    flex-direction: column;
    bottom: 0px;
    transition: 0.3s;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    padding: ${props => props.isLook ? "24px 12px 24px 24px" : "72px 12px 24px 24px"};
    box-sizing: border-box;
    direction: ${() => window.RTL_LANGS.includes(i18n.language) ? "rtl" : ""};

    @media (orientation : landscape){
        width: 55vw;
        right: 0px;
        height: 100vh;
        justify-content: start;
        border-radius: 0px;
        overflow: hidden;
        
    }

    @media (orientation: portrait){
        left: 0px;
        padding: 32px 16px 24px 16px;
        max-height: 70%;
        min-height: ${props => props.isPrivateMode ? "280px" : "150px"};
    }

    @media (min-width: 768px){
        min-height: initial;
    }
`

const handlePGContainerHeight = (isCartOpen: boolean, isMaxProduct: boolean, isShadeView: boolean): string => {
    if (isCartOpen && isMaxProduct && !isShadeView) {
        return "250px"
    } else if (isShadeView) {
        return "210px"
    } else if (isCartOpen || isMaxProduct) {
        return "190px"
    } else {
        return "150px"
    }
}

export const TryOnPGContainer = styled.div <{ $isPrivateMode: boolean, $isCartOpen: boolean, $isShadesView: boolean, $isMaxProduct: boolean }>`
    display: flex;
    justify-content: ${props => props.$isShadesView ? "" : "center"};
    align-items: center;
    background-color: white;
    min-height: 10%;
    height: ${props => handlePGContainerHeight(props.$isCartOpen, props.$isMaxProduct, props.$isShadesView)};
    width: 100vw;
    position: fixed;
    flex-direction: column;
    bottom: 0px;
    transition: 0.3s;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    padding: 72px 12px 24px 24px;
    box-sizing: border-box;
    transition: height 0.3s;
    z-index: 2;
    direction: ${() => window.RTL_LANGS.includes(i18n.language) ? "rtl" : ""};

    @media (orientation : landscape){
        width: 55vw;
        right: 0px;
        height: 100vh;
        justify-content: start;
        border-radius: 0px;
        overflow: hidden;
        
    }

    @media (orientation: portrait){
        left: 0px;
        padding: 20px 0px 16px;
        max-height: 70%;
        justify-content: ${props => props.$isShadesView && "flex-end"};
        min-height: ${props => props.$isPrivateMode ? "280px" : "150px"};
    }
`

export const ProductSimpleContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;

    @media (orientation: landscape){
        width: calc(100% - 84px);
    }
`

export const VTOContainer = styled.div`
    display: flex;
    width: 100vw;
    height: 100vh;
`

export const TryOnContainer = styled.div`
    width: 100%;
    height: 100%;
    max-height: 900px;
`

export const TryOnCameraContainer = styled.div`
    display: flex;
    width: 100vw;
    top: 0px;
    height: 90vh;
    background-color: rgb(40,40,40);

    @media (orientation : landscape){
        width: 45vw;
        height: 100vh
    }
`

export const TryOnProductContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 8px 0px;
    position: relative;

    @media (orientation: landscape){
        align-items: flex-start;
    }
`

export const TryOnProductTextContainer = styled.div<{ isDisplayed: boolean }>`
    display: flex;
    flex-direction: column;
    padding-right: 8px;
    padding-left: 8px;
    flex: 5;
    height: 64px;
    justify-content: center;
    opacity: ${props => props.isDisplayed ? "" : "0.3"};

    @media (orientation: landscape){
        padding: 0px 24px 0px 8px;
    }
`

export const ShadeRound = styled.div<{ isLittle: boolean, color: string }>`
    background-color: ${props => props.color ? props.color : "black"};
    width: ${props => props.isLittle ? "12px" : "44px"};
    height: ${props => props.isLittle ? "12px" : "44px"};
    outline: none;
    border-radius: 24px;

    @media (orientation: landscape){
        width: ${props => props.isLittle ? "12px" : "38px"};
        height: ${props => props.isLittle ? "12px" : "38px"};
    }

    &:hover {
        outline: none;
    }
`

export const RoundBar = styled.div`
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: white;
    transform: rotate(-45deg);
`

export const NoShadeIcon = styled.img`
    width: 44px;
    height: 44px;

    @media (orientation: landscape){
        width: 38px;
        height: 38px;
    }
`

export const SelectedShadeRound = styled.div<{ isMonoProduct: boolean, $isOOS?: boolean, playground?: boolean }>`
    border-radius: 50px;
    border: 1px solid grey;
    border-color: ${props => props.$isOOS && "#b23515"};
    position: absolute;
    width: 60px;
    height: 60px;
    left: calc(50% - 30px);
    z-index: 2;
    top: ${props => props.playground ? "8px" : "73px"}; 
    pointer-events: none;

    @media (orientation: landscape){
        width: 54px;
        height: 54px;
        left: calc(50% - 27px);
        top: 76px;
    }
`

export const ExtendedProductContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const ShadeCarouselContainer = styled.div <{ isMonoProduct: boolean, playground?: boolean }>`
    width: 100%;
    display: ${props => props.playground && "flex"};
    flex-direction: column;
    align-items: ${props => props.playground && "center"};
    position: ${props => props.playground && "relative"};

    @media (orientation: landscape) {
        width: calc(100% - 86px);
        overflow: hidden;
    }
`

export const LabelPerf = styled.label`
    margin-left: 50%; 
    
    align-items: stretch;
    z-index: 10;
    width: auto; 
    position: fixed;
    margin-left: initial;
    left: 8px;
    top: 8px;
    padding: 4px;
    background-color: white;
    
`

export const BeautifierLabel = styled.label`
    margin-left: 50%; 
    display: block;
    align-items: stretch;
    width: auto; 
    position: fixed;
    margin-left: initial;
    top: 82px;
    left: 8px;
    padding: 4px;
    background-color: white;
`


export const VerticalSlider = styled.input`
    margin-top: 50px;
    width: 150px;
    position: fixed;
    z-index: 10;
    left: 8px;
    top: 62px;
`

export const MakeupCanvas = styled.canvas`
    display: block;
    margin-left: ${() => window.RTL_LANGS.includes(i18n.language) ? "" : "50%"}; 
    margin-right: ${() => window.RTL_LANGS.includes(i18n.language) ? "-50%" : ""}; 
    transform: translateX(-50%) scale(-1,1) ;
    align-items: stretch;
    height: 82vh;
    width: auto; 

    @media (orientation: landscape){
        height: 100vh;
        position: fixed;
        transform: scale(-1, 1);
        margin-left: initial;
        left: 0px;
    }
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
        height: 90vh;
    }
`

export const Loader = styled.img`
    width: 120px;
    position: absolute;
    top: calc(40% - 60px);
    left: calc(50% - 60px);
    z-index: -1;

    @media(orientation: landscape){
        left: calc(22.5% - 60px);
    }
`

export const EyeTwistContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    column-gap: 24px;

    @media (orientation: landscape){
        margin-top: 16px;
    }
`


export const AddToCartContainer = styled.div <{ $isLook: boolean, playground?: boolean }>`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: ${props => props.playground && "border-box"};
    padding: ${props => props.playground && "0px 12px"};
    bottom: 0px;

    @media (orientation: landscape){
        width: ${props => props.$isLook ? "45vw" : "60vw"};
        left : ${props => props.$isLook ? "0px" : ""};
        bottom: 24px;
        position: fixed;
    }

    @media (orientation: portrait) {
        margin-top: 8px;
    }
`

export const Fader = styled.div<{ isLeft: boolean }>`
    width: 80px;
    position: absolute;
    top: 85px;
    left: ${props => props.isLeft ? "0px" : ""};
    right: ${props => !props.isLeft ? "84px" : ""};
    height: 38px;
    z-index: 8;
    background: ${props => props.isLeft ? "linear-gradient(to right, white 65%, rgba(255,255,255,0.001))" : ""};
    background: ${props => !props.isLeft ? "linear-gradient(to left, white 65%, rgba(255,255,255,0.001))" : ""};
`

export const DropdownWrapper = styled.form`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
    padding: 4px;
    align-items: stretch;
    z-index: 10;
    width: auto; 
    position: fixed;
    margin-left: initial;
    left: 8px;
    top: 38px;
`;

export const StyledSelect = styled.select`
  max-width: 50%;
  height: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  
`;

export const StyledOption = styled.option`
  color: ${(props) => (props.selected ? "lightgrey" : "black")};
`;


export const StyledButton = styled.input`
  max-width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  padding: 0.5rem;
  
`;
export const VTOLiner = styled.div<{ position: number, isMonoProduct: boolean }>`
    height: 0.5px;
    background-color: #E5E5E5;
    opacity: ${props => props.position === -1 ? "0" : ""};
    pointer-events: none;
    position: fixed;
    width: 55%;
    left: 45vw;
    margin-top: 4px;
    top: ${props => props.position === 0 && props.isMonoProduct ? "225px" : ""};
    top: ${props => props.position === 1 && props.isMonoProduct ? "260px" : ""};
    top: ${props => props.position === 0 && !props.isMonoProduct ? "270px" : ""};
    top: ${props => props.position === 1 && !props.isMonoProduct ? "290px" : ""};
`

export const WaterMark = styled.div`
    position: absolute;
    color: white;
    display: flex;
    gap: 4px;
    top: -36px;
    right: 12px;
    text-shadow: 0px 1px 1px rgb(0 0 0 / 50%);
    font: normal normal 500 12px/100% Helvetica; 

    @media (orientation: landscape){
        display: none;
    }

    @media (min-width: 768px){
        top: -42px;
    }
`

export const DesktopPageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
`

export const Scan = styled.div`
    position: absolute;
    top: 5%;
    width: 90%;
    height: 36px;
    background-color: black;
    opacity: 0.1;
    border-radius: 12px;
    animation: Scanner 1s;
    animation-timing-function: linear;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1.0);
`

export const OvalContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0px;
    width: 100vw;
    height: 80vh;
    left: 0px;
    transition: 0.3s;
`

export const PGNavigationContainer = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    box-sizing: border-box;
    padding: 0px 15px;
    width: 100%;
`

export const PGNavigationText = styled(motion.p) <{ $isUnderlined: boolean, $isFirst?: boolean }>`
    font: normal normal normal 12px/20px Helvetica Bold;
    margin: 0px;
    position: relative;
    text-decoration: ${props => props.$isUnderlined ? "underline" : ""};
`

export const PGCarousel = styled(motion.div)`
    display: flex;
    position: relative;
    flex: 1;
    width: 100%;
    overflow: auto;
    margin-top: 12px;
    padding: 8px 0px;
    padding-left: 15px;
    padding-right: 15px;
    box-sizing: border-box;
    gap: 15px;
    scrollbar-width: 0px;

    ::-webkit-scrollbar {
        display: none;
    }

    @media (min-width: 768px){
        padding-left: 28px;
        padding-right: 28px;
        gap: 32px;
    }
`

export const CategoryCellContainer = styled(motion.div)`
    display: flex;
    flex-direction: column;
    position: relative;
    flex: 1 0 34%;
    box-shadow: 0px 3px 6px #00000012;
    border-radius: 8px;
    height: 100px;
    justify-content: center;
    align-items: center;

    @media (min-width: 768px){
        flex: 1 0 30%;
    }
`

export const CategoryImg = styled.img`
    border-radius: 24px;
    width: 40px;
    height: 40px;
    object-fit: cover;
    background-color: grey;
`

export const WhiteTextContainer = styled(motion.div)`
    display: flex;
    position: absolute;
    top: 40%;
    align-items: center;
    justify-content: center;
    width: 100vw;
    left: 0px;
`

export const PGProductCell = styled.div`
    display: flex;
    flex: 1 0 34%;
    border-radius: 7px;
    box-shadow: 0px 3px 6px #00000012;
`

export const PGProductCellText = styled.p`
    margin: 0px;
    font: normal normal 300 13px/21px Helvetica;
    flex: 1;
`

export const PGProductCellContainer = styled(motion.div) <{ $isShadeMode?: boolean }>`
    box-shadow: 0px 3px 6px #00000012;
    max-width: 200px;
    border-radius: 7px;
    padding-right: 10px;
    box-sizing: border-box;
    background-color: white;
    height: 50px;
    flex: 1 0 48%;
    display: flex;
    position: ${props => props.$isShadeMode ? "absolute" : "relative"};
    top: ${props => props.$isShadeMode && "-58px"};
    width: ${props => props.$isShadeMode && "200px"};
    align-items: center;
    gap: 10px;
    z-index: ${props => props.$isShadeMode && 10};
`

export const PGProductCellImg = styled.img`
    border-radius: 7px;
    max-width: 50px;
    aspect-ratio: 1;
    position: relative;
    flex: 1;
`

export const PGShadeText = styled.p`
    margin: 0px;
    font: normal normal 400 12px/20px Helvetica;
    color: black;
`

const handleCartBottom = (isInProductMenu: boolean, isMaxProducts: boolean): string => {
    if (isInProductMenu && !isMaxProducts) {
        return "120px"
    }
    else if (isInProductMenu && isMaxProducts) {
        return "160px"
    } else if (isMaxProducts) {
        return "210px"
    } else {
        return "160px"
    }
}

const handleCartHeight = (isInProductMenu: boolean, isMaxProducts: boolean, isWide: boolean) => {
    if (isWide) {
        if (isMaxProducts && !isInProductMenu) {
            return "65%"
        } else if (isMaxProducts && isInProductMenu) {
            return "72%"
        } else if (isInProductMenu) {
            return "75%"
        }
        return "72%"
    } else {
        return "125px"
    }
}

export const CartPanelCtnr = styled(motion.div) <{ $isWide: boolean, $isInProductMenu: boolean, $isMaxProduct: boolean }>`
    height: ${props => handleCartHeight(props.$isInProductMenu, props.$isMaxProduct, props.$isWide)};
    transition: all 0.25s;
    background-color: #F6F6F6;
    position: fixed;
    bottom: ${props => handleCartBottom(props.$isInProductMenu, props.$isMaxProduct)};
    border-radius: 20px 20px 0px 0px;
    width: 100%;
    display: flex;
    z-index: 1;
    left: 0px;
    display: flex;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
    padding: 38px 15px;
    overscroll-behavior: none;
    direction: ${() => window.RTL_LANGS.includes(i18n.language) ? "rtl" : ""};

    @media (min-width:768px){
        height: ${props => props.$isWide ? "70%" : "180px"};
        padding: 38px 28px;
    }
`

export const PGProductCartCtnr = styled(motion.li)`
    width: 100%;
    height: 105px;
    box-shadow: 0px 3px 6px #00000012;
    background-color: white;
    border-radius: 7px;
    display: flex;
    position: relative;
`

export const PGProductCartImg = styled.img`
    flex: 1;
    aspect-ratio: 1;
    background-color: grey;
    border-radius: 7px;
    max-width: 100px;
`

export const PGProductCartContent = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    box-sizing: border-box;
    position: relative;
`

export const PGProductCartShadeCtnr = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
`

export const CartPanelSmallCtnr = styled.div`
    display: flex;
    align-items: center;
    flex: 1;
    gap: 6px;
`

export const CartSmallProductImage = styled.img`
    background-color: grey;
    border-radius: 7px;
    width: 45px;
    height: 45px;
    box-shadow: 0px 3px 6px #00000012;

    @media (min-width: 768px){
        width: 92px;
        height: 92px;
    }
`

export const PopUpCtnr = styled(motion.div)`
    position: absolute;
    z-index: 10;
    width: 100%;
    background-color: white;
    border-radius: 24px 24px 0px 0px;
    padding: 54px 15px 15px 15px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    bottom: 0px;
`

export const PGNavigationCntr = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    position: relative;
    gap: 10px;
`

export const CartProductCellsCtnr = styled(motion.div)`
    display: flex;
    overflow: auto;
    flex-direction: column;
    width: 100%;
    gap: 12px;
    padding: 8px;
    padding-top: 16px;
    padding-bottom: 64px;
`

export const PGNavigationChevron = styled(motion.img)`
    width: 10px;
    transform: ${() => window.RTL_LANGS.includes(i18n.language) ? "rotate(-90deg)" : "rotate(90deg)"};
    filter: brightness(0);
`

const handleWaterMarkTop = (isCartPanel: boolean, isInShadesMode: boolean, isTab: boolean = false) => {
    if (isInShadesMode) {
        return "-80px"
    } else if (isCartPanel) {
        if (isTab) {
            return "-194px"
        }
        return "-134px"
    } else {
        return "-42px"
    }
}

export const PGWaterMark = styled.div<{ $isCartPanel: boolean, $isDisplay?: boolean, $isInShadeMode: boolean }>`
    position: absolute;
    color: white;
    display: flex;
    opacity: ${props => props.$isDisplay ? 1 : 0};
    gap: 4px;
    top: ${props => handleWaterMarkTop(props.$isCartPanel, props.$isInShadeMode)};
    right: 12px;
    transition: top 0.7s, opacity 0.3s;
    text-shadow: 0px 1px 1px rgb(0 0 0 / 50%);
    font: normal normal 500 12px/100% Helvetica; 
    height: 15px;

    @media (min-width: 768px){
        top: ${props => handleWaterMarkTop(props.$isCartPanel, props.$isInShadeMode, true)};
    }
`

export const PGCartCurtain = styled(motion.div)`
    position: fixed;
    background-color: black;
    height: 100%;
    top: 0px;
    z-index: 1;
    left: 0px;
    width: 100vw;
    overscroll-behavior: none;
`

export const PGShadeCarouselContainer = styled.div <{ playground?: boolean }>`
    width: 100%;
    display: ${props => props.playground && "flex"};
    flex-direction: column;
    align-items: ${props => props.playground && "center"};
    position: ${props => props.playground && "relative"};

    @media (orientation: landscape) {
        width: calc(100% - 86px);
        overflow: hidden;
    }
`

export const SwiperCtnr = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 12px;
`