
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend'


i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(Backend)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'en_GB',
    debug: true,
    whitelist: ["cs_CZ", "de_AT", "de_CH",
      "de_DE", "da_DK", 'en_GB', 'fr_FR', "ja_JP", "ko_KR", 'en_US', 'en_SG',
      'en_MY', "en_AU", "es_ES", "fr_CH", "fr_BE", "it_IT",
      "nl_BE", "nl_NL", "pl_PL", "pt_PT", "tw_TW", "pt_BR", "en_CA", "fr_CA", "es_MX", "zh_HK", "en_HK", "en_ZA", "en_MEA", "ar_MEA", "en_SA", "ar_SA"],
    lng: 'en_GB',

    backend: {
      loadPath: `${process.env.PUBLIC_URL}/locales/{{lng}}.json`,
    },

    interpolation: {
      escapeValue: false,
    },
  });


export default i18n;