import { SkinTypeImageContainer, DropDownContainer, ExposomesContainer, ResultViewContainer, ConcernBox, PreoccupationContainer, PreoccupationsTextContainer, ConcernBoxContainer, SkinImageTextContainer, SkinAdviceContainer, CircleProgressBar } from "../../Styles/Containers"
import { BackToScoreViewButtton, SeeOurRecoButton, WhiteButton } from '../../Styles/Buttons'
import { AdviceBoldText, DropDownContentText, H2, SkinTypeText, YourPreoccupationTitle } from '../../Styles/Texts'
import { setAnswerTranslation } from '../../Constants/QuestsConstants'
import { useTranslation } from "react-i18next"
import FooterView from './FooterView'
import { Answer } from "../../Constants/QuestsConstants"
import { useSelector, useDispatch } from "react-redux"
import { MySkinDiagState } from "../../type"
import * as ActionTypes from '../../Constants/ActionsTypes'
import { CSSTransition } from "react-transition-group"
import { useEffect, useState } from "react"
import SisleyAPI from '../../API/SisleyAPI'
import RecoSisley from "../../Recos/RecoSisley"
import OptInView from "../OptInView"
import RecoViewComponent from "./RecoView/RecoView"
import AnimateHeight from "react-animate-height"

import ArrowIcon from '../../Assets/Icons/ArrowIcon.png'
import SIcon from '../../Assets/Icons/SPlus.svg'
import CHEVRON from '../../Assets/Icons/Chevron.svg'

import SkinTypeMob from '../../Assets/Background-Images/Mob/SkinTypeMob.jpg'
import SkinTypeTab from '../../Assets/Background-Images/Tab/SkinTypeTab.jpg'
import SkinTypeDesk from '../../Assets/Background-Images/Desk/SkinTypeDesk.jpg'


import LumiereBG from '../../Assets/Background-Images/LumiereBleue.png'
import PollutionBG from '../../Assets/Background-Images/Pollution.jpg'
import SommeilBG from '../../Assets/Background-Images/Sommeil.jpg'
import StressBG from '../../Assets/Background-Images/Stress.jpg'

import { Concern } from '../../Constants/Concern'
import { SkinTypeImage } from "../../Styles/Images"

import ConfirmPopUp from '../ConfirmPopUp'
import i18n from "../../i18nextInit"
import styled from "styled-components"
import { isOnProd, runHeightMachine, sendAnalytics } from "../../Constants/Utilitaries"

const ResultsView = () => {
    const { t } = useTranslation()
    const questions: Array<any> = useSelector((state: MySkinDiagState) => state.session.questions)
    const reco = useSelector((state: MySkinDiagState) => state.session.reco)
    const analyses = useSelector((state: MySkinDiagState) => state.session.analyses)
    const imageUser = useSelector((state: MySkinDiagState) => state.session.imageUser)
    const [isDesktop, setDesktop] = useState<boolean>(window.innerWidth > 1224)

    const dispatch = useDispatch()
    const [inProp, setInProp] = useState<boolean>(false)
    const [optIn, setOptin] = useState<boolean>(false)
    const [closeIn, setCloseIn] = useState<boolean>(false)

    useEffect(() => {
        document.getElementById("APP")!.style.position = 'relative'
        window.enableScrolling()

        const recoSisley = new RecoSisley()

        if (window.ASIAN_LANGS.includes(i18n.language)) {
            recoSisley.getAsiaReco(questions.flat(), analyses, dispatch)
        } else {
            recoSisley.getReco(questions.flat(), analyses, dispatch, i18n.language)
        }

        try {
            sessionStorage.setItem('questions', questions.toString())
            sessionStorage.setItem('analyses', JSON.stringify(analyses))
        } catch (error) {
            console.error("Erreur avec sessionStorage")
        }

        //eslint-disable-next-line
    }, [dispatch, questions, analyses])

    useEffect(() => {
        let data = {
            messageType: "toggle_fullscreen",
            value: false
        }
        console.log("[RESULTVIEW] disable full screen !")
        console.log(data)
        window.parent.postMessage(data, "*")
        window.parent.postMessage(data, "*")

        if (!isDesktop) {
            console.log("[RESULTVIEW] disable full screen !")
            console.log(data)
            window.parent.postMessage(data, "*")
        }
        if (isDesktop) {
            try {
                if (sessionStorage.getItem('finish_results') !== "true") {
                    sessionStorage.setItem("finish_results", "true")
                    sendAnalytics("finish_results", "Diag")
                }
            } catch (error) {
                console.error("Erreur avec session Storage")
                console.error(error)
            }

            let dataBis = {
                messageType: "scroll_top",
            };
            console.log(dataBis)
            window.parent.postMessage(dataBis, "*")
        }

        if (isDesktop) {
            try {
                if (sessionStorage.getItem('finish_results') !== "true") {
                    sessionStorage.setItem("finish_results", "true")
                    sendAnalytics("finish_results", "Diag")
                }
            } catch (error) {
                console.error("Erreur avec session Storage")
                console.error(error)
            }
        }

    }, [isDesktop])

    useEffect(() => {
        console.warn(reco)
        const recoSisley = new RecoSisley()
        recoSisley.createProducts(reco, dispatch)

        setInProp(true)

        setTimeout(() => {
            runHeightMachine(false, "RESULT_VIEW", 24)
        }, 300)

        //eslint-disable-next-line
    }, [setInProp, reco, dispatch])

    useEffect(() => {
        console.warn("RECO:")
        console.warn(reco)

        const sisleyAPI = new SisleyAPI()
        sisleyAPI.getProducts(reco, dispatch)

        setTimeout(() => {
            sisleyAPI.getProducts(reco, dispatch)
        }, 1000);

        //sisleyAPI.getToken()

    }, [dispatch, reco])

    useEffect(() => {

        const scrollHandler = () => {
            if (window.innerWidth > 1224) {
                setDesktop(true)
            } else {
                setDesktop(false)
            }
        }

        window.addEventListener('resize', scrollHandler)

        return (() => {
            window.removeEventListener("resize", scrollHandler)
        })
    }, [])


    const displayOptIn = () => {
        if (optIn) {
            return (<div style={{ width: "100vw", height: "100vh", top: 0, position: "fixed", zIndex: 10, left: 0 }}>
                <OptInView key={0} setOptIn={setOptin} />
            </div>)
        } else {
            return <div />
        }
    }

    const displayClosePopUp = () => {
        if (closeIn) {
            return (<div style={{ width: "100vw", height: "100vh", top: "0px", position: "fixed", zIndex: 10, left: "0px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <ConfirmPopUp key={0} setOptIn={setCloseIn} />
            </div>)
        } else {
            return <div />
        }
    }

    const optinHandler = () => {
        setOptin(true)

        let data = {
            messageType: "toggle_fullscreen",
            value: true
        }
        console.log(data)
        window.parent.postMessage(data, "*")
    }

    const checkIfSamePreoccupation = (): boolean => {
        if ((questions.includes(Answer.CYrides) || questions.includes(Answer.CYridules)) && questions.includes(Answer.PPrides)) {
            return true
        }

        if (questions.includes(Answer.CYrelachement) && questions.includes(Answer.PPperteFermete)) {
            return true
        }

        return false
    }


    return (
        <CSSTransition addEndListener={() => null} classNames="swipeToRightResults" in={inProp}>

            <ResultViewContainer id="RESULT_VIEW">
                {!isDesktop && !window.MAIL_DISABLED_LANGS.includes(i18n.language) && <WhiteButton
                    style={{ margin: "24px 0px" }}
                    onClick={() => optinHandler()}
                >{t('results.getByMailCTA', "Recevoir mes résultats par email")}
                </WhiteButton>}

                {/* <SkinCard skinType={questions.flat()[1]} /> */}
                <SkinTypeImageContainer id="SKIN_CARD">
                    {!isOnProd() && <a style={{
                        display: window.innerWidth > 1224 ? "none" : "",
                        position: "absolute",
                        top: "8px",
                        right: "8px"
                    }} download={"Face_Capture"} href={imageUser}>
                        Download
                    </a>}
                    <SkinTypeImage src={window.innerWidth > 1224 ? SkinTypeDesk : window.innerWidth > 768 ? SkinTypeTab : SkinTypeMob} alt="Bannière" />

                    <SkinImageTextContainer>

                        {window.innerWidth >= 768 &&
                            <div onClick={() => dispatch({ type: ActionTypes.SET_STEP, value: 21 })} style={{ display: "flex", width: "100%", boxSizing: "border-box", margin: "16px 0px 8px", gap: "4px" }}>
                                <BackToScoreViewButtton>
                                    {t("results.seeYourScores", "Retours aux scores")}
                                </BackToScoreViewButtton>

                                <img alt="Back to scores button" src={CHEVRON} style={{ width: "14px", filter: "Brightness(0)", transform: window.RTL_LANGS.includes(i18n.language) ? "rotate(-90deg)" : "rotate(90deg)" }} />
                            </div>
                        }
                        {window.innerWidth >= 768 && <H2 style={{ padding: "0px", textAlign: "left" }}>
                            {t('results.introText', 'Grâce à l’Intelligence Artificielle de SisleySkin Expert et à vos réponses au questionnaire, nous avons pu déterminer les besoins de votre peau et établir votre routine de soin idéale.')}
                        </H2>}

                        <SkinTypeText>
                            {t('results.yourResults', 'Vos résultats')}
                        </SkinTypeText>

                    </SkinImageTextContainer>

                </SkinTypeImageContainer>

                {window.innerWidth < 768 && <H2 style={{ margin: "16px 12px", padding: "0px", textAlign: "left" }}>
                    {t('results.introText', 'Grâce à l’Intelligence Artificielle de SisleySkin Expert et à vos réponses au questionnaire, nous avons pu déterminer les besoins de votre peau et établir votre routine de soin idéale.')}
                </H2>}

                {window.innerWidth < 768 &&
                    <div onClick={() => dispatch({ type: ActionTypes.SET_STEP, value: 21 })} style={{ display: "flex", width: "100%", padding: "0px 12px", boxSizing: "border-box", marginBottom: "12px", gap: "4px" }}>
                        <BackToScoreViewButtton>
                            {t("results.seeYourScores", "Retours aux scores")}
                        </BackToScoreViewButtton>

                        <img alt="Back to scores button" src={CHEVRON} style={{ width: "14px", filter: "Brightness(0)", transform: window.RTL_LANGS.includes(i18n.language) ? "rotate(-90deg)" : "rotate(90deg)" }} />
                    </div>
                }

                <PreoccupationContainer isExposome={false} id="PREOCCUPATION_CONTAINER">
                    <PreoccupationsTextContainer>
                        <YourPreoccupationTitle>
                            {t('results.yourPreoccupations', "Vos préoccupations")}
                        </YourPreoccupationTitle>
                    </PreoccupationsTextContainer>

                    <ConcernBoxComponent concernName={questions.flat()[1]} />
                    <ConcernBoxComponent concernName={questions.flat()[2]} />
                    {!checkIfSamePreoccupation() && <ConcernBoxComponent concernName={questions.flat()[3]} />}
                </PreoccupationContainer>

                {!isDesktop && <SkinAdviceComponent isDesktop={isDesktop} questions={questions} />}

                {questions.length > 9 && <ExposomesContainer>
                    <PreoccupationsTextContainer style={{ display: questions.length > 9 ? "" : "none" }}>
                        <YourPreoccupationTitle>
                            {t('results.yourExposomes', "Vos exposomes")}
                        </YourPreoccupationTitle>
                    </PreoccupationsTextContainer>

                    {questions.length > 9 &&
                        <PreoccupationContainer isExposome={true}>
                            <ConcernBoxComponent isExposomes concernName={questions.flat()[9]} />
                            {questions.length > 10 &&
                                <ConcernBoxComponent isExposomes concernName={questions.flat()[10]} />
                            }
                        </PreoccupationContainer>}

                </ExposomesContainer>}

                {isDesktop && <SkinAdviceComponent questions={questions} isDesktop={isDesktop} />}

                <SeeOurRecoButton
                    onClick={() => {
                        try {
                            if (sessionStorage.getItem('finish_results') !== "true") {
                                sessionStorage.setItem("finish_results", "true")
                                sendAnalytics("finish_results", "Diag")
                            }
                        } catch (error) {
                            console.error("Erreur avec session Storage")
                            console.error(error)
                        }
                        dispatch({ type: ActionTypes.SET_STEP, value: 51 })
                    }
                    }>
                    {t('results.seeOurRecommandations', 'Voir nos recommendations')}
                </SeeOurRecoButton>

                <FooterView retry={setCloseIn} isDesktop={isDesktop} optinHandler={optinHandler} />

                {displayOptIn()}
                {displayClosePopUp()}

                {isDesktop &&
                    <RecoViewComponent />
                }

                {isDesktop &&
                    <div style={{ flex: 1 }} />
                }

            </ResultViewContainer>
        </CSSTransition>
    )
}

const setBackgroundImage = (concern: Answer) => {
    switch (concern) {
        case Answer.pollution:
            return PollutionBG
        case Answer.stress:
            return StressBG
        case Answer.ecrans:
            return LumiereBG
        case Answer.manqueSommeil:
            return SommeilBG
        default:
            break;
    }
}

const ConcernBoxComponent = (props: any) => {
    const analyses = useSelector((state: MySkinDiagState) => state.session.analyses)

    const concern = new Concern(props.concernName, analyses)
    const { t } = useTranslation()
    const [isExpanded, setExpanded] = useState<boolean>(false)

    const expand = () => {

        setExpanded(!isExpanded)

        setTimeout(() => {
            runHeightMachine(true, "RESULT_VIEW", 24)
        }, 300);
    }

    const setNumberVisibility = (): string => {
        if (concern.name === Answer.PPsigneFatigue || concern.name === Answer.PPdeshydratation || concern.name === Answer.PPsensibilite || concern.name === Answer.PPimperfections) {
            return "4"
        }
        if (concern.score > 0.75) {
            return "1"
        } else if (concern.score > 0.5) {
            return "2"
        } else if (concern.score > 0.25) {
            return "3"
        } else {
            return "4"
        }
    } //retry

    const setVisibilitytext = (): string => {
        if (concern.name === Answer.PPsigneFatigue || concern.name === Answer.PPdeshydratation || concern.name === Answer.PPsensibilite || concern.name === Answer.PPpores || concern.name === Answer.PPimperfections) {
            return ""
        }

        if (concern.name === Answer.pollution || concern.name === Answer.ecrans || concern.name === Answer.stress || concern.name === Answer.manqueSommeil) {
            return ""
        }

        if (concern.score > 0.75) {
            return t("results.veryNoticeable", "Très visible")
        } else if (concern.score > 0.5) {
            return t("results.noticeable", "Visible")
        } else if (concern.score > 0.25) {
            return t("results.slightlyNoticeable", "Peu visible")
        } else {
            return t("results.notNoticeable", "Très peu visible")
        }
    }

    const setConcernTitleText = (concernName: string): string => {

        if (i18n.language === "en_US" && concernName === Answer.CYpoche) {
            return "Under Eye Puffiness"
        }

        if (i18n.language !== "fr_FR") {
            return setAnswerTranslation(concernName, t)
        }

        switch (concernName) {
            case Answer.PPsigneFatigue:
                return t("results.concernTitle.PPsigneFatigue", "Les signes de fatigue")
            case Answer.pollution:
                return t("results.concernTitle.pollution", "La pollution")
            case Answer.ecrans:
                return t("results.concernTitle.ecrans", "La lumière bleue")
            case Answer.stress:
                return t("results.concernTitle.stress", "Du stress")
            case Answer.manqueSommeil:
                return t("results.concernTitle.manqueSommeil", "Un manque de sommeil")
            case Answer.PPdeshydratation:
                return t("results.concernTitle.PPdeshydratation", "Le manque d'hydratation")
            case Answer.PPsensibilite:
                return t("results.concernTitle.PPsensibilite", "La sensibilité")
            default:
                return setAnswerTranslation(concernName, t)
        }
    }

    return (
        <ConcernBoxContainer>
            <ConcernBox backgroundImage={props.isExposomes ? setBackgroundImage(concern.name) : null} color={concern.color} onClick={() => expand()}>
                {!props.isExposomes && <CircleProgressBar color={concern.color} angle={concern.icon ? 360 : 360 * concern.score}>
                    {!concern.icon && Math.trunc(concern.score * 100)}{!concern.icon && <span style={{ marginRight: "-5px", fontSize: "12px", fontWeight: 500, paddingBottom: "5px" }}>%</span>}
                    {concern.icon && <img alt={concern.name} style={{ width: concern.name === Answer.PPpores || concern.name === Answer.PPimperfections ? "36px" : "18px" }} src={concern.icon} />}
                </CircleProgressBar>
                }
                <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
                    <VisibleText>
                        {setVisibilitytext()}
                    </VisibleText>
                    <H2 style={{
                        font: "normal normal normal 18px/18px Playfair",
                        color: 'white',
                        textAlign: 'start',
                        padding: "0px"
                    }}>
                        {setConcernTitleText(concern.name)}
                    </H2>
                </div>

                <img alt="Flèche" src={ArrowIcon} style={{
                    width: "20px",
                    transform: isExpanded ? "" : "rotate(180deg)",
                    transition: "all 0.3s"
                }} />

            </ConcernBox>

            <AnimateHeight height={isExpanded ? "auto" : 0} style={{ width: "100%", marginTop: "-5px" }}>
                <DropDownContainer noExposomes={false} isInConcernBox>
                    <DropDownContentText>
                        {t('results.preoccupation.' + concern.name + "." + setNumberVisibility() + ".intro", 'Lorem Ipsum')}
                    </DropDownContentText>

                    <div style={{ display: "flex", margin: "24px 0px", alignItems: "center", gap: "6px" }}>
                        <img alt="Sisley Logo" src={SIcon} style={{ width: "44px", objectFit: "contain" }} />
                        <AdviceBoldText>
                            {t("results.advice", "advice")}
                        </AdviceBoldText>
                    </div>

                    <DropDownContentText style={{ fontSize: "13px" }}>
                        {t('results.preoccupation.' + concern.name + "." + setNumberVisibility() + ".conseil", 'Lorem Ipsum')}
                    </DropDownContentText>
                </DropDownContainer>
            </AnimateHeight>
        </ConcernBoxContainer>

    )
}

const VisibleText = styled.p`
    margin: 0px;
    margin-bottom: 2px;
    font: normal normal 500 9px/11px Helvetica;
    color: white;
    text-transform: uppercase;
    letter-spacing: 0.36px;
`

const SkinAdviceComponent = (props: any) => {
    const { t } = useTranslation()
    return (
        <SkinAdviceContainer id="SKIN_ADVICE">
            <PreoccupationsTextContainer style={{ width: "100%" }}>
                <YourPreoccupationTitle>
                    {t('results.skinAdviceTitle')}
                    {window.innerWidth >= 768 && <span style={{ fontWeight: 900, whiteSpace: "pre-wrap" }}>{(window.innerWidth > 768 ? ' ' : '') + t("results.skinType." + props.questions.flat()[4])}</span>}

                </YourPreoccupationTitle>

                {window.innerWidth < 768 && <YourPreoccupationTitle style={{ fontWeight: 900, whiteSpace: "pre-wrap" }}>
                    {(window.innerWidth > 768 ? ' ' : '') + t("results.skinType." + props.questions.flat()[4])}
                </YourPreoccupationTitle>}
            </PreoccupationsTextContainer>

            <DropDownContainer noExposomes={props.questions.flat().length === 9} isInConcernBox={false}>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flex: 1 }}>
                    <DropDownContentText>
                        {t('results.skinTypeText.intro.' + props.questions.flat()[4], 'Lorem Ipsum')}
                    </DropDownContentText>
                </div>


                <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
                    <div style={{ display: "flex", margin: "0px 0px 24px", alignItems: "center", gap: "6px" }}>
                        <img alt="Sisley Logo" src={SIcon} style={{ width: "44px", objectFit: "contain" }} />
                        <AdviceBoldText>
                            {t("results.advice", "conseil")}
                        </AdviceBoldText>
                    </div>

                    <DropDownContentText>
                        {t('results.skinTypeText.' + props.questions.flat()[4], 'Lorem Ipsum')}
                    </DropDownContentText>
                </div>


            </DropDownContainer>
        </SkinAdviceContainer>)
}

const ResultsViewComponent = () => {
    return <ResultsView />
}

export default ResultsViewComponent