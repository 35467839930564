import styled from "styled-components"
import { useTranslation } from 'react-i18next'
import SisleyIcon from '../Assets/Icons/SISLEY.svg'
import { CloseButton } from "../Styles/Buttons"
import { useState } from "react"
import ConfirmPopUp from "./ConfirmPopUp"
import closeIcon from '../Assets/Icons/Close_B.svg'
import { Base64 } from "js-base64"
import { closeIFrame } from "../Constants/Utilitaries"

const CameraPermissionView = () => {
    const { t } = useTranslation()
    const [closeIn, setCloseIn] = useState<boolean>(false)

    const displayClosePopUp = () => {
        let url = new URL(window.location.href)
        if (url.searchParams.get("data")) {
            let urlString = Base64.atob(url.searchParams.get("data")!.replace(" ", "+"))
            let data = JSON.parse(urlString)
            if (data["mode"] === "try-on" && closeIn) {
                closeIFrame()
                return <div/>
            }
        }
        if (closeIn) {
            return (<div style={{ width: "100vw", height: "100vh", top: 0, position: "fixed", zIndex: 10 }}>
                <ConfirmPopUp key={0} setOptIn={setCloseIn} />
            </div>)
        } else {
            return <div />
        }
    }

    return (
        <>
            <CloseButton style={{ top: "20px" }} src={closeIcon} alt="close" onClick={() => setCloseIn(true)}>
            </CloseButton>
            {displayClosePopUp()}

            <Container>
                <img alt="Logo Sisley" style={{ width: "128px", marginBottom: "24px" }} src={SisleyIcon} />
                <Text>
                    {t("cameraView.permissionError", "Activez votre caméra de navigateur puis rechargez la page pour poursuivre l'expérience.")}
                </Text>
            </Container>
        </>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    position: fixed;

    @supports (-webkit-touch-callout: none) {
        /* The hack for Safari */
        height: -webkit-fill-available;
        width: -webkit-fill-available;
        box-sizing: initial;
        position: initial;
    }
`

const Text = styled.p`
    margin: 0px;
    font: normal normal 2em/100% Helvetica bold;
    color: black;
    width: 70%;
    text-align: center;

    @media (max-width: 768px){
        font-size: 1em;
    }
`

const CameraErrorComponent = () => {
    return <CameraPermissionView />
}

export default CameraErrorComponent