import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { LightTryOnClass, TryOnProductClass, TryOnType } from "../../Constants/TryOnProduct"
import { CategoryCellContainer, CategoryImg, PGCarousel, PGNavigationChevron, PGNavigationCntr, PGNavigationContainer, PGNavigationText, PGProductCellContainer, PGProductCellImg, PGProductCellText, PGWaterMark, TryOnPGContainer } from "../../Styles/VTO/VTOContainers"
import { MaxProductsText, PGCellGreyText, PGCellTitle } from "../../Styles/VTO/VTOTexts"
import * as ActionTypes from '../../Constants/ActionsTypes'
import { useDispatch, useSelector } from "react-redux"
import { BeforeAfterSlider, BeforeAfterCtnr, BeforeAfterTxt, BeforeAfterBtn } from "../../Styles/VTO/VTOButtons"
import { getPlayGroundProducts, PlayGroundAllProductsType, PGCategory, PGUnderCategory, PlayGroundState, countProductsForCategories, countProductsForUnderCategories, setCategoriesSrc, displayProductNameWithLimiter, checkProductsAvailability } from "./PlaygroundConstants"
import { AnimatePresence } from "framer-motion"
import chevron from '../../Assets/Icons/Chevron.svg'
import CartPanel from "./PlayGround/CartPanel"
import Logo from '../../Assets/Icons/LogoAAA.png'
import ShadeSelector from './PlayGround/ShadeSelector'
import { MySkinDiagState } from "../../type"
import i18n from "../../i18nextInit"
import { handleProductsText } from "./Utilities/utils"

interface PlayGroundProps {
    setPlayGroundState: Function,
    playGroundState: PlayGroundState,
    enableOval: Function,
    actualTryOnProduct: LightTryOnClass | undefined,
    setActualTryOnProduct: Function,
    isShadesBackButtonDisplayed: boolean,
    displayShadesBackButton: Function
}

const PlayGroundUI = (props: PlayGroundProps) => {
    const [category, setCategory] = useState<PGCategory | null>(null)
    const [underCategory, setUnderCategory] = useState<PGUnderCategory | null>(null)
    const [isProductsAreHidden, hideProducts] = useState<boolean>(false)
    const playGroundProducts = useRef<PlayGroundAllProductsType | undefined>()
    const tryOnProducts: TryOnProductClass[] = useSelector((state: MySkinDiagState) => state.session.tryOnProducts)
    // eslint-disable-next-line
    const [isReplacePopUpDisplayed, displayReplacePopUp] = useState<boolean>(false)
    const cartProducts: TryOnProductClass[] = useSelector((state: MySkinDiagState) => state.session.playGroundCart)
    const [isMaxProductTextDisplayed, displayMaxProductText] = useState<boolean>(false)
    const [isCartPanelWide, setCartPanelWide] = useState<boolean>(true)
    // eslint-disable-next-line
    const [croppedSkuForReplace, setCroppedSkuForReplace] = useState<string>("")
    const [areProductsCountDisplayed, displayProductCount] = useState<boolean>(false)

    const { t } = useTranslation()
    const dispatch = useDispatch()

    const NAVIGATION_ANIMATED_X = window.RTL_LANGS.includes(i18n.language) ? "10px" : "-10px"
    const NAVIGATION_ANIMATED_TRANSFORM = window.RTL_LANGS.includes(i18n.language) ? "rotate(-90deg)" : "rotate(90deg)"

    useEffect(() => {
        if (props.playGroundState === PlayGroundState.chooseCategory) {
            props.enableOval(false)
            props.setPlayGroundState(PlayGroundState.chooseCategory)
        }
        //eslint-disable-next-line
    }, [props.playGroundState])

    useEffect(() => {
        // Au démarrage de la vue on récupère tous les produits du json de TryOn avec les effets etc. (myStoreUpdated.json)
        // On check egalement s'il y  a des produits du cart dans le session storage.

        if (sessionStorage.getItem('playgroundCart')) {
            console.log('storage found for playground cart')
            console.warn(JSON.parse(sessionStorage.getItem('playgroundCart')!))
            dispatch({ type: ActionTypes.UPDATE_PLAYGROUND_CART, value: JSON.parse(sessionStorage.getItem('playgroundCart')!) })
            dispatch({ type: ActionTypes.NEW_UPDATE_TRY_ON_PRODUCT, value: JSON.parse(sessionStorage.getItem('playgroundCart')!) })
        } else {
            console.warn("storage pas found")
        }

        playGroundProducts.current = getPlayGroundProducts()
        checkProductsAvailability(playGroundProducts.current)
            .then((playgroundProducts) => {
                playGroundProducts.current = playgroundProducts
                displayProductCount(true)
            })
    }, [dispatch])

    const back = () => {
        if (props.playGroundState === PlayGroundState.chooseCategory) {
            // Cas de base,  il ne se passe rien.
        } else if (props.playGroundState === PlayGroundState.chooseUnderCategory) {
            props.setPlayGroundState(PlayGroundState.chooseCategory)
            setCategory(null)
        } else if (props.playGroundState === PlayGroundState.chooseProduct) {
            props.setPlayGroundState(PlayGroundState.chooseUnderCategory)
            setUnderCategory(null)
        }
        displayMaxProductText(false)
    }

    const beforeBtnHandler = () => {
        let _updatedProducts = [...tryOnProducts]
        if (!isProductsAreHidden) {
            _updatedProducts.forEach(product => {
                product.isActive = false
            })
        }

        dispatch({ type: ActionTypes.UPDATE_TRYON_PRODUCTS, value: _updatedProducts })
        hideProducts(true)
    }

    const afterBtnHandler = () => {
        let _updatedProducts = [...tryOnProducts]
        if (isProductsAreHidden) {
            _updatedProducts.forEach(product => {
                product.isActive = true
            })
        }
        dispatch({ type: ActionTypes.UPDATE_TRYON_PRODUCTS, value: _updatedProducts })
        hideProducts(false)
    }

    return (<>
        <AnimatePresence>
            {tryOnProducts.length > 0 && <BeforeAfterCtnr
                key="BEFORE_AFTER_PANEL"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.25 }}>
                <BeforeAfterSlider isOnAfterPosition={!isProductsAreHidden} />

                <BeforeAfterBtn onClick={() => beforeBtnHandler()}>
                    <BeforeAfterTxt isSelected={isProductsAreHidden}>
                        {t("playGround.before")}
                    </BeforeAfterTxt>
                </BeforeAfterBtn>

                <BeforeAfterBtn onClick={() => afterBtnHandler()}>
                    <BeforeAfterTxt isSelected={!isProductsAreHidden}>
                        {t("playGround.after")}
                    </BeforeAfterTxt>
                </BeforeAfterBtn>

            </BeforeAfterCtnr>}

            <CartPanel
                setUnderCategory={setUnderCategory}
                displayMaxProductText={displayMaxProductText}
                isMaxProductTextDisplayed={isMaxProductTextDisplayed}
                isCartPanelWide={isCartPanelWide}
                setCartPanelWide={setCartPanelWide}
                displayShadesBackButton={props.displayShadesBackButton}
                playGroundState={props.playGroundState}
                setActualTryOnProduct={props.setActualTryOnProduct}
                setPlayGroundState={props.setPlayGroundState} />
        </AnimatePresence>



        <TryOnPGContainer
            $isShadesView={props.playGroundState === PlayGroundState.chooseShade}
            $isCartOpen={props.playGroundState !== PlayGroundState.chooseProduct}
            $isMaxProduct={isMaxProductTextDisplayed}
            $isPrivateMode={false}
        >

            <PGNavigationContainer>
                <PGWaterMark $isInShadeMode={props.playGroundState === PlayGroundState.chooseShade} $isCartPanel={cartProducts.length > 0} $isDisplay={!isCartPanelWide || cartProducts.length === 0}>
                    Created by <img src={Logo} style={{ height: "15px" }} alt="WaterMark" />
                </PGWaterMark>

                {props.playGroundState !== PlayGroundState.chooseShade && <PGNavigationCntr>
                    <PGNavigationText
                        $isFirst
                        $isUnderlined={props.playGroundState === PlayGroundState.chooseUnderCategory || props.playGroundState === PlayGroundState.chooseProduct}
                        onClick={() => back()}>
                        {t("playGround.category")}
                    </PGNavigationText>

                    <AnimatePresence>
                        {category && <PGNavigationChevron
                            src={chevron}
                            key="TEXT_0"
                            initial={{
                                x: NAVIGATION_ANIMATED_X,
                                opacity: 0,
                                transform: NAVIGATION_ANIMATED_TRANSFORM
                            }}
                            animate={{ x: 0, opacity: 1, transform: NAVIGATION_ANIMATED_TRANSFORM }}
                            exit={{
                                x: NAVIGATION_ANIMATED_X,
                                opacity: 0,
                                transform: NAVIGATION_ANIMATED_TRANSFORM
                            }}
                            transition={{ duration: 0.25 }}
                            onClick={() => back()} />}


                        {category && <PGNavigationText
                            $isUnderlined={props.playGroundState === PlayGroundState.chooseProduct}
                            key="TEXT_1"
                            initial={{
                                x: NAVIGATION_ANIMATED_X,
                                opacity: 0
                            }}
                            animate={{
                                x: 0,
                                opacity: 1
                            }}
                            exit={{
                                x: NAVIGATION_ANIMATED_X,
                                opacity: 0
                            }}
                            transition={{ duration: 0.25 }}
                            onClick={() => back()}>
                            {t("playGround.categories." + category)}
                        </PGNavigationText>}

                        {underCategory && <PGNavigationChevron
                            src={chevron}
                            key="TEXT_2"
                            initial={{
                                x: NAVIGATION_ANIMATED_X,
                                opacity: 0,
                                transform: NAVIGATION_ANIMATED_TRANSFORM
                            }}
                            animate={{
                                x: 0,
                                opacity: 1,
                                transform: NAVIGATION_ANIMATED_TRANSFORM
                            }}
                            exit={{
                                x: NAVIGATION_ANIMATED_X,
                                opacity: 0,
                                transform: NAVIGATION_ANIMATED_TRANSFORM
                            }}
                            transition={{ duration: 0.25 }}
                            onClick={() => back()} />}

                        {underCategory && <PGNavigationText
                            $isUnderlined={false}
                            key="TEXT_3"
                            initial={{ x: NAVIGATION_ANIMATED_X, opacity: 0 }}
                            animate={{ x: 0, opacity: 1 }}
                            exit={{ x: NAVIGATION_ANIMATED_X, opacity: 0 }}
                            transition={{ duration: 0.25 }}
                            onClick={() => back()}>
                            {t("playGround.subCategories." + underCategory)}
                        </PGNavigationText>}
                    </AnimatePresence>
                </PGNavigationCntr>}

            </PGNavigationContainer>

            {props.playGroundState !== PlayGroundState.chooseShade && isMaxProductTextDisplayed && <MaxProductsText $isInRed>
                {t("playGround.textLimit")}
            </MaxProductsText>}

            {props.playGroundState === PlayGroundState.chooseCategory && playGroundProducts.current && <PlayGroundCategories
                playGroundProducts={playGroundProducts.current}
                setPlayGroundState={props.setPlayGroundState}
                areProductsCountDisplayed={areProductsCountDisplayed}
                setCategory={setCategory} />}

            {props.playGroundState === PlayGroundState.chooseUnderCategory && playGroundProducts.current && <PlayGroundUnderCategories
                selectedCategory={category!}
                playGroundProducts={playGroundProducts.current}
                setPlayGroundState={props.setPlayGroundState}
                setUnderCategory={setUnderCategory}
            />}

            {props.playGroundState === PlayGroundState.chooseProduct && playGroundProducts.current && <PlayGroundProducts
                displayMaxProductText={displayMaxProductText}
                category={category!}
                underCategory={underCategory!}
                playGroundProducts={playGroundProducts.current}
                setPlayGroundState={props.setPlayGroundState}
                setActualProduct={props.setActualTryOnProduct}
                setCroppedSku={setCroppedSkuForReplace}
                setCartPanelWide={setCartPanelWide}
            />}

            {playGroundProducts.current && props.actualTryOnProduct && props.playGroundState === PlayGroundState.chooseShade && <ShadeSelector
                selectedSku={props.actualTryOnProduct.sku}
                afterBtnHandler={afterBtnHandler}
                selectedParentSku={props.actualTryOnProduct.parentSku}
                displayShadesBackButton={props.displayShadesBackButton}
                isShadesBackButtonDisplayed={props.isShadesBackButtonDisplayed}
                undercategory={underCategory!}
                setCategory={setCategory}
                setUnderCategory={setUnderCategory}
                setActualTryOnProduct={props.setActualTryOnProduct}
                setPlayGroundState={props.setPlayGroundState}
                setCartPanelWide={setCartPanelWide} />}

        </TryOnPGContainer>
    </>
    )
}

interface PGProductsProps {
    category: PGCategory
    underCategory: PGUnderCategory
    playGroundProducts: PlayGroundAllProductsType
    setPlayGroundState: Function
    setActualProduct: Function
    setCroppedSku: Function
    displayMaxProductText: Function
    setCartPanelWide: Function
}

const PlayGroundProducts = (props: PGProductsProps) => {
    const dispatch = useDispatch()
    const [products, setProducts] = useState<any[]>([])
    const playGroundCart: TryOnProductClass[] = useSelector((state: MySkinDiagState) => state.session.playGroundCart)
    const tryOnProducts: TryOnProductClass[] = useSelector((state: MySkinDiagState) => state.session.tryOnProducts)
    const MAX_PRODUCTS = 6
    const CAROUSEL_INITIAL_X = window.RTL_LANGS.includes(i18n.language) ? "20px" : "-20px"
    const CAROUSEL_ANIMATED_X = window.RTL_LANGS.includes(i18n.language) ? "-20px" : "20px"

    useEffect(() => {

        const openProductsShades = (sku: string, parentSku: string) => {

            if (playGroundCart.length === MAX_PRODUCTS) {
                props.displayMaxProductText(true)
                props.setCartPanelWide(true)
                return
            }

            let _croppedSku: string = ""
            if (sku.length <= 6) {
                _croppedSku = sku
            } else if (sku.length === 12) {
                _croppedSku = sku.slice(6, -1)
            } else if (sku.length === 13) {
                _croppedSku = sku.slice(6, -1)
            }

            // On check ici si il y a deja un produit de la meme category (ou LipStick/gloss)
            // SI c'est le cas, on supprime le produit déjà affiché et on le remplace par
            // le nouveau.
            playGroundCart.forEach(product => {
                let _productToReplace = tryOnProducts.find(product => product.makeUpCategory === props.underCategory ||
                    (product.makeUpCategory === PGUnderCategory.glosses && props.underCategory === PGUnderCategory.lipSticks) ||
                    (product.makeUpCategory === PGUnderCategory.lipSticks && props.underCategory === PGUnderCategory.glosses))
                if (_productToReplace) {
                    dispatch({ type: ActionTypes.REMOVE_TRYON_PRODUCT, value: _productToReplace })
                }
                props.setCroppedSku(_croppedSku)

            })


            let _tryOnProduct: TryOnProductClass = {
                actualVariant: _croppedSku,
                description: "",
                imgURL: "",
                introduction: "",
                isActive: true,
                isKholHigh: false,
                isLiner: false,
                name: "Test",
                sku: _croppedSku,
                isVTOEnable: true,
                parentName: "Test",
                parentSku: "161527",
                addVariant: () => { },
                price: 0,
                promo: 0,
                shadeColor: "",
                shadeName: "Test",
                stock: 12,
                type: TryOnType.item,
                variants: []
            }

            props.setActualProduct({ sku: _tryOnProduct.sku, category: props.underCategory, parentSku: parentSku })
            props.setPlayGroundState(PlayGroundState.chooseShade)
        }

        let _products = []
        let key: number = 0

        let underCateg = props.playGroundProducts[props.category] as any

        for (let i = 0; i < underCateg[props.underCategory].length; i++) {
            if (underCateg[props.underCategory][i].isEnable && underCateg[props.underCategory][i].parentName) {
                _products.push(<PGProductCellContainer key={key} onClick={() => {
                    openProductsShades(underCateg[props.underCategory][i].sku, underCateg[props.underCategory][i].parentSku)
                }}>
                    <PGProductCellImg src={underCateg[props.underCategory][i].imageURL} />
                    <PGProductCellText>
                        {displayProductNameWithLimiter(underCateg[props.underCategory][i].parentName, 24)}
                    </PGProductCellText>
                </PGProductCellContainer>)
            }
            key++
        }

        setProducts(_products)
        // eslint-disable-next-line
    }, [props, dispatch, playGroundCart, playGroundCart.length])

    return (
        <PGCarousel
            key="PRODUCTS"
            initial={{ x: CAROUSEL_INITIAL_X, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: CAROUSEL_ANIMATED_X, opacity: 0 }}
            transition={{ duration: 0.25 }}>
            {products}
        </PGCarousel>
    )
}

interface PGCategoryProps {
    playGroundProducts: PlayGroundAllProductsType
    areProductsCountDisplayed: boolean
    setPlayGroundState: Function
    setCategory: Function
}

const PlayGroundCategories = (props: PGCategoryProps) => {
    const { t } = useTranslation()
    const [productsCategories, setProductsCategories] = useState<any[]>([])
    const CAROUSEL_INITIAL_X = window.RTL_LANGS.includes(i18n.language) ? "20px" : "-20px"
    const CAROUSEL_ANIMATED_X = window.RTL_LANGS.includes(i18n.language) ? "-20px" : "20px"

    useEffect(() => {
        // Générer les catégories et les produits à partir du JSON de Benjamin.

        let productsCategories = Object.keys(props.playGroundProducts)

        let _catégories = []
        let key: number = 0

        for (let i = 0; i < productsCategories.length; i++) {

            let countProducts = countProductsForCategories(productsCategories[i] as PGCategory, props.playGroundProducts)

            _catégories.push(<CategoryCellContainer
                key={key}
                onClick={() => {
                    props.setPlayGroundState(PlayGroundState.chooseUnderCategory)
                    props.setCategory(productsCategories[i] as PGCategory)
                }}>
                <CategoryImg src={setCategoriesSrc(productsCategories[i] as PGCategory)} />
                <PGCellTitle>
                    {t("playGround.categories." + productsCategories[i])}
                </PGCellTitle>

                {props.areProductsCountDisplayed && <PGCellGreyText>
                    {handleProductsText(t, countProducts)}
                </PGCellGreyText>}
            </CategoryCellContainer>)
            key++
        }

        setProductsCategories(_catégories)
    }, [props, t])

    return (
        <PGCarousel
            key="CATEGORY"
            initial={{ x: CAROUSEL_INITIAL_X, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: CAROUSEL_ANIMATED_X, opacity: 0 }}
            transition={{ duration: 0.25 }}>
            {productsCategories}
        </PGCarousel>
    )
}

interface PGUnderCategoryProps {
    playGroundProducts: PlayGroundAllProductsType
    selectedCategory: PGCategory
    setPlayGroundState: Function
    setUnderCategory: Function
}

const PlayGroundUnderCategories = (props: PGUnderCategoryProps) => {
    const { t } = useTranslation()
    const [productsUnderCategories, setProductsunderCategories] = useState<any[]>([])
    const CAROUSEL_INITIAL_X = window.RTL_LANGS.includes(i18n.language) ? "20px" : "-20px"
    const CAROUSEL_ANIMATED_X = window.RTL_LANGS.includes(i18n.language) ? "-20px" : "20px"

    useEffect(() => {
        let _productsUnderCategories = Object.keys(props.playGroundProducts[props.selectedCategory])
        let _catégories = []
        let key: number = 0

        for (let i = 0; i < _productsUnderCategories.length; i++) {
            const countProducts = countProductsForUnderCategories(_productsUnderCategories[i] as PGUnderCategory, props.playGroundProducts)
            if (countProducts > 0) {
                _catégories.push(<CategoryCellContainer key={key} onClick={() => {
                    props.setUnderCategory(_productsUnderCategories[i])
                    props.setPlayGroundState(PlayGroundState.chooseProduct)
                }}>
                    <CategoryImg src={setCategoriesSrc(_productsUnderCategories[i] as PGUnderCategory)} />
                    <PGCellTitle>
                        {displayProductNameWithLimiter(t("playGround.subCategories." + _productsUnderCategories[i]), 11)}
                    </PGCellTitle>

                    <PGCellGreyText>
                        {handleProductsText(t, countProducts)}
                    </PGCellGreyText>
                </CategoryCellContainer>)
                key++
            }

        }

        setProductsunderCategories(_catégories)
    }, [props, t])

    return (
        <PGCarousel
            key="UNDER_CATEGORY"
            initial={{ x: CAROUSEL_INITIAL_X, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: CAROUSEL_ANIMATED_X, opacity: 0 }}
            transition={{ duration: 0.25 }}>
            {productsUnderCategories}
        </PGCarousel>
    )
}

export default PlayGroundUI