import { Loader, MakeupCanvas, OvalContainer, WhiteTextContainer } from "../../Styles/VTO/VTOContainers"
import { MutableRefObject, useEffect, useRef, useState } from "react"
import * as BK2 from './BeautyKit2'
import { parse } from './BeautyKit2/BeautyKit2JSONParser'
import { useDispatch, useSelector } from "react-redux"
import { MySkinDiagState } from "../../type"
import { TryOnProductClass } from "../../Constants/TryOnProduct"
//import { LoadingText } from "../../Styles/VTO/VTOTexts"
import LoadingFlower from '../../Assets/gif/LoadingFlower.gif'
import { TryOnCloseButton } from "../../Styles/VTO/VTOButtons"
import { isDesktop, isTablet } from "react-device-detect"
import * as ActionTypes from '../../Constants/ActionsTypes'
import styled from "styled-components"
import { PlayGroundWhiteText } from "../../Styles/VTO/VTOTexts"
import { useTranslation } from "react-i18next"
import { PlayGroundState } from "./PlaygroundConstants"
import { AnimatePresence } from "framer-motion"

//import { Dropdown, Option } from "./../Dropdown";
const beautyfier = new BK2.Layer("29032018_clarte_shape6.png", BK2.Effect.MATT, BK2.Shapetype.SKIN_BEAUTIFY, [1, 1, 1], 0.7, 0, 0, "", "")
let store = parse();

interface TryOnCameraProps {
    beautyKit2: any,
    score: number,
    isOvalEnabled: boolean,
    playGroundState: PlayGroundState,
    playgroundMode: MutableRefObject<boolean | null>
}

const TryOnCamera = (props: TryOnCameraProps) => {
    const dispatch = useDispatch()
    const ovalRef = useRef<HTMLDivElement>(null)
    const [oval, setOval] = useState<any>([])
    const eyeTwists = ["187011", "187012", "187013", "187014", "187015", "187016", "187017", "187018", "187019", "187020", "187021", "187022", "187023", "187024", "187025", "187026", "187027"]
    const eyeKhol = ["187311", "187312", "187313", '187314', "187315", "187316", "187317", "187318", "187319", "187320"];
    const session = useSelector((state: MySkinDiagState) => state.session)
    const [isLoaderDisplay, displayLoader] = useState<boolean>(true)
    const { t } = useTranslation()

    useEffect(() => {
        props.beautyKit2.start("makeupCamera", "#rendering", true).then(() => {
            console.log("Mqkeup OK")
            createVTOOval()
            displayLoader(false)
        })
            .catch((error: any) => {
                console.warn(`error : ${error}`)
                dispatch({ type: ActionTypes.SET_STEP, value: 12 })
            })
        // eslint-disable-next-line
    }, [props.beautyKit2])

    useEffect(() => {
        if (props.score <= 3 && props.isOvalEnabled) {
            ovalRef.current!.style.opacity = "0.7"
        } else {
            ovalRef.current!.style.opacity = "0"
        }
    }, [props.score, props.isOvalEnabled])

    useEffect(() => {
        console.log(session.tryOnProducts)
        let layers = constructLayers()
        props.beautyKit2.setLayers(layers)
        //eslint-disable-next-line
    }, [session, props.beautyKit2])


    function constructLayers(): BK2.Layer[] {
        var layers: BK2.Layer[] = []
        //if(isOn){ // else return Beautyfier Only


        session.tryOnProducts.forEach((make: TryOnProductClass) => {
            var makeup: any
            if (make.actualVariant === "") {
                makeup = store.find((mu: { sku: string }) => mu.sku.includes(make.sku))
            }
            else {
                makeup = store.find((mu: { sku: string }) => mu.sku.includes(make.actualVariant))
            }
            if (makeup && make.isActive) {
                if (makeup.shapeType === BK2.Shapetype.EYESHADOW) {
                    if (eyeTwists.includes(make.sku)) {
                        if (make.isLiner) {
                            makeup.shape = "/eyeliner_twist_update_2.png"
                        }
                        else {
                            makeup.shape = "/eyeshadow_twist.png"
                        }
                    }
                }
                if (makeup.shapeType === BK2.Shapetype.EYELINER) {
                    if (eyeKhol.includes(make.sku)) {
                        if (make.isKholHigh) {
                            makeup.shape = "/eyeliner_khol_update_2.png"
                            if (make.sku === "87311") {
                                makeup.opacity = 180
                            }
                        }
                        else {
                            makeup.shape = "/eyeliner_khol_update_3.png"
                            if (make.sku === "87311") {
                                makeup.opacity = 180
                            }
                            else {
                                makeup.opacity = 350
                            }
                        }
                    }
                }
                if ([BK2.Shapetype.LIPGLOSS, BK2.Shapetype.LIPSTICK].some(x => x === makeup!.shapeType)) {
                    props.beautyKit2.setShinyValue(makeup.shineLevel - (isDesktop ? 0.02 : 0))
                    console.log("SHINE : " + (makeup.shineLevel - (isDesktop ? 0.02 : 0)));
                }
                if (![BK2.Shapetype.EYELINER/*, BK2.Shapetype.CONCEALER, BK2.Shapetype.FOUNDATION, BK2.Shapetype.POWDER*/].some(x => x === makeup!.shapeType)) {
                    if (makeup.blurLevel !== 0) {
                        if (![BK2.Shapetype.LIPGLOSS, BK2.Shapetype.LIPSTICK].some(x => x === makeup!.shapeType)) {
                            layers = layers.concat(new BK2.Layer(makeup.shape, BK2.Effect.MATT, BK2.Shapetype.SKIN_BEAUTIFY, [1, 1, 1], makeup.blurLevel, 0, 0, "", ""))
                        }
                        else {
                            layers = layers.concat(new BK2.Layer(makeup.shape, BK2.Effect.MATT, BK2.Shapetype.SKIN_BEAUTIFY, [1, 1, 1], makeup.blurLevel / 1.5, 0, 0, "", ""))
                        }
                    }
                }
                layers = layers.concat(makeup)

            }

        });

        if (layers.length > 0 && !layers.some(x => x.shapeType === BK2.Shapetype.FOUNDATION) && !layers.some(x => x.shapeType === BK2.Shapetype.POWDER)) {
            //beautyfier.a = rangeVal / 100
            layers.push(beautyfier)
            console.log("PUSH DU BEAUTIFIER : " + beautyfier.blurLevel)
        }
        return layers

    }

    const closeCamera = () => {

        let data = {
            messageType: "close",
        }

        let dataBis = {
            messagetype: "toggle_fullscreen",
            value: false
        }

        window.parent.postMessage(dataBis, '*')
        window.parent.postMessage(data, '*')
        console.log(data)
        console.log(dataBis)

    }

    const createVTOOval = () => {
        let ovalHeight: number
        let ovalWidth: number
        ovalHeight = isTablet ? window.innerHeight * 0.6 : window.innerHeight * 0.5 * 1.2
        ovalWidth = isTablet ? window.innerHeight * 0.408 : window.innerHeight * 0.34 * 1.2

        const Oval = styled.div<{ step: number }>`
        position: absolute;
        display: block;
        transition: 0.5s all;
        height: ${ovalHeight}px; 
        width: ${ovalWidth}px;
        Z-index: 3;
        border-radius: ${ovalWidth}px/${ovalHeight}px;
        border-width: 2px;
        border-style: ${props => props.step === 2 ? 'dashed' : 'solid'};
        border-color: white;
        clip-path: ${props => props.step === 1 && 'polygon(0 83%, 100% 83%, 100% 100%, 0 100%)'};
        clip-path: ${props => props.step === 2 && 'polygon(0 30%, 100% 30%, 100% 60%, 0 60%);'};
        clip-path: ${props => props.step === 3 && 'polygon(0 0, 100% 0, 100% 20%, 0 20%)'};
        `;

        let tab = []
        tab.push(<Oval key={0} step={1} id={'OVAL'} />)
        tab.push(<Oval key={1} step={2} id={'OVAL_2'} />)
        tab.push(<Oval key={2} step={3} id={'OVAL_3'} />)

        setOval(tab)
    }

    const handlePlaygroundStateText = () => {
        switch (props.playGroundState) {
            case PlayGroundState.chooseCategory:
                return t("playGround.selectCategory")
            case PlayGroundState.chooseUnderCategory:
                return t("playGround.selectUnderCategory")
            case PlayGroundState.chooseProduct:
                return t("playGround.selectProduct")
            case PlayGroundState.chooseShade:
                return t("playGround.selectShade")
            default:
                return "Choose Under-Category"
        }
    }

    const displayWhiteText = () => {
        if (props.playGroundState === PlayGroundState.none || !props.playgroundMode.current || isLoaderDisplay || session.playGroundCart.length !== 0) {
            return false
        }

        // S'il y a un shade "NoShade" ET qu'il n'y a pas de produit masqué, alors on peut afficher le texte.
        let isNoShadeProduct: boolean = false
        let isHiddenProduct: boolean = false

        session.tryOnProducts.forEach((product: TryOnProductClass) => {
            if (product.sku === 'NoShade') {
                isNoShadeProduct = true
            }

            if (product.isActive === false) {
                isHiddenProduct = true
            }
        })

        if ((!isNoShadeProduct || isHiddenProduct) && session.tryOnProducts.length > 0) {
            return false
        }

        return true
    }

    return (<>
        <TryOnCloseButton style={{ top: "20px"}} onClick={() => closeCamera()} />
        <canvas id="makeupCamera" width={props.beautyKit2.resolution} height={props.beautyKit2.resolution} style={{ display: 'none' }}></canvas>
        <MakeupCanvas id="rendering" >

        </MakeupCanvas>

        <AnimatePresence mode="wait">
            {displayWhiteText() &&
                <WhiteTextContainer
                    key={handlePlaygroundStateText()}
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 10 }}
                    transition={{ duration: 0.25 }}>
                    <PlayGroundWhiteText>
                        {handlePlaygroundStateText()}
                    </PlayGroundWhiteText>
                </WhiteTextContainer>
            }
        </AnimatePresence>

        <OvalContainer ref={ovalRef}>
            {oval}
        </OvalContainer>
        <Loader src={LoadingFlower} />
    </>)
}

const TryOnCameraComponent = (props: TryOnCameraProps) => {
    return <TryOnCamera playgroundMode={props.playgroundMode} playGroundState={props.playGroundState} isOvalEnabled={props.isOvalEnabled} score={props.score} beautyKit2={props.beautyKit2} />
}

export default TryOnCameraComponent