import * as ActionTypes from "../Constants/ActionsTypes"
import { Answer } from "../Constants/QuestsConstants"
import { castConcernNameToLowerCase } from "../Constants/Utilitaries"
import i18n from "../i18nextInit"

export default class RecoSisley {
    productsMap = require('../Assets/productsMap.json')
    skuMap = require('../Assets/skuMap.json')
    // Pour le produit SIAA, sa ref change suivant la local. (https://docs.google.com/spreadsheets/d/14uo-9VClPmxpUHBiQISGgqtnpYKcrQYfiKcY4L7lUX0/edit#gid=0)
    SIAA_NEW_REF_LOCALES = ["fr_FR", "en_US", "fr_BE", "nl_BE", "es_ES", "de_DE",
        "de_AT", "pl_PL", "cs_CZ", "it_IT", "nl_NL", "fr_CH", "de_CH",
        "da_DK", "pt_PT", "en_CA", "fr_CA"]

    recoFile: any
    recoFileAsia: any
    recoFileGB: any
    recoFileFR: any
    recoFileUS: any

    constructor() {
        this.recoFile = require('../Assets/recoFile.json')
        this.recoFileAsia = require('../Assets/recoFileAsia.json')
        this.recoFileFR = require('../Assets/recoFileFR.json')
        this.recoFileGB = require('../Assets/recoFileGB.json')
        this.recoFileUS = require('../Assets/recoFileUS.json')
    }

    getReco(answers: Array<any>, OTSTCAnalysis: Array<any>, dispatch: any, lang?: string) {
        let packFromReco: Array<any> = []
        let demaquiller1Tab: Array<any> = []
        let cibler2Tab: Array<any> = []
        let traiterContour3Tab: Array<any> = []
        let traiterVisage4Tab: Array<any> = []
        let proteger5Tab: Array<any> = []
        let parfaire6Tab: Array<any> = []
        let makeup7Tab: Array<any> = []
        let masque7Tab: Array<any> = []

        let recoToUse: any

        switch (lang) {
            case "en_US":
                recoToUse = this.recoFileUS
                break;
            case "en_GB":
                recoToUse = this.recoFileGB
                break;
            case "fr_FR":
                recoToUse = this.recoFileFR
                break;
            default:
                recoToUse = this.recoFileFR
                break;
        }

        for (const [questionName, pack] of Object.entries(recoToUse.genre[answers[0]].typePeau[answers[4]])) {
            for (const [packName, conditions] of Object.entries(pack as Object)) {
                let numberOfConditions: number = Object.keys(conditions).length
                let conditionsFilled: number = 0

                if (conditions.reponses && !conditions.order) {
                    for (let answer of answers) {
                        if (conditions.reponses.includes(answer)) {
                            //console.log(answer + " is included in " + packName)
                            conditionsFilled++
                            break
                        }
                    }
                }

                if (conditions.order && conditions.reponses) {
                    for (let answer of answers) {
                        // POur checker l'ordre on check la position de la reponse. Ca aurait été mieux de checker l'ordre dans un tableau (relatif)
                        if (conditions.reponses.includes(answer) && conditions.order === 1) {
                            if (answers.indexOf(answer) === 1) {
                                conditionsFilled += 2
                                break
                            }
                        }

                        if (conditions.reponses.includes(answer) && conditions.order === 2) {
                            if (answers.indexOf(answer) === 2) {
                                conditionsFilled += 2
                                break
                            }
                        }
                    }
                }

                if (conditions.orderBis && conditions.reponsesBis) {
                    for (let answer of answers) {
                        // POur checker l'ordre on check la position de la reponse. Ca aurait été mieux de checker l'ordre dans un tableau (relatif)
                        if (conditions.reponsesBis.includes(answer) && conditions.orderBis === 1) {
                            if (answers.indexOf(answer) === 1) {
                                conditionsFilled += 2
                                break
                            }
                        }

                        if (conditions.reponsesBis.includes(answer) && conditions.orderBis === 2) {
                            if (answers.indexOf(answer) === 2) {
                                conditionsFilled += 2
                                break
                            }
                        }
                    }
                }

                if (conditions.reponsesExclue) {
                    let flag = false
                    for (let answer of answers) {
                        if (conditions.reponsesExclue.includes(answer)) {
                            //console.log(answer + " is included in " + packName)
                            flag = true
                            break
                        }
                    }
                    if (!flag) {
                        conditionsFilled++
                    }
                }

                if (conditions.reponsesBis && !conditions.orderBis) {
                    for (let answer of answers) {
                        if (conditions.reponsesBis.includes(answer)) {
                            conditionsFilled++
                            break
                        }
                    }
                }

                if (conditions.reponsesTierces) {
                    for (let answer of answers) {
                        if (conditions.reponsesBis.includes(answer)) {
                            conditionsFilled++
                            break
                        }
                    }
                }

                if (conditions.analyses) {
                    for (let [analyse, score] of Object.entries(conditions.analyses as Object)) {
                        let currentAnalyseFromOTSTC = OTSTCAnalysis.find(e => castConcernNameToLowerCase(e.name) === analyse)
                        if (currentAnalyseFromOTSTC) {
                            if (currentAnalyseFromOTSTC.score > score[0] && currentAnalyseFromOTSTC.score < score[1]) {
                                //console.log(analyse + " is included in " + packName)
                                conditionsFilled++
                                break
                            }
                        } else {
                            //console.warn("probleme avec l'analyse: " + analyse)
                        }

                    }
                }


                if (numberOfConditions === conditionsFilled) {
                    switch (questionName) {
                        case 'preferenceDemaquillage':
                            demaquiller1Tab.push(packName)
                            break
                        case 'contourYeux':
                            traiterContour3Tab.push(packName)
                            break
                        case 'proteger':
                            proteger5Tab.push(packName)
                            break
                        case 'principale':
                            traiterVisage4Tab.push(packName)
                            break
                        case 'serum':
                            cibler2Tab.push(packName)
                            break
                        case 'everyCaseMakeUp':
                            makeup7Tab.push(packName)
                            break
                        case 'preferenceTeint':
                            makeup7Tab.push(packName)
                            break
                        case 'soinSpecifique':
                            parfaire6Tab.push(packName)
                            break
                        case 'masque':
                            masque7Tab.push(packName)
                            break
                        default:
                        //parfaire6Tab.push(packName)

                    }
                    //}

                    //packFromReco.push(packName)
                    if (questionName !== "everyCase" && questionName !== "everyCaseMakeUp" && questionName !== "preferenceTeint") {
                        break
                    }
                } else {
                    // console.log("not all the conditions aren't complete for " + packName + ". conditions: " + conditionsFilled + '/ ' + numberOfConditions)
                }

            }
        }

        let sortedAnalysis: Array<any> = [...OTSTCAnalysis].sort((a: any, b: any) => {
            return b.score - a.score
        })

        // Gère les comparaisons de score pour CONTOUR YEUX.
        if (answers.includes(Answer.CYridules) || answers.includes(Answer.CYpoche) || answers.includes(Answer.CYcernes)) {

            let eyesAnalysis: Array<any> = [...sortedAnalysis.filter((e: any) => e.name === "DARK_CIRCLES" || e.name === "LOSS_OF_FIRMNESS" || e.name === "WRINKLES_UNDER_EYE" || e.name === "EYES_BAGS")]

            if ((eyesAnalysis[0].name === "WRINKLES_UNDER_EYE" || eyesAnalysis[0].name === "LOSS_OF_FIRMNESS")) {
                if (answers.includes(Answer.SDNoui)) {
                    traiterContour3Tab.push("CY1")
                } else {
                    traiterContour3Tab.push("CY2")
                }
            } else {
                traiterContour3Tab.push("CY3")
            }
        }
        console.warn(demaquiller1Tab)
        console.warn(traiterContour3Tab)
        console.warn(cibler2Tab)
        console.warn(traiterVisage4Tab)
        console.warn(proteger5Tab)
        console.warn(parfaire6Tab)
        console.warn(masque7Tab)

        packFromReco.push(demaquiller1Tab)
        packFromReco.push(traiterContour3Tab)
        packFromReco.push(cibler2Tab)
        packFromReco.push(traiterVisage4Tab)
        packFromReco.push(proteger5Tab)
        packFromReco.push(parfaire6Tab)
        packFromReco.push(masque7Tab)
        //packFromReco.push(makeup7Tab)

        let tabSkus: Array<any> = [[], [], [], [], [], [], []]

        for (let i = 0; i < packFromReco.length; i++) {
            packFromReco[i].forEach((pack: any) => {

                if (this.productsMap[pack].length === 1) {
                    tabSkus[i].push(this.productsMap[pack][0])
                }
                else {
                    this.productsMap[pack].forEach((sku: string) => {
                        tabSkus[i].push(sku)
                    });
                }
            });
        }

        tabSkus.forEach((reco: string[]) => {
            if (reco.length) {
                for (let i = 0; i < reco.length; i++) {
                    if (reco[i] === "151011" && this.SIAA_NEW_REF_LOCALES.includes(i18n.language)) {
                        reco[i] = "151012"
                        return
                    }
                }
            }
        })

        console.warn(tabSkus)
        dispatch({ type: ActionTypes.SET_RECO, value: tabSkus })

        setTimeout(() => {
            dispatch({ type: ActionTypes.SET_RECO, value: tabSkus })
        }, 1000);
    }

    getAsiaReco(answers: Array<any>, OTSTCAnalysis: Array<any>, dispatch: any) {
        let packFromReco: Array<any> = []

        let preparatoryLotion: Array<any> = []
        let eyeCare: Array<any> = []
        let serum: Array<any> = []
        let prepTreatment: Array<any> = []
        let dayNightCare: Array<any> = []
        let nightCare: Array<any> = []
        let protectiveShield: Array<any> = []
        let corrector: Array<any> = []
        let antiAging: Array<any> = []
        let mask: Array<any> = []

        // console.log("[RECO] QUESTIONS:")
        //console.log(answers)

        for (const [questionName, pack] of Object.entries(this.recoFileAsia.genre[answers[0]].typePeau[answers[4]])) {
            for (const [packName, conditions] of Object.entries(pack as Object)) {
                let numberOfConditions: number = Object.keys(conditions).length
                let conditionsFilled: number = 0

                if (conditions.reponses && !conditions.order) {
                    for (let answer of answers) {
                        if (conditions.reponses.includes(answer)) {
                            //console.log(answer + " is included in " + packName)
                            conditionsFilled++
                            break
                        }
                    }
                }

                if (conditions.order && conditions.reponses) {
                    for (let answer of answers) {
                        // POur checker l'ordre on check la position de la reponse. Ca aurait été mieux de checker l'ordre dans un tableau (relatif)
                        if (conditions.reponses.includes(answer) && conditions.order === 1) {
                            if (answers.indexOf(answer) === 1) {
                                conditionsFilled += 2
                                break
                            }
                        }

                        if (conditions.reponses.includes(answer) && conditions.order === 2) {
                            if (answers.indexOf(answer) === 2) {
                                conditionsFilled += 2
                                break
                            }
                        }
                    }
                }

                if (conditions.orderBis && conditions.reponsesBis) {
                    for (let answer of answers) {
                        // POur checker l'ordre on check la position de la reponse. Ca aurait été mieux de checker l'ordre dans un tableau (relatif)
                        if (conditions.reponsesBis.includes(answer) && conditions.orderBis === 1) {
                            if (answers.indexOf(answer) === 1) {
                                conditionsFilled += 2
                                break
                            }
                        }

                        if (conditions.reponsesBis.includes(answer) && conditions.orderBis === 2) {
                            if (answers.indexOf(answer) === 2) {
                                conditionsFilled += 2
                                break
                            }
                        }
                    }
                }

                if (conditions.reponsesExclue) {
                    let flag = false
                    for (let answer of answers) {
                        if (conditions.reponsesExclue.includes(answer)) {
                            //console.log(answer + " is included in " + packName)
                            flag = true
                            break
                        }
                    }
                    if (!flag) {
                        conditionsFilled++
                    }
                }

                if (conditions.reponsesBis && !conditions.orderBis) {
                    for (let answer of answers) {
                        if (conditions.reponsesBis.includes(answer)) {
                            conditionsFilled++
                            break
                        }
                    }
                }

                if (conditions.reponsesTierces) {
                    for (let answer of answers) {
                        if (conditions.reponsesTierces.includes(answer)) {
                            conditionsFilled++
                            break
                        }
                    }
                }

                if (conditions.analyses) {
                    for (let [analyse, score] of Object.entries(conditions.analyses as Object)) {
                        let currentAnalyseFromOTSTC = OTSTCAnalysis.find(e => castConcernNameToLowerCase(e.name) === analyse)
                        if (currentAnalyseFromOTSTC) {
                            if (currentAnalyseFromOTSTC.score > score[0] && currentAnalyseFromOTSTC.score < score[1]) {
                                //console.log(analyse + " is included in " + packName)
                                conditionsFilled++
                                break
                            }
                        } else {
                            //console.warn("probleme avec l'analyse: " + analyse)
                        }

                    }
                }


                if (numberOfConditions === conditionsFilled) {
                    switch (questionName) {
                        case 'preparatoryLotion':
                            preparatoryLotion.push(packName)
                            break
                        case 'eyeCare':
                            eyeCare.push(packName)
                            break
                        case 'serum':
                            serum.push(packName)
                            break
                        case 'prepTreatment':
                            prepTreatment.push(packName)
                            break
                        case 'dayNightCare':
                            dayNightCare.push(packName)
                            break
                        case 'protectiveShield':
                            protectiveShield.push(packName)
                            break
                        case 'corrector':
                            corrector.push(packName)
                            break
                        case 'antiAging':
                            antiAging.push(packName)
                            break
                        case 'soinDeNuit':
                            console.warn(numberOfConditions)
                            console.warn(conditionsFilled)
                            nightCare.push(packName)
                            break
                        case 'mask':
                            mask.push(packName)
                            break
                        default:
                        //parfaire6Tab.push(packName)

                    }
                    //}

                    //packFromReco.push(packName)
                    if (questionName !== "everyCase" && questionName !== "everyCaseMakeUp" && questionName !== "preferenceTeint") {
                        break
                    }
                } else {
                    // console.log("not all the conditions aren't complete for " + packName + ". conditions: " + conditionsFilled + '/ ' + numberOfConditions)
                }

            }
        }

        let sortedAnalyse = [...OTSTCAnalysis].sort((a: any, b: any) => {
            return b.score - a.score
        })

        let eyesAnalysis: Array<any> = [...sortedAnalyse.filter((e: any) => e.name === "DARK_CIRCLES" || e.name === "LOSS_OF_FIRMNESS" || e.name === "WRINKLES_UNDER_EYE" || e.name === "EYES_BAGS")]

        if (answers.includes(Answer.CYcernes) || answers.includes(Answer.CYpoche) || answers.includes(Answer.CYridules)) {
            if (eyesAnalysis[0].name === "WRINKLES_UNDER_EYES" || eyesAnalysis[0].name === "LOSS_OF_FIRMNESS") {
                eyeCare.push("EC2")
            } else {
                eyeCare.push("EC3")
            }
        }


        if (!dayNightCare.includes("DNC") && !dayNightCare.includes("DNC2")) {
            protectiveShield.push("ADAY")
        }

        packFromReco.push(preparatoryLotion)
        packFromReco.push(eyeCare)
        packFromReco.push(serum)
        packFromReco.push(prepTreatment)
        packFromReco.push(dayNightCare)
        packFromReco.push(protectiveShield)
        packFromReco.push(corrector)
        packFromReco.push(antiAging)
        packFromReco.push(nightCare)
        packFromReco.push(mask)

        let tabSkus: Array<any> = [[], [], [], [], [], [], [], [], [], []]

        for (let i = 0; i < packFromReco.length; i++) {

            packFromReco[i].forEach((pack: any) => {
                if (this.productsMap[pack].length === 1) {
                    tabSkus[i].push(this.productsMap[pack][0])
                }
                else {
                    this.productsMap[pack].forEach((sku: string) => {
                        tabSkus[i].push(sku)
                    });
                }
            });
        }
        //console.log("[RECO] SKU RECO TAB:") // OK pour ce qu'on envoie
        //console.log(tabSkus)
        //tabSkus.push(["113000"])
        console.warn(tabSkus)
        dispatch({ type: ActionTypes.SET_RECO, value: tabSkus })
    }

    createProducts(productSkus: Array<any>, dispatch: Function) {
        console.warn(productSkus)

        productSkus.forEach(skus => {
            if (skus.length === 0) {
                dispatch({ type: ActionTypes.UPDATE_ONE_PRODUCT, value: { sku: "NOTHING IN CATEGORY" } })
            }
            skus.forEach((sku: any) => {
                dispatch({ type: ActionTypes.UPDATE_ONE_PRODUCT, value: { sku: sku, name: this.skuMap[sku] + "[Not from API]" } })
            });
        });
    }
}