// --- OTSTC Primitive Type (not used) -----
interface CBSKCategory {
    category: string
    analyses: Array<CBSKAnalyse>
    url:string
}
// interface CBSKDiagnosis {
//     name: string
//     result: number
// }
interface CBSKAnalyse {
    analysis: string
    results: Array<CBSKResult>
    url?:string
}
interface CBSKResult {
    name: string
    score: number
}

//--- Simplified Analyse ---
export interface Analyse {
    name: string
    score: number
}

function parseCategory(category:CBSKCategory):[Array<Analyse>,number]{
    var analysisArray = Array<Analyse>()
    var sumScores = 0 
    category.analyses.forEach(e => {
        let score = e.results[0].score
        sumScores += score
        analysisArray.push({
            name: e.analysis,
            score:score
        })
    });
    return [analysisArray,sumScores/analysisArray.length]
}

export function parseAnalyse(json:any): Array<Analyse>{
    var analysisArray = Array<Analyse>()
    
    let otstc_categories = json['cbsk']['categories'] as Array<CBSKCategory>
    otstc_categories.forEach(category => {
        let subs = parseCategory(category)
        let analyseCateg :Analyse = {
            name: category.category,
            score: subs[1],
        }
        analysisArray.push(analyseCateg)
        analysisArray.push(...subs[0])
    })

    // let otstc_diagnoses = json['cbsk']['diagnose']['diagnoses'] as Array<CBSKDiagnosis>
    
    // otstc_diagnoses.forEach(diagnosis => {
    //     let analysis :Analyse = {
    //         name: diagnosis.name === "HYDRATION" ? "HYDRATATION" : diagnosis.name,
    //         score: diagnosis.result
    //     }
    //     if(analysis.name === "HYDRATATION"){
    //         analysis.score = analysis.score * 0.2
    //     }
    //     console.log(analysis.name + analysis.score)
    //     analysisArray.push(analysis)
    // });

    console.log(analysisArray)

    return analysisArray
}