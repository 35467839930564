import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { sendAnalytics, sendMailtoSTAR } from '../Constants/Utilitaries'
import i18n from '../i18nextInit'
import { InputButton, MainButton } from '../Styles/Buttons'
import { OptInContainer } from '../Styles/Containers'
import { BlackCurtain } from '../Styles/Images'
import { H4, H1 } from '../Styles/Texts'
import { MySkinDiagState } from '../type'
import * as Actiontypes from '../Constants/ActionsTypes'
import { PanelContainer } from './HelpSkinView'

const OptInView = (props: any) => {

    const [name, setName] = useState<string>('')
    const [firstName, setFirstName] = useState<string>('')
    const [mail, setMail] = useState<string>('')
    const [phone, setPhone] = useState<string>("")
    const [closed, setClosed] = useState<boolean>(true)
    const analyses = useSelector((state: MySkinDiagState) => state.session.analyses)
    const questions = useSelector((state: MySkinDiagState) => state.session.questions)
    const products = useSelector((state: MySkinDiagState) => state.session.products)
    const [sendStatus, setSendStatus] = useState<number>(0)
    const dispatch = useDispatch()
    const [disabled, setDisabled] = useState<boolean>(false)

    const { t } = useTranslation()

    const handleSubmit = (evt: any) => {
        evt.preventDefault();
        sendAnalytics("finish_email")
        setDisabled(true)
        sendMailtoSTAR(analyses, questions, products, firstName, name, mail, i18n.language, phone, setSendStatus, sendStatus)
    }

    useEffect(() => {
        setClosed(false)
    }, [])

    const close = () => {

        let data = {
            messageType: "toggle_fullscreen",
            value: false
        }
        console.log(data)
        window.parent.postMessage(data, "*")

        setClosed(true)
        if (document.getElementById("BLACK_CURTAIN")) {
            document.getElementById("BLACK_CURTAIN")!.style.opacity = "0"
        }
        if (window.innerWidth > 1224) {
            props.setOptIn(false)
        } else {
            setTimeout(() => {
                props.setOptIn(false)
            }, 400);
        }
    }

    useEffect(() => {
        if (sendStatus === 1 || sendStatus === 2) {
            dispatch({ type: Actiontypes.SET_SIGNED_IN, value: true })
            setTimeout(() => {
                close()
            }, 3000);
        }
        //eslint-disable-next-line
    }, [sendStatus])



    return (
        <PanelContainer>
            <BlackCurtain onClick={() => close()} id="BLACK_CURTAIN" isForDetails={false} isActive={true} />

            <OptInContainer isHelp={false} isClosed={closed}>
                <H4 style={{ font: 'normal normal 300 13px/20px Helvetica light', marginTop: '0px', marginBottom: "0px", padding: "0px" }}>{t('optIn.title', 'Pour recevoir vos résultats par email et bénéficier d\'un programme d\'accompagnement personnalisé, veuillez saisir vos coordonnées.')}</H4>
                <H1 onClick={() => window.open(t("onboardView.href1"), "_blank")} style={{ textDecoration: "underline", marginBottom: "24px", lineHeight: "18px" }}>{t('optIn.politic', 'Voir notre politique de données personnelles.')}</H1>
                <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: "100%", position: "relative" }}>
                    <InputButton
                        required
                        onInvalid={(e:any) => e.target.setCustomValidity(t("optIn.emptySlots", "Veuillez saisir les champs vides."))}
                        onInput={(e: any) => e.target.setCustomValidity('')}
                        placeholder={t('optIn.name', 'Prénom')}
                        type="text"
                        value={firstName}
                        onChange={e => setFirstName(e.target.value)} ></InputButton>
                    <InputButton
                        required
                        type="text"
                        onInvalid={(e:any) => e.target.setCustomValidity(t("optIn.emptySlots", "Veuillez saisir les champs vides."))}
                        onInput={(e: any) => e.target.setCustomValidity('')}
                        value={name}
                        onChange={e => setName(e.target.value)}
                        placeholder={t('optIn.lastName', 'Nom')}></InputButton>
                    <InputButton
                        required
                        placeholder={t('optIn.email', 'E-mail')}
                        type="text"
                        pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
                        value={mail}
                        onChange={e => setMail(e.target.value)}
                        onInvalid={(e:any) => e.target.setCustomValidity(t("optIn.mailError", "Veuillez saisir une adresse e-mail valide."))}
                        onInput={(e: any) => e.target.setCustomValidity('')}>
                    </InputButton>

                    {i18n.language === "tw_TW" && <InputButton
                        required
                        placeholder={t('optIn.phone', 'Phone number')}
                        onInvalid={(e:any) => e.target.setCustomValidity(t("optIn.emptySlots", "Veuillez saisir les champs vides."))}
                        onInput={(e: any) => e.target.setCustomValidity('')}
                        type="tel"
                        value={phone}
                        onChange={e => setPhone(e.target.value)} >
                    </InputButton>}

                    {sendStatus === 1 && <H1 style={{ lineHeight: "16px" }}>{t('optIn.confirmation', 'Vous recevrez un mail prochainement.')}</H1>}
                    {sendStatus === 2 && <H1 style={{ lineHeight: "16px" }}>{t('optIn.confirmation', 'Vous recevrez un mail prochainement.')}</H1>}
                    {sendStatus === 3 && <H1 style={{ color: "red", lineHeight: "16px" }}>{t('optIn.problem', 'Il y a eu un problème. Veuillez réessayer.')}</H1>}
                    <MainButton disabled={disabled} type="submit" style={{ fontSize: '12px', textTransform: 'uppercase', width: '100%', margin: '24px 0px 12px' }}>{t("results.getByMail")}</MainButton>

                    <H1 onClick={() => close()} style={{cursor: "pointer", textDecoration: 'underline', marginBottom: "24px" }}>{t('optIn.continue', 'Continuer, asns recevoir mes résultats')}</H1>
                </form>
            </OptInContainer>
        </PanelContainer>)
}

export default OptInView