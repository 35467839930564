import { useTranslation } from "react-i18next"
import styled from "styled-components"

import { isDesktop, isTablet } from "react-device-detect"
import { MainButton } from "../../Styles/Buttons"
import { useEffect, useRef, useState } from "react"
import reactStringReplace from "react-string-replace"
import i18n from "../../i18nextInit"
import { useDispatch } from "react-redux"
import * as ActionTypes from '../../Constants/ActionsTypes'
import { getPlayGroundMode } from "./TryOnIndex"
import { sendAnalytics } from "../../Constants/Utilitaries"
import { ParagraphContainer } from "../../Styles/Containers"

interface onBoardingProps {
    displayOnBoard: Function
    setAuthorized: Function
}

const VTOonboarding = (props: onBoardingProps) => {
    const { t } = useTranslation()
    const containerRef = useRef<HTMLDivElement>(null)
    const [onBoardTexts, setOnboardTexts] = useState<any>()
    const isTextExpend = useRef<boolean>(false)
    const dispatch = useDispatch()

    const close = () => {
        if (isDesktop && !isTablet) {
            props.displayOnBoard(false)
            dispatch({ type: ActionTypes.SET_ONBOARD_DISPLAYED, value: false })

        } else {
            containerRef.current!.style.bottom = "-100%"
            setTimeout(() => {
                props.displayOnBoard(false)
            }, 700);


            if (getPlayGroundMode()) {
                sendAnalytics("legal_notice_ok", "vto_playground")
            } else {
                sendAnalytics("legal_notice_ok", "vto")
            }
        }

        props.setAuthorized(true)
    }

    useEffect(() => {
        setOnboardTexts(<PrivateNavigationText>
            {reactStringReplace(t('tryOn.privacyTextsShort', ""), t("tryOn.textToLink1"), (match, i): any => {
                return <span key={10} onClick={() => window.open(t("tryOn.href1"), "_blank")} style={{ textDecoration: "underline", cursor: "pointer" }}>{t("tryOn.textToLink1")}</span>
            })}
        </PrivateNavigationText>)

        let timeOut = setTimeout(() => {
            containerRef.current!.style.bottom = "0%"
        }, 2000);

        return () => window.clearTimeout(timeOut)
    }, [t])

    const expandText = () => {
        isTextExpend.current = true
        // eslint-disable-next-line
        // eslint-disable-next-line
        let warningsTab: Array<any> = []
        let key = 0
        for (let i = 1; i < 7; i++) {
            if (i18n.exists('tryOn.privacyTextsLong' + i)) {
                warningsTab.push(<ParagraphContainer key={key + i}>
                    <PrivateNavigationText>
                        {reactStringReplace(t('tryOn.privacyTextsLong' + i, ""), t("tryOn.textToLink1"), (match, i): any => {
                            return <span key={10} onClick={() => window.open(t("tryOn.href1"), "_blank")} style={{ textDecoration: "underline", cursor: "pointer" }}>{t("tryOn.textToLink1")}</span>
                        })}
                    </PrivateNavigationText>
                </ParagraphContainer>)
            }
        }

        setOnboardTexts(warningsTab)
    }

    return (
        <OnboardContainer ref={containerRef} id="VTOonboard">

            <OnBoardPrivacyTitle>
                {t("tryOn.title", "Privacy Notice")}
            </OnBoardPrivacyTitle>

            <OnBoardPrivacyContainer>
                {onBoardTexts}
            </OnBoardPrivacyContainer>

            {isTextExpend.current !== true && <ReadMoreText onClick={() => expandText()}>
                {t("tryOn.readMore", "En savoir plus.")}
            </ReadMoreText>}

            <OnBoardButtonContainer>
                <OnBoardButton onClick={() => close()}>
                    {t("tryOn.understand", "J'ai compris")}
                </OnBoardButton>
            </OnBoardButtonContainer>

        </OnboardContainer>
    )
}

const OnBoardPrivacyContainer = styled.div`
    overflow: auto;
    max-height: 60%;

    @media (orientation: landscape){
        width: 80%;
    }
`

const OnboardContainer = styled.div`
    width: 100%;
    height: initial;
    bottom: -100%;
    display: flex;
    position: fixed;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: white;
    z-index: 100;
    max-height: 80%;
    overflow: auto;
    left: 0px;

    @media (orientation: portrait){
        border-top-left-radius: 24px;
        border-top-right-radius: 24px;
        padding: 16px;
        box-sizing: border-box;
        transition: bottom 0.7s;
        margin: 0px;
        min-height: 200px;
    }

    @media (orientation: landscape){
        width: 100vw;
        min-height: 100vh;        
        position: absolute;
        bottom: 0;
    }
`
const OnBoardPrivacyTitle = styled.p`
    font: normal normal 500 20px/24px Playfair;
    font-size: ${i18n.language === "tw_TW" ? "22px" : ""};
    color: black;
    width: 80%;

    @media (orientation: portrait){
        margin: 0px;
        margin: 24px 0px 12px;
        width: 100%;
    }
`
const PrivateNavigationText = styled.p`
    font: normal normal 500 13px/21px Helvetica light;
    font-size: ${i18n.language === "tw_TW" ? "14px" : ""};
    color: black;
    text-align: start;

    @media (orientation: portrait){
    }
`

const OnBoardButton = styled(MainButton)`
    letter-spacing: 1px;
    font: normal normal 500 12px/15px Helvetica Bold;
    font-size: ${i18n.language === "tw_TW" ? "14px" : ""};
    padding: 12px 24px;
    width: 100%;
    text-transform: uppercase;
    margin-top: 16px;
    margin-bottom: 8px;

    @media (orientation: landscape){
        width: initial;
    }

    &:hover {
        border: solid 1px #CACACA;
        background-color: white;
        color: black;
    }
`

const OnBoardButtonContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (orientation: landscape){
        width: 80%;
        justify-content: left;
    }
`

const ReadMoreText = styled.p`
    font: normal normal 500 12px/23px Helvetica Bold;
    font-size: ${i18n.language === "tw_TW" ? "13px" : ""};
    text-decoration: underline;
    margin: 8px 0px;
    width: 100%;
    cursor: pointer;

    @media (orientation: landscape){
        width: 80%;
    }
`
export default VTOonboarding