import { useTranslation } from 'react-i18next'
import { CSSTransition } from 'react-transition-group'

import { ProductContainer, ProductDetailBuyContainer, ProductDetailTextContainer, TagElement } from '../../Styles/Containers'
import { H2, H1, ProductDetailText } from '../../Styles/Texts'

import ProductImage from '../../Assets/Images Produits/DefaultImage.svg'
import { useEffect, useState } from 'react'
import { BlackCurtain } from '../../Styles/Images'

import { AddToCartButton, CloseButtonProduct } from '../../Styles/Buttons'
import { useDispatch, useSelector } from 'react-redux'
import * as ActionsTypes from '../../Constants/ActionsTypes'

import closeIcon from '../../Assets/Icons/Close_W.svg'
import { addToCartProduct } from '../../API/SalesForceAPI'
import { MySkinDiagState } from '../../type'
import { LookSize } from '../../Styles/VTO/VTOTexts'
import { setTagTranslations, Tag, Tags } from '../../Constants/Tags'
import { disableScroll, enableScroll, handleFormatPrice, runHeightMachine, sendAnalytics } from '../../Constants/Utilitaries'
import i18n from '../../i18nextInit'

interface ProductDetailProps {
    savedYPos: number,
    closePopUp: Function,
    product: any
}

const ProductDetailView = (props: ProductDetailProps) => {
    const { t } = useTranslation()
    const [inProp, setInProp] = useState<boolean>(false)
    const [isCurtainActive, setCurtainActive] = useState<boolean>(false)
    const [productImage, setProductImage] = useState<string>(ProductImage)
    const dispatch = useDispatch()
    const session = useSelector((state: MySkinDiagState) => state.session)
    const [tagComponents, setTagsComponents] = useState<any>([])

    const closePopUp = (e: any) => {
        setInProp(false)

        enableScroll()

        setCurtainActive(false)
        props.closePopUp(e)
        //window.scrollTo(0, props.savedYPos)

        var data = {
            messageType: "toggle_fullscreen",
            value: false
        };
        console.log("[PRODUCTS DETAILS VIEW] disable full screen !")
        window.parent.postMessage(data, "*")

        setTimeout(() => {
            let data = {
                messageType: "scroll_to",
                value: props.savedYPos
            }
            window.parent.postMessage(data, "*")

            document.getElementById("APP")!.style.position = "relative"
            document.getElementById("APP")!.style.overflow = ""
            document.getElementById("APP")!.style.height = ""
            document.getElementById("APP")!.style.top = ""

            runHeightMachine()
        }, 10);
    }

    useEffect(() => {
        if (props.product.imageURL) {
            setProductImage(props.product.imageURL)
        } else {
            setProductImage(ProductImage)
        }
    }, [props.product.imageURL])

    // Gestion de l'UI des Tags
    useEffect(() => {

        let tagFile = require('../../Assets/tags.json')
        let tags = new Tags(props.product.sku, tagFile, session.questions)
        let array: Array<any> = []
        let key: number = 0
        tags.tags.forEach((tag: Tag) => {
            array.push(<TagElement color={tag.color} key={key}>{setTagTranslations(tag.code, t)}</TagElement>)
            key++
        })
        setTagsComponents(array)
        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        disableScroll()

        setCurtainActive(true)
        setInProp(true)

        var data = {
            messageType: "toggle_fullscreen",
            value: true
        };
        console.log("[PRODUCTS DETAILS VIEW] enable full screen !")
        window.parent.postMessage(data, "*")
        setTimeout(() => {
            document.getElementById("APP")!.style.position = "fixed"
            document.getElementById("APP")!.style.overflow = "visible"
            document.getElementById("APP")!.style.height = "100%"
            document.getElementById("APP")!.style.top = -props.savedYPos + "px"
            window.scrollTo(0, props.savedYPos)

        }, 10);

    }, [props.product, props.savedYPos])

    const priceHandler = (product: any, isOldPrice: boolean = false) => {

        if (!product) {
            return "No product"
        }

        if (!product.stock) {
            if (product.stock !== 0)
                return "[API MISSING] No stock"
        }

        if (!product.price) {
            return "[API MISSING] No price"
        }

        if (product.stock > 0) {
            if (product.promoPrice && !isOldPrice) {
                return handleFormatPrice(product.promoPrice)
            }
            return handleFormatPrice(product.price)

        } else {
            return t("tryOn.outOfStock")

        }
    }

    const addToCartTexthandler = (product: any) => {
        if (!product || !product.stock || !product.price) {
            return t("tryOn.outOfStock")
        } else {

        }

        if (product.stock > 0) {
            return t('results.AddToCart', 'Ajouter au panier')
        }
    }

    const addToCart = (e: any) => {
        addToCartProduct(props.product.sku, session.clientData)
        sendAnalytics("addtocart1")
        dispatch({ type: ActionsTypes.UPDATE_PANIER, value: props.product.sku })
    }

    const handleStock = (): number => {
        if (session.panier.filter((e: string) => e === props.product.sku).length >= 4) {
            return 0
        }
        return props.product.stock
    }

    return (
        <>
            <CloseButtonProduct src={closeIcon} isActive={isCurtainActive} onClick={(e) => closePopUp(e)}>
            </CloseButtonProduct>
            <CSSTransition addEndListener={() => null} classNames={window.innerWidth < 1224 ? "swipeToLeftResultsMob" : "swipeToLeftResults"} in={inProp}>

                <ProductContainer isDetail={true} id="PRODUCT_DETAIL">
                    <img style={{ width: '100%' }} onError={() => setProductImage(ProductImage)} src={productImage} alt={'product'} />

                    <ProductDetailTextContainer>
                        <div style={{ width: '100%', marginTop: window.innerWidth < 1224 ? "24px" : "", display: 'flex', alignItems: 'center', paddingLeft: "12px", boxSizing: "border-box" }}>
                            {tagComponents}
                        </div>
                        <H2 style={{
                            font: 'normal normal normal 20px/28px Playfair',
                            textAlign: 'start'
                        }}>
                            {props.product ? props.product.name : ''}
                        </H2>
                        <H1 style={{
                            font: 'normal normal 300 13px/21px Helvetica',
                            fontSize: i18n.language === "tw_TW" ? "14px" : "",
                            textAlign: 'start',
                            padding: '12px 12px'
                        }} dangerouslySetInnerHTML={{ __html: props.product ? props.product.introduction ? props.product.introduction : "[API INTRO MISSING]" : "No INTRO" }}>
                        </H1>

                        <div style={{ display: "flex" }}>
                            {props.product.promoPrice && (props.product.promoPrice !== props.product.price) && <LookSize style={{ width: "auto", fontWeight: 900, textDecoration: "line-through", color: "#CACACA", paddingLeft: "12px" }}>
                                {priceHandler(props.product, true)}
                            </LookSize>}
                            {<LookSize style={{ width: "auto", fontWeight: 900, paddingLeft: "12px" }}>
                                {priceHandler(props.product)}
                            </LookSize>}
                        </div>

                        <H1 style={{
                            font: "normal normal 500 18px/21px Playfair",
                            textAlign: "left",
                            padding: "24px 12px 8px"
                        }}>
                            {t("results.benefits")}
                        </H1>

                        <H1 style={{
                            font: 'normal normal 300 13px/21px Helvetica',
                            fontSize: i18n.language === "tw_TW" ? "14px" : "",
                            textAlign: 'start',
                            padding: '4px 12px 12px 12px'
                        }} dangerouslySetInnerHTML={{ __html: props.product ? props.product.description ? props.product.description : "[API DESC MISSING]" : "Erreur de produit" }} />

                        <H1 style={{
                            font: "normal normal 500 18px/21px Playfair",
                            textAlign: "left",
                            padding: "8px 12px 8px"
                        }}>
                            {t("results.howApply")}
                        </H1>
                        <ProductDetailText dangerouslySetInnerHTML={{ __html: props.product ? props.product.tips ? props.product.tips : '[API SHORT TIPS MISSING] LOREM IPSUM ' : 'Erreur de produit' }} />

                        <ProductDetailBuyContainer>
                            <AddToCartButton isInStock={handleStock() > 0} onClick={(e) => addToCart(e)}>{addToCartTexthandler(props.product)}</AddToCartButton>
                        </ProductDetailBuyContainer>
                    </ProductDetailTextContainer>
                </ProductContainer>
            </CSSTransition >

            {window.innerWidth > 1224 &&
                <BlackCurtain onClick={(e) => closePopUp(e)} isForDetails={true} isActive={isCurtainActive} />
            }
        </>
    )
}

export default ProductDetailView