import styled from "styled-components";
import i18n from "../../i18nextInit";
import { MainButton } from "../Buttons";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: #FCE9D5;
    width: 100vw;
    position: relative;

    @media (orientation: landscape){
        flex-direction: row;
        justify-content: center;
        height: 100vh;
    }
`

export const VideoContainer = styled.div`
    flex: 1;
    width: 100%;
    position: relative;
    max-height: 400px;

    @media (max-width: 414px){
        max-height: 180px;
    }

    @media (orientation: landscape){
        flex: 2;
        max-height: initial;
    }
`

export const Video = styled.video`
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
`

export const ContentContainer = styled.div`
    flex: 3;
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    box-sizing: border-box;
    padding: 42px 15px;

    @media (max-width: 414px){
        padding: 22px 15px;
    }

    @media (orientation: landscape){
        justify-content: center;
    }
`

export const Title = styled.p`
    font: normal normal 400 30px/38px Playfair;
    text-transform: uppercase;
    text-align: center;
    margin: 0px;
    margin-bottom: 20px;
    color: black;
`

export const Intro = styled.p`
    font: normal normal 400 16px/24px Helvetica;
    text-align: center;
    color: black;
    margin: 0px;
    margin-bottom: 20px;
    max-width: 350px;

    @media (min-width: 768px){
        margin-bottom: 30px;
    }

    @media (orientation: landscape){
        font: normal normal 400 18px/24px Helvetica;
    }
`

export const CarouselContainer = styled.div`
    position: relative;
    flex: 1;
    display: flex;
    width: 100%;
    overflow-x: auto;
    transition: all 0.3s;
    scroll-behavior: smooth;
    scrollbar-width: 0px;
    pointer-events: none;
    max-height: 210px;
    min-height: 130px;

    @media (max-width: 414px){
        max-height: 160px;
    }

    ::-webkit-scrollbar{
        width: 0px;
        height: 0px;
    }

    @media (orientation: landscape){
        max-height: 300px;
        min-height: 150px;
    }
`

export const BeginButton = styled(MainButton)`
    font: normal normal 500 12px/15px Helvetica Bold;
    font-size: ${i18n.language === "tw_TW" ? "14px" : ""};
    text-transform: uppercase;
    width: 100%;
    letter-spacing: 1px;

    @media (min-width: 768px) {
        width: initial;
        padding: 12px 42px;
    }

    @media (orientation: landscape){
        width: 350px;
    }
`

export const Item = styled.div`
    flex: 1 0 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
`

export const ItemTitle = styled.p`
    font: normal normal 400 15px/21px Playfair;
    color: black;
    text-transform: uppercase;
    text-align: center;
    z-index: 1;
    margin: 0px;
    margin-bottom: 10px;

    @media (orientation: landscape){
        font: normal normal 400 18px/24px Playfair;
    }
`

export const ItemText = styled.p`
    font: normal normal 400 13px/21px Helvetica;
    color: black;
    z-index: 1;
    margin: 0px;
    text-align: center;
    max-width: 350px;

    @media (orientation: landscape){
        font: normal normal 400 15px/22px Playfair;
    }
`

export const BigNumber = styled.p`
    position: absolute;
    font: normal normal 400 250px/20% Playfair;
    color: white;
    padding: 0px;
    top: 15%;
    margin: 0px;

    @media (max-width: 414px){
        font: normal normal 400 180px/20% Playfair;
    }

    @media (min-width: 768px){
        top: 30%;
    }

    @media (orientation: landscape){
        font-size: 300px;
    }

    @media (max-height: 550px) and (orientation: landscape){
        font-size: 170px;
        top: 20%;
    }
`

export const SisleySkinExpertContainer = styled.div<{height?: number}>`
    position: absolute;
    display: flex;
    flex-direction: column;
    bottom: 20px;
    left: ${() => window.RTL_LANGS.includes(i18n.language) ? "" : "15px"};
    right: ${() => window.RTL_LANGS.includes(i18n.language) ? "15px" : ""};
    z-index: 1;
    pointer-events: none;

    @media (orientation: rtl){
        left: 15px;
        right: initial;
    }

    @media (min-width: 768px){
        bottom: 40px;
        left: 30px;
    }

    @media (orientation: landscape){
        bottom: initial;
        left: initial;
        padding-left: 6vw;
        top: 0px;
        width: 100%;
        height: ${props => props.height ? props.height + "px" : ""};
        justify-content: flex-end;
    }
`

export const SisleyText = styled.p`
    color: white;
    font: normal normal 300 18px/22px Helvetica;
    margin: 0px;

    @media(min-width: 768px){
        font: normal normal 300 28px/34px Helvetica;
    }
`

export const SkinExpertText = styled.p`
    color: white;
    font: normal normal bold 26px/26px Helvetica Bold;
    margin: 0px;

    @media(min-width: 768px){
        font: normal normal bold 41px/50px Helvetica Bold;
    }
`

export const CarouselPointsContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0px;
    width: 100%;
    height: 45px;
    gap: 10px;
`

export const CarouselPoint = styled.div<{ selected?: boolean }>`
    height: 8px;
    width: 8px;
    border-radius: 24px;
    transition: all 0.3s;
    background-color: ${props => props.selected ? "black" : "white"};
`

export const CarouselButton = styled.button<{ height?: number, top?: number }>`
    width: 100%;
    height: ${props => props.height ? props.height + "px" : "200px"};
    opacity: 0.3;
    left: 0px;
    position: absolute;
    top: ${props => props.top ? props.top + "px" : ""};
`