import { NumberContainer, PanierProduct, PriceContainer, ProductContainer, ProductTextContainer, TagContainer, TagElement } from '../../Styles/Containers'
import { BigNumber, H2, H1, CatText, TitleProduct } from '../../Styles/Texts'

import ProductImageDefault from '../../Assets/Images Produits/DefaultImage.svg'
import ShopIcon from '../../Assets/Icons/Union 4.svg'
import { useEffect, useRef, useState } from 'react'
import ProductDetailView from './ProductDetailView'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import * as ActionsTypes from '../../Constants/ActionsTypes'
import { addToCartProduct } from '../../API/SalesForceAPI'
import { MySkinDiagState } from '../../type'
import { LookSize } from '../../Styles/VTO/VTOTexts'
import i18n from '../../i18nextInit'
import { setTagTranslations, Tag, Tags } from '../../Constants/Tags'
import { ImageCellProduct } from '../../Styles/Images'
import { handleFormatPrice, sendAnalytics } from '../../Constants/Utilitaries'

export const ProductView = (props: any) => {
    const [detailView, setDetailView] = useState<Array<any>>([])
    //eslint-disable-next-line
    const [inProp, setInProp] = useState<boolean>(false)
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const session = useSelector((state: MySkinDiagState) => state.session)
    const [tagComponents, setTagsComponents] = useState<Array<any>>([])
    const [isDesktop, setDesktop] = useState<boolean>(window.innerWidth > 1224)
    const [productImage, setProductImage] = useState<string>(ProductImageDefault)
    const [displayBuyButton, setDisplayBuyButton] = useState<boolean>(window.innerWidth < 1224)
    const htmlElement = useRef<HTMLDivElement>(null)

    const SetView = () => {
        setInProp(true)
        console.warn(htmlElement.current?.getBoundingClientRect().top)
        setDetailView([<ProductDetailView key={0} closePopUp={closePopUp} savedYPos={htmlElement.current!.getBoundingClientRect().top} product={props.product} />])
    }

    const closePopUp = (e: any) => {
        e.stopPropagation()
        setTimeout(() => {
            setDetailView([])
        }, 400);
    }

    const display = () => {
        if (detailView[0]) {
            return detailView[0]
        }
        else {
            return (<div></div>)
        }
    }

    const setCategory = (key: number): string => {
        if (window.ASIAN_LANGS.includes(i18n.language)) { //changer en tw
            switch (key) {
                case 1:
                    return t('results.category.preparatoryLotion', 'Preparatory lotion')
                case 2:
                    return t('results.category.eyeCare', 'Eye care')
                case 3:
                    return t('results.category.serum', 'Serum')
                case 4:
                    return t('results.category.prepTreatment', 'Prep treatment')
                case 5:
                    return t('results.category.dailyCare', 'Daily care')
                case 6:
                    return t('results.category.bouclier', "Protective shield")
                case 7:
                    return t('results.category.corrector', "Corrector")
                case 8:
                    return t('results.category.antiAging', "Anti-aging")
                case 9:
                    return t('results.category.nightCare', "Night care")
                case 10:
                    return t('results.category.mask', "Masque")
                default:
                    return "extras";
            }
        } else {
            switch (key) {
                case 1:
                    return t('results.category.demaquillant', 'Démaquillant / Nettoyant')
                case 2:
                    return t('results.category.contourYeux', 'Sérum')
                case 3:
                    return t('results.category.serum', 'Contour des yeux')
                case 4:
                    return t('results.category.soinQuotidien', 'Soin quotidien')
                case 5:
                    return t('results.category.bouclier', 'Bouclier protecteur')
                case 6:
                    return t('results.category.soinSpecifique', "Soin spécifique")
                case 7:
                    return t('results.category.masque', "Masque")
                default:
                    return "extras";
            }
        }

    }

    const addToCart = (e: any, sku: string) => {
        e.stopPropagation()
        addToCartProduct(props.product.sku, session.clientData)
        sendAnalytics("addtocart1")
        dispatch({ type: ActionsTypes.UPDATE_PANIER, value: sku })
    }

    const priceHandler = (product: any, isOldPrice: boolean = false) => {

        if (!product) {
            return "No product"
        }

        if (!product.stock) {
            if (product.stock !== 0)
                return "[API MISSING] No stock"
        }

        if (!product.price) {
            return "[API MISSING] No price"
        }

        if (product.stock > 0) {
            if (product.promoPrice && !isOldPrice) {
                return handleFormatPrice(product.promoPrice)
            } else {
                return handleFormatPrice(product.price)
            }
        } else {
            return t("tryOn.outOfStock")
        }
    }

    const handleStock = (): number => {
        if (session.panier.filter((e: string) => e === props.product.sku).length >= 4) {
            return 0
        }
        return props.product.stock
    }

    // Gestion de l'UI des Tags
    useEffect(() => {

        let tagFile = require('../../Assets/tags.json')
        let tags = new Tags(props.product.sku, tagFile, session.questions)
        let array: Array<any> = []
        let key: number = 0
        tags.tags.forEach((tag: Tag) => {
            array.push(<TagElement color={tag.color} key={key}>{setTagTranslations(tag.code, t)}</TagElement>)
            key++
        })
        setTagsComponents(array)

        const scrollHandler = () => {
            if (window.innerWidth > 1224) {
                setDesktop(true)
            } else {
                setDesktop(false)
            }
        }
        window.addEventListener('resize', scrollHandler)
        return (() => {
            window.removeEventListener("resize", scrollHandler)
        })
        //eslint-disable-next-line
    }, [])

    const handleIntroText = () => {
        if (!props.product) {
            return "[NO PRODUCT]"
        }

        if (!props.product.introduction) {
            return "[NO INTRODUCTION]"
        }

        if (props.product.introduction.length > 60) {
            return props.product.introduction.substring(0, 60) + "...<span style=\"text-decoration: underline;font-weight: 900;\">" + t('results.moreInformation', 'En savoir plus') + "</span>"

        }

        return props.product.introduction
    }

    // const handleImage = () => {
    //     if (props.product.imageURL) {
    //         fetch(props.product.imageURL)
    //             .then((response) => {
    //                 if (response.status === 200) {
    //                     return props.product.imageURL
    //                 } else {
    //                     return ProductImageDefault
    //                 }
    //             })
    //             .catch(() => { return ProductImageDefault })
    //     } else {
    //         return ProductImageDefault
    //     }
    // }

    useEffect(() => {
        if (props.product.imageURL) {
            setProductImage(props.product.imageURL)
        } else {
            setProductImage(ProductImageDefault)
        }
    }, [props.product.imageURL])

    return (<ProductContainer ref={htmlElement} onMouseOver={() => setDisplayBuyButton(true)} onMouseOut={() => setDisplayBuyButton(false)} id={props.product.sku} isDetail={false} onClick={() => SetView()}>
        <NumberContainer>
            <BigNumber>{props.shortIndex ? props.index.toLocaleString('en-us', { minimumIntegerDigits: 2 }) : props.globalKey.toLocaleString('en-us', { minimumIntegerDigits: 2 })}</BigNumber>
            {!isDesktop && <H2 style={{
                textTransform: "uppercase",
                font: 'normal normal 300 16px/28px Playfair',
                paddingTop: "4px"
            }}>{setCategory(props.shortIndex ? props.shortIndex : props.index)}</H2>}
        </NumberContainer>

        <ImageCellProduct onError={() => setProductImage(ProductImageDefault)} src={productImage} alt={'product'} />

        <ProductTextContainer>

            {isDesktop && <CatText>{setCategory(props.shortIndex ? props.shortIndex : props.index)}</CatText>}

            {!isDesktop && <TagContainer>
                {tagComponents}
            </TagContainer>}

            <TitleProduct>
                {props.product ? props.product.name : 'Erreur de nom'}
            </TitleProduct>

            <H1 style={{
                font: 'normal normal 300 13px/21px Helvetica',
                textAlign: 'start',
                padding: isDesktop ? "16px 0px" : "0px 12px",
                height: isDesktop ? "60px" : ""
            }} dangerouslySetInnerHTML={{ __html: handleIntroText() }}>

            </H1>

            {isDesktop && <TagContainer>
                {tagComponents}
            </TagContainer>}

            <PriceContainer>

                <div style={{ display: "flex", width: "100%", flexDirection: "row" }}>

                    {props.product.promoPrice && props.product.stock > 0 && <LookSize style={{ width: "auto", fontWeight: 900, textDecoration: "line-through", color: "#CACACA" }}>
                        {priceHandler(props.product, true)}
                    </LookSize>}

                    {<LookSize style={{ width: "auto", fontWeight: 900, paddingLeft: true ? "0px" : "" }}>
                        {priceHandler(props.product)}
                    </LookSize>}


                </div>

                <PanierProduct isDisplay={isDesktop ? displayBuyButton : true} stock={handleStock()} onClick={(e) => addToCart(e, props.product.sku)}>
                    +
                    <img style={{ width: '16px', paddingLeft: '8px' }} src={ShopIcon} alt={'shop icon'} />
                </PanierProduct>

            </PriceContainer>
        </ProductTextContainer>
        {display()}
    </ProductContainer>)
}
