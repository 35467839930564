import styled from 'styled-components'
import { MainButton, WhiteButton } from '../Buttons'
import CloseSVG from '../../Assets/Icons/Close_White.svg'
import CloseHoverSvg from '../../Assets/Icons/Close_Hover.svg'
import i18n from '../../i18nextInit'
import { motion } from 'framer-motion'

export const ExtendRoundButton = styled.button<{ isExtended: boolean }>`
    position: absolute;
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -23px;
    background-color: #EBEBEB;
    border-radius: 30px;
    border: none;
    padding: 0px;
    transform: ${props => !props.isExtended ? "rotate(0deg)" : "rotate(180deg)"};
    transition: 0.3s;

    @media (orientation : landscape){
        display: none;
    }
`

export const AddToCartButton = styled(MainButton) <{ noProduct: boolean, isExtended: boolean, isTwist: boolean }>`
    font: normal normal 400 12px/13px Helvetica Bold;
    font-size: ${i18n.language === "tw_TW" ? "14px" : ""};
    text-transform: uppercase;
    transition: all 0.3s;
    margin-top: ${props => props.isExtended || props.isTwist ? "16px" : ""};
    background-color: ${props => props.noProduct ? "#B3B3B3" : ""};
    pointer-events: ${props => props.noProduct ? "none" : ""};
    width: 100%;
    z-index: 2;

    @media (orientation : landscape){
        position: relative;
        width: initial;
        padding: 12px 32px;
        margin-top: 0px;
    }
`

export const AddPlayGroundToCart = styled(motion.button)`
    font: normal normal 400 12px/13px Helvetica Bold;
    font-size: ${i18n.language === "tw_TW" ? "14px" : ""};
    text-transform: uppercase;
    background-color: black;
    color: white;
    position: absolute;
    bottom: 42px;
    width: calc(100% - 30px);
    border-radius: 24px;
    transition: background-color 0.5s;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    box-sizing: border-box;
    padding: 15px;

    @media (hover: hover){
        &:hover {
            background-color: #5d8c82;
        }
    }

    @media (hover: none){
        -webkit-tap-highlight-color: transparent;
        transition: none;
        outline: none;

        &:active {
            background-color: #5d8c82;
        }
    }

    @media (min-width: 1224px){
        font-size: ${i18n.language === "tw_TW" ? "14px" : "12px"};
    }
`

export const LinerButton = styled(WhiteButton) <{ isSelected: boolean }>`
    width: 100%;
    box-shadow: ${props => props.isSelected ? "0 0 0 2px black" : "0 0 0 1px #CACACA"};
    border: none;
    margin-top: 12px;
    font-size: 12px;
    margin-right: 2px;
    margin-left: 2px;
    margin-bottom: 2px;

    &:hover {
        background-color: initial;
        box-shadow: 0 0 0 2px black;
        color: black;
    }

    @media (orientation: landscape){
        width: initial;
        padding: 16px 32px;
        margin: 12px 4px;
    }
`

export const TryOnCloseButton = styled.button`
background-image: url(${CloseSVG});
background-color: transparent;
transition: 0.5s;
cursor: pointer;
background-repeat: no-repeat;
background-position: center;
border: none;
position: fixed;
width: 42px;
height: 42px;
top: 15px;
right: 15px;
z-index: 1;

&:hover {
    background-image: url(${CloseHoverSvg});
    color: white;
    border: initial;
}

@media (orientation: landscape){
    top: 24px;
    right: 24px;
}
`

export const PGShadeClose = styled.button`
    background-image: url(${CloseHoverSvg});
    color: white;
    border: initial;
    width: 38px;
    height: 38px;
    position: relative;
`

export const PGShadeCloseContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    z-index: 2;
    position: absolute;
    left: 0px;
    top: 10px;
    height: 62px;
    width: 68px;
`

export const CarouselArrow = styled.button<{ isLeft: boolean }>`
    border: 1px solid #CACACA;
    outline: none;
    border-radius: 50px;
    width: 38px;
    height: 38px;
    position: absolute;
    right: ${props => !props.isLeft ? "84px" : ""};
    top: 85px;
    background-color: transparent;
    padding-left: ${props => props.isLeft ? "4px" : ""};
    padding-right: ${props => !props.isLeft ? "4px" : ""};
    cursor: pointer;
    z-index: 10;
    background-color: white;
    transition: 0.5s;

    &:hover {
        background: black;
        border: none;
    }
`

export const IconButtonCarousel = styled.img<{ hover: boolean, isLeft: boolean }>`
    width: 12px;
    filter: ${props => props.hover ? "" : "brightness(0)"};
    transform: ${props => props.isLeft ? "rotate(-90deg)" : "rotate(90deg)"};
`

export const BeforeAfterCtnr = styled(motion.div)`
    width: 200px;
    backdrop-filter: blur(30px);
    background-color: rgba(0,0,0, 0.3);
    display: flex;
    border-radius: 100px;
    left: calc(50% - 100px);
    position: fixed;
    top: 15px;
    z-index: 0;
    padding: 8px;
    height: 54px;
    align-items: center;
    box-sizing: border-box;
`

export const BeforeAfterTxt = styled.p<{ isSelected: boolean }>`
    flex: 1;
    color: ${props => props.isSelected ? "black" : "white"};
    text-align: center;
    margin: 0px;
    z-index: 1;
    transition: all 0.3s;
    font: normal normal 300 13px/21px Helvetica;
`

export const BeforeAfterSlider = styled.button<{ isOnAfterPosition: boolean }>`
    position: absolute;
    color: black;
    background-color: white;
    width: 50%;
    left: ${props => props.isOnAfterPosition ? "calc(50% - 8px)" : "calc(0% + 8px)"};
    border-radius: 100px;
    min-height: 36px;
    transition: left 0.3s;
`

export const BeforeAfterBtn = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    height: 100%;
`

export const OpenCollapseButton = styled.button<{ isCollapse: boolean }>`
    border: none;
    border-radius: 100px;
    background-color: #EBEBEB;
    width: 45px;
    height: 45px;
    position: absolute;
    top: -22.5px;
    transform: ${props => props.isCollapse ? "rotate(180deg)" : ""};
`

export const Chevron = styled.img<{ $isWide: boolean }>`
    filter: brightness(0);
    width: 12px;
    transform: ${props => props.$isWide ? "" : "rotate(180deg)"};
    transition: all 0.25s;
`

export const PGCartCrossButton = styled(motion.button)`
    background-image: url(${CloseSVG});
    background-color: transparent;
    transition: 0.5s;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center;
    border: none;
    width: 42px;
    height: 42px;
    position: absolute;
    right: ${() => window.RTL_LANGS.includes(i18n.language) ? "" : "8px"};
    left: ${() => window.RTL_LANGS.includes(i18n.language) ? "8px" : ""};

    top: 8px;
`

export const AddToCartSmallbutton = styled.button`
    border-radius: 100px;
    background-color: black;
    display: flex;
    width: 72px;
    height: 42px;
    gap: 8px;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: white;
`