import { ShadeCarouselContainer, ExtendedProductContainer, SelectedShadeRound, ShadeRound, TryOnProductContainer, TryOnProductTextContainer, TryOnUIContainer, ProductSimpleContainer, NoShadeIcon, EyeTwistContainer, AddToCartContainer, Fader, VTOLiner, WaterMark } from "../../Styles/VTO/VTOContainers"
import { AddToCartButton, CarouselArrow, ExtendRoundButton, IconButtonCarousel, LinerButton } from '../../Styles/VTO/VTOButtons'
import { ErrorDistanceMessage, ErrorDistancePanel, LookSize, LookTitle, ProductName, ProductShade, UnavailableText } from '../../Styles/VTO/VTOTexts'
import Carousel from 'react-multi-carousel'
import ArrowIcon from '../../Assets/Icons/Chevron.svg'
import { useTranslation } from "react-i18next"
import { useEffect, useRef, useState } from "react"
import Switch from 'react-switch'
import "react-multi-carousel/lib/styles.css";
import * as ActionTypes from '../../Constants/ActionsTypes'
import SisleyAPI from '../../API/SisleyAPI'

import ProductThumbnail from '../../Assets/Images Produits/DefaultImage.svg'
import { TryOnProductClass, TryOnType } from "../../Constants/TryOnProduct"
import { useDispatch, useSelector } from "react-redux"
import AnimateHeight from "react-animate-height"
import { MySkinDiagState } from "../../type"
import { addToCartProducts } from '../../API/SalesForceAPI'

import { checkIsMaxQuantity, getChromeVersion, handleFormatPrice, sendAnalytics } from '../../Constants/Utilitaries'

import NoShade from '../../Assets/Icons/NoShade.png'
import { isDesktop, isTablet } from "react-device-detect"
import BadQualityPopUp from './BadQualityPopUp'
import ChromePopUp from './ChromePopUp'
import Logo from '../../Assets/Icons/LogoAAA.png'

interface TryOnUIProps {
    resolution: number,
    score: number,
    setAuthorized: Function
}

const TryOnUI = (props: TryOnUIProps) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const session = useSelector((store: MySkinDiagState) => store.session)

    const [extended, setExtended] = useState<boolean>(false)
    const [extendedProducts, setExtendedProducts] = useState<Array<any>>([])
    const [isMonoProduct, setMonoProduct] = useState<boolean>(true)

    const [enableAnimation, setEnableAnimation] = useState<boolean>(false)
    const [prices, setPrices] = useState<Array<number>>([])
    const [noProduct, setNoProduct] = useState<boolean>(false)
    const [buttonTextHandler, setButtontextHandler] = useState<string>("")
    const [lookName, setLookName] = useState<string>("")
    const [privateNavigationFlag, setPrivateNavigationFlag] = useState<boolean>(false)
    const [isRoundActivated, setRoundActivated] = useState<boolean>(false)
    //eslint-disable-next-line
    const [isOnboardDisplayed, displayOnboard] = useState<boolean>(false)
    const [isBadQualityDisplayed, displayBadQuality] = useState<boolean>(true)

    // permet d'étendre juste une fois la hauteur du panneau pour les produits eyetwist
    const eyeTwistFlag = useRef<boolean>(false)

    const isPrivateNavigation = useSelector((state: MySkinDiagState) => state.session.isPrivateNavigation)
    const sisleyAPI = new SisleyAPI()
    // const eyeKhol = ["873118", "873125","873132","873149","873156","873163","873170","873187","873194","873200","874207","874214","874221","874238","874245","874252","874269","874276","874283","874290","874504","874511","874528","874535","874542","874559"]
    const eyeTwists = ["187011", "187012", "187013", "187014", "187015", "187016", "187017", "187018", "187019", "187020", "187021", "187022", "187023", "187024", "187025", "187026", "187027"]
    const [isChromeErrorPopUpDisplayed, displayChromeErrorPopUp] = useState<boolean>(false)
    // var beautifierValue = 0.6;

    useEffect(() => {
        if (isPrivateNavigation) {
            setPrivateNavigationFlag(true)
            displayOnboard(false)
        } else if (!privateNavigationFlag) {
            displayOnboard(true)
        }
        //eslint-disable-next-line
    }, [isPrivateNavigation])

    useEffect(() => {

        let productSku: string = ""
        let data: any

        let url = new URL(window.location.href)
        if (url.searchParams.get("data")) {
            data = JSON.parse(atob(url.searchParams.get("data")!.replace(" ", "+")))
        }
        if (data) {
            if (data!["look"]) {
                sisleyAPI.fakeLook(dispatch)
                return
            }
            if (!data!["mode"]) {
                console.error("No Skus !")
                return;
            }
            productSku = data!["pid"]
            //sisleyAPI.getVTOProducts(productsSkus, dispatch)
            sisleyAPI.getMasterProducts(productSku, dispatch)

        } else {
            sisleyAPI.fakeLook(dispatch)
            console.error("No Data in URL !")
        }

        // Check chrome version

        if (getChromeVersion() === 113) {
            displayChromeErrorPopUp(true)
        }

        // let productTest = new TryOnProductClass(
        //     "187800", "Phyto-test", 10, true, "blue", "blue", "", true, "", 38, 0, "", TryOnType.master, []
        // )

        // productTest.variants.push(new TryOnProductClass(
        //     "187811", "Phyto Lip Twist test", 10, true, "", "blue", "blue", true, "", 38, 0, "", TryOnType.variant, []
        // ))
        // productTest.variants.push(new TryOnProductClass(
        //     "187814", "Phyto Lip Twist test 2", 10, true, "", "green", "green", true, "", 38, 0, "", TryOnType.variant, []
        // ))

        // dispatch({ type: ActionTypes.UPDATE_TRYON_PRODUCTS, value: productTest })

        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        // Gère si le Panel est extend  ou non au lancement
        let timeout = setTimeout(() => {
            if (isMonoProduct) {
                setExtended(true)
            } else {
                setExtended(false)
            }
            setRoundActivated(true)
        }, 1000);

        return () => { window.clearTimeout(timeout) }
    }, [isMonoProduct])

    useEffect(() => {
        let productsTab: Array<any> = []
        let key: number = 0
        session.tryOnProducts.forEach((tryOnProduct: TryOnProductClass) => {
            //console.warn(tryOnProduct.name + " added")

            if (tryOnProduct.type === TryOnType.look) {
                setLookName(tryOnProduct.name)
                return
            }

            if (tryOnProduct.type === TryOnType.bundle) {
                setLookName(tryOnProduct.name)
                return
            }

            //cas du mono produit
            if (session.tryOnProducts.length === 1) {
                productsTab.push(<TryOnProduct shadeEnabled={true} key={key} isMonoProduct={true} tryOnProductClass={tryOnProduct} />)
            }
            else if (productsTab.length === 0) {
                productsTab.push(<TryOnProduct shadeEnabled={true} enableAnimation={enableAnimation} key={key} tryOnProductClass={tryOnProduct} />)
            } else {
                if (tryOnProduct.type === TryOnType.master) {
                    productsTab.unshift(<TryOnProduct key={key} tryOnProductClass={tryOnProduct} />)
                } else {
                    productsTab.push(<TryOnProduct key={key} tryOnProductClass={tryOnProduct} />)
                }
            }
            key++
        })

        setExtendedProducts(productsTab)

        let totalPrice: Array<number> = [0, 0]
        session.tryOnProducts.forEach((product: TryOnProductClass) => {
            if (product.isActive && !checkIsMaxQuantity(product.type === TryOnType.master ? product.actualVariant : product.sku, session.panier)) {
                totalPrice[0] += product.price ? product.price : 0
                totalPrice[1] += product.promo ? product.promo : product.price
            }
        });
        setPrices(totalPrice)

        let count: number = 0
        let disabledProductsCount: number = 0
        let isBundleMainProdductInactive: boolean = false

        session.tryOnProducts.forEach((product: TryOnProductClass) => {
            if (product.actualVariant !== "NoShade" && product.isActive && product.stock > 0 && product.type !== TryOnType.look && product.type !== TryOnType.bundle && !checkIsMaxQuantity(product.actualVariant, session.panier)) {
                count++
            }
            // cas pour les produits soin qui ne sont pas activé et les produits soin.
            if (!product.isVTOEnable) {
                disabledProductsCount++
            }

            if (product.actualVariant.charAt(0) === '1' && product.isActive === false) {
                isBundleMainProdductInactive = true
            }

            if (product.actualVariant.charAt(0) === '1' && product.stock <= 0) {
                isBundleMainProdductInactive = true
            }
        });

        if (count === 0 || isBundleMainProdductInactive) {
            !noProduct && setNoProduct(true)
            setButtontextHandler(t("tryOn.noProduct", "Aucun produit"))
        } else if (count === (session.tryOnProducts.length - 1 - disabledProductsCount)) {
            noProduct && setNoProduct(false)
            setButtontextHandler(t('tryOn.addLookToCart', 'Ajouter le look au panier'))
        } else {
            noProduct && setNoProduct(false)
            setButtontextHandler(t("tryOn.addProductsToCart", "Ajouter # produits au panier").replace('#', count.toString()))
        }

        if (session.tryOnProducts.length === 1) {
            setMonoProduct(true)
            setButtontextHandler(t('tryOn.addToCart', 'Ajouter au panier'))
        } else {
            setMonoProduct(false)
        }

        if (eyeTwists.includes(session.tryOnProducts[0] ? session.tryOnProducts[0].sku : "") && isMonoProduct && !eyeTwistFlag.current) {
            expand()
            eyeTwistFlag.current = true
        }

        //eslint-disable-next-line
    }, [session, setPrices])

    const expand = () => {
        setExtended(!extended)
        dispatch({ type: ActionTypes.SET_EXPAND, value: extended })
        setEnableAnimation(true)
    }

    const addToCartHandler = () => {
        let skus: Array<string> = []
        let bundleSku: string | undefined = undefined
        let count: number = 0
        let disabledProductsCount: number = 0
        let skusToCart: Array<string> = []

        if (session.tryOnProducts.length === 1) {
            let tryOnProduct: TryOnProductClass = session.tryOnProducts[0]
            if (tryOnProduct.isActive && !checkIsMaxQuantity(tryOnProduct.actualVariant, session.panier)) {
                skus.push(tryOnProduct.actualVariant)
                skusToCart.push(tryOnProduct.actualVariant)
                count++
            }
        } else {
            session.tryOnProducts.forEach((product: TryOnProductClass) => {

                if (!product.isVTOEnable) {
                    disabledProductsCount++
                }
                
                if (product.type === TryOnType.master && product.isActive && !checkIsMaxQuantity(product.actualVariant, session.panier)) {
                    skus.push(product.actualVariant)
                    count++
                } else if (product.type !== TryOnType.master && product.isActive && product.stock > 0 && !checkIsMaxQuantity(product.sku, session.panier)) {
                    skus.push(product.sku)
                    count++
                }

                if ((product.type === TryOnType.look || product.type === TryOnType.bundle) && product.isActive && product.stock > 0) {
                    bundleSku = product.sku
                }

                if (count !== session.tryOnProducts.length) {
                    skus = skus.filter(e => e !== bundleSku)
                }

                if (product.type === TryOnType.master && product.stock > 0 && product.isActive) {
                    skusToCart.push(product.actualVariant)
                } else if (product.stock > 0 && product.isActive) {
                    skusToCart.push(product.sku)
                }

            })
        }

        sendAnalytics("add_to_cart", "vto")
        console.warn(bundleSku)
        dispatch({ type: ActionTypes.UPDATE_PANIER, value: skusToCart.filter(e => e !== bundleSku) })
        addToCartProducts(skus, session.clientData, bundleSku, count === (session.tryOnProducts.length - disabledProductsCount))
    }

    const heightHandler = () => {
        if (extended || (isMonoProduct && !eyeTwists.includes(session.tryOnProducts[0] ? session.tryOnProducts[0].sku : ""))) {
            return "auto"
        } else
            if (!isMonoProduct && (!isDesktop || isTablet)) {
                return 0
            } else
                if (window.innerWidth > window.innerHeight) {
                    return "100%"
                } else {
                    return 80
                }
    }

    const checkIfRoundButtonDisplayed = (): boolean => {

        if (session.tryOnProducts[0]) {
            if (isMonoProduct && session.tryOnProducts[0].isActive === false) {
                return false
            }
        }

        if (!isRoundActivated) { // OK
            return false
        }

        if (isPrivateNavigation) { // ok
            return false
        }

        if (isMonoProduct && session.tryOnProducts[0].variants.length === 0) {
            return false
        }

        return true
    }

    const displayAddToCart = (): boolean => {
        if (!isDesktop || isTablet) {
            if (!privateNavigationFlag) {
                return true
            }
        }

        if (isDesktop) {
            if (!isPrivateNavigation) {
                console.warn("DISPLAY !")
                return true
            }
        }

        return false
    }

    useEffect(() => {
        console.warn(props.score)
    }, [props.score])

    // (!isMonoProduct || eyeTwists.includes(session.tryOnProducts[0] ? session.tryOnProducts[0].sku : "")) &&
    return (<TryOnUIContainer isPrivateMode={isPrivateNavigation} isLook={!isMonoProduct} id="CONTAINER">
        {checkIfRoundButtonDisplayed() && <ExtendRoundButton isExtended={extended} onClick={() => expand()}>
            <img alt="Arrow" src={ArrowIcon} style={{ width: "12px", filter: "brightness(0)" }} />
        </ExtendRoundButton>}

        <WaterMark>
            Created by <img src={Logo} alt="WaterMark" style={{ height: "15px" }} />
        </WaterMark>

        {<ErrorDistancePanel isDisplayed={(props.score <= 3) && !isOnboardDisplayed}>
            <ErrorDistanceMessage>
                {props.score <= 3 && t("cameraView.no_face")}
            </ErrorDistanceMessage>
        </ErrorDistancePanel>}

        {!isMonoProduct && <LookTitle>
            {lookName}
        </LookTitle>}

        {!isMonoProduct && session.tryOnProducts.length > 0 && <LookSize style={{ font: "normal normal 500 12px/12px Helvetica", marginTop: "8px" }}>
            {session.tryOnProducts.length - 1} {t('tryOn.products', 'produits').toUpperCase()}
        </LookSize>}

        {!isMonoProduct && <div style={{ display: "flex", width: "100%", margin: "8px 0px 16px" }}>
            {prices[0] !== prices[1] && <LookSize style={{ width: "auto", fontWeight: 900, paddingTop: "0px", textDecoration: "line-through", color: "#CACACA" }}>
                {handleFormatPrice(prices[0])}
            </LookSize>}

            {!noProduct && <LookSize style={{ width: "auto", fontWeight: 900, paddingTop: "0px", paddingLeft: prices[0] > prices[1] ? "0px" : "" }}>
                {handleFormatPrice(prices[1])}
            </LookSize>}
        </div>}

        {
            <div style={{ width: "100%", height: "100%", overflowY: "auto", overflowX: "hidden" }}>
                <AnimateHeight height={heightHandler()} style={{ width: "100%" }}>
                    {extendedProducts[0]}
                    <ExtendedProductContainer>
                        {extendedProducts.slice(1)}
                    </ExtendedProductContainer>
                </AnimateHeight>
            </div>
        }

        {
            isChromeErrorPopUpDisplayed && (!isDesktop || isTablet) && <ChromePopUp />
        }

        {
            isBadQualityDisplayed && (!isDesktop || isTablet) && props.resolution < 480 && <BadQualityPopUp displayPopUp={displayBadQuality} />
        }

        {displayAddToCart() && <AddToCartContainer $isLook={!isMonoProduct}>
            <AddToCartButton isTwist={eyeTwists.includes(session.tryOnProducts[0] ? session.tryOnProducts[0].sku : "")} isExtended={extended} onClick={() => addToCartHandler()} noProduct={noProduct}>
                {buttonTextHandler}
            </AddToCartButton>
        </AddToCartContainer>}

    </TryOnUIContainer>)
}

export const TryOnProduct = (props: any) => {
    let tryOnProduct: TryOnProductClass = props.tryOnProductClass
    let hasVariants: boolean = props.tryOnProductClass.variants.length > 1
    let shades: Array<any> = []
    const isExpand = useSelector((state: MySkinDiagState) => state.session.isExpand)

    const eyeTwists = ["187011", "187012", "187013", "187014", "187015", "187016", "187017", "187018", "187019", "187020", "187021", "187022", "187023", "187024", "187025", "187026", "187027"]
    //const eyeKhol = ["873118", "873125","873132","873149","873156","873163","873170","873187","873194","873200","874207","874214","874221","874238","874245","874252","874269","874276","874283","874290","874504","874511","874528","874535","874542","874559"]
    const eyeKhol = ["187311", "187312", "187313", '187314', "187315", "187316", "187317", "187318", "187319", "187320"];

    const handleBeginCheck = (): boolean => {

        if (!tryOnProduct.isVTOEnable && tryOnProduct.type !== TryOnType.master) {
            return false
        }

        if (tryOnProduct.type === TryOnType.master && !props.isMonoProduct) {
            return false
        }

        return true
    }

    const { t } = useTranslation()
    const [checked, setChecked] = useState<boolean>(handleBeginCheck())
    const [name, setName] = useState<string>(tryOnProduct.parentName ? tryOnProduct.parentName : tryOnProduct.name)
    const [shadeColor, setShadeColor] = useState<string>(tryOnProduct.shadeColor)
    const [shadeName, setShadeName] = useState<string>(tryOnProduct.shadeName)
    const tryOnProducts = useSelector((store: MySkinDiagState) => store.session.tryOnProducts)
    const panier = useSelector((store: MySkinDiagState) => store.session.panier)
    const [isLandscape, setLandscape] = useState<boolean>(window.innerWidth > window.innerHeight)
    const [isLinerMode, setLinerMode] = useState<boolean>(!eyeTwists.includes(tryOnProduct.sku))
    const [isEyeKholHigh, setEyeKholHigh] = useState<boolean>(eyeKhol.includes(tryOnProduct.sku))

    //eslint-disable-next-line
    const [sku, setSku] = useState<string>(tryOnProduct.sku)

    const dispatch = useDispatch()
    const carousel = useRef<any>(null)

    const [currentTryOnProduct, setCurrentTryOnProduct] = useState<TryOnProductClass>(new TryOnProductClass(
        "MASTER",
        "none",
        1, true,
        "",
        "",
        "",
        true,
        "description",
        0,
        0,
        "introduction",
        TryOnType.master,
        [])
    )

    const switchHandler = () => {
        setChecked(!checked)
        dispatch({ type: ActionTypes.UPDATE_TRYON_PRODUCTS, value: { ...tryOnProduct, isActive: !checked, actualVariant: currentTryOnProduct.sku } })
    }

    if (hasVariants && (tryOnProduct.type === TryOnType.master || props.isMonoProduct)) {
        tryOnProduct.variants.forEach((tryOnProduct: TryOnProductClass) => {
            if (tryOnProduct.sku === "NoShade") {
                shades.push(<NoShadeIcon key={0} src={NoShade} />)
            } else {
                shades.push(<ShadeRound key={10} color={tryOnProduct.shadeColor} isLittle={false} />)
            }
        });
    }

    useEffect(() => {
        if (carousel.current) {
            if (carousel.current.state && carousel.current.state.currentSlide > 0) {
                setCurrentTryOnProduct(tryOnProduct.variants[carousel.current.state.currentSlide - 1]) // -1
            }
        }

        if (!tryOnProduct.isVTOEnable && tryOnProduct.type !== TryOnType.master) {
            currentTryOnProduct.isActive = false
            tryOnProduct.isActive = false
            setCurrentTryOnProduct(currentTryOnProduct)
        }

        let product: TryOnProductClass = tryOnProducts.find((e: TryOnProductClass) => e.sku === tryOnProduct.sku)

        if (product.parentName) {
            setName(product.parentName)
        } else {
            setName(product.name)
        }

        //eslint-disable-next-line
    }, [carousel.current, tryOnProducts])

    useEffect(() => {
        if (currentTryOnProduct.name !== "none") {
            setName(tryOnProduct.parentName)
            setShadeColor(currentTryOnProduct.shadeColor)
            setShadeName(currentTryOnProduct.shadeName)
            setSku(currentTryOnProduct.sku)
            tryOnProduct.actualVariant = currentTryOnProduct.sku
            tryOnProduct.price = currentTryOnProduct.price
            tryOnProduct.promo = currentTryOnProduct.promo
            tryOnProduct.isVTOEnable = currentTryOnProduct.isVTOEnable
            tryOnProduct.stock = currentTryOnProduct.stock
            tryOnProduct.isActive = currentTryOnProduct.isVTOEnable
            tryOnProduct.isLiner = isLinerMode
            tryOnProduct.isKholHigh = isEyeKholHigh

            // if (eyeTwists.includes(tryOnProduct.sku)) {
            //     tryOnProduct.isLiner = true
            // }

            dispatch({ type: ActionTypes.UPDATE_TRYON_PRODUCTS, value: tryOnProduct })
        }

        //eslint-disable-next-line
    }, [currentTryOnProduct, isLinerMode, isEyeKholHigh])

    const isSwitchDisabled = (): boolean => {

        if (!tryOnProduct.isVTOEnable) {
            return true
        }
        return false
    }

    // C'est pas terrible mais derniere minute
    const slideMachine = (index: number) => {
        let interval = setInterval(() => {

            let slides = document.getElementsByClassName("react-multi-carousel-list")
            if (slides[0] instanceof HTMLElement && carousel.current) {
                if (window.innerWidth > window.innerHeight) {
                    slides[0].style.left = "calc(50% - 23px)"
                } else {
                    slides[0].style.left = "calc(50% - 66px)"
                }
                slides[0].style.transition = "left 0.5s"
                carousel.current.goToSlide(index)
            }

        }, 500)



        setTimeout(() => {
            clearInterval(interval)
        }, 2000);

    }

    const responsive = {
        mobile: {
            breakpoint: { max: 899, min: 0 },
            items: 5,
        },
        desktop: {
            breakpoint: { max: 3000, min: 900 },
            items: 5
        }
    };

    useEffect(() => {
        if (hasVariants) {
            // create an Observer instance
            const resizeObserver = new ResizeObserver((entries: any) => {
                if (entries[0].target.clientHeight > 250) {
                    setTimeout(() => {
                        setChecked(true)
                        resizeObserver.unobserve(document.getElementById("CONTAINER")!)
                    }, 500)
                }
            })

            // start observing a DOM node
            resizeObserver.observe(document.getElementById("CONTAINER")!)
        }

        setTimeout(() => {
            let index = tryOnProduct.variants.findIndex((e: TryOnProductClass) => e.sku === tryOnProduct.sku)
            if (index === -1 || tryOnProduct.variants.length === 1) {
                slideMachine(2)
                return;
            }

            if (tryOnProducts.length > 1 && tryOnProduct.type !== TryOnType.master) {
                return
            }

            // + 1 car il y a le shade invisible (tweak)
            slideMachine(index + 1)
        }, 1000);

        const resize = () => {
            setLandscape(window.innerWidth > window.innerHeight)
        }

        window.addEventListener('resize', resize)
        return () => window.removeEventListener("resize", resize)


        //eslint-disable-next-line
    }, [])

    const handleShadeName = (): string => {
        if (currentTryOnProduct.sku === "NoShade") {
            return ""
        }

        if ((!props.isMonoProduct && tryOnProduct.shadeName) || (tryOnProduct.shadeName || currentTryOnProduct.shadeName)) {
            return shadeName
        }

        return t('tryOn.uniqueShade', "Teinte unique")
    }

    const switchTwistMode = (isLiner: boolean) => {
        setLinerMode(isLiner)
    }

    const switchEyeKholMode = (isHigh: boolean) => {
        setEyeKholHigh(isHigh)
    }

    const afterChangeHandler = () => {
        if (carousel.current.state.currentSlide === 0) {
            carousel.current.goToSlide(carousel.current.state.currentSlide + 1)
        } else if (carousel.current.state.currentSlide > tryOnProduct.variants.length) {
            console.warn("Out of bound")
            carousel.current.goToSlide(tryOnProduct.variants.length)
        } else {
            setCurrentTryOnProduct(tryOnProduct.variants[carousel.current.state.currentSlide - 1])
        }
    }

    const imgHandler = () => {
        let img = tryOnProduct.variants.find((e: TryOnProductClass) => e.sku === tryOnProduct.actualVariant)?.imgURL

        if (img) {
            return tryOnProduct.variants.find((e: TryOnProductClass) => e.sku === tryOnProduct.actualVariant)?.imgURL
        } else {
            return ProductThumbnail
        }
    }

    const maximumShadeTextHandler = () => {
        if (tryOnProduct.type === TryOnType.master) {
            return t("tryOn.maxShadeQuantity", "Maximum quantity")
        }

        if (tryOnProduct.variants.length === 0) {
            return t("tryOn.maxQuantity", "Maximum quantity")
        }

        if (tryOnProduct.variants.length > 0 && !props.isMonoProduct) {
            return t("tryOn.maxQuantity", "Maximum quantity")
        }

        return t("tryOn.maxShadeQuantity", "Maximum quantity")
    }

    const linerPositionHandler = () => {
        if (!currentTryOnProduct.isVTOEnable || currentTryOnProduct.stock <= 0 || checkIsMaxQuantity(tryOnProduct.actualVariant, panier)) {
            return 1
        }

        if (!checked) {
            return -1
        }

        return 0
    }

    const heightHandler = () => {
        if (isExpand && !eyeTwists.includes(currentTryOnProduct.sku)) {
            return 80
        }
        if (checked) {
            return "auto"
        } else {
            return 80
        }
    }

    return (
        <AnimateHeight id="ANIMATE_CONTAINER" style={{ width: "100%" }} height={heightHandler()}>
            <TryOnProductContainer>
                <ProductSimpleContainer id="PRODUCT_SIMPLE_CONTAINER" >
                    {!props.isMonoProduct &&
                        <div style={{ maxWidth: "64px", height: "64px", flex: 1, opacity: checked ? "" : "0.4", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <img style={{ width: "100%" }} alt="Product Thumbnail" src={imgHandler()} />
                        </div>}
                    <TryOnProductTextContainer isDisplayed={checked}>
                        <ProductName>{name}</ProductName>
                        {tryOnProduct.actualVariant !== "NoShade" &&
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", paddingTop: "6px", gap: "4px" }}>
                                {tryOnProduct.shadeColor && tryOnProduct.actualVariant !== "NoShade" && <ShadeRound color={shadeColor} isLittle={true} />}
                                <ProductShade isSingleShade={tryOnProduct.variants.length === 0} isMonoproduct={props.isMonoProduct}>{handleShadeName()}</ProductShade>
                            </div>}

                        {currentTryOnProduct.isActive && tryOnProduct.actualVariant !== "NoShade" && <div style={{ display: "flex", width: "100%", marginTop: "8px" }}>
                            {tryOnProduct.promo > 0 && props.isMonoProduct && tryOnProduct.price > tryOnProduct.promo && <LookSize style={{ width: "auto", fontWeight: 900, paddingTop: "0px", paddingLeft: "0px", textDecoration: "line-through", color: "#CACACA" }}>
                                {handleFormatPrice(tryOnProduct.price)}
                            </LookSize>}

                            {props.isMonoProduct && tryOnProduct.price > 0 && <LookSize style={{ width: "auto", fontWeight: 900, paddingTop: "0px", paddingLeft: "0px" }}>
                                {handleFormatPrice(tryOnProduct.promo ? tryOnProduct.promo : tryOnProduct.price)}
                            </LookSize>}
                        </div>}

                    </TryOnProductTextContainer>
                    {<Switch disabled={isSwitchDisabled()} handleDiameter={28} checkedIcon={<div />} activeBoxShadow={'0 0 0px 0px #fff'} uncheckedIcon={<div />} width={54} height={32} onColor={"#5D8C82"} offColor={"#757575"} onChange={switchHandler} checked={checked} />}
                </ProductSimpleContainer>

                {hasVariants && (tryOnProduct.type === TryOnType.master || props.isMonoProduct) &&
                    <ShadeCarouselContainer isMonoProduct={props.isMonoProduct}>
                        <SelectedShadeRound isMonoProduct={props.isMonoProduct} />

                        {isLandscape && <CustomArrow isLeft={true} carousel={carousel} tryOnProduct={tryOnProduct} />}
                        {isLandscape && <CustomArrow isLeft={false} carousel={carousel} tryOnProduct={tryOnProduct} />}
                        {isLandscape && <Fader isLeft={true} />}
                        {isLandscape && <Fader isLeft={false} />}

                        <Carousel
                            ref={(el) => (carousel.current = el)}
                            afterChange={() => afterChangeHandler()}
                            ssr={false}
                            responsive={responsive}
                            autoPlay={false}
                            showDots={false}
                            centerMode={true}
                            infinite={false}
                            minimumTouchDrag={10}
                            focusOnSelect={true}
                            draggable={!isLandscape ? true : false}
                            removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
                            itemClass="carouselItem"
                            containerClass="carouselContainer">

                            {<div style={{ backgroundColor: "white", height: "50px", width: "80px" }} />}
                            {shades}
                            {!isLandscape && <div style={{ backgroundColor: "white", height: "50px", width: "80px" }} />}
                            {!isLandscape && <div style={{ backgroundColor: "white", height: "50px", width: "80px" }} />}
                            {!isLandscape && <div style={{ backgroundColor: "white", height: "50px", width: "80px" }} />}
                            {!isLandscape && <div style={{ backgroundColor: "white", height: "50px", width: "80px" }} />}
                            {!isLandscape && <div style={{ backgroundColor: "white", height: "50px", width: "80px" }} />}
                        </Carousel>

                    </ShadeCarouselContainer>
                }

                {!currentTryOnProduct.isVTOEnable && <UnavailableText isLeft={tryOnProducts.length > 1 && tryOnProduct.type === TryOnType.variant}>{t("tryOn.vtoDisabled", "VTO disabled")}</UnavailableText>}
                {currentTryOnProduct.isVTOEnable && (tryOnProduct.stock <= 0 || currentTryOnProduct.stock <= 0) && <UnavailableText isLeft={tryOnProducts.length > 1 && tryOnProduct.type === TryOnType.variant}>{t("tryOn.outOfStock", "Out of stock")}</UnavailableText>}
                {checkIsMaxQuantity(tryOnProduct.actualVariant, panier) && <UnavailableText isLeft={tryOnProducts.length > 1 && tryOnProduct.type === TryOnType.variant}>{maximumShadeTextHandler()}</UnavailableText>}

                {((tryOnProducts.length === 1 && tryOnProduct.variants.length > 1) || tryOnProduct.type === TryOnType.master) && !isTablet && isDesktop && <VTOLiner isMonoProduct={tryOnProducts.length === 1} position={linerPositionHandler()} />}

                {eyeTwists.includes(currentTryOnProduct.sku) &&
                    <EyeTwistContainer>
                        <LinerButton onClick={() => switchTwistMode(false)} isSelected={!tryOnProduct.isLiner}>
                            {t("tryOn.eyeShadow", "Eye Shadow")}
                        </LinerButton>
                        <LinerButton onClick={() => switchTwistMode(true)} isSelected={tryOnProduct.isLiner}>
                            {t("tryOn.eyeLiner", "Eye liner")}
                        </LinerButton>
                    </EyeTwistContainer>}

                {eyeKhol.includes(currentTryOnProduct.sku) &&
                    <EyeTwistContainer>
                        <LinerButton onClick={() => switchEyeKholMode(true)} isSelected={tryOnProduct.isKholHigh}>
                            {t("tryOn.eyeKholHigh", "High")}
                        </LinerButton>
                        <LinerButton onClick={() => switchEyeKholMode(false)} isSelected={!tryOnProduct.isKholHigh}>
                            {t("tryOn.eyeKholLow", "Low")}
                        </LinerButton>
                    </EyeTwistContainer>}

            </TryOnProductContainer>
        </AnimateHeight>

    )
}

const CustomArrow = (props: any) => {

    const [hover, setHover] = useState<boolean>(false)

    const move = () => {
        if (props.isLeft) {
            props.carousel.current.goToSlide(props.carousel.current.state.currentSlide - 1)
        } else {
            props.carousel.current.goToSlide(props.carousel.current.state.currentSlide + 1)
        }
    }

    const checkIfDisabled = (): boolean => {
        if (props.carousel.current) {
            if (props.carousel.current.state.currentSlide === 1 && props.isLeft) {
                return true
            } else if (!props.isLeft && props.carousel.current.state.currentSlide === props.tryOnProduct.variants.length) {
                return true
            } else {
                return false
            }
        }
        return true
    }

    return (
        <CarouselArrow onMouseOver={() => setHover(true)} onMouseOut={() => setHover(false)} onMouseLeave={() => setHover(false)} isLeft={props.isLeft} disabled={checkIfDisabled()} onClick={() => move()}>
            <IconButtonCarousel alt="next shade" hover={hover} isLeft={props.isLeft} src={ArrowIcon} />
        </CarouselArrow>)
};

const TryOnUIComponent = (props: TryOnUIProps) => {
    return <TryOnUI setAuthorized={props.setAuthorized} score={props.score} resolution={props.resolution} />
}

export default TryOnUIComponent