import { DesktopViewContainer } from "../Styles/Containers"
import { BigText, H1, ParagraphTitle } from "../Styles/Texts"
import { useEffect, useRef, useState } from "react";
import { Bridge } from "../type";
import { v4 as uuidv4 } from 'uuid';

import * as ActionTypes from "../Constants/ActionsTypes"
import FaceIcon from "../Assets/Icons/FaceIcon.png"
import LightIcon2 from "../Assets/Icons/LightIcon2.png"
import MascaraIcon from "../Assets/Icons/MascaraIcon.png"
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { QRCodeSVG } from 'qrcode.react';
import { Unsubscribe } from 'firebase/database';
import { deleteBridgeValue, listenBridge, setBridgeValue } from "../API/firebase/database";
import { useDispatch } from "react-redux";
import { getVideoLanguage, runHeightMachine } from "../Constants/Utilitaries";

const DesktopView = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const [bridgeId] = useState(uuidv4());
    const [qrCodeUrl, setQrCodeUrl] = useState<string>('');
    const unsubRef = useRef<Unsubscribe>();

    useEffect(() => {

        // en prod/QA
        let data: any
        let completeURL: string = ""
        runHeightMachine()

        let url = new URL(window.location.href)
        if (url.searchParams.get("data")) {
            data = JSON.parse(atob(url.searchParams.get("data")!.replace(" ", "+")))
            console.log(data)
            if (data["options"]) {
                let activeURL = data["options"]["currentUrl"]

                completeURL = activeURL + "&bridge=" + bridgeId
                console.log("[DESKTOP View] DATA: ")
                console.log(completeURL)
            }
        }
        setQrCodeUrl(completeURL);
    }, [bridgeId]);

    useEffect(() => {
        console.warn(bridgeId)
        setBridgeValue(bridgeId, {
            status: 'WAITING',
        }).then(() => {
            unsubRef.current = listenBridge(bridgeId, manageBridgeRes);
        });

        return () => {
            unsubRef.current && unsubRef.current();
            deleteBridgeValue(bridgeId);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bridgeId]);

    const manageBridgeRes = (res: Bridge) => {
        if (res.status === 'DONE') {
            console.warn(res)
            dispatch({ type: ActionTypes.SET_ANALYSE, value: res.analysisResults });
            unsubRef.current && unsubRef.current();
            deleteBridgeValue(bridgeId).finally(() => {
                dispatch({ type: ActionTypes.SET_STEP, value: 21 });
            });
        }
    };

    return <DesktopViewContainer>
        <div style={{ display: "flex", flex: 2, justifyContent: 'center', alignItems: 'center', flexDirection: "column", minWidth: "400px" }}>
            <BigText>
                {t("desktopView.takePhoto", "Prendre la photo depuis votre mobile")}
            </BigText>
            <H1 style={{ font: "normal normal normal 16px/24px Helvetica Bold", maxWidth: "70%" }}>
                {t("desktopView.flashQRCode", "Flashez le QR code pour prendre une photo de votre visage depuis votre mobile ")}
                <span style={{ font: "normal normal normal 16px/24px Helvetica" }}> {t("desktopView.flashQRCode2", ": l'image sera de meilleure qualité sur votre smartphone. Vous pourrez ensuite passer aux étapes suivantes sur votre mobile ou votre ordinateur.")}</span>
            </H1>
            {/* <MainButton style={{ maxWidth: "300px", marginTop: "24px" }} onClick={() => debugContinue()}>[Debug] Continuer</MainButton> */}
            <QRCodeSVG style={{ width: "128px", height: "128px", padding: "36px 0px" }} value={qrCodeUrl} />
            {/* <QRCodeCanvas size={164} value={qrCodeUrl} /> */}

        </div>

        <div style={{ flex: 1, backgroundColor: "#FAFAFA", width: '100%', minWidth: "200px", flexDirection: "column", justifyContent: "center", display: "flex", alignItems: "center" }}>
            <img alt="Degagez votre visage" style={{ width: "84px" }} src={FaceIcon}>
            </img>
            <ParagraphTitle style={{ marginBottom: "32px", padding: "0px 24px", textAlign: "center" }}>
                {t('desktopView.cleanFace', "Dégager votre visage")}
            </ParagraphTitle>
            <img alt="Ne pas porter de maquillage" style={{ width: "54px" }} src={MascaraIcon}>
            </img>
            <ParagraphTitle style={{ marginBottom: "32px", padding: "0px 24px", textAlign: "center" }}>
                {t('desktopView.noMakeUp', "Ne pas porter de maquillage")}
            </ParagraphTitle>
            <img alt="Etre dans une bonne lumiere" style={{ width: "54px", padding: "0px 24px", textAlign: "center" }} src={LightIcon2}>
            </img>
            <ParagraphTitle style={{ marginBottom: "32px", padding: "0px 24px", textAlign: "center" }}>
                {t('desktopView.goodLight', "Être dans la lumière")}
            </ParagraphTitle>
            <img alt="Ne pas sourire" style={{ width: "54px" }} src={FaceIcon}>
            </img>
            <ParagraphTitle style={{ marginBottom: "32px", padding: "0px 24px", textAlign: "center" }}>
                {t('desktopView.noSmile', "Ne pas sourire")}
            </ParagraphTitle>
        </div>

        <VideoContainerGlobal>
            <VideoContainer loop autoPlay playsInline muted>
                <source src={getVideoLanguage()} type="video/mp4" />
            </VideoContainer>
        </VideoContainerGlobal>

    </DesktopViewContainer>
}

const VideoContainerGlobal = styled.div`
    background-color: black;
    flex: 1 1 30%;
    max-width: 30%;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    display: flex;
    max-height: 100svh;
`

const VideoContainer = styled.video`
    object-fit: cover;
    width: auto;
    height: 100%;

    @media (max-width: 1224px){
        object-fit: contain;
        max-height: 100vh;
    }
`

const DesktopViewComponent = () => {
    return <DesktopView />
}

export default DesktopViewComponent