import * as ActionsTypes from '../Constants/ActionsTypes'
import i18n from '../i18nextInit';
import { getEnvURL } from './SisleyAPI';


export function getBearer(dispatch, lang) {
    console.warn("[BEARER]]")
    var data = JSON.stringify({
        "type": "session"
    });

    var xhr = new XMLHttpRequest();
    xhr.withCredentials = true;

    xhr.addEventListener("readystatechange", function () {
        if (this.readyState === 4) {
            if (this.responseText) {
                // Convert the header string into an array
                // of individual headers
                var arr = xhr.getAllResponseHeaders().split(/[\r\n]+/);

                // Create a map of header names to values
                var headerMap = {};
                arr.forEach(function (line) {
                    var parts = line.split(': ');
                    var header = parts.shift();
                    var value = parts.join(': ');
                    headerMap[header] = value;
                });
                console.dir(headerMap);
                console.log(typeof headerMap);
                if (headerMap.authorization) {
                    let bearer = headerMap.authorization
                    console.log("Bearer succesfully obtened : " + bearer)

                    getBasketId(dispatch, bearer, lang)
                    //getBasketIDFetch(dispatch, bearer)

                } else {
                    // dispatch({type: ActionsTypes.SET_PRIVATE_MODE, value: true})
                    // eslint-disable-next-line
                    // throw ("Bearer failure");
                }
            }
        }
    });


    xhr.open("POST", getEnvURL() + "customers/auth?client_id=89da2e79-fc2f-4618-aaa9-1bca77cb883f");
    xhr.setRequestHeader("Authorization", "Basic Og==");
    xhr.setRequestHeader("Content-Type", "application/json");

    xhr.send(data);
}

export function getBasketId(dispatch, bearer, lang) {
    var xhr = new XMLHttpRequest();
    xhr.withCredentials = true;

    xhr.addEventListener("readystatechange", function () {
        if (this.readyState === 4) {
            if (typeof this.response != "undefined") {
                var res = JSON.parse(this.response);
                if (this.status === 200) {
                    console.log("--------200--------")
                    let basketID = res.basket_id
                    //On save dans le redux ici
                    dispatch({ type: ActionsTypes.SET_CLIENT_DATA, value: { bearer: bearer, basketID: basketID.split(',')[0] } })
                    console.log("basket id created : " + basketID.split(',')[0]);

                } else if (this.status === 400) {
                    console.log("--------400--------")
                    if (typeof res.fault.arguments.basketIds !== "undefined") {
                        let basketID = res.fault.arguments.basketIds.split(',')[0];
                        console.log("basket already exist but id found : " + basketID.split(',')[0]);
                        dispatch({ type: ActionsTypes.SET_CLIENT_DATA, value: { bearer: bearer, basketID: basketID.split(',')[0] } })
                    }
                } else {
                    console.log("--------status--------" + this.status);
                    console.dir(res);
                    console.dir(this.response);
                }
            }
        }
    });


    xhr.open("POST", getEnvURL() + "baskets");
    console.warn("bearer in getBasketID: ")
    console.warn(bearer)
    xhr.setRequestHeader("Authorization", bearer);
    xhr.setRequestHeader("Content-Type", "application/json");

    xhr.send();
}

export function addToCartProduct(sku, clientData) {
    let data

    if(sku === "108310" && (i18n.language === "fr_FR" || i18n.language === "en_GB" || i18n.language === "en_US")){
        sku = "108311"
    }

    data = JSON.stringify([{ "product_id": sku, "quantity": 1 }]);

    var xhr = new XMLHttpRequest();
    xhr.withCredentials = true;

    xhr.addEventListener("readystatechange", function () {
        if (this.readyState === 4) {
            console.log(this.responseText);
            var data = {
                messageType: "add_to_bag",
                skus: sku
            };
            console.log(data)
            window.parent.postMessage(data, "*")
        }
    });

    xhr.open("POST", getEnvURL() + "baskets/" + clientData.basketID.split(',')[0] + "/items");
    xhr.setRequestHeader("Authorization", clientData.bearer);
    xhr.setRequestHeader("Content-Type", "application/json");

    xhr.send(data);
}

export function addToCartProducts(skus, clientData, bundleSku, isComplete) {

    let dataTab = []

    skus.forEach(sku => {
        if (sku !== bundleSku) {

            if(sku === "108310" && (i18n.language === "fr_FR" || i18n.language === "en_GB" || i18n.language === "en_US")){
                sku = "108311"
            }

            dataTab.push({
                "product_id": sku,
                "quantity": 1
            })
        }
    });

    console.warn(skus)

    let bundleData = [{
        "product_id": bundleSku,
        "quantity": 1,
        "bundled_product_items": dataTab
    }]
    //bundleSku && console.log(bundleData)

    let bundleDatas = JSON.stringify(bundleData)

    var datas = JSON.stringify(dataTab)

    var xhr = new XMLHttpRequest();
    xhr.withCredentials = true;

    xhr.addEventListener("readystatechange", function () {
        if (this.readyState === 4) {
            console.log(this.responseText);
            var data = {
                messageType: "add_to_bag",
                skus: bundleSku && isComplete ? bundleSku : Array.isArray(skus) && skus.length > 1 ? skus : skus.toString()
            };
            console.log(data)
            window.parent.postMessage(data, "*")
        }
    });


    xhr.open("POST", getEnvURL() + "baskets/" + clientData.basketID.split(',')[0] + "/items");
    xhr.setRequestHeader("Authorization", clientData.bearer);
    xhr.setRequestHeader("Content-Type", "application/json");

    //console.log(bundleSku && isComplete ? bundleDatas : datas)
    if (bundleSku) {
        // if (bundleSku.charAt(0) === 'B') {
        //     console.log(bundleDatas)
        //     xhr.send(bundleDatas)
        // }
        // else 
        if (bundleSku && isComplete) {
            console.log(bundleDatas)
            xhr.send(bundleDatas)
        } else {
            console.log(datas)
            xhr.send(datas)
        }
    }
    else {
        console.log(datas)
        xhr.send(datas)
    }

    //xhr.send(bundleSku ? bundleDatas : datas);
}
