import * as ActionType from '../../Constants/ActionsTypes'
// import getProductRecommendation, { Product } from '../Firebase/getProductRecommendation'

import {
    SkinDiagApiConfig,
    defaultCBSKConfig,
    Biometrics,
    CBSKEndPoint,
    CBSKError,
    AnalyseStep,
    Point
} from './defaults'

import { CBSKWarning, riseWarnings } from './warnings'
import { Analyse, parseAnalyse } from './analysis'
import { sendAnalytics } from '../../Constants/Utilitaries'

export class SkinDiagApi {

    checkCBSKConfig() {
        let siteURL = new URL(window.location.href)
        let CBSKConfig: SkinDiagApiConfig = defaultCBSKConfig() // pre-prod

        if (siteURL.searchParams.get("data")) {
            let data = JSON.parse(atob(siteURL.searchParams.get("data")!.replace(" ", "+")))
            if (data["options"]) {
                let parentURL = data["options"]["currentUrl"]
                if (parentURL) {
                    if (parentURL.includes("staging")) {
                        return defaultCBSKConfig()
                    } else if (parentURL.includes("sisley-paris")) {
                        return defaultCBSKConfig()
                    }
                }
            }
        }

        return CBSKConfig
    }

    config: SkinDiagApiConfig = this.checkCBSKConfig()

    async getWarning(blob: Blob, dispatch: Function) {
        console.log("==> [SkinDiag] Get Warnings")

        var formData = new FormData();
        formData.append("face", blob, "sample.jpg");
        const parameters = {
            method: 'POST',
            body: formData,
            headers: {
                'Authorization': "bearer " + this.config.apiKey,
            },
        }
        let response = await fetch(this.config.url + CBSKEndPoint.warnings, parameters)
        console.log("==> [SkinDiag] Get Warnings : Response " + response.status)
        if (response.status !== 200) {
            console.log("==> [SkinDiag] Get Warnings: Fail ")
            let text = await response.text()
            if (!text.includes("Computing warnings")) {
                // En cas d'erreur, on mets la page d'erreur.
                dispatch({ type: ActionType.SET_STEP, value: 14 })
            }
            return [CBSKError.no_face]
        }
        let json = await response.json()
        let warnings: Array<CBSKWarning> = json['cbsk']['warnings'] as Array<CBSKWarning>
        console.log("==> [SkinDiag] Get Warnings : Warnings ")
        console.log(warnings)
        sendAnalytics("photo_validation")

        window.dataLayer.push({
            event: 'events',
            eventProps: {
                category: "Diag",
                action: "click",
                label: "photo_validation"
            }
        })

        return riseWarnings(warnings, this.config.warnings)
    }
    async getBiometrics(blob: Blob) {
        console.log("==> [SkinDiag] Get Biometrie")

        var formData = new FormData();
        formData.append("face", blob, "sample.jpg");
        const parameters = {
            method: 'POST',
            body: formData,
            headers: {
                'Authorization': "bearer " + this.config.apiKey,
            },
        }
        let response = await fetch(this.config.url + CBSKEndPoint.biometrics, parameters)
        console.log("==> [SkinDiag] Get Biometrie: Response " + response.status)
        let json = await response.json()

        return json['cbsk']['biometrics'] as Biometrics
    }

    async getAnalysis(face: Blob) {
        console.log("==> [SkinDiag] Get Analysis")
        var formData = new FormData();
        formData.append("face", face, "sample.jpg");
        //formData.append("cheek", cheek, "cheek.jpg");
        //formData.append("saveImages", 'false');
        const parameters = {
            method: 'POST',
            body: formData,
            headers: {
                'Authorization': "bearer " + this.config.apiKey,
            },
        }
        let response = await fetch(this.config.url + CBSKEndPoint.analyze, parameters)
        console.log("==> [SkinDiag] Get Analysis:  " + response.status)
        if (response.status !== 200) {
            console.log("==> [SkinDiag] Get Analysis: Fail ")
            return [CBSKError.no_analyse]
        }
        let json = await response.json()
        return parseAnalyse(json)
    }

    dataURItoBlob(dataURI: any) {
        // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
        var byteString = atob(dataURI.split(',')[1]);
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        var blob = new Blob([ab], { type: mimeString });
        return blob;

    }


    /**
* recupere les points de l'analyse
* @param {int} index 
* @param {int} zone 
*/
    getPoint(index: number, zone: number, biometris: Biometrics): Point {
        var point: Point = { x: 0, y: 0 };
        console.log(biometris)
        if (!zone) {
            point.x = biometris.contour[index][0];
            point.y = biometris.contour[index][1];
        } else {
            point.x = biometris.zones[zone][index][0];
            point.y = biometris.zones[zone][index][1];
        }
        return point;
    }

    // getLocalPoint():Point {

    // }

    async getEffect(faceURI: any, effect: string) {
        let face = await fetch(faceURI).then(r => r.blob());

        console.log("==> [SkinDiag] Get Effect: " + effect)
        var formData = new FormData();
        formData.append("face", face, "sample.jpg");
        formData.append("effect", effect);

        const parameters = {
            method: 'POST',
            body: formData,
            headers: {
                'Authorization': "bearer " + this.config.apiKey,
            },
        }
        let response = await fetch(this.config.url + CBSKEndPoint.effect, parameters)
        console.log("==> [SkinDiag] Get Effect:  " + response.status)
        if (response.status !== 200) {
            console.log("==> [SkinDiag] Get Effect: Fail ")
            return [CBSKError.no_analyse]
        }
        let json = await response.json()

        return json["url"]

    }
}

//middleware
export default async function skinDiagDispatcher(dispatch: any, getState: any) {
    console.log("==> [SkinDiag] Start Grand Skin Diag Dispatcher")
    let state = getState()

    dispatch({ type: ActionType.ANALYSE_UPDATE, value: AnalyseStep.start })
    if (state && state.session && state.session.imageUser) {
        // Test Effect - DO NOT DELETE
        let skinDiag = new SkinDiagApi()

        let face = await fetch(state.session.imageUser).then(r => r.blob());

        skinDiag.getWarning(face, dispatch).then(warnings => {
            if (warnings.length !== 0) { // Error in warnings
                dispatch({ type: ActionType.ANALYSE_ERROR, value: warnings })
                if(new URL(window.location.href).searchParams.get("otstcMode")){
                    dispatch({ type: ActionType.SET_STEP, value: 0 })
                }
                throw new Error("==> [SkinDiag] Warning Fail")
            }
            dispatch({ type: ActionType.ANALYSE_UPDATE, value: AnalyseStep.analysingFace })

            skinDiag.getBiometrics(face).then(biometrics => {
                // setTimeout(() => {
                //     dispatch({ type: ActionType.SET_STEP, value: 3 })
                // }, 3000);
                dispatch({ type: ActionType.ANALYSE_UPDATE, value: AnalyseStep.biometricsDone })
                dispatch({ type: ActionType.ANALYSE_BIOMETRICS_DONE, value: biometrics })

                skinDiag.getAnalysis(face).then(items => {
                    console.log("==> [SkinDiag] Analyse Done")
                    let analysis = items as Array<Analyse>
                    if (analysis) {
                        dispatch({ type: ActionType.ANALYSE_UPDATE, value: AnalyseStep.done })
                        dispatch({ type: ActionType.ANALYSE_DONE, value: analysis })
                        console.log("get analysis")
                        //getProductRecommendation(state.firebase.firestore,state.currentCentral,analysis,appendProducts)
                    } else {
                        dispatch({ type: ActionType.ANALYSE_ERROR, value: CBSKError.no_analyse })
                        throw new Error("==> [SkinDiag] Analyse Fail")
                    }
                })

            })
        })
            .catch(error => {
                console.log(error)
                // dispatch({type: ActionType.SET_STEP, value: 14})
                // console.error(error)
            })
    } else {
        console.log("==> [SkinDiag] No Photo ==>")
        const action = { type: ActionType.ANALYSE_ERROR, value: [CBSKError.no_photo] }
        dispatch(action)
    }
}
