import { AnimatePresence } from "framer-motion"
import { PlayGroundState } from '../PlaygroundConstants'
import { OpenCollapseButton, Chevron, AddPlayGroundToCart, AddToCartSmallbutton, PGCartCrossButton } from "../../../Styles/VTO/VTOButtons"
import { CartPanelCtnr, CartPanelSmallCtnr, CartProductCellsCtnr, CartSmallProductImage, PGProductCartCtnr, PGProductCartImg, PGProductCartContent, PGProductCartShadeCtnr, ShadeRound, PGCartCurtain } from "../../../Styles/VTO/VTOContainers"
import { MaxProductsText, PGCartLookText, PGCartModifyText, PGCartPrice, PGCartShadeText, PGCartTitle } from "../../../Styles/VTO/VTOTexts"
import { useEffect, useState } from "react"
import { TryOnProductClass } from '../../../Constants/TryOnProduct'
import { MySkinDiagState } from "../../../type"

import chevron from '../../../Assets/Icons/Chevron.svg'
import { useDispatch, useSelector } from "react-redux"
import cartIcon from '../../../Assets/Icons/Union 4.svg'
import { useTranslation } from "react-i18next"
import * as ActionTypes from '../../../Constants/ActionsTypes'
import { addToCartProducts } from "../../../API/SalesForceAPI"
import { handleFormatPrice } from "../../../Constants/Utilitaries"

import CloseHoverSvg from '../../../Assets/Icons/Close_Hover.svg'

interface CartPanelProps {
    playGroundState: PlayGroundState,
    setPlayGroundState: Function,
    displayShadesBackButton: Function,
    setActualTryOnProduct: Function,
    isCartPanelWide: boolean,
    setCartPanelWide: Function,
    isMaxProductTextDisplayed: boolean,
    displayMaxProductText: Function,
    setUnderCategory: Function
}

const CartPanel = (props: CartPanelProps) => {
    const [cartProductCells, setCartProductCells] = useState<any[]>([])
    const [smallCartProducts, setSmallCartProduct] = useState<any[]>([])
    const cartProducts: TryOnProductClass[] = useSelector((state: MySkinDiagState) => state.session.playGroundCart)
    const isInPrivateMode: boolean = useSelector((state: MySkinDiagState) => state.session.isPrivateNavigation)

    const { t } = useTranslation()
    const dispatch = useDispatch()
    const clientData = useSelector((state: MySkinDiagState) => state.session.clientData)
    const MAX_PRODUCTS = 6

    const handleCartPanelSize = () => {
        props.setCartPanelWide(!props.isCartPanelWide)
    }

    useEffect(() => {
        try {
            // sessionStorage.removeItem("playgroundCart")
            if (cartProducts.length > 0) {
                sessionStorage.setItem("playgroundCart", JSON.stringify(cartProducts))
            }
        } catch (error) {
            console.error("Erreur avec session Storage")
            console.error(error)
        }

    }, [cartProducts, isInPrivateMode])

    const handleAddTocart = (e: any) => {
        let _skusToAddtoCart: string[] = []

        cartProducts.forEach((product) => {
            if (product.actualVariant === "") {
                _skusToAddtoCart.push(product.parentSku)
            } else {
                _skusToAddtoCart.push(product.actualVariant)
            }
        })

        addToCartProducts(_skusToAddtoCart, clientData)
        // sendAnalytics("vto_playground_add_to_cart", "TryOn")
        e.stopPropagation()
    }

    const deleteCartProduct = (e: any, product: TryOnProductClass) => {

        // Si il n'y a plus de produit, on remove le session storage, on ne stock rien
        if (cartProducts.length === 1) {
            sessionStorage.removeItem("playgroundCart")
        }
        dispatch({ type: ActionTypes.REMOVE_PLAYGROUND_CART_PRODUCT, value: product })
        dispatch({ type: ActionTypes.REMOVE_TRYON_PRODUCT, value: product })
        props.displayMaxProductText(false)
        e.stopPropagation()
    }

    /* Lors de l'edition d'un produit, on stock d'abord le produit dans le store, puis on ouvre le selecteur de shade avec toutes ses
    informations. Ensuite dans PLaygroundUI.tsx, on détecte lorsqu'il y a un produit dans le store (CurrentPGProductToModify), puis s'il y en a
    un, on cherche l'index du variant et on déplace le carousel. Puis on supprime le produit du store et finalement on supprime le produit doublon
    dans tryOnProducts (dans le store aussi)
    */

    const editProduct = (e: any, product: TryOnProductClass) => {
        props.setPlayGroundState(PlayGroundState.chooseShade)
        dispatch({ type: ActionTypes.REMOVE_PLAYGROUND_CART_PRODUCT, value: product })
        props.displayMaxProductText(false)

        let _croppedSku: string = ""
        if (product.sku.length <= 6) {
            _croppedSku = product.sku
        } else if (product.sku.length === 12) {
            _croppedSku = product.sku.slice(6, -1)
        } else if (product.sku.length === 13) {
            _croppedSku = product.sku.slice(6, -1)
        }

        props.displayShadesBackButton(false)
        // sisleyAPI.getMasterProducts(_croppedSku, dispatch, true)
        props.setUnderCategory(product.makeUpCategory)
        // dispatch({ type: ActionTypes.NEW_UPDATE_TRY_ON_PRODUCT, value: product })
        props.setActualTryOnProduct({ sku: _croppedSku, category: product.makeUpCategory, parentSku: product.parentSku })
        e.stopPropagation()
    }

    const handleImage = (product: TryOnProductClass) => {

        if (!product || product.sku === "NoShade" || !product.imgURL) {
            return product.variants[1].imgURL
        }

        return product.imgURL
    }

    useEffect(() => {
        let _cartProductCells: any[] = []
        let _smallCartProductCell: any[] = []

        let key = 0
        cartProducts.forEach(product => {
            _cartProductCells.push(
                <PGProductCartCtnr
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 1 }}
                    transition={{ duration: 0.3 }}
                    key={key.toString()}>
                    <PGProductCartImg src={handleImage(product)} />
                    <PGProductCartContent>
                        <PGCartCrossButton whileTap={{ backgroundImage: `url(${CloseHoverSvg})` }} onClick={(e) => deleteCartProduct(e, product)} />
                        <PGCartTitle>
                            {product.parentName}
                        </PGCartTitle>

                        <PGCartPrice>
                            {product.promo && <span style={{ textDecoration: "line-through", color: "#CACACA" }}>{handleFormatPrice(product.price)}</span>}
                            {!product.promo && handleFormatPrice(product.price)}
                            {product.promo && handleFormatPrice(product.promo)}
                        </PGCartPrice>

                        <PGProductCartShadeCtnr>
                            <ShadeRound color={product.shadeColor} isLittle />
                            <PGCartShadeText>
                                {product.shadeName}
                            </PGCartShadeText>
                            <PGCartModifyText onClick={(e) => editProduct(e, product)}>
                                {t("playGround.modify")}
                            </PGCartModifyText>
                        </PGProductCartShadeCtnr>

                    </PGProductCartContent>
                </PGProductCartCtnr>)
            key++
            _smallCartProductCell.push(
                <CartSmallProductImage onClick={() => props.setCartPanelWide(true)} src={handleImage(product)} key={key} />)
            key++
        });
        setSmallCartProduct(_smallCartProductCell)
        setCartProductCells(_cartProductCells)
        //eslint-disable-next-line
    }, [cartProducts])

    return (
        <AnimatePresence>
            {props.isCartPanelWide && cartProducts.length > 0 && props.playGroundState !== PlayGroundState.chooseShade && <PGCartCurtain
                key="CART_CURTAIN"
                initial={{ opacity: 0 }}
                animate={{ opacity: 0.5 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2 }} />}

            {cartProductCells.length > 0 && props.playGroundState !== PlayGroundState.chooseShade && <CartPanelCtnr
                initial={{ y: "100%" }}
                animate={{ y: 0 }}
                exit={{ y: "100%" }}
                transition={{ duration: 0.2 }}
                key="CART_PANEL"
                onClick={() => handleCartPanelSize()}
                $isInProductMenu={props.playGroundState === PlayGroundState.chooseProduct}
                $isMaxProduct={props.isMaxProductTextDisplayed}
                $isWide={props.isCartPanelWide}>
                <OpenCollapseButton isCollapse onClick={() => handleCartPanelSize()}>
                    <Chevron $isWide={props.isCartPanelWide} src={chevron} />
                </OpenCollapseButton>

                {props.isCartPanelWide &&
                    <AnimatePresence>
                        <PGCartLookText
                            key="MY_LOOK"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.25 }}>
                            {t("playGround.myLook")} ({cartProducts.length} /6)
                        </PGCartLookText>

                        {cartProducts.length === MAX_PRODUCTS && <MaxProductsText key="Max_PRODUCTS_TEXT">
                            {t("playGround.cartTextLimit")}
                        </MaxProductsText>}

                        <CartProductCellsCtnr key="PRODUCTS_CTNR">
                            <AnimatePresence mode={'popLayout'}>
                                {cartProductCells}
                            </AnimatePresence>
                        </CartProductCellsCtnr>

                        {!isInPrivateMode && <AddPlayGroundToCart key="ADD_TO_BAG" onClick={(e) => handleAddTocart(e)}>
                            {t("playGround.addLookToBag")}
                        </AddPlayGroundToCart>}
                    </AnimatePresence>}

                {!props.isCartPanelWide && <div style={{ display: "flex", width: "100%", gap: window.innerWidth >= 768 ? "12px" : "6px", alignItems: "center" }}>
                    <CartPanelSmallCtnr>
                        {smallCartProducts}
                    </CartPanelSmallCtnr>

                    {!isInPrivateMode && <AddToCartSmallbutton onClick={(e) => handleAddTocart(e)}>
                        + <img src={cartIcon} alt="Ajout au panier" />
                    </AddToCartSmallbutton>}
                </div>}

            </CartPanelCtnr>}
        </AnimatePresence>
    )
}

export default CartPanel