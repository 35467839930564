import styled from 'styled-components'
import { Quest } from '../Constants/QuestsConstants';
import i18n from '../i18nextInit';

export const MainButton = styled.button`
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: black 0% 0% no-repeat padding-box;
    border-radius: 24px;
    width: calc(100% - 24px);
    border-width: 0;
    text-align: center;
    letter-spacing: 0.28px;
    color: #FFFFFF;
    font: normal normal 500 10px/23px Helvetica Bold;
    font-size: ${i18n.language === "tw_TW" ? "14px" : ""};
    letter-spacing: 1px;
    transition: background-color 0.5s;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;

    @media (hover: hover){
        &:hover {
            background-color: #5d8c82;
        }
    }

    @media (hover: none){
        -webkit-tap-highlight-color: transparent;
        transition: none;
        outline: none;

        &:active {
            background-color: #5d8c82;
        }
    }

    @media (min-width: 1224px){
        font-size: ${i18n.language === "tw_TW" ? "14px" : "12px"};
    }
`;

export const WhiteButton = styled(MainButton)`
    background-color: white;
    color: black;
    text-transform: uppercase;
    border: 0.5px solid #979797;
    padding: 12px;
    box-shadow: none;

        &:hover {
            background-color: black;
            color: white;
            border: initial;
        }
`

export const BeginButton = styled(MainButton)`
    margin-bottom: 64px;
`;

export const QuestBubble = styled.button<{ isSelected: boolean, idAnswer: number, questStep: number }>`
    background: ${props => props.isSelected && props.questStep !== 4 ? "white" : !props.isSelected && props.questStep === Quest.contourYeux ? 'white' : ''};
    border: none;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    background-color: ${props => props.questStep === Quest.contourYeux || props.questStep === Quest.exposomes ? 'rgba(255,255,255,0.3)' : 'white'};
    z-index: 2;
    color: ${props => (props.questStep === Quest.contourYeux || props.questStep === Quest.exposomes) && props.isSelected ? 'black' : props.questStep === Quest.contourYeux || props.questStep === Quest.exposomes ? 'white' : 'black'};

    right: ${props => props.idAnswer === 1 && props.questStep === Quest.preferenceDemaquillage ? '12px' : ''};
    left: ${props => props.idAnswer === 0 && props.questStep === Quest.preferenceDemaquillage ? '12px' : ''};
    top: ${props => props.idAnswer === 0 && props.questStep === Quest.preferenceDemaquillage ? '90px' : props.idAnswer === 1 && props.questStep === Quest.preferenceDemaquillage ? '0px' : ''};
    
    right: ${props => props.idAnswer === 1 && props.questStep === Quest.peau ? '30px' : props.idAnswer === 2 && props.questStep === Quest.peau ? '70px' : ''};
    left: ${props => props.idAnswer === 0 && props.questStep === Quest.peau ? '-20px' : ''};
    top: ${props => props.idAnswer === 0 && props.questStep === Quest.peau ? '50px' : props.idAnswer === 1 && props.questStep === Quest.peau ? '0px' : ''};
    bottom: ${props => props.idAnswer === 2 && props.questStep === Quest.peau ? '0px' : ''};

    right: ${props => props.idAnswer === 1 && props.questStep === Quest.contourYeux ? '65px' : props.idAnswer === 4 && props.questStep === Quest.contourYeux ? '55px' : ''};
    left: ${props => props.idAnswer === 3 && props.questStep === Quest.contourYeux ? '60px' : props.idAnswer === 0 && props.questStep === Quest.contourYeux ? '50px' : ''};
    bottom: ${props => props.idAnswer === 3 && props.questStep === Quest.contourYeux ? '0px' : props.idAnswer === 4 && props.questStep === Quest.contourYeux ? '-25px' : ''};
    top: ${props => props.idAnswer === 0 && props.questStep === Quest.contourYeux ? '5px' : props.idAnswer === 1 && props.questStep === Quest.contourYeux ? '-5px' : ''};
    
    right: ${props => props.idAnswer === 1 && props.questStep === 6 ? '-20px' : ''};
    left: ${props => props.idAnswer === 0 && props.questStep === 6 ? '-20px' : ''};
    top: ${props => props.idAnswer === 0 && props.questStep === 6 ? '10px' : ''};
    bottom: ${props => props.idAnswer === 1 && props.questStep === 6 ? '-10px' : ''};

    right: ${props => props.idAnswer === 1 && props.questStep === 7 ? '-20px' : ''};
    left: ${props => props.idAnswer === 0 && props.questStep === 7 ? '-20px' : ''};
    top: ${props => props.idAnswer === 0 && props.questStep === 7 ? '10px' : ''};
    bottom: ${props => props.idAnswer === 1 && props.questStep === 7 ? '-10px' : ''};

    right: ${props => props.idAnswer === 3 && props.questStep === 8 ? '40px' : props.idAnswer === 0 && props.questStep === 8 ? '10px' : ''};
    left: ${props => props.idAnswer === 2 && props.questStep === 8 ? '15px' : props.idAnswer === 1 && props.questStep === 8 ? '45px' : ''};
    bottom: ${props => props.idAnswer === 1 && props.questStep === 8 ? '25px' : props.idAnswer === 0 && props.questStep === 8 ? '85px' : ''};
    top: ${props => props.idAnswer === 2 && props.questStep === 8 ? '10px' : props.idAnswer === 3 && props.questStep === 8 ? '-50px' : ''};

    right: ${props => props.idAnswer === 3 && props.questStep === 9 ? '40px' : props.idAnswer === 0 && props.questStep === 9 ? '10px' : ''};
    left: ${props => props.idAnswer === 2 && props.questStep === 9 ? '15px' : props.idAnswer === 1 && props.questStep === 9 ? '45px' : ''};
    bottom: ${props => props.idAnswer === 1 && props.questStep === 9 ? '25px' : props.idAnswer === 0 && props.questStep === 9 ? '85px' : ''};
    top: ${props => props.idAnswer === 2 && props.questStep === 9 ? '10px' : props.idAnswer === 3 && props.questStep === 9 ? '-50px' : ''};

    display: flex;
    flex-direction: column;
    position: absolute;
    opacity: ${props => props.questStep === Quest.contourYeux ? 1 : 0};
    height: ${props => (props.questStep === Quest.contourYeux && (props.idAnswer === 2 || props.idAnswer === Quest.contourYeux)) || props.questStep === 8 ? '130px' : props.questStep === Quest.contourYeux ? '110px' : (props.questStep === 6 || props.questStep === 7) ? '220px' : '180px'};
    max-height: ${props => props.questStep !== 6 && props.questStep !== 7 ? '200px' : ''};
    max-width: ${props => props.questStep !== 6 && props.questStep !== 7 ? '200px' : ''};
    width: ${props => (props.questStep === Quest.contourYeux && (props.idAnswer === 2 || props.idAnswer === Quest.contourYeux)) || props.questStep === 8 ? '130px' : props.questStep === Quest.contourYeux ? '110px' : (props.questStep === 6 || props.questStep === 7) ? '220px' : '180px'};
    
    width: ${props => props.questStep === 8 ? '160px' : ''};
    height: ${props => props.questStep === 8 ? '160px' : ''};

    width: ${props => props.questStep === 9 ? '160px' : ''};
    height: ${props => props.questStep === 9 ? '160px' : ''};

    border-radius: 150px;
    letter-spacing: 0px;
    align-items: center;
    justify-content: center;
    animation: ${props => props.idAnswer === 2 && props.questStep === Quest.peau ? 'BubbleDown' : props.idAnswer === 1 && props.questStep === Quest.peau ? 'BubbleRight' : props.questStep === Quest.peau ? 'BubbleLeft' : ''};
    
    animation: ${props => props.idAnswer === 0 && props.questStep === Quest.preferenceDemaquillage ? 'BubbleDuoLeft' : props.questStep === Quest.preferenceDemaquillage ? 'BubbleDuoRight' : ''};
    

    animation-duration: ${props => props.questStep === Quest.peau ? '1s' : '2s'};
    animation-delay: ${props => props.questStep === Quest.preferenceDemaquillage ? '1.5s' : '2s'};

    animation: ${props => props.idAnswer === 0 && props.questStep === 6 ? 'Bubble6Left 1s Forwards 2s' : props.questStep === 6 ? 'Bubble6Right 1s Forwards 2s' : ''};
    animation: ${props => props.idAnswer === 0 && props.questStep === 7 ? 'Bubble7Left 1s Forwards 2s' : props.questStep === 7 ? 'Bubble7Right 1s Forwards 2s' : ''};

    animation: ${props => props.idAnswer === 1 && props.questStep === Quest.contourYeux ? 'BubbleCircleAnim1 4.5s Forwards 0s' : ''};
    animation: ${props => props.idAnswer === 3 && props.questStep === Quest.contourYeux ? 'BubbleCircleAnim3 4.5s Forwards 0s' : ''};
    animation: ${props => props.idAnswer === 4 && props.questStep === Quest.contourYeux ? 'BubbleCircleAnim4 4.5s Forwards 0s' : ''};
    
    animation: ${props => props.idAnswer === 0 && props.questStep === 8 ? 'Bubble8Right 1s Forwards 2s' : ''};
    animation: ${props => props.idAnswer === 1 && props.questStep === 8 ? 'Bubble8Down 1s Forwards 2s' : ''};
    animation: ${props => props.idAnswer === 2 && props.questStep === 8 ? 'Bubble8Left 1.5s Forwards 2s' : ''};
    animation: ${props => props.idAnswer === 3 && props.questStep === 8 ? 'Bubble8Top 1s Forwards 2s' : ''};

    animation: ${props => props.idAnswer === 0 && props.questStep === 9 ? 'Bubble8Right 1s Forwards 2s' : ''};
    animation: ${props => props.idAnswer === 1 && props.questStep === 9 ? 'Bubble8Down 1s Forwards 2s' : ''};
    animation: ${props => props.idAnswer === 2 && props.questStep === 9 ? 'Bubble8Left 1.5s Forwards 2s' : ''};
    animation: ${props => props.idAnswer === 3 && props.questStep === 9 ? 'Bubble8Top 1s Forwards 2s' : ''};
    animation-fill-mode: Forwards;
    transition: transform 1s, background-color 1s, left 1s;
    font: normal normal normal ${props => props.questStep !== Quest.contourYeux ? '18px/24px' : '16px/24px'} Playfair;
    padding: 0px 24px;

    @media (min-width: 1224px) {
        &:hover {
            background-color: black;
            color: white;
        }
    }
    
    @media (min-width: 1224px) {
        position: relative;
        top: 0px;
        left: 0px;
        right: 0px;
        animation: Fading 1s Forwards 2s;
        opacity: 0;
        margin: 0px 12px;
    }
`;

export const QuestButton = styled.button<{ idAnswer: number }>`
    animation: ${props => props.idAnswer === 0 ? 'SeparedByLineTextUp' : 'SeparedByLineTextDown'} 1s Forwards 2s;
    position: absolute;
    opacity: 0;
    padding: 0 48px;
    display: flex;
    cursor: pointer;
    z-index: 2;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: transform 1s, background-color 1s;
    font: normal normal normal 18px/23px Playfair;
    color: white;
    background-color: transparent;
    border: none;
    -webkit-tap-highlight-color: transparent;

    @media (min-width: 768px) {
        animation: ${props => props.idAnswer === 0 ? 'SeparedByLineTextUpTab' : 'SeparedByLineTextDownTab'} 1s Forwards 2s;
    }

    @media (min-width: 1224px) {
        animation: ${props => props.idAnswer === 0 ? 'SeparedByLineTextUpDesk' : 'SeparedByLineTextDownDesk'} 1s Forwards 2s;
    }
`;

export const OldQuestButton = styled.button<{ isSelected: boolean }>`
    background: ${props => props.isSelected ? "green" : 'rgb(230,230,230)'};
    display: flex;
    flex-direction: column;
    position: relative;
    opacity: 1;
    height: 60px;
    margin-left: 24px;
    margin-right: 24px;
    margin-top: 10px;
    padding-top: 22px;
    padding-bottom: 22px;
    letter-spacing: 0px;
    align-items: center;
    justify-content: center;
    color: ${props => props.isSelected ? '#FFFFFF' : '#1D1D1B'};
    border-width: 0;
    transition: 1000ms;
    &:focus {
        background: green 0% 0% no-repeat padding-box;
        color: #FFFFFF;
        font: normal normal 500 16px/24px GothamMedium;
        align-items: center;
        justify-content: center;
        display: flex;
        transition: 1000ms;
    }
`;

export const RoundQuestButton = styled.div<{ idAnswer: number }>`
    position: relative;
    bottom: ${props => props.idAnswer === 0 ? '40px' : ''};
    top: ${props => props.idAnswer === 1 ? '40px' : ''};
    width: 28px;
    height: 28px;
    color: black;
    background-color: white;
    border-radius: 15px;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    pointer-events: "none";
    animation: ${props => props.idAnswer === 0 ? "RoundQuestAnimUp" : "RoundQuestAnimDown"} 2s Forwards 3s;
`
export const PPButton = styled.button<{ isSelected: boolean }>`
    cursor: pointer;
    border: none;
    background-color: rgba(255,255,255,0.12);
    padding: 8px 14px 8px 24px;
    opacity: 1;
    margin: 6px;
    flex: 1 1 100%;
    display: flex;
    border-radius: 40px;
    font: normal normal normal 15px/25px Playfair;
    transition: all 0.5s;
    color: white;
    -webkit-tap-highlight-color: transparent;
    position: relative;
    
    @media(min-width: 1224px){
        &:hover {
        background-color: black;
        color: white;
        }
    }

    @media (max-width: 768px){
        margin: 6px 4%;
    }
    
`

export const CYButton = styled.button<{ isSelected: boolean }>`
    border: none;
    background-color: rgba(255,255,255,0.3);
    padding: 8px 14px;
    opacity: 0;
    margin: 8px 6px;
    display: flex;
    border-radius: 40px;
    font: normal normal normal 15px/25px Playfair;
    transition: all 0.5s;
    cursor: pointer;
    animation: Fading 1.5s Forwards ${props => props.isSelected ? '2s' : '3s'};
    color: white;
    @media (min-width: 1224px) {
        &:hover {
            background-color: black;
            color: white;
        }
    }
    -webkit-tap-highlight-color: transparent;
`

export const OrderIcon = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 24px;
    width: 24px;
    border-radius: 24px;
    background-color: white;
    color: #1B4175;
    right: 24px;
    margin: auto;
    font-size: 12px;
    top: 0;
    bottom: 0;
`;

export const CloseButton = styled.img`
    cursor: pointer;
    background: black 0% 0% no-repeat padding-box;   
    border-radius: 24px;
    height: 40px;
    width: 40px;
    position: fixed;
    top: 15px;
    left: ${() => window.RTL_LANGS.includes(i18n.language) ? "15px" : ""};
    right: ${() => window.RTL_LANGS.includes(i18n.language) ? "" : "15px"};
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 35;
    -webkit-tap-highlight-color: transparent;
`

export const AgreeButton = styled(MainButton)`
    position: relative;

    @media (min-width: 768px) {
        max-width: 200px;
    }
`

export const OTSTCInputButton = styled.input`
    position: absolute;
    z-index: -10;
    top: 400px;
`

export const NextQuestion = styled(MainButton) <{ isReady: boolean }>`
    position: relative;
    z-index: 1;
    flex: 1;
    margin-left: 4px;
    text-transform: uppercase;
    max-width: 300px;
    border: none;
    transition: background-color 1s;
    animation: Fading 1s Forwards 3s;
    pointer-events: ${props => !props.isReady ? 'none' : ''};
    color: ${props => !props.isReady ? 'white' : 'white'};
    background-color: ${props => !props.isReady ? 'rgba(0,0,0,0.3)' : 'black'};
    font-size: ${i18n.language === "tw_TW" ? "14px" : "12px"};
    
`

export const InputButton = styled.input`
    background-color: transparent;
    border: 1px solid #CACACA;
    border-radius: 25px;
    font: normal normal 500 12px/23px Helvetica Bold;
    padding: 12px; 
    width: 100%;
    margin: 8px 0px;
    box-sizing: border-box;
`

export const SeeOurRecoButton = styled(MainButton)`
    font: normal normal 500 12px/15px Helvetica Bold;
    text-transform: uppercase;
    margin-bottom: 24px;
    margin-top: 24px;

    @media (min-width: 1224px) {
        display: none;
    }
    @media (min-width: 1224px) {
        &:hover {
            background-color: #5d8c82;
        }
    }
`

export const CloseButtonProduct = styled.img<{ isActive: boolean }>`
    width: 36px;
    cursor: pointer;
    height: 36px;
    display: flex;
    margin: 12px;
    color: #979797;
    position: fixed;
    z-index: 11;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 100px; 
    opacity: ${props => props.isActive ? 1 : 0};
    transition: opacity 0.3s;
    top: 0px;
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;

    @media (min-width: 1224px) {

        right: calc(468px);
    }
`

export const AddToCartButton = styled(MainButton) <{ isInStock: boolean }>`
    background-color: ${props => props.isInStock ? 'black' : "#8d8d8d"};
    pointer-events: ${props => props.isInStock ? '' : 'none'};
    text-transform: uppercase;

    @media (max-width: 1224px){
        width: 100%;
    }
`

export const AddToCartRoutineButton = styled(MainButton) <{ isActive: boolean }>`
    background-color: ${props => props.isActive ? 'black' : "#8d8d8d"};
    pointer-events: ${props => props.isActive ? '' : 'none'};
    bottom: 12px;
    font-size: 10px;
    font-size: ${i18n.language === "tw_TW" ? "12px" : ""};
    text-transform: uppercase;
    width: 100%;

    @media (min-width: 1224px) {
        max-width: 270px;
        width: 100%;
    }
`

export const HelpSkinButton = styled.button`
    border: 0.5px solid #979797;
    cursor: pointer;
    font: normal normal 500 12px/15px Helvetica bold;
    font: ${i18n.language === "tw_TW" ? "14px" : ""};
    color: white;
    margin: 0px;
    padding: 12px 24px;
    opacity: 0;
    background-color: transparent;
    border-radius: 25px;
    animation: Fading 1s Forwards 3s;
    transition: all 0.3s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
        background-color: black;
        color: white;
        border: none;
    }

`

export const StaticAddRoutineButton = styled(MainButton)`
    margin: 54px 0px 12px;
    font-size: 12px;
    text-transform: uppercase;

    @media (max-width: 1224px) {
        margin: 12px 0px;
        padding: 0px;
        width: 100%;
    }
`

export const BackToScoreViewButtton = styled.p`
    cursor: pointer;
    font: normal normal 300 12px/14px Helvetica Bold;
    text-decoration: underline;
    color: black;
    margin: 0px;
`

export const OTSTCButton = styled.button`
    margin: 0px;
    border-radius: 24px;
    font-size: 10px;
    opacity: 0.8;
    width: 16px;
    height: 16px;
    border: 1.5px solid black;
    color: black; 
    margin-right: 4px;
    font-weight: 700;
    box-sizing: border-box;
    padding: 0px;
`
