import * as Actiontype from '../Constants/ActionsTypes'
import { TryOnProductClass, TryOnType } from '../Constants/TryOnProduct';
import { StarProduct } from '../Constants/StarProduct'
import i18n from '../i18nextInit';
import { PGUnderCategory } from '../Components/Try-On/PlaygroundConstants';

export const getEnvURL = (): string => {
    let siteURL = new URL(window.location.href)

    if (siteURL.searchParams.get("data")) {
        let data = JSON.parse(atob(siteURL.searchParams.get("data")!.replace(" ", "+")))
        if (data["options"]) {
            if (data["region"]) {
                let fullURL: string = data["options"]["currentUrl"]

                if (fullURL) {

                    const parts = fullURL.split("/");
                    const baseURL = parts[0] + "//" + parts[2];

                    if (fullURL.includes("preprod")) {
                        return baseURL + `/s/Sites-Sisley_${data["region"].slice(3)}-Site/dw/shop/v21_10/`
                    } else if (fullURL.includes("staging")) {
                        return baseURL + `/s/Sisley_${data["region"].slice(3)}/dw/shop/v21_10/`
                    } else if (fullURL.includes("sisley-paris")) {
                        return baseURL + `/s/Sites-Sisley_${data["region"].slice(3)}-Site/dw/shop/v21_10/`
                    }
                }
            } else {
                let fullURL: string = data["options"]["currentUrl"]

                if (fullURL) {

                    const parts = fullURL.split("/");
                    const baseURL = parts[0] + "//" + parts[2];

                    if (fullURL.includes("preprod")) {
                        return baseURL + `/s/Sites-Sisley_${i18n.language.slice(3)}-Site/dw/shop/v21_10/`
                    } else if (fullURL.includes("staging")) {
                        return baseURL + `/s/Sisley_${i18n.language.slice(3)}/dw/shop/v21_10/`
                    } else if (fullURL.includes("sisley-paris")) {
                        return baseURL + `/s/Sites-Sisley_${i18n.language.slice(3)}-Site/dw/shop/v21_10/`
                    }
                }
            }
        }
    }
    return "Error"
}

export default class SisleyAPI {
    GoogleAuth: any;
    localProducts: Array<any>
    localTryOnProducts: Array<any>
    fallBackLocalProducts: Array<any>
    url: string
    lang: string
    NUMBER_MAX_VARIANTS: number
    debugCountries: string[]

    constructor() {
        this.lang = i18n.language
        this.localTryOnProducts = require('../Assets/STAR_PRODUCTS_MAKE_UP.json')
        this.fallBackLocalProducts = require('../Assets/STAR_PRODUCTS_en_GB.json')
        this.localProducts = []
        this.NUMBER_MAX_VARIANTS = 24
        this.debugCountries = ["en_SG", "en_MY", "es_ES", "fr_BE", "nl_BE", "de_DE", "de_AT"]

        this.url = getEnvURL()
    }

    getProducts = (productSkus: Array<any>, dispatch: Function) => {
        // console.warn("GET_PRODUCTS")
        // console.warn(productSkus)

        // const flatArrayWithHoles = (arr: Array<any>): Array<string> => {
        //     let newArr: Array<string> = []
        //     arr.forEach((element: any) => {
        //         newArr.push(element[0])
        //     })

        //     return newArr
        // }

        //STAR feeding: textes, image. C'EST DU LOCAL.
        // let starProducts = new StarProductsList()
        try {
            this.localProducts = require(`../Assets/STAR_PRODUCTS_${this.lang}.json`)
        } catch (error) {
            this.localProducts = require(`../Assets/STAR_PRODUCTS_en_GB.json`)
        }
        // console.log(flatArrayWithHoles(productSkus))
        // starProducts.getProductsInfosFromLocalJson(this.localProducts, flatArrayWithHoles(productSkus), this.fallBackLocalProducts)
        
        // SalesForce feeding: Prix / promo / stock
        productSkus.forEach(skus => {
            skus.forEach((sku: string) => {
                let starProduct = this.localProducts.find((product: StarProduct) => product.sku === sku)
                
                if (sku === "NO PRODUCT") {
                    dispatch({ type: Actiontype.UPDATE_ONE_PRODUCT, value: { sku: "NO PRODUCT" } })
                }

                if (sku === "108310" && (this.lang === "fr_FR" || this.lang === "en_GB" || this.lang === "en_US")) {
                    sku = "108311"
                }

                fetch(this.url + `products/${sku}?client_id=89da2e79-fc2f-4618-aaa9-1bca77cb883f&expand=availability&expand=promotions&expand=prices&expand=bundled_products&expand=variations`, {
                    method: 'GET',
                    redirect: 'follow',
                })
                    .then(response => response.json())
                    .then(result => this.dispatchProducts(this.parserFromSalesForce(result, starProduct!), dispatch))
                    .catch(error => {
                        console.error('error', error)
                        this.dispatchProducts(starProduct, dispatch)
                    });
            });
        });

    }

    dispatchProducts = (result: any, dispatch: Function, VTO: boolean = false) => {
        if (!result) {
            return;
        }
        dispatch({ type: VTO ? Actiontype.UPDATE_TRYON_PRODUCTS : Actiontype.UPDATE_ONE_PRODUCT, value: result })
    }

    dispatchVariants = async (result: any, dispatch: Function) => {
        if (!result) {
            return;
        }
        dispatch({ type: Actiontype.UPDATE_TRYON_VARIANTS, value: result })
    }

    getVariantsProducts = (result: any, dispatch: Function, playGroundMode?: boolean, makeUpCategory?: any) => {
        let variantSkus: Array<string> = []
        let masterTryOnProduct = this.parseMasterProducts(result)

        // //Cas ou le produit est un simple variant à afficher.
        // if (masterTryOnProduct?.type === TryOnType.variant || masterTryOnProduct?.type === TryOnType.item) {
        //     dispatch({ type: Actiontype.UPDATE_TRYON_PRODUCTS, value: masterTryOnProduct })
        //     return;
        // }

        if (masterTryOnProduct?.variants.length === 0) {
            dispatch({ type: Actiontype.UPDATE_TRYON_PRODUCTS, value: masterTryOnProduct })
        }

        masterTryOnProduct!.variants.forEach((variant: TryOnProductClass) => {
            variantSkus.push(variant.sku)
        });


        // STAR ne peut gérer que 15 produits max dans la requête. Donc on split si jamais tel est le cas.
        //Get variants (15 premiers)
        fetch(this.url + `products/(${variantSkus.slice(0, this.NUMBER_MAX_VARIANTS)})?client_id=89da2e79-fc2f-4618-aaa9-1bca77cb883f&expand=availability&expand=promotions&expand=prices&expand=bundled_products&expand=variations`, {
            method: 'GET',
            redirect: 'follow',
        })
            .then(response => response.json())
            .then(result => this.dispatchMasterWithVariants(result, masterTryOnProduct!, dispatch, variantSkus, playGroundMode, makeUpCategory))
            .catch(error => console.error('error', error));

    }

    dispatchNewVariant = (result: any, dispatch: Function) => {
        let starTryOnProducts = require("../Assets/STAR_PRODUCTS_MAKE_UP.json")

        result.data.forEach((product: any) => {
            let productVariant = new TryOnProductClass(
                product["id"],
                product["name"],
                product["inventory"] ? product["inventory"]["stock_level"] : null,
                product["c_displayVTO"] ? product["c_displayVTO"] : this.debugCountries.includes(this.lang) ? true : false,
                starTryOnProducts.find((e: StarProduct) => e.sku === product.id) ? starTryOnProducts.find((e: StarProduct) => e.sku === product.id)!.imageURL : "",
                this.checkForColorCode(product),
                product["c_colorDisplayValue"],
                true,
                product["c_description"],
                product["price"],
                product['product_promotions'] ? product['product_promotions'][0]['promotional_price'] : undefined,
                product["c_introduction"],
                TryOnType.variant,
                [], product["id"], false, product["master"]["master_id"], "", "foundations"
            )
            console.warn(productVariant)
            dispatch({ type: Actiontype.CREATE_NEW_VARIANT, value: productVariant })
        });
    }

    getBundledProducts = (result: any, dispatch: Function) => {
        let bundledProducts: Array<any> = result["bundled_products"]

        let lookProduct: TryOnProductClass = new TryOnProductClass(
            result["id"],
            result["name"],
            result["inventory"]["stock_level"],
            result["c_displayVTO"] ? result["c_displayVTO"] : this.debugCountries.includes(this.lang) ? true : false,
            "", "", "", true, "", 0, 0, "", result["id"].charAt(0) === "B" ? TryOnType.bundle : TryOnType.look, []
        )

        // Add a test product
        // let productTest: TryOnProductClass = new TryOnProductClass(
        //     "Test",
        //     "Test",
        //     0,
        //     true,
        //     "", "", "", true, "", 0, 0, "", TryOnType.variant, []
        // )

        //dispatch({ type: Actiontype.UPDATE_TRYON_PRODUCTS, value: productTest })

        dispatch({ type: Actiontype.UPDATE_TRYON_PRODUCTS, value: lookProduct })
        bundledProducts.forEach((product: any) => {
            this.getVariantsProducts(product["product"], dispatch)
        });
    }

    parserFromSalesForce = (result: any, starProduct: StarProduct) => {
        if (!result.id) {
            return;
        }

        return {
            sku: starProduct.sku,
            price: result['price'] !== undefined ? result["price"] : 0,
            name: starProduct.name,
            description: starProduct.description,
            introduction: starProduct.introduction,
            tips: starProduct.application,
            stock: result['inventory'] ? result['inventory']["stock_level"] : 1,
            colorCode: this.checkForColorCode(result),
            promoPrice: result['product_promotions'] ? result['product_promotions'][0]['promotional_price'] : undefined,
            imageURL: starProduct.imageURL,
            capacity: starProduct.capacity
        }
    }

    parseMasterProducts = (result: any): TryOnProductClass | null => {
        let variants: Array<TryOnProductClass> = []

        if (!result) {
            return null
        }
        if (result["variants"]) {
            result["variants"].forEach((variant: any) => {
                variants.push(new TryOnProductClass(
                    variant["product_id"],
                    "",
                    variant["inventory"] ? variant["inventory"]["stock_level"] : null,
                    variant["c_displayVTO"] ? variant["c_displayVTO"] : false,
                    '',
                    "",
                    "",
                    true,
                    "",
                    0,
                    0,
                    "",
                    TryOnType.variant,
                    [], variant["product_id"]))
            });
        }

        return (new TryOnProductClass(
            result["id"],
            result['name'],
            result["inventory"] ? result["inventory"]["stock_level"] : null,
            result["c_displayVTO"] ? result["c_displayVTO"] : this.debugCountries.includes(this.lang) ? true : false,
            '',
            this.checkForColorCode(result),
            result["c_colorDisplayValue"],
            true,
            result['c_description'],
            result['price'] ? result["price"] : 0,
            result['product_promotions'] ? result['product_promotions'][0]['promotional_price'] : undefined,
            result['c_introduction'],
            this.setType(result),
            variants, result["id"], false,
            result["master"] ? result["master"]["master_id"] : null))
    }

    checkForColorCode = (product: any) => {
        if (!product["c_colorCode"]) {
            return null
        }

        if (product["c_colorCode"].charAt(0) !== "#") {
            return null
        }

        return product["c_colorCode"]
    }

    dispatchMasterWithVariants = (result: any, master: TryOnProductClass, dispatch: Function, variantSkus: Array<string>, playGroundMode?: boolean, makeUpCategory?: PGUnderCategory) => {
        let variants: Array<TryOnProductClass> = []
        //STAR feeding: textes, image. C'EST DU LOCAL.
        let starTryOnProducts = require("../Assets/STAR_PRODUCTS_MAKE_UP.json")

        if (!result["data"]) {
            if (master) {

                if (playGroundMode) {
                    for (let i = 0; i < starTryOnProducts.length; i++) {
                        if (starTryOnProducts[i].sku === master.sku) {
                            master.imgURL = starTryOnProducts[i].imageURL
                        }
                    }
                    master.variants.push(new TryOnProductClass("NoShade", "NoShade", 1, true, "", "", "NoShade", false, "", 0, 0, "", TryOnType.variant, [], "", false, master.sku, master.name))
                    master.variants.push(new TryOnProductClass(master.sku, master.name, master.stock, master.isVTOEnable, master.imgURL, master.shadeColor, master.shadeName, true, master.description, master.price, master.promo, master.introduction, TryOnType.variant, [], "", false, master.sku, master.name))
                    master.parentSku = master.sku
                    master.parentName = master.name
                    dispatch({ type: Actiontype.NEW_UPDATE_TRY_ON_PRODUCT, value: master })
                }
            } else {
                console.error("This product does not exist")
                let errorProduct = new TryOnProductClass("ERROR_BUNDLE", "UNKNOWN BUNDLE/LOOK SKU", 0, true, "", "error", "", true, "", 0, 0, "", TryOnType.error, [])
                dispatch({ type: Actiontype.UPDATE_TRYON_PRODUCTS, value: errorProduct })
            }

            return;
        }

        result["data"].forEach((product: any) => {
            variants.push(new TryOnProductClass(
                product["id"],
                product["name"],
                product["inventory"] ? product["inventory"]["stock_level"] : null,
                product["c_displayVTO"] ? product["c_displayVTO"] : this.debugCountries.includes(this.lang) ? true : false,
                starTryOnProducts.find((e: StarProduct) => e.sku === product.id) ? starTryOnProducts.find((e: StarProduct) => e.sku === product.id)!.imageURL : "",
                this.checkForColorCode(product),
                product["c_colorDisplayValue"],
                true,
                product["c_description"],
                product["price"],
                product['product_promotions'] ? product['product_promotions'][0]['promotional_price'] : undefined,
                product["c_introduction"],
                TryOnType.variant,
                [], product["id"], false, product["master"]["master_id"], "", makeUpCategory
            ))

        });
        // ajoute l'image du produit à partir de la base locale STAR

        // if (starProducts.starProducts.find((e: StarProduct) => e.sku === master.sku)) {
        //     master.imgURL = starProducts.starProducts.find((e: StarProduct) => e.sku === master.sku)!.imageURL
        // }
        if (playGroundMode) {
            for (let i = 0; i < starTryOnProducts.length; i++) {
                if (starTryOnProducts[i].sku === master.sku) {
                    master.imgURL = starTryOnProducts[i].imageURL
                }
            }

            // if(starProducts.starProducts.find((e: StarProduct) => e.sku === master.sku)){
            //     master.imgURL = starProducts.starProducts.find((e: StarProduct) => e.sku === master.sku)!.imageURL
            //     console.warn(master)
            // }
        }

        if (master.name.includes("Teint") || master.name.includes("Phyto-Blanc")) {
            // Tri des variants par nom
            master.variants = variants.sort((a, b) => a.name.localeCompare(b.name))

            // Cas spécifique du Ecu +, qui doit etre après le  Ecu normal
            let ecuPlus = master.variants.find((e: TryOnProductClass) => e.sku === "180722")

            if (ecuPlus) {
                let index: number = master.variants.indexOf(ecuPlus)
                master.variants.splice(index, 1);
                master.variants.splice(index + 1, 0, ecuPlus);
            }
        }
        else {
            master.variants = variants.sort(function (a, b) {
                return a.name.localeCompare(b.name, undefined, {
                    numeric: true,
                    sensitivity: 'base'
                });
            });
        }


        //Rajout du variant Sans Shade
        if (master.variants.length >= 1) {
            master.variants.unshift(new TryOnProductClass("NoShade", "", 1, true, "", "", "NoShade", true, "", 0, 0, "", TryOnType.variant, [], "", false, master.parentSku))
        }

        // if (master.sku === "100111") {
        //     master.variants.unshift(new TryOnProductClass(
        //         "703477", "Rose Nouméa", 1, true, "", "#9c5e66", "Rose Nouméa", true, "", 56, 25, "", TryOnType.variant, []

        //     ))
        //     master.variants.unshift(new TryOnProductClass(
        //         "878083", "Phyto-Lip Twist N°8 Candy", 1, true, "", "#EF5D6B", "Candy", true, "", 56, 25, "", TryOnType.variant, []

        //     ))
        //     master.variants.unshift(new TryOnProductClass(
        //         "78267", "Phyto-Lip Twist N°26 True Red", 1, true, "", "#A12243", "True Red", true, "", 56, 25, "", TryOnType.variant, []

        //     ))
        // }

        // let productTest = new TryOnProductClass(
        //     "187811", "Phyto Lip Twist test", 10, true, "", "", "", true, "", 38, 0, "", TryOnType.master, []
        // )

        // productTest.variants.push(new TryOnProductClass(
        //     "187811", "Phyto Lip Twist test", 10, true, "", "blue", "blue", true, "", 38, 0, "", TryOnType.variant, []
        // ))
        // productTest.variants.push(new TryOnProductClass(
        //     "187814", "Phyto Lip Twist test 2", 10, true, "", "green", "green", true, "", 38, 0, "", TryOnType.variant, []
        // ))
        // master.variants.unshift(new TryOnProductClass(
        //     "TEST", "Test prix", 13, true, "", "blue", "Shade Test", true, "", 56, 25, "", TryOnType.variant, []
        // ))

        //dispatch({type: Actiontype.UPDATE_TRYON_PRODUCTS, value: productTest})
        master.variants = variants.sort(function (a, b) {
            return a.name.localeCompare(b.name, undefined, {
                numeric: false,
                sensitivity: 'base'
            });
        });

        dispatch({ type: Actiontype.NEW_UPDATE_TRY_ON_PRODUCT, value: master })

        if (variantSkus.length > this.NUMBER_MAX_VARIANTS) {
            console.warn("WE NEED TO ADD MORE THINGS")
            //Get variants (XX premiers)
            fetch(this.url + `products/(${variantSkus.slice(this.NUMBER_MAX_VARIANTS, 54)})?client_id=89da2e79-fc2f-4618-aaa9-1bca77cb883f&expand=availability&expand=promotions&expand=prices&expand=bundled_products&expand=variations`, {
                method: 'GET',
                redirect: 'follow',
            })
                .then(response => response.json())
                .then(result => this.dispatchNewVariant(result, dispatch))
                .catch(error => console.error('error', error));
        }

        //Get Master Product Name
        fetch(this.url + `products/(${master!.parentSku})?client_id=89da2e79-fc2f-4618-aaa9-1bca77cb883f&expand=availability&expand=promotions&expand=prices&expand=bundled_products&expand=variations`, {
            method: 'GET',
            redirect: 'follow',
        })
            .then(response => response.json())
            .then(result => this.dispatchProductWithMasterName(result, master, dispatch))
            .catch(error => console.error('error', error));
    }

    dispatchProductWithMasterName = (result: any, product: TryOnProductClass, dispatch: Function) => {

        if (!result) {
            return;
        }
        product.parentName = result["data"][0]["name"]
        dispatch({ type: Actiontype.NEW_UPDATE_TRY_ON_PRODUCT, value: product })
    }

    fakeLook = (dispatch: Function) => {
        let p = [new TryOnProductClass(
            "873118", "Phyto-Lip Twist N°26 True Red", 1, true, "", "#A12243", "True Red", true, "", 56, 25, "", TryOnType.variant, []
        ),
        new TryOnProductClass(
            "73118", "Phyto-Eye Twist N°6 Marine", 1, true, "", "#007A9E", "Marine", true, "", 56, 25, "", TryOnType.variant, []
        ),
        new TryOnProductClass(
            "873118", "Phyto-Hydra Teint N°3 Golden", 1, true, "", "#C89373", "Golden", true, "", 56, 25, "", TryOnType.variant, []
        ),
        new TryOnProductClass(
            "853417", "Phyto-Hydra Teint N°3 Golden", 1, true, "", "#C89373", "Golden", true, "", 56, 25, "", TryOnType.variant, []
        ),
        new TryOnProductClass(
            "874207", "Phyto-Hydra Teint N°3 Golden", 1, true, "", "#C89373", "Golden", true, "", 56, 25, "", TryOnType.variant, []
        )
        ]


        dispatch({ type: Actiontype.UPDATE_TRYON_PRODUCTS, value: p })
    }

    getMasterProducts = (masterSku: string, dispatch: Function, playGroundMode?: boolean, makeUpCategory?: PGUnderCategory, lang?: string) => {

        if (masterSku === "180607") {
            masterSku = "100117"
        }

        fetch(this.url + `products/${masterSku}?client_id=89da2e79-fc2f-4618-aaa9-1bca77cb883f&expand=availability&expand=promotions&expand=prices&expand=bundled_products&expand=variations`, {
            method: 'GET',
            redirect: 'follow',
        })
            .then(response => response.json())
            .then(result => this.handleProduct(result, dispatch, playGroundMode, makeUpCategory))
            .catch(error => console.error('error', error));
    }

    getAvailibiltyAndParentNameProduct = async (masterSku: string): Promise<[boolean, string]> => {
        let result: [boolean, string] = [false, ""]

        result = await fetch(this.url + `products/${masterSku}?client_id=89da2e79-fc2f-4618-aaa9-1bca77cb883f&expand=availability&expand=promotions&expand=prices&expand=bundled_products&expand=variations`, {
            method: 'GET',
            redirect: 'follow',
        })
            .then(response => response.json())
            .then(result => {
                let res: [boolean, string] = [false, ""]

                if (this.debugCountries.includes(this.lang)) {
                    res[0] = true
                } else if (result.c_displayVTO) {
                    res[0] = result.c_displayVTO
                }

                if (result.name) {
                    res[1] = result.name
                }

                return res
            })
            .catch(error => { return [false, ""] });

        return result
    }

    handleProduct = (result: any, dispatch: Function, playGroundMode?: boolean, makeUpCategory?: PGUnderCategory) => {

        if (result["bundled_products"]) {
            this.getBundledProducts(result, dispatch)
        } else {
            this.getVariantsProducts(result, dispatch, playGroundMode, makeUpCategory)
        }
    }

    setType = (result: any): TryOnType => {
        if (result["type"]) {
            if (result["type"]["bundle"]) {
                return TryOnType.bundle
            }
            if (result["type"]["master"]) {
                return TryOnType.master
            }
            if (result["type"]["item"]) {
                return TryOnType.item
            }
            if (result["type"]["variant"]) {
                return TryOnType.variant
            }
        }
        return TryOnType.master
    }

    getAuth() {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Basic Og==");

        var raw = JSON.stringify({
            "type": "session"
        });


        fetch(this.url + "customers/auth?client_id=89da2e79-fc2f-4618-aaa9-1bca77cb883f", {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        })
            .then(response => response.text())
            .then(result => console.log(result))
            .catch(error => console.log('error', error));
    }
}