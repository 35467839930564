import { motion } from 'framer-motion'
import styled from 'styled-components'
import i18n from '../../i18nextInit'

export const LookTitle = styled.p`
    font: normal normal 300 26px/34px Playfair;
    width: 100%;
    margin: 0px;
    box-sizing: border-box;

    @media (min-width: 1224px){
        padding-left: 0px;
    }
`

export const LookSize = styled.p`
    font: normal normal 500 12px/15px Helvetica Bold;
    width: 100%;
    margin: 0px;
    padding-right: 8px;
    box-sizing: border-box;
`

export const ProductName = styled.p`
    font: normal normal 300 15px/18px Playfair;
    font-size: ${i18n.language === "tw_TW" ? "16px" : ""};
    width: 100%;
    padding-top: 0px;
    text-transform: uppercase;
    margin: 0px;

    @media (orientation: landscape){
        padding-bottom: 6px;
    }
`

export const ProductShade = styled.p<{ isMonoproduct: boolean, isSingleShade: boolean }>`
    font: normal normal 500 13px/15px Helvetica;
    font-size: ${i18n.language === "tw_TW" ? "14px" : ""};
    text-transform: ${props => !props.isMonoproduct ? "uppercase" : ""};
    margin: 0px;
`

export const LittleShadeText = styled.p`
    font: normal normal 300 12px/24px Helvetica;
    margin: 0px;
`

export const LoadingText = styled.p`
    top: 150px;
    left: 0px;
    position: absolute;
    width: 100%;
    text-align: center;
    font: normal normal 500 24px/24px Helvetica bold;
    z-index: -1;

    @media (orientation: landscape){
        width: 50%;
    }
`

export const UnavailableText = styled.p<{ isLeft: boolean }>`
    width: 100%;
    text-align: ${props => props.isLeft ? "left" : "center"};
    margin: ${props => props.isLeft ? "8px 0px 8px" : "8px 0px 12px"};;
    font: normal normal 500 13px/13px Helvetica;
    color: #B23515;
`

export const ErrorDistanceMessage = styled.p`
    color: black;
    margin: 0px;
    font: normal normal 500 14px/12px Helvetica bold;
    font-size: ${i18n.language === "tw_TW" ? "16px" : ""};
`

export const ErrorDistancePanel = styled.div<{ isDisplayed: boolean}>`
    border-radius: 50px;
    background-color: white;
    opacity: ${props => props.isDisplayed ? "0.7" : "0"};
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    transition: all 0.3s;
    position: absolute;
    top: ${props => props.isDisplayed ? "-100px" : "-75px"};
    width: 80%;
    padding: 24px;

    @media (min-width: 768px){
        width: 35%;
        font-size: 16px;
        top: -160px;
    }

    @media (orientation: landscape){
        width: 45vw;
        left : 0px;
        bottom: 64px;
        position: fixed;
        top: initial;
    }
`

export const ErrorDistancePanelDesk = styled.div<{isDisplayed: boolean}>`
    width: 250px;
    left: calc(22.5% - 125px);
    border-radius: 24px;
    background-color: white;
    opacity: ${props => props.isDisplayed ? "0.7" : "0"};
    display: flex;
    transition: all 0.3s;
    top: 120px;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    padding: 16px 0px;
    box-sizing: border-box;
`

export const DesktopTitle = styled.p`
    font: normal normal normal 20px/28px Playfair;
    color: black;
    margin: 0px;
`

export const DesktopText = styled.p`
    font: normal normal 300 13px/21px Helvetica;
    color: black;
    max-width: 450px;
    margin: 20px 0px 48px;
    text-align: center;
`

export const QRCodeContainer = styled.div`
    width: 172px;
    height: 172px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
    position: relative;
`

export const TopLeftBorder = styled.div`
    width: 24px;
    height: 24px;
    border: solid transparent 1px;
    border-top-color: #707070;
    border-left-color: #707070;
    border-top-left-radius: 12px;
    position: absolute;
    top: 0px;
    left: 0px;
`

export const TopRightBorder = styled.div`
    width: 24px;
    height: 24px;
    border: solid transparent 1px;
    border-top-color: #707070;
    border-right-color: #707070;
    border-top-right-radius: 12px;
    position: absolute;
    top: 0px;
    right: 0px;
`

export const BottomLeftBorder = styled.div`
    width: 24px;
    height: 24px;
    border: solid transparent 1px;
    border-bottom-color: #707070;
    border-left-color: #707070;
    border-bottom-left-radius: 12px;
    position: absolute;
    bottom: 0px;
    left: 0px;
`

export const BottomRightBorder = styled.div`
    width: 24px;
    height: 24px;
    border: solid transparent 1px;
    border-bottom-color: #707070;
    border-right-color: #707070;
    border-bottom-right-radius: 12px;
    position: absolute;
    bottom: 0px;
    right: 0px;
`

export const PlayGroundWhiteText = styled.p`
    font: normal normal 900 26px/34px Playfair;
    color: white;
    margin: 0px;
    width: 70%;
    text-align: center;
`

export const PGCellTitle = styled.p<{$isSmall?: boolean}>`
    font: normal normal bold 13px/21px Helvetica;
    font-size: ${props => props.$isSmall && "10px"};
    color: black;
    margin: 10px 0px 0px;
`

export const PGCellGreyText = styled.p`
    color: #8D8D8D;
    font: normal normal 300 13px/20px Helvetica;
    margin: 0px;
`

export const PGCartLookText = styled(motion.p)`
    font: normal normal 400 20px/28px Playfair;
    color: black;
    margin: 0px;
    position: relative;
`

export const PGCartPrice = styled.p`
    margin: 8px 0px;
    color: black;
    display: flex;
    gap: 12px;
    font: normal normal 900 13px/21px Helvetica;
`

export const PGCartTitle = styled.p`
    font: normal normal 300 13px/21px Helvetica;
    margin: 0px;
    color: black;
`

export const PGCartShadeText = styled.p`
    margin: 0px;
    color: black;
    font: normal normal 300 13px/21px Helvetica;
    text-transform: uppercase;
`

export const PGCartModifyText = styled.p`
    color: black;
    font: normal normal bold 12px/21px Helvetica;
    margin: 0px;
    text-align: end;
    text-decoration: underline;
    flex: 1;
`

export const ReplaceText = styled.p`
    color: black;
    font: normal normal normal 20px/28px Playfair;
    text-align: center;
    margin: 0px;
`

export const ReplaceContentText = styled.p`
    color: black;
    font: normal normal normal 13px/21px Helvetica;
    margin: 20px 0px 30px;
    text-align: center;
    padding: 0px 38px;
`

export const MaxProductsText = styled.p<{$isInRed?: boolean}>`
    font: normal normal 300 13px/21px Helvetica;
    color: ${props => props.$isInRed ? "#C53131" : "black"};
    padding: ${props => props.$isInRed && "0px 15px"};
    margin: 10px 0px 10px;
`