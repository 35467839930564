import { TFunction } from "i18next"

import imageMob1 from '../Assets/Background-Images/Mob/Question1_MOB.jpg'
import imageMob2 from '../Assets/Background-Images/Mob/Question2_MOB.jpg'
import imageMob3 from '../Assets/Background-Images/Mob/Question3_MOB.jpg'
import imageMob4 from '../Assets/Background-Images/Mob/Question4_MOB.jpg'
import imageMob5 from '../Assets/Background-Images/Mob/Question5_MOB.jpg'
import imageMob6 from '../Assets/Background-Images/Mob/Question6_MOB.jpg'
import imageMob7 from '../Assets/Background-Images/Mob/Question7_MOB.jpg'
import imageMob8 from '../Assets/Background-Images/Mob/Question8_MOB.jpg'

import imageTab1 from '../Assets/Background-Images/Tab/Question1_TAB.jpg'
import imageTab2 from '../Assets/Background-Images/Tab/Question2_TAB.jpg'
import imageTab3 from '../Assets/Background-Images/Tab/Question3_TAB.jpg'
import imageTab4 from '../Assets/Background-Images/Tab/Question4_TAB.jpg'
import imageTab5 from '../Assets/Background-Images/Tab/Question5_TAB.jpg'
import imageTab6 from '../Assets/Background-Images/Tab/Question6_TAB.jpg'
import imageTab7 from '../Assets/Background-Images/Tab/Question7_TAB.jpg'
import imageTab8 from '../Assets/Background-Images/Tab/Question8_TAB.jpg'

import imageDesk1 from '../Assets/Background-Images/Desk/Question1_DESK.jpg'
import imageDesk2 from '../Assets/Background-Images/Desk/Question2_DESK.jpg'
import imageDesk3 from '../Assets/Background-Images/Desk/Question3_DESK.jpg'
import imageDesk4 from '../Assets/Background-Images/Desk/Question4_DESK.jpg'
import imageDesk5 from '../Assets/Background-Images/Desk/Question5_DESK.jpg'
import imageDesk6 from '../Assets/Background-Images/Desk/Question6_DESK.jpg'
import imageDesk7 from '../Assets/Background-Images/Desk/Question7_DESK.jpg'
import imageDesk8 from '../Assets/Background-Images/Desk/Question8_DESK.jpg'

import { analysisNameToConcernKey } from './Utilitaries'
import i18n from "../i18nextInit"

export enum Quest {
    sex = 0,
    preoccupationsPrincipales,
    contourYeux,
    peau,
    preferenceDemaquillage2,
    preferenceDemaquillage,
    manqueVitalite,
    routineNuit,
    exposomes,
    done
}

export enum Answer {

    homme = "homme",
    femme = "femme",

    //type peau
    seche = "seche",
    normal = "normal",
    grasse = "grasse",

    //preference demaquillage
    avecRincage = "PDavecRincage",
    sansrincage = "PDsansRincage",
    visageYeux = "PDvisageYeux",
    specifiqueVisage = "PDspecifiqueVisage",

    //preoccupation contour des yeux
    CYpoche = "CYpoches",
    CYcernes = "CYcernes",
    CYrelachement = "CYrelachement",
    CYrides = "CYrides",
    CYridules = "CYridules",

    //preoccupations principales
    PPrides = "PPrides",
    PPperteFermete = "PPperteFermete",
    PPtaches = "PPtaches",
    PPsigneFatigue = "PPsignesFatigue",
    PPmanqueEclat = "PPmanqueEclat",
    PPdeshydratation = "PPdeshydratation",
    PPsensibilite = "PPsensibilite",
    PPimperfections = "PPimperfections",
    PPbrillance = "PPbrillance",
    PPgrainDePeau = "PPgrainDePeau",
    PPtracesResiduelles = "PPtracesResiduelles",
    PPpores = "PPpores",

    //manque de vitalite
    MVoui = "MVoui",
    MVnon = "MVnon",

    // soin de nuit
    SDNoui = "SDNoui",
    SDNnon = "SDNnon",

    // exposomes
    pollution = "pollution",
    ecrans = "ecrans",
    stress = "stress",
    manqueSommeil = "manqueSommeil",

    // preference teint
    finiNaturel = "finiNaturel",
    finiSatine = "finiSatine",
    finiMat = "finiMat",
    finiVeloute = "finiVeloute",

    noAnswer = "noAnswer",
    problem = 'Il y a un problem'
}

export const setQuest = (quest: Quest, t: TFunction): string => {
    switch (quest) {
        case Quest.sex:
            return t('questionView.question.sex', 'Etes-vous ?')
        case Quest.peau:
            return t('questionView.question.peau', 'Votre peau est plutôt :')
        case Quest.preferenceDemaquillage:
            return t('questionView.question.preferenceDemaquillage', 'Vous aimez les soins démaquillants :')
        case Quest.preferenceDemaquillage2:
            return t('questionView.question.preferenceDemaquillage2', 'Vous aimez vous démaquiller avec ?')
        case Quest.contourYeux:
            return t('questionView.question.contourYeux', "Qu'observez-vous sur votre contour des yeux ?")
        case Quest.preoccupationsPrincipales:
            return t('questionView.question.preoccupationsPrincipales', 'Quelles sont vos 3 principales préoccupations beauté ?')
        case Quest.manqueVitalite:
            return t('questionView.question.manqueVitalite', "Sentez-vous votre peau particulièrement fatiguée, en manque de vitalité ?")
        case Quest.routineNuit:
            return t('questionView.question.routineNuit', 'Avez-vous l’habitude d’utiliser un soin de nuit ?')
        case Quest.exposomes:
            return t('questionView.question.exposomes', 'Votre peau est-elle exposée à un ou plusieurs de ces éléments ?')
        default:
            return 'Il y a un probleme'
    }
}
export const setAnswers = (quest: Quest): Array<Answer> => {
    switch (quest) {
        case Quest.peau:
            return [Answer.seche, Answer.normal, Answer.grasse]
        case Quest.preferenceDemaquillage:
            return [Answer.avecRincage, Answer.sansrincage]
        case Quest.preferenceDemaquillage2:
            return [Answer.visageYeux, Answer.specifiqueVisage]
        case Quest.contourYeux:
            return [Answer.CYpoche, Answer.CYcernes, Answer.CYrelachement, Answer.CYrides, Answer.CYridules]
        case Quest.preoccupationsPrincipales:
            if(i18n.language === "tw_TW"){
                return [Answer.PPrides, Answer.PPperteFermete, Answer.PPtaches, Answer.PPpores, Answer.PPmanqueEclat, Answer.PPdeshydratation, Answer.PPsensibilite, Answer.PPimperfections, Answer.PPbrillance, Answer.PPgrainDePeau, Answer.PPtracesResiduelles]
            } else {
                return [Answer.PPrides, Answer.PPperteFermete, Answer.PPtaches, Answer.PPsigneFatigue, Answer.PPmanqueEclat, Answer.PPdeshydratation, Answer.PPsensibilite, Answer.PPimperfections, Answer.PPbrillance, Answer.PPgrainDePeau, Answer.PPtracesResiduelles]
            }
        case Quest.manqueVitalite:
            return [Answer.MVoui, Answer.MVnon]
        case Quest.routineNuit:
            return [Answer.SDNoui, Answer.SDNnon]
        case Quest.exposomes:
            return [Answer.pollution, Answer.ecrans, Answer.stress, Answer.manqueSommeil]
        default:
            return [Answer.problem]
    }
}

export const setAnswerTranslation = (answer: Answer | string, t: TFunction, plurals: boolean = false) => {
    switch (answer) {
        case Answer.CYcernes:
            return t('questionView.answer.CYcernes', 'Cernes')
        case Answer.PPpores:
            return t('questionView.answer.PPpores', "Pores")
        case Answer.CYpoche:
            return t('questionView.answer.CYpoches', 'Poches')
        case Answer.CYrelachement:
            return t('questionView.answer.CYrelachement', 'Relâchement')
        case Answer.CYrides:
            return t('questionView.answer.CYrides', 'Rides')
        case Answer.CYridules:
            return t('questionView.answer.CYridules', 'Ridules')
        case Answer.MVnon:
            return t('questionView.answer.MVnon', 'Non')
        case Answer.MVoui:
            return t('questionView.answer.MVoui', 'Oui')
        case Answer.PPbrillance:
            return t('questionView.answer.PPbrillance', 'Brillance')
        case Answer.PPdeshydratation:
            return t('questionView.answer.PPdeshydratation', 'Déshydratation')
        case Answer.PPgrainDePeau:
            return t('questionView.answer.PPgrainDePeau', 'Grain de peau irrégulier')
        case Answer.PPimperfections:
            return t('questionView.answer.PPimperfections', 'Imperfections')
        case Answer.PPmanqueEclat:
            return t('questionView.answer.PPmanqueEclat', 'Manque d\'éclat')
        case Answer.PPperteFermete:
            return t('questionView.answer.PPperteFermete', 'Perte de fermeté')
        case Answer.PPrides:
            return t('questionView.answer.PPrides', 'Rides')
        case Answer.PPsensibilite:
            return t('questionView.answer.PPsensibilite', 'Sensibilités')
        case Answer.PPsigneFatigue:
            return t('questionView.answer.PPsignesFatigue', 'Signes de fatigues')
        case Answer.PPtaches:
            return t('questionView.answer.PPtaches', 'Taches pigmentaires')
        case Answer.PPtracesResiduelles:
            return t('questionView.answer.PPtachesResiduelles', 'Traces résiduelles post imperfections')
        case Answer.SDNnon:
            return t('questionView.answer.SDNnon', 'Non')
        case Answer.SDNoui:
            return t('questionView.answer.SDNoui', 'Oui')
        case Answer.avecRincage:
            return t('questionView.answer.avecRincage', 'Avec rinçage')
        case Answer.sansrincage:
            return t('questionView.answer.sansRincage', 'Sans rinçage')
        case Answer.visageYeux:
            return t('questionView.answer.visageYeux', 'Un soin différent pour les yeux et le visage')
        case Answer.specifiqueVisage:
            return t('questionView.answer.specifiqueVisage', 'Un seul soin pour les yeux et le visage')
        case Answer.pollution:
            return t('questionView.answer.pollution', 'Pollution')
        case Answer.ecrans:
            return t('questionView.answer.ecrans', 'Ecrans')
        case Answer.stress:
            return t('questionView.answer.stress', 'Stress')
        case Answer.manqueSommeil:
            return t('questionView.answer.manqueSommeil', 'Manque de sommeil')
        case Answer.finiMat:
            return t('questionView.answer.finiMat', 'Fini mat + haut de couvrance')
        case Answer.finiNaturel:
            return t('questionView.answer.finiNaturel', 'Fini naturel + couvrance légère')
        case Answer.finiSatine:
            return t('questionView.answer.finiSatine', 'Fini satiné + couvrance moyenne')
        case Answer.finiVeloute:
            return t('questionView.answer.finiVeloute', 'Fini velouté + couvrance moyenne')
        case Answer.homme:
            return t('questionView.answer.homme', 'Un homme')
        case Answer.femme:
            return t('questionView.answer.femme', 'Une femme')
        case Answer.seche:
            return t('questionView.answer.seche', 'Sèche')
        case Answer.normal:
            return t('questionView.answer.normal', 'Normal')
        case Answer.grasse:
            return t('questionView.answer.grasse', 'Mixte à grasse')
        case "drySkin":
            return t('questionView.answer.peauSeche', 'Peau sèche')
        case "normalSkin":
            return t('questionView.answer.peauNormal', 'Peau normale')
        case "confort":
            return t('questionView.answer.confort', 'Confort')
        default:
            return t('questionView.answer.problem', 'Il y a une traduction manquante')
    }
}

export const setSelectionnable = (quest: Quest) => {
    switch (quest) {
        case Quest.preferenceDemaquillage:
            return false
        case Quest.preferenceDemaquillage2:
            return false
        case Quest.contourYeux:
            return true
        case Quest.exposomes:
            return true
        case Quest.preoccupationsPrincipales:
            return true
        default:
            return false
    }
}

export const setOrder = (quest: Quest) => {
    switch (quest) {
        default:
            return false
    }
}

export const setNumberOfAnswer = (quest: Quest): number => {
    switch (quest) {
        case Quest.contourYeux:
            return 1
        case Quest.preoccupationsPrincipales:
            return 2
        case Quest.exposomes:
            return 2
        default: return 1
    }
}

export const setStrengthAnswer = (quest: Quest, analyses: any): Array<string> => {
    let recommendedArray: Array<string> = []
    let sortedAnalyses: Array<any> = [...analyses]

    sortedAnalyses.sort((a: any, b: any) => {
        return b.score - a.score
    })

    if (sortedAnalyses.length > 0) {
        switch (quest) {
            case Quest.contourYeux:
                let eyesAnalysis: Array<any> = [...sortedAnalyses.filter((e: any) => e.name === "DARK_CIRCLES" || e.name === "EYES_BAGS" || e.name === "LOSS_OF_FIRMNESS" || e.name === "WRINKLES")]
                if (eyesAnalysis[eyesAnalysis.length -1].name === "WRINKLES") {
                    if (eyesAnalysis.find((e: any) => e.name === "WRINKLES").score > 0.25) {
                        recommendedArray.push(Answer.CYrides)
                    } else {
                        recommendedArray.push(Answer.CYridules)
                    }
                } if (eyesAnalysis[eyesAnalysis.length -1].name === "DARK_CIRCLES") {
                    recommendedArray.push(Answer.CYcernes)

                } if (eyesAnalysis[eyesAnalysis.length -1].name === "EYES_BAGS") {
                    recommendedArray.push(Answer.CYpoche)
                }
                if (eyesAnalysis[eyesAnalysis.length -1].name === "LOSS_OF_FIRMNESS") {
                    recommendedArray.push(Answer.CYrelachement)
                }

                break
            case Quest.preoccupationsPrincipales:
                let ppAnalysis: Array<any> = [...sortedAnalyses.filter((e: any) => e.name === "SHININESS" || e.name === "WRINKLES" || e.name === "DARK_SPOTS" || e.name === "LOSS_OF_FIRMNESS" || e.name === "RADIANCE" || e.name === "ROUGHNESS")]
                recommendedArray.push(analysisNameToConcernKey(ppAnalysis[ppAnalysis.length -1].name))
                recommendedArray.push(analysisNameToConcernKey(ppAnalysis[ppAnalysis.length -2].name))
                break
            default:
                break
        }
    }
    return recommendedArray
}

export const setPriorityAnswers = (quest: Quest, analyses: Array<any>): Array<string> => {
    let recommendedArray: Array<string> = []
    let sortedAnalyses: Array<any> = [...analyses]
    sortedAnalyses.sort((a: any, b: any) => {
        return b.score - a.score
    })

    if (sortedAnalyses.length > 0) {
        switch (quest) {
            case Quest.contourYeux:
                let eyesAnalysis: Array<any> = [...sortedAnalyses.filter((e: any) => e.name === "DARK_CIRCLES" || e.name === "EYES_BAGS" || e.name === "LOSS_OF_FIRMNESS" || e.name === "WRINKLES")]
                if (eyesAnalysis[0].name === "WRINKLES") {
                    if (eyesAnalysis.find((e: any) => e.name === "WRINKLES").score > 0.25) {
                        recommendedArray.push(Answer.CYrides)
                    } else {
                        recommendedArray.push(Answer.CYridules)
                    }
                } if (eyesAnalysis[0].name === "DARK_CIRCLES") {
                    recommendedArray.push(Answer.CYcernes)
                } if (eyesAnalysis[0].name === "EYES_BAGS") {
                    recommendedArray.push(Answer.CYpoche)
                }
                if (eyesAnalysis[0].name === "LOSS_OF_FIRMNESS") {
                    recommendedArray.push(Answer.CYrelachement)
                }

                break
            case Quest.preoccupationsPrincipales:
                let ppAnalysis: Array<any> = [...sortedAnalyses.filter((e: any) => e.name === "SHININESS" || e.name === "WRINKLES" || e.name === "DARK_SPOTS" || e.name === "LOSS_OF_FIRMNESS" || e.name === "RADIANCE" || e.name === "ROUGHNESS")]

                recommendedArray.push(analysisNameToConcernKey(ppAnalysis[0].name))
                recommendedArray.push(analysisNameToConcernKey(ppAnalysis[1].name))
                break
            default:
                break
        }
    }
    return recommendedArray
}

export const setbackgroundSRC = (questStep: Quest): string => {
    switch (questStep) {
        case Quest.peau:
            return window.innerWidth > 1224 ? imageDesk3 : window.innerWidth > 768 ? imageTab3 : imageMob3
        case Quest.preferenceDemaquillage:
            return window.innerWidth > 1224 ? imageDesk5 : window.innerWidth > 768 ? imageTab5 : imageMob5
        case Quest.preferenceDemaquillage2:
            return window.innerWidth > 1224 ? imageDesk4 : window.innerWidth > 768 ? imageTab4 : imageMob4
        case Quest.contourYeux:
            return window.innerWidth > 1224 ? imageDesk2 : window.innerWidth > 768 ? imageTab2 : imageMob2
        case Quest.exposomes:
            return window.innerWidth > 1224 ? imageDesk8 : window.innerWidth > 768 ? imageTab8 : imageMob8
        case Quest.preoccupationsPrincipales:
            return window.innerWidth > 1224 ? imageDesk1 : window.innerWidth > 768 ? imageTab1 : imageMob1
        case Quest.manqueVitalite:
            return window.innerWidth > 1224 ? imageDesk6 : window.innerWidth > 768 ? imageTab6 : imageMob6
        case Quest.routineNuit:
            return window.innerWidth > 1224 ? imageDesk7 : window.innerWidth > 768 ? imageTab7 : imageMob7
        default:
            return window.innerWidth > 1224 ? imageDesk7 : window.innerWidth > 768 ? imageTab7 : imageMob7
    }
}