import { RoutineContainer, SwitchSelectorContainer, RecoViewContainer, SingleRoutineContainer, SwitchButtonCtnr, SwitchButtonTextCtnr, SwitchSlider } from '../../../Styles/Containers'
import { AddToCartRoutineButton, StaticAddRoutineButton, WhiteButton } from '../../../Styles/Buttons'
import { useTranslation } from 'react-i18next'
import { BlackRound, Line } from '../../../Styles/Images'
import { RoutineText, LittleRoutineText, RoutineTextIntro, YourRoutine } from '../../../Styles/Texts'
import { useEffect, useState } from 'react'
import { ProductView } from '../ProductView'
import FooterView from '../FooterView'
import { CSSTransition } from 'react-transition-group'
import { useDispatch, useSelector } from 'react-redux'
import * as ActionTypes from '../../../Constants/ActionsTypes'
import { MySkinDiagState } from '../../../type'
import OptInView from "../../OptInView"
import { addToCartProducts } from '../../../API/SalesForceAPI'

import chevronIcon from "../../../Assets/Icons/Chevron.svg"
import ConfirmPopUp from '../../ConfirmPopUp'
import { handleFormatPrice, runHeightMachine, sendAnalytics } from '../../../Constants/Utilitaries'
import { LookSize } from '../../../Styles/VTO/VTOTexts'
import i18n from '../../../i18nextInit'

const RecoView = () => {
    const { t } = useTranslation()
    const [inProp, setInProp] = useState<boolean>(false)
    const dispatch = useDispatch()
    const session = useSelector((state: MySkinDiagState) => state.session)
    const [longRoutineProducts, setLongRoutine] = useState<Array<any>>([])
    const [shortRoutineProducts, setshortRoutine] = useState<Array<any>>([])
    const [isShortRoutineHeight, setShortRoutineHeight] = useState<boolean>(true)
    const [optIn, setOptin] = useState<boolean>(false)
    const [isRoutineCompleteDisplayed, setRoutineCompleteDisplay] = useState<boolean>(false)
    const [closeIn, setCloseIn] = useState<boolean>(false)
    const [isDesktop, setDesktop] = useState<boolean>(window.innerWidth > 1224)
    const [shortRoutinePrices, setShortPrices] = useState<[number, number]>([0, 0])
    const [longRoutinePrices, setLongPrices] = useState<[number, number]>([0, 0])
    const isSignedIn = useSelector((store: MySkinDiagState) => store.session.isSignedIn)

    //eslint-disable-next-line
    let recoButtonOffset: any

    useEffect(() => {
        if (document.getElementById("RECO_BUTTON")) {
            //eslint-disable-next-line
            recoButtonOffset = document.getElementById("SWITCH_SELECTOR")!.getBoundingClientRect().top - window.innerHeight * 0.5
        }
        setInProp(true)
        window.scrollTo(0, 0)

        let dataBis = {
            messageType: "scroll_top",
        };
        console.log(dataBis)
        window.parent.postMessage(dataBis, "*")

        const scrollHandler = () => {
            if (window.innerWidth > 1224) {
                setDesktop(true)
            } else {
                setDesktop(false)
            }
        }

        window.addEventListener('scroll', scrollHandler)

        return (() => {
            window.removeEventListener("scroll", scrollHandler)
        })

    }, [])



    useEffect(() => {
        console.log(session.products)
        let longRoutineProductsTab: Array<any> = []
        let shortRoutineProductsTab: Array<any> = []
        let shortPrices: [number, number] = [0, 0]
        let longPrices: [number, number] = [0, 0]

        let key: number = 1
        let shortKey: number = 1
        let globalKey: number = 1
        session.products.forEach((product: any) => {
            if (product.sku === "NOTHING IN CATEGORY") {
                key++
            } else {
                longRoutineProductsTab.push(<ProductView product={product} index={key} key={key} globalKey={globalKey} />)

                if (window.ASIAN_LANGS.includes(i18n.language) ? (key === 1 || key === 2 || key === 5) : (key === 1 || key === 2 || key === 4)) {
                    shortRoutineProductsTab.push(<ProductView product={product} shortIndex={key} index={shortKey} key={key} />)
                    shortKey++

                    if (product.price && product.stock > 0) {
                        if (product.promoPrice < product.price) {
                            shortPrices[1] += product.promoPrice
                        } else {
                            shortPrices[1] += product.price
                        }
                        shortPrices[0] += product.price
                    }

                }

                if (product.price && product.stock > 0) {
                    if (product.promoPrice < product.price) {
                        longPrices[1] += product.promoPrice
                    } else {
                        longPrices[1] += product.price
                    }
                    longPrices[0] += product.price
                }

                key++
                globalKey++
            }
        });

        setShortPrices(shortPrices)
        setLongPrices(longPrices)
        setLongRoutine(longRoutineProductsTab)
        setshortRoutine(shortRoutineProductsTab)

        //eslint-disable-next-line
    }, [session])

    useEffect(() => {
        setTimeout(() => {
            runHeightMachine()
        }, 300);
    }, [shortRoutineProducts])

    useEffect(() => {
        if (document.getElementById("RECO_VIEW")!.offsetHeight !== 0 && window.innerWidth < 1224) {
            runHeightMachine(true, "RECO_VIEW", 24)

        }

        if (window.innerWidth > 1224 && document.getElementById("RESULT_VIEW")!.offsetHeight !== 0) {
            runHeightMachine(true, "RESULT_VIEW", 24)
        }

        //Gère si la hauteur de la courte routine.
        if (document.getElementById("LONG_ROUTINE_CONTAINER")) {
            if (isRoutineCompleteDisplayed) {
                document.getElementById("LONG_ROUTINE_CONTAINER")!.style.height = "initial"
            } else {
                document.getElementById("LONG_ROUTINE_CONTAINER")!.style.height = "1000px"
                //window.scrollTo(0, 0)
            }
        }


    }, [isRoutineCompleteDisplayed])

    const changeReco = (shouldGoToTop: boolean = false) => {

        if (shouldGoToTop) {
            window.scrollTo(0, 0)

            let dataBis = {
                messageType: "scroll_top",
            };
            console.log(dataBis)
            window.parent.postMessage(dataBis, "*")
        }

        setRoutineCompleteDisplay(!isRoutineCompleteDisplayed)
        setShortRoutineHeight(!isShortRoutineHeight)
    }

    const displayOptIn = () => {
        if (optIn) {
            return (
                <div style={{ width: "100vw", height: "100vh", top: 0, position: "fixed", zIndex: 10 }}>
                    <OptInView key={0} setOptIn={setOptin} />
                </div>)
        } else {
            return <div />
        }
    }

    const optinHandler = () => {
        setOptin(true)

        let data = {
            messageType: "toggle_fullscreen",
            value: true
        }
        console.log(data)
        window.parent.postMessage(data, "*")
    }

    const addRoutineToCart = () => {
        let shortRoutineSkus: Array<string> = []
        let longRoutineSkus: Array<string> = []
        sendAnalytics("addtocartall")

        for (let i = 0; i < session.products.length; i++) {
            if (session.panier.filter((e: string) => e === session.products[i].sku).length < 4) {
                if (session.products[i].stock > 0) {

                    if (session.products[i].sku === "108310" && (i18n.language === "fr_FR" || i18n.language === "en_GB" || i18n.language === "en_US")) {
                        session.products[i].sku = "108311"
                        console.warn("CHANGED !")
                    }

                    longRoutineSkus.push(session.products[i].sku)
                }
            }
            if (window.ASIAN_LANGS.includes(i18n.language)) {
                if (i === 0 || i === 1 || i === 4) {
                    if (session.panier.filter((e: string) => e === session.products[i].sku).length < 4) {
                        if (session.products[i].stock > 0) {
                            shortRoutineSkus.push(session.products[i].sku)
                        }
                    }
                }
            } else {
                if (i === 0 || i === 1 || i === 3) {
                    if (session.panier.filter((e: string) => e === session.products[i].sku).length < 4) {
                        if (session.products[i].stock > 0) {

                            if (session.products[i].sku === "108310" && (i18n.language === "fr_FR" || i18n.language === "en_GB" || i18n.language === "en_US")) {
                                session.products[i].sku = "108311"
                                console.warn("CHANGED !")
                            }

                            shortRoutineSkus.push(session.products[i].sku)
                        }
                    }
                }
            }

        };

        addToCartProducts(isShortRoutineHeight ? shortRoutineSkus : longRoutineSkus, session.clientData)
        dispatch({ type: ActionTypes.UPDATE_PANIER, value: isShortRoutineHeight ? shortRoutineSkus : longRoutineSkus })
    }

    const checkRoutineClickable = (): boolean => {
        let count: number = 0
        if (isShortRoutineHeight) {
            session.panier.forEach((sku: string) => {
                if (session.panier.filter((e: string) => e === sku).length >= 4) {
                    count++
                }
            });

            if (count >= 12) {
                return false
            }
        } else {
            session.panier.forEach((sku: string) => {
                if (session.panier.filter((e: string) => e === sku).length >= 4) {
                    count++
                }
            });

            if (count >= session.products.length * 4) {
                return false
            }
        }

        return true
    }

    const displayClosePopUp = () => {
        if (closeIn) {
            return (<div style={{ width: "100vw", height: "100vh", top: "0px", position: "fixed", zIndex: 10 }}>
                <ConfirmPopUp key={0} setOptIn={setCloseIn} />
            </div>)
        } else {
            return <div />
        }
    }

    const handleSisleyProductsText = (length: number) => {
        if (i18n.language !== "pl_PL") {
            return t('results.sisleyProducts')
        }

        if (length > 4) {
            return t('results.sisleyProducts_many')
        } else {
            t('results.sisleyProducts')
        }
    } 

    return (
        <CSSTransition addEndListener={() => null} classNames="swipeToRight" in={inProp}>
            <RecoViewContainer id="RECO_VIEW">
                {window.innerWidth < 1224 && <div style={{ minHeight: '42px', display: 'flex', justifyContent: 'space-between', width: 'calc(100% - 24px)', margin: '8px 0 32px', gap: "8px" }}>
                    <div style={{ flex: 1, alignItems: 'center', display: 'flex' }}>
                        <BlackRound onClick={() => dispatch({ type: ActionTypes.SET_STEP, value: 5 })} style={{
                            backgroundColor: 'transparent',
                            border: '0.5px solid #979797',
                            color: '#979797',
                            width: '36px',
                            height: '36px'
                        }}><img style={{ filter: "brightness(0", height: "8px", transform: window.RTL_LANGS.includes(i18n.language) ? "rotate(90deg)" : "rotate(-90deg)" }} src={chevronIcon} alt="chevrons" /></BlackRound>
                    </div>
                    {!window.MAIL_DISABLED_LANGS.includes(i18n.language) && <WhiteButton onClick={optinHandler} disabled={isSignedIn}
                        style={{
                            flex: '5 1 100%',
                            font: 'normal normal 500 10px/15px Helvetica Bold',
                            color: isSignedIn ? "gray" : ""
                        }}>{t('results.getByMailCTA', "Recevoir mes résultats par email")}</WhiteButton>}
                </div>}

                <YourRoutine>{t('results.votreRoutine', 'Votre')}<span style={{ fontStyle: "italic" }}> {t('results.votreRoutine2', 'Routine')}</span></YourRoutine>

                <RoutineTextIntro>
                    {t('results.recoIntro', 'Pour sublimer votre peau au quotidien, nous vous recommandons deux routines soin : essentielle ou experte.')}
                    <span style={{ fontFamily: "Helvetica Bold" }}> {t("results.recoIntro2")}</span>
                </RoutineTextIntro>

                <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <SwitchSelectorContainer isDisplayed={true} isInDesktopMode={false} isFlying={false} id="SWITCH_SELECTOR">

                        <SwitchButtonCtnr onClick={() => changeReco()}>
                            <SwitchSlider $selected={!isRoutineCompleteDisplayed} />

                            <SwitchButtonTextCtnr $selected={!isRoutineCompleteDisplayed}>
                                <RoutineText>{t('results.essentialRoutine', 'Routine essentielle')}</RoutineText>
                                <LittleRoutineText>3 {t('results.sisleyProducts')}</LittleRoutineText>
                            </SwitchButtonTextCtnr>

                            <SwitchButtonTextCtnr $selected={isRoutineCompleteDisplayed}>
                                <RoutineText>{t('results.expertRoutine', 'Routine experte')}</RoutineText>
                                <LittleRoutineText>{longRoutineProducts.length} {handleSisleyProductsText(longRoutineProducts.length)}</LittleRoutineText>
                            </SwitchButtonTextCtnr>
                        </SwitchButtonCtnr>

                    </SwitchSelectorContainer>

                    {isDesktop &&
                        <>
                            <div style={{ display: "flex", width: "270px", flexDirection: "row-reverse" }}>

                                {<LookSize style={{ width: "auto", fontWeight: 900, paddingLeft: true ? "0px" : "" }}>
                                    {handleFormatPrice(isRoutineCompleteDisplayed ? longRoutinePrices[1] : shortRoutinePrices[1])}
                                </LookSize>}

                                {shortRoutinePrices[0] !== shortRoutinePrices[1] && <LookSize style={{ width: "auto", fontWeight: 900, textDecoration: "line-through", color: "#CACACA" }}>
                                    {handleFormatPrice(isRoutineCompleteDisplayed ? longRoutinePrices[0] : shortRoutinePrices[0])}
                                </LookSize>}

                            </div>

                            <AddToCartRoutineButton onClick={() => addRoutineToCart()} isActive={checkRoutineClickable()}>
                                {t('results.addRoutineToProducts', 'Ajouter la routine au panier')}
                            </AddToCartRoutineButton>
                        </>}
                </div>

                <RoutineContainer $routineComplete={isRoutineCompleteDisplayed}>
                    <SingleRoutineContainer id="SHORT_ROUTINE_CONTAINER" isCompleteRoutineDisplayed={isRoutineCompleteDisplayed}>
                        {!isRoutineCompleteDisplayed && shortRoutineProducts}
                        {isDesktop && isRoutineCompleteDisplayed && longRoutineProducts}

                        {!isDesktop && <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", padding: "12px", boxSizing: "border-box" }}>

                            {!isDesktop &&
                                <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>

                                    <div style={{ display: "flex", flexDirection: "row-reverse" }}>

                                        {<LookSize style={{ width: "auto", fontWeight: 900, paddingLeft: true ? "0px" : "" }}>
                                            {handleFormatPrice(isRoutineCompleteDisplayed ? longRoutinePrices[1] : shortRoutinePrices[1])}
                                        </LookSize>}

                                        {shortRoutinePrices[0] !== shortRoutinePrices[1] && <LookSize style={{ width: "auto", fontWeight: 900, textDecoration: "line-through", color: "#CACACA" }}>
                                            {handleFormatPrice(isRoutineCompleteDisplayed ? longRoutinePrices[0] : shortRoutinePrices[0])}
                                        </LookSize>}

                                    </div>
                                </div>}

                            <StaticAddRoutineButton onClick={() => addRoutineToCart()}>
                                {t('results.addRoutineToProducts', 'Ajouter la routine au panier')}
                            </StaticAddRoutineButton>
                        </div>
                        }

                        {!isDesktop ?
                            <FooterView isInReco={true} retry={setCloseIn} optinHandler={optinHandler} isShortRoutine /> : <div style={{ width: "100%", height: "20px" }} id="FOOTER_VIEW" />}
                    </SingleRoutineContainer>

                    {!isDesktop && <SingleRoutineContainer id="LONG_ROUTINE_CONTAINER" isCompleteRoutineDisplayed={!isRoutineCompleteDisplayed}>
                        {longRoutineProducts}

                        {!isDesktop && <Line />}
                        {!isDesktop &&
                            <div style={{ width: "100%", display: "flex" }}>
                                <SwitchSelectorContainer isDisplayed={true} isInDesktopMode={false} isFlying={false} id="SWITCH_SELECTOR">

                                    <SwitchButtonCtnr onClick={() => changeReco(true)}>
                                        <SwitchSlider $selected={!isRoutineCompleteDisplayed} />

                                        <SwitchButtonTextCtnr $selected={!isRoutineCompleteDisplayed}>
                                            <RoutineText>{t('results.essentialRoutine', 'Routine essentielle')}</RoutineText>
                                            <LittleRoutineText>3 {t('results.sisleyProducts', 'Soins Sisley')}</LittleRoutineText>
                                        </SwitchButtonTextCtnr>

                                        <SwitchButtonTextCtnr $selected={isRoutineCompleteDisplayed}>
                                            <RoutineText>{t('results.expertRoutine', 'Routine experte')}</RoutineText>
                                            <LittleRoutineText>{longRoutineProducts.length} {handleSisleyProductsText(longRoutineProducts.length)}</LittleRoutineText>
                                        </SwitchButtonTextCtnr>
                                    </SwitchButtonCtnr>
                                </SwitchSelectorContainer>
                            </div>
                        }

                        {!isDesktop && <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", padding: "24px 12px 12px", boxSizing: "border-box" }}>

                            {!isDesktop &&
                                <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>

                                    <div style={{ display: "flex", flexDirection: "row-reverse" }}>

                                        {<LookSize style={{ width: "auto", fontWeight: 900, paddingLeft: true ? "0px" : "" }}>
                                            {handleFormatPrice(isRoutineCompleteDisplayed ? longRoutinePrices[1] : shortRoutinePrices[1])}
                                        </LookSize>}

                                        {shortRoutinePrices[0] !== shortRoutinePrices[1] && <LookSize style={{ width: "auto", fontWeight: 900, textDecoration: "line-through", color: "#CACACA" }}>
                                            {handleFormatPrice(isRoutineCompleteDisplayed ? longRoutinePrices[0] : shortRoutinePrices[0])}
                                        </LookSize>}

                                    </div>
                                </div>}

                            <StaticAddRoutineButton onClick={() => addRoutineToCart()}>
                                {t('results.addRoutineToProducts', 'Ajouter la routine au panier')}
                            </StaticAddRoutineButton>
                        </div>
                        }

                        {!isDesktop &&
                            <FooterView isInReco={true} retry={setCloseIn} optinHandler={optinHandler} />
                        }
                    </SingleRoutineContainer>}


                </RoutineContainer>

                {isDesktop && isRoutineCompleteDisplayed && <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <SwitchSelectorContainer isDisplayed={true} isInDesktopMode={false} isFlying={false} id="SWITCH_SELECTOR">

                        <SwitchButtonCtnr onClick={() => changeReco()}>
                            <SwitchSlider $selected={!isRoutineCompleteDisplayed} />

                            <SwitchButtonTextCtnr $selected={!isRoutineCompleteDisplayed}>
                                <RoutineText>{t('results.essentialRoutine', 'Routine essentielle')}</RoutineText>
                                <LittleRoutineText>3 {t('results.sisleyProducts')}</LittleRoutineText>
                            </SwitchButtonTextCtnr>

                            <SwitchButtonTextCtnr $selected={isRoutineCompleteDisplayed}>
                                <RoutineText>{t('results.expertRoutine', 'Routine experte')}</RoutineText>
                                <LittleRoutineText>{longRoutineProducts.length} {handleSisleyProductsText(longRoutineProducts.length)}</LittleRoutineText>
                            </SwitchButtonTextCtnr>
                        </SwitchButtonCtnr>


                    </SwitchSelectorContainer>

                    {isDesktop &&
                        <>
                            <div style={{ display: "flex", width: "270px", flexDirection: "row-reverse" }}>

                                {<LookSize style={{ width: "auto", fontWeight: 900, paddingLeft: true ? "0px" : "" }}>
                                    {handleFormatPrice(isRoutineCompleteDisplayed ? longRoutinePrices[1] : shortRoutinePrices[1])}
                                </LookSize>}

                                {shortRoutinePrices[0] !== shortRoutinePrices[1] && <LookSize style={{ width: "auto", fontWeight: 900, textDecoration: "line-through", color: "#CACACA" }}>
                                    {handleFormatPrice(isRoutineCompleteDisplayed ? longRoutinePrices[0] : shortRoutinePrices[0])}
                                </LookSize>}

                            </div>

                            <AddToCartRoutineButton style={{ width: "100%" }} onClick={() => addRoutineToCart()} isActive={checkRoutineClickable()}>
                                {t('results.addRoutineToProducts', 'Ajouter la routine au panier')}
                            </AddToCartRoutineButton>
                        </>}
                </div>
                }
                {displayOptIn()}
                {displayClosePopUp()}
            </RecoViewContainer>
        </CSSTransition>)
}

const RecoViewComponent = () => {
    return <RecoView />
}

export default RecoViewComponent