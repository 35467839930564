import styled from 'styled-components';
import RotateIcon from '../Assets/Icons/RotateIcon.png';
import { Quest } from '../Constants/QuestsConstants';
import i18n from '../i18nextInit';

export const AppStyled = styled.div<{ step: number }>`
	display: flex;
	flex-direction: column;
	min-height: ${(props) => (props.step !== 11 && props.step !== 8 && props.step !== 2 ? '100%' : '100vh')};
	min-height: ${(props) => (props.step === 3 || props.step === -1 ? '100vh' : '')};
	align-items: ${(props) => (props.step === 3 || props.step === 4 ? 'stretch' : 'center')};
	justify-content: ${(props) => (props.step === 3 ? 'center' : '')};
	background-color: ${props => props.step === 11 ? 'black' : 'white'};
	min-width: 350px;
	width: 100%;
	overflow-y: auto;
	overflow: hidden;
	position: ${(props) => (props.step === 1 || props.step === 3 ? 'absolute' : '')};
	@media (min-width: 768px) and (max-width: 1224px) {
		background-color: ${(props) => (props.step === 11 ? 'black' : '')};
	}

	@media (min-width: 1224px) {
		min-height: ${(props) => (props.step === 21 ? 'initial' : '')};
	}
`;

export const AppLandscape = styled.img`
	display: block;
	background-image: url(${RotateIcon});
	background-size: contain;
	background-repeat: no-repeat;
	background-position-y: center;
	width: 100px;
	height: 100px;
`;

export const ComponentContainer = styled.div`
	display: inherit;
	flex-direction: inherit;
	align-items: center;
	background: inherit;
	position: relative;
	width: 100%;
	background-color: #fcfcff;

	@media (min-width: 768px) {
		width: calc(100% - 24px);
	}
`;

export const CameraViewComponent = styled(ComponentContainer)`
	position: fixed;
	transition: 0.4s;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
`;

export const QuestsViewContainer = styled(ComponentContainer)`
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
	width: 100%;
	position: absolute;
`;

export const ParagraphContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

export const PopOver = styled.div`
	position: fixed;
	display: flex;
	align-items: center;
	flex-direction: column;
	width: 100vw;
	z-index: 4;
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 15px #00000029;
	border-radius: 20px 20px 0px 0px;
	animation-duration: 0.4s;
	animation-fill-mode: forwards;
	overflow: auto;
	bottom: -100%;
	right: 0;
	left: 0;
`;

export const Mask = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: rgba(255, 255, 255, 0.5);
	z-index: 3;
`;

export const QuestContainer = styled.div`
	display: flex;
	align-items: stretch;
	flex-direction: column;
	margin-top: 32px;
	margin-bottom: 100px;
`;

export const IconContainer = styled.div`
	height: 32px;
	width: 32px;
	margin-bottom: 12px;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const NextQuestContainer = styled.div<{ questStep: boolean }>`
	background: rgba(255, 255, 255, 0.45) 0% 0% no-repeat padding-box;
	backdrop-filter: blur(25px);
	-webkit-backdrop-filter: blur(25px);
	height: 64px;
	position: fixed;
	left: 0;
	bottom: 0;
	display: ${(props) => (props.questStep ? 'flex' : 'none')};
	width: 100%;
	justify-content: center;
	align-items: center;
`;

export const Bubble = styled.div<{ color: string; isBig: boolean }>`
	width: ${(props) => (props.isBig ? '50px' : '36px')};
	height: ${(props) => (props.isBig ? '50px' : '36px')};
	background-color: white;
	color: ${(props) => props.color};
	z-index: 4;
	border-radius: 25px;
	display: flex;
	align-items: center;
	justify-content: center;
`;
/* eslint-disable no-useless-concat */
export const BubbleContent = styled.div<{ radius: number; isInCarousel: boolean }>`
	position: ${(props) => (!props.isInCarousel ? 'absolute' : 'relative')};
	top: ${(props) => !props.isInCarousel && 'calc(' + 150 * Math.cos(props.radius) + 'px + ' + 'calc(50% - 30px))'};
	left: ${(props) => !props.isInCarousel && 'calc(' + 150 * Math.sin(props.radius) + 'px + ' + 'calc(50% - 18px))'};
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

export const CarouselContainer = styled.div`
	margin-top: 200px;
	height: 120px;
	width: 100%;
	display: flex;
	align-items: center;
	position: relative;
	justify-content: space-between;
`;

export const ProductCellContainer = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 24px;
	width: 100%;
`;

export const SmallProductCellContainer = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const TextProductCellContainer = styled(SmallProductCellContainer)`
	flex-direction: column;
	padding-left: 8px;
	padding-right: 20px;
	align-items: flex-start;
`;

export const ProductDetailTextContainer = styled.div`
	display: flex;
	flex-direction: column;
	box-sizing: border-box;

	@media (min-width: 1224px) {
		padding: 24px 48px 0px 24px;
	}
`;

export const CameraPanel = styled.div`
	position: fixed;
	height: 90px;
	width: 100%;
	top: 0px;
	padding-top: 48px;
	background-color: white;
	display: flex;
	z-index: 3;
	justify-content: space-evenly;
	align-items: center;
`;

export const WarningContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100px;
`;

export const CameraTextPanel = styled.div<{ show?: boolean; isTablet?: boolean }>`
	display: flex;
	position: fixed;
	background-color: white;
	opacity: 0.7;
	justify-content: center;
	align-items: center;
	width: calc(100% - 30px);
	height: 74px;
	border-radius: 10px;
	z-index: 1;
	transition: all 0.4s;
	bottom: ${(props) => (props.show ? (props.isTablet ? '50px' : '10px') : '-100px')};

	@media (min-width: 768px) {
		max-width: 256px;
		bottom: 50px;
	}
`;

export const BridgeButtonsContainer = styled.div<{ isActive: boolean }>`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: fill-available;
	position: fixed;
	bottom: 0;
	margin-bottom: 16px;
	opacity: ${(props) => (props.isActive ? 1 : 0)};
	transition: opacity 0.7s;
`;

export const Flash = styled.div`
	position: fixed;
	height: 100vh;
	width: 100vw;
	background-color: white;
	opacity: 0;
`;

export const ProgressBarContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	bottom: 30px;
	opacity: 0;
	animation: Fading 1s forwards 2s;
`;

export const ProgressBar = styled.div`
	width: 0;
	height: 1px;
	background-color: white;
	animation: BarFilling2 1s forwards 2s;
`;

export const OldProgressBar = styled.div<{ step: number }>`
	position: fixed;
	height: 4px;
	left: 0;
	width: ${(props) => (props.step * 100) / (Object.keys(Quest).length / 2 - 1) + 100 / (Object.keys(Quest).length / 2 - 1) + '%'};
	background-color: green;
	top: 64px;
	transition-duration: 1s;
	z-index: 4;
`;

const switchTopForTitleContainer = (questStep: number): string => {
	switch (questStep) {
		case Quest.contourYeux:
			return '40px';
		default:
			return '70px';
	}
};

const switchTopForBubbleContainer = (questStep: number, top: number): string => {
	switch (questStep) {
		case Quest.preferenceDemaquillage:
			return (top + 130).toString() + 'px';
		default:
			return (top + 130).toString() + 'px';
	}
};

const switchTopForBubbleContainerTab = (questStep: number, top: number): string => {
	switch (questStep) {
		case Quest.preferenceDemaquillage:
			return (top + 250).toString() + 'px';
		default:
			return (top + 250).toString() + 'px';
	}
};

const setAnimationForTitleContainer = (questStep: Quest, isTablet: boolean = false): string => {
	if (!isTablet) {
		switch (questStep) {
			case Quest.preferenceDemaquillage2:
				return 'TextUp2 2s both 1s';
			case Quest.contourYeux:
				return 'TextUp4 1s both 1s';
			case Quest.preoccupationsPrincipales:
				return 'TextUpPP 1s both 1s';
			case Quest.exposomes:
				return 'TextUp3 2s both 1s';
			default:
				return 'TextUp 1s both 1s';
		}
	} else {
		switch (questStep) {
			case Quest.preferenceDemaquillage2:
				return 'TextUp2Tab 2s both 1s';
			case Quest.contourYeux:
				return 'TextUp4Tab 2s both 1s';
			case Quest.exposomes:
				return 'TextUp3Tab 2s both 1s';
			default:
				return 'TextUpTab 1s both 1s';
		}
	}
};

const setAnimationForTitleContainerDesk = (questStep: Quest): string => {
	switch (questStep) {
		case Quest.preferenceDemaquillage2:
			return 'TextUp2Desk 2s both 1s';
		case Quest.contourYeux:
			return 'TextUp4Desk 1s both 1s';
		case Quest.preoccupationsPrincipales:
			return 'TextUpPPDesk 1s both 1.5s';
		default:
			return 'TextUpDesk 0.5s both 0.5s';
	}
};

export const QuestTitleContainer = styled.div<{ questStep: number }>`
	position: fixed;
	display: flex;
	z-index: 1;
	top: ${(props) => switchTopForTitleContainer(props.questStep)};
	color: white;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	animation: ${(props) => setAnimationForTitleContainer(props.questStep)};

	@media (min-width: 768px) {
		animation: ${(props) => setAnimationForTitleContainer(props.questStep, true)};
	}

	@media (min-width: 1224px) {
		animation: ${(props) => setAnimationForTitleContainerDesk(props.questStep)};
	}
`;

export const QuestContainerBubbles = styled.div<{ questStep: number; top: number }>`
	display: flex;
	max-width: 400px;
	z-index: 1;
	width: 100%;
	top: ${(props) => switchTopForBubbleContainer(props.questStep, props.top)};
	height: 370px;
	position: absolute;
	opacity: ${(props) => (props.questStep === Quest.contourYeux ? 0 : 1)};
	justify-content: center;
	align-items: center;
	animation: ${(props) => (props.questStep === Quest.contourYeux ? 'RotateBubbles 2.5s Forwards 2s' : '')};

	@media (min-width: 768px) {
		top: ${(props) => switchTopForBubbleContainerTab(props.questStep, props.top)};
	}

	@media (min-width: 1224px) {
		display: flex;
		height: 300px;
		justify-content: center;
		max-width: 800px;
		animation: Fading 1s forwards 2s;
		top: 30%;
	}

	@media (min-width: 1224px) and (max-height: 700px) {
		height: 250px;
		top: 40%;
	}
`;
//top: ${props => props.questStep === 4 || props.questStep === 8 ? '-110px' : '-80px'};

const setTopForQuestContainerPP = (questStep: number, top: number): string => {
	switch (questStep) {
		case Quest.contourYeux:
			return (top + 170).toString();
		case Quest.preoccupationsPrincipales:
			return (top + 20).toString();
		default:
			return (top + 70).toString();
	}
};

const setTopForQuestContainerPPTab = (questStep: number, top: number): string => {
	switch (questStep) {
		case Quest.contourYeux:
			return (top + 220).toString();
		case Quest.preoccupationsPrincipales:
		default:
			return (top + 220).toString();
	}
};

export const QuestContainerForPP = styled.div<{ top: number; questStep: number }>`
	display: flex;
	z-index: 1;
	flex-wrap: wrap;
	max-width: 400px;
	position: absolute;
	justify-content: center;
	align-items: flex-start;

	@media (max-width: 1224px) {
		padding: 0px 4.3%;
		top: ${(props) => setTopForQuestContainerPP(props.questStep, props.top)}px;
	}

	@media (min-width: 768px) {
		top: ${(props) => setTopForQuestContainerPPTab(props.questStep, props.top)}px;
	}

	@media (min-width: 1224px) {
		max-width: 700px;
	}
`;

export const QuestContainerForCY = styled.div<{ top: number; questStep: number }>`
	display: flex;
	z-index: 1;
	flex-wrap: wrap;
	max-width: 400px;
	position: absolute;
	justify-content: center;
	align-items: flex-start;

	@media (max-width: 1224px) {
		padding: 0px 4.3%;
		top: ${(props) => setTopForQuestContainerPP(props.questStep, props.top)}px;
	}

	@media (min-width: 768px) {
		top: 50%;
	}

	@media (min-width: 768px) and (min-height: 750px) {
		top: 40%;
	}

	@media (min-width: 1224px) {
		max-width: 700px;
	}
`;

export const ProgressBarEmpty = styled.div`
	position: fixed;
	background-color: #eeece7;
	height: 4px;
	top: 64px;
	width: 100%;
	z-index: 4;
	left: 0;
`;

export const NextQuestionContainer = styled.div`
	position: fixed;
	bottom: 12px;
	opacity: 0;
	transition: all 0.5s;
	z-index: 1;
	display: flex;
	justify-content: center;
	animation: Fading 1s Forwards 3s;
	width: calc(100% - 24px);
`;

export const Curtain = styled.div`
	position: fixed;
	width: 100vw;
	height: 100vh;
	background-color: white;
	transition: 1.5s;
	opacity: 0;
	z-index: 10;
	animation: Fading 2s;
`;

export const OptInContainer = styled.div<{ isClosed: boolean; isHelp: boolean }>`
	position: fixed;
	height: auto;
	width: 100%;
	background-color: white;
	bottom: ${(props) => (props.isClosed ? '-100%' : '0.001%')};
	border-top-right-radius: 20px;
	border-top-left-radius: 20px;
	display: flex;
	flex-direction: column;
	align-items: ${(props) => (props.isHelp ? 'initial' : 'center')};
	z-index: 30;
	transition: all 0.3s;
	padding: ${(props) => (props.isHelp ? '54px' : '')};
	box-sizing: border-box;

	@media (min-width: 768px) {
		border-radius: 20px;
		opacity: ${(props) => (props.isClosed ? 0 : 1)};
		width: ${(props) => (!props.isHelp ? '400px' : '600px')};
		height: auto;
		position: relative;
		left: initial;
		top: initial;
		transform: initial;
		padding: 4%;
		transition: none;
	}

	@media (max-width: 768px) {
		padding: ${(props) => (props.isHelp ? '64px 4% 24px' : '38px 38px 24px')};
	}

	@media (min-width: 1224px) {
		box-sizing: ${(props) => (props.isHelp ? 'initial' : '')};
		max-width: ${(props) => (props.isHelp ? '550px' : '')};
		padding: 24px;
	}
`;

export const ConcernBox = styled.div<{ color: string; backgroundImage: any }>`
	cursor: pointer;
	height: 90px;
	width: 100%;
	z-index: 2;
	display: flex;
	align-items: center;
	border-radius: 6px;
	background-color: ${(props) => props.color};
	background-image: ${(props) => (props.backgroundImage ? `url(${props.backgroundImage})` : '')};
	background-position-y: center;
	background-size: cover;
	justify-content: space-between;
	box-sizing: border-box;
	padding: ${() => window.RTL_LANGS.includes(i18n.language) ? "0px 12px 0px 24px" : "0px 24px 0px 12px"};
	gap: 12px;
`;

export const ConcernBoxRound = styled.div`
	border: 0.5px solid white;
	width: 64px;
	height: 64px;
	border-radius: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
	font: normal normal 700 19px/24px Helvetica;
	letter-spacing: 1px;
	color: white;
	margin-left: 12px;
`;

export const SwitchSelectorContainer = styled.div<{ isFlying: boolean; isInDesktopMode: boolean; isDisplayed: boolean }>`
	position: ${(props) => (props.isFlying ? 'fixed' : 'relative')};
	width: 100%;
	left: ${(props) => (props.isFlying ? '-12px' : '')};

	height: 60px;
	padding: 0px 12px;
	box-sizing: border-box;
	z-index: 5;
	background-color: white;
	border-radius: 50px;

	@media (min-width: 768px) {
		width: ${(props) => (props.isFlying ? 'calc(100% - 48px)' : '')};
		left: ${(props) => (props.isFlying ? '0px' : '')};
		padding: initial;
		margin: 0px 2%;
	}

	@media (min-width: 1224px) {
		transition: opacity 0.7s;
		width: ${(props) => (props.isInDesktopMode ? '100%' : '100%')};
		margin: ${(props) => (props.isInDesktopMode ? '0px 0px 36px' : '')};
		opacity: ${(props) => (props.isInDesktopMode ? (props.isDisplayed ? 1 : 0) : '')};
		max-width: 570px;
		margin: 24px 0px;
	}
`;

const handleLeftRoutine = (routineComplete: boolean): string => {
	if(window.RTL_LANGS.includes(i18n.language)){
		return routineComplete ? "50%" : "-50%"
	} else {
		return routineComplete ? "-50%" : "50%"
	}
}

export const RoutineContainer = styled.div<{ $routineComplete: boolean }>`
	width: 200%;
	display: flex;
	left: ${(props) => handleLeftRoutine(props.$routineComplete)};
	transition: all 0.4s;
	position: relative;

	@media (min-width: 1224px) {
		width: 100%;
		left: 0%;
	}
`;

export const ProductContainer = styled.div<{ isDetail: boolean }>`
	width: 100%;
	display: flex;
	position: ${(props) => (props.isDetail ? 'fixed' : 'relative')};
	margin: ${(props) => (props.isDetail ? '0' : '16px 0px')};
	top: ${(props) => (props.isDetail ? '0px' : '')};
	height: ${(props) => (props.isDetail ? '100vh' : '')};
	overscroll-behavior: ${(props) => (props.isDetail ? 'none' : '')};
	background-color: ${(props) => (props.isDetail ? 'white' : '')};
	z-index: ${(props) => (props.isDetail ? 10 : '')};
	flex-flow: ${(props) => (props.isDetail ? 'column nowrap' : 'row wrap')};
	overflow: ${(props) => (props.isDetail ? 'auto' : 'visible')};
	cursor: pointer;

	@media (min-width: 1224px) {
		width: ${(props) => (props.isDetail ? '464px' : '268px')};
		height: ${(props) => (props.isDetail ? '100%' : '')};
		flex-flow: column nowrap;
		top: 0px;
		right: 0px;
	}

	@media (min-width: 768px) and (max-width: 1224px) {
		width: 96%;
		padding-left: 2%;
	}
`;

export const TagElement = styled.div<{ color: string }>`
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: ${(props) => props.color};
	color: white;
	font: normal normal 700 8px/10px Helvetica;
	font-size: ${i18n.language === 'tw_TW' ? '10px' : '8px'};
	text-transform: uppercase;
	margin-right: 8px;
	margin-bottom: 8px;
	letter-spacing: 1px;
	padding: 4px 10px;
	border-radius: 24px;
`;

export const PanierProduct = styled.div<{ stock: number; isDisplay: boolean }>`
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: ${(props) => (props.stock > 0 ? 'black' : '#8d8d8d')};
	pointer-events: ${(props) => (props.stock === 0 || !props.isDisplay ? 'none' : '')};
	color: white;
	border-radius: 100px;
	padding: 10px 20px;
	opacity: ${(props) => (props.isDisplay ? 1 : 0)};
	transition: all 0.5s;

	&:hover {
		background-color: #5d8c82;
	}
`;

export const ProductTextContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-top: 12px;

	@media (min-width: 768px) {
		width: 60%;
		margin-top: -50px;
		box-sizing: border-box;
		padding-left: 12px;
	}

	@media (min-width: 768px) and (max-width: 1224px) {
		width: 70%;
		padding-left: 4%;
	}

	@media (min-width: 1224px) {
		width: 100%;
		margin: 0px;
		padding: 0px;
	}
`;

export const OnboardingContainer = styled.div`
	z-index: 20;
	position: relative;
	overflow: auto;
	width: 100%;
	background-color: white;
	min-height: 400px;
	max-height: 100%;
	top: 0px;
	overscroll-behavior: none;
	box-sizing: border-box;
	padding: 64px 8.12%;
	max-width: 1200px;

	@media (min-width: 1224px) {
		padding: 64px 6%;
	}

	@media (max-width: 768px) {
		padding: 64px 4%;
	}
`;

export const OnboardingButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	bottom: 0px;
	height: 80px;
	width: 100%;

	@media (min-width: 1224px) {
		width: calc(100% - 40%);
		left: 20%;
	}

	@media (max-width: 1224px) {
		left: 0px;
	}
`;

export const DesktopViewContainer = styled.div`
	width: 100vw;
	display: flex;
	box-sizing: border-box;
	flex-wrap: wrap;
	overflow: auto;
`;

export const PreoccupationsTextContainer = styled.div`
	display: flex;
	justify-content: flex-start;
	width: calc(100% - 24px);
	margin: 24px 0px;

	@media (max-width: 768px) {
		flex-direction: column;
	}

	@media (min-width: 1224px) {
		width: 100%;
	}
`;

export const PreoccupationContainer = styled.div<{ isExposome: boolean }>`
	display: flex;
	flex-direction: column;
	width: calc(100%);
	align-items: center;

	@media (min-width: 1224px) {
		max-width: ${(props) => (props.isExposome ? '100%' : '50%')};
		padding: 0px 0px;
		flex: 1;
	}
`;

export const ExposomesContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: calc(100%);
	align-items: center;

	@media (min-width: 1224px) {
		max-width: 50%;
		padding: 0px 0px;
		flex: 1;
		min-width: 45%;
	}
`;

export const ResultViewContainer = styled(ComponentContainer)`

	@media (min-width: 1224px) {
		flex-flow: row wrap;
		align-items: flex-start;
		max-width: 1200px;
		column-gap: 5%;
		margin-top: 0px;
	}
`;

export const RecoViewContainer = styled(ComponentContainer)`
	margin-top: 24px;

	@media (min-width: 1224px) {
		flex-direction: column;
		align-items: flex-start;
		width: 1200px;
		padding: 0px 0px;
	}
`;

export const SingleRoutineContainer = styled.div<{ isCompleteRoutineDisplayed: boolean }>`
	width: 100%;
	transition: 0.3s;
	opacity: ${(props) => (props.isCompleteRoutineDisplayed ? 0 : 1)};

	@media (min-width: 1224px) {
		width: 100%;
		flex-wrap: wrap;
		display: flex;
		column-gap: 42px;
		opacity: 1;
	}
`;

export const RightPanel = styled.div<{ isFlying: boolean }>`
	position: relative;
	right: 0px;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const ProductDetailBuyContainer = styled.div`
	width: 100%;
	display: flex;
	bottom: 0px;
	left: 0px;
	position: fixed;
	height: 80px;
	justify-content: center;
	align-items: center;

	@media (min-width: 1224px) {
		margin: 24px 0px;
		position: sticky;
	}

	@media (max-width: 1224px) {
		box-sizing: border-box;
		padding: 0px 24px;
	}

	@media (max-width: 768px) {
		padding: 0px 12px;
	}
`;

export const DropDownContainer = styled.div<{ isInConcernBox: boolean; noExposomes: boolean }>`
	display: flex;
	flex-direction: column;
	background-color: #f6f6f6;
	border-bottom-left-radius: 6px;
	border-bottom-right-radius: 6px;
	padding: 24px;
	padding-top: 29px;
	box-sizing: border-box;
	row-gap: ${(props) => (!props.isInConcernBox ? '24px' : '')};

	@media (min-width: 1224px) {
		flex-direction: ${(props) => (!props.isInConcernBox && !props.noExposomes ? 'initial' : 'column')};
		flex-wrap: ${(props) => (!props.isInConcernBox ? 'wrap' : '')};
		column-gap: 64px;
	}
`;

export const ConcernBoxContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: calc(100% - 24px);
	margin-bottom: 12px;

	@media (min-width: 1224px) {
		margin-bottom: 16px;
		width: 100%;
	}
`;

export const SkinTypeImageContainer = styled.div`
	position: relative;
	width: 100vw;

	@media (min-width: 1224px) {
		height: 450px;
		margin-top: 0px;
	}

	@media (min-width: 2500px) {
		height: 750px;
	}
`;

export const SkinImageTextContainer = styled.div`
	position: absolute;
	display: flex;
	flex-direction: column-reverse;
	bottom: 0px;
	padding: 12px;
	max-width: 400px;

	@media (min-width: 768px) {
		padding: 24px;
	}

	@media (min-width: 1224px) {
		padding: 24px 0px;
		max-width: 570px;
	}
`;

export const SkinAdviceContainer = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	padding: 0px 12px;

	@media (min-width: 1224px) {
		padding: 0px 0px;
		flex: 1;
		min-width: 45%;
	}
`;

export const ContactTextContainer = styled.div`
	display: flex;
	flex-direction: column;
	flex: 6;

	@media (min-width: 1224px) {
		flex-direction: row;
	}
`;

export const FooterContainer = styled(ComponentContainer)`
	align-items: center;
	padding: 0px 12px;

	@media (min-width: 1224px) {
		padding: 0px 0px;
		margin: 24px 0px;
		width: 100%;
		justify-content: space-between;
	}
`;

export const CircleProgressBar = styled.div<{ angle: number; color: any }>`
	background: radial-gradient(${(props) => props.color} 67%, transparent 69%), conic-gradient(transparent 0deg ${(props) => props.angle}deg, gainsboro ${(props) => props.angle}deg 360deg), conic-gradient(white 0deg, white 360deg, white 180deg, transparent);
	border-radius: 50%;
	font: normal normal 700 19px/24px Helvetica;
	width: 64px;
	height: 64px;
	display: flex;
	color: white;
	justify-content: center;
	align-items: center;
	letter-spacing: 1px;
	gap: 4px;
`;

export const TagContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	padding-left: 12px;
	box-sizing: border-box;
	flex-wrap: wrap;

	@media (min-width: 1224px) {
		padding: 8px 0px;
		height: 64px;
		align-items: start;
	}
`;

export const PriceContainer = styled.div`
	width: 100%;
	box-sizing: border-box;
	padding: 0px 12px;
	margin: 12px 0px;
	display: flex;
	align-items: flex-start;
	margin-top: 32px;

	@media (min-width: 1224px) {
		padding: 0px;
		margin-top: 12px;
	}

	@media (min-width: 768px) and (max-width: 1224px) {
		padding-right: 0px;
	}
`;

export const NumberContainer = styled.div`
	height: 80px;
	width: 100%;
	margin: 12px;
	position: relative;
	display: flex;
	margin-top: 24px;

	@media (min-width: 768px) and (max-width: 1224px) {
		margin: 12px 0px;
	}

	@media (min-width: 1224px) {
		margin: 0px;
	}
`;

export const OTSTCWaterMarkContainer = styled.div<{ isWMDisplayed: boolean }>`
	display: flex;
	align-items: center;
	height: 12px;
	position: fixed;
	bottom: ${(props) => (props.isWMDisplayed ? '92px' : '100px')};
	right: ${(props) => (props.isWMDisplayed ? '-226px' : '8px')};
	transform: ${(props) => (props.isWMDisplayed ? 'rotate(-90deg)' : '')};
	transform-origin: center left;
	-webkit-tap-highlight-color: transparent;

	@media (min-width: 768px) {
		bottom: ${(props) => (props.isWMDisplayed ? '92px' : '100px')};
	}

	@media (min-width: 1224px) {
		left: 8px;
	}
`;

export const SwitchButtonCtnr = styled.div`
	width: 100%;
	border-radius: 30px;
	background-color: #F6F6F6;
	min-height: 64px;
	position: relative;
	display: flex;
`

export const SwitchButtonTextCtnr = styled.div<{ $selected: boolean }>`
	flex: 1;
	display: flex;
	flex-direction: column;
	color: ${props => props.$selected ? "white" : "black"};
	justify-content: center;
	align-items: center;
	z-index: 2;
	transition: color 0.3s;
`

export const SwitchSlider = styled.div<{ $selected: boolean }>`
	width:50%;
	height: 100%;
	position: absolute;
	background-color: black;
	border-radius: 30px;
	left: ${props=> props.$selected || window.RTL_LANGS.includes(i18n.language) ? "0%" : "50%"};
	right: ${props=> props.$selected || !window.RTL_LANGS.includes(i18n.language) ? "0%" : "50%"};
	transition: all 0.3s;
	z-index: 1;
`
