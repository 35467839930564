export const SET_STEP = 'SET_STEP'
export const ANALYSE_PHOTO = 'ANALYSE_PHOTO'
export const ANALYSE_UPDATE = 'ANALYSE_UPDATE'
export const ANALYSE_BIOMETRICS_DONE = 'ANALYSE_BIOMETRICS_DONE'
export const ANALYSE_DONE = 'ANALYSE_DONE'
export const UPDATE_ONE_PRODUCT = 'UPDATE_PRODUCTS'
export const ANALYSE_ERROR = 'ANALYSE_ERROR'
export const SET_QUESTION = 'SET_QUESTION' // save les questions dans le store
export const UPDATE_PANIER = "FILL_PANIER"
export const RESET_EXPERIENCE = "RESET_EXPERIENCE"
export const UPDATE_RITUAL_KIT = "UPDATE_RITUAL_KIT"
export const SET_RECO = "SET_RECO"
export const ANALYSE_FAKE = "ANALYSE_FAKE"
export const SET_ONBOARD_DISPLAYED = "SET_ONBOARD_DISPLAYED"
export const SET_ACTUAL_CONCERN = "SET_ACTUAL_CONCERN"
export const FAKE_RESET = "FAKE_RESET"
export const SET_BRIDGE = "SET_BRIDGE"
export const SET_CLIENT_BEARER = "SET_CLIENT_BEARER"
export const SET_CLIENT_BASKETID = "SET_CLIENT_BASKETID"
export const SET_CLIENT_DATA = "SET_CLIENT_DATA"
export const UPDATE_TRYON_PRODUCTS = "UPDATE_TRYON_PRODUCTS" 
export const REMOVE_TRYON_PRODUCT = "REMOVE_TRYON_PRODUCT"
export const UPDATE_TRYON_VARIANTS = "UPDATE_TRYON_VARIANTS"
export const UPDATE_PRODUCT_WITH_STAR_INFOS = "UPDATE_PRODUCT_WITH_STAR_INFOS"
export const SET_ANALYSE = "SET_ANALYSE"
export const SET_SIGNED_IN = "SET_SIGNED_IN"
export const SET_PRIVATE_MODE = "SET_PRIVATE_MODE"
export const SET_EXPAND = "SET_EXPAND"
export const CREATE_NEW_VARIANT = "CREATE_NEW_VARIANT"
export const UPDATE_PLAYGROUND_CART = "UPDATE_PLAYGROUND_CART"
export const REMOVE_PLAYGROUND_CART_PRODUCT = "REMOVE_PLAYGROUND_CART_PRODUCT"
export const UPDATE_MODEL = "UPDATE_MODEL"
export const UPDATE_CURRENT_PG_PRODUCT = "UPDATE_CURRENT_PG_PRODUCT"
export const NEW_UPDATE_TRY_ON_PRODUCT = "NEW_UPDATE_TRY_ON_PRODUCT"