import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { WhiteButton } from "../../../Styles/Buttons"
import { ReplaceContentText, ReplaceText } from "../../../Styles/VTO/VTOTexts"
import { PGUnderCategory, PlayGroundState, displayProductNameWithLimiter } from "../PlaygroundConstants"
import * as ActionTypes from '../../../Constants/ActionsTypes'
import { MySkinDiagState } from "../../../type"
import { TryOnProductClass } from "../../../Constants/TryOnProduct"
import { AnimatePresence } from "framer-motion"
import { PopUpCtnr, PGProductCellContainer, PGProductCellText, PGProductCellImg } from '../../../Styles/VTO/VTOContainers'
import { AddToCartButton } from "../../../Styles/VTO/VTOButtons"


interface ReplacePopUpI {
    setPlaygroundState: Function,
    setUnderCategory: Function,
    underCategory: PGUnderCategory,
    displayPopUp: Function,
    setActualProduct: Function,
    currentTryOnProduct: TryOnProductClass
}

const ReplacePopUp = (props: ReplacePopUpI) => {
    const dispatch = useDispatch()
    const playGroundCart: TryOnProductClass[] = useSelector((state: MySkinDiagState) => state.session.playGroundCart)
    const { t } = useTranslation()

    const cancel = () => {
        props.displayPopUp(false)
    }

    const replace = () => {

        let _productToRemove: TryOnProductClass | undefined = playGroundCart.find((product => product.makeUpCategory === props.underCategory ||
            (product.makeUpCategory === PGUnderCategory.lipSticks && props.underCategory === PGUnderCategory.glosses) ||
            (product.makeUpCategory === PGUnderCategory.glosses && props.underCategory === PGUnderCategory.lipSticks)))

        if (_productToRemove) {
            dispatch({ type: ActionTypes.REMOVE_PLAYGROUND_CART_PRODUCT, value: _productToRemove })
        }

        dispatch({ type: ActionTypes.UPDATE_PLAYGROUND_CART, value: props.currentTryOnProduct })
        props.setActualProduct(null)
        props.setPlaygroundState(PlayGroundState.chooseUnderCategory)
        props.setUnderCategory(null)
        props.displayPopUp(false)
    }

    const handleImage = () => {
        let _product: TryOnProductClass | undefined = props.currentTryOnProduct

        if (!_product || _product.sku === "NoShade" || !_product.imgURL) {
            return _product!.variants[1].imgURL
        }

        return _product.imgURL
    }

    return (<PopUpCtnr
        key="POP_UP_REPLACE"
        initial={{ y: "100%" }}
        animate={{ y: 0 }}
        exit={{ y: "100%" }}
        transition={{ duration: 0.25 }}>

        <AnimatePresence>
            {props.currentTryOnProduct && <PGProductCellContainer
                style={{ top: "-24px", left: "calc(50% - 100px)" }}
                $isShadeMode>
                <PGProductCellImg src={handleImage()} />
                <PGProductCellText>
                    {displayProductNameWithLimiter(props.currentTryOnProduct!.parentName)}
                </PGProductCellText>
            </PGProductCellContainer>}
        </AnimatePresence>

        <ReplaceText>
            {t("playGround.replace")}
        </ReplaceText>

        <ReplaceContentText>
            {t("playGround.replaceProductContent")}
        </ReplaceContentText>

        <AddToCartButton style={{ marginTop: "initial", marginBottom: "10px" }} isTwist={false} isExtended={true} onClick={() => replace()} noProduct={false}>
            {t("playGround.replaceContinue")}
        </AddToCartButton>

        <WhiteButton style={{ marginTop: "initial", width: "100%", fontSize: "12px" }} onClick={() => cancel()}>
            {t("playGround.replaceCancel")}
        </WhiteButton>
    </PopUpCtnr>)
}

export default ReplacePopUp
