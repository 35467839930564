import { useState } from "react"
import { useDispatch } from "react-redux"
import * as ActionTypes from '../Constants/ActionsTypes'

const AnalysesMaker = () => {
    const [rides, setRides] = useState<number>(0.4) // Dans l'ordre: Rides, RidesYeux, Relachement, Ptose, fermete, cernes, brillance
    const [ridesYeux, setRidesYeux] = useState<number>(0.7)
    const [ptose, setPtose] = useState<number>(0.2)
    const [fermete, setFermete] = useState<number>(0.6)
    const [cernes, setCernes] = useState<number>(0.5)
    const [brillance, setBrillance] = useState<number>(0.9)
    const [taches, setTaches] = useState<number>(0.3)

    const [imperfections, setImperfections] = useState<number>(0.3)
    const [eclat, setEclat] = useState<number>(0.7)
    const [heterogeneite, setHeterogeneite] = useState<number>(0.3)

    const dispatch = useDispatch()

    const submit = () => {
        let tabAnalyses: Array<any> = []
        tabAnalyses.push({ name: "rides", score: rides })
        tabAnalyses.push({ name: "ridesYeux", score: ridesYeux })
        tabAnalyses.push({ name: "ptose", score: ptose })
        tabAnalyses.push({ name: "fermete", score: fermete })
        tabAnalyses.push({ name: "cernes", score: cernes })
        tabAnalyses.push({ name: "brillance", score: brillance })
        tabAnalyses.push({ name: "taches", score: taches })
        tabAnalyses.push({ name: "imperfection", score: imperfections })
        tabAnalyses.push({ name: "eclat", score: eclat })
        tabAnalyses.push({ name: "heterogeneite", score: heterogeneite })


        dispatch({type: ActionTypes.ANALYSE_FAKE, value: tabAnalyses})
        dispatch({type: ActionTypes.SET_STEP, value: 7})
    }

    return (
        <div style={{ marginTop: '90px', display: 'flex', flexDirection: 'column' }}>
            <p style={{ fontSize: '24px' }}>Simulation d'analyses:</p>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <label style={{ marginRight: '24px', width: '60px' }}>Rides</label>
                <input
                    style={{ width:'35px'}}
                    value={rides}
                    name={"Rides"}
                    type={"number"}
                    onChange={event => setRides(parseFloat(event.target.value))}
                />
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <label style={{ marginRight: '24px' }}>Rides des Yeux</label>
                <input
                    style={{ width: '35px' }}
                    value={ridesYeux}
                    name={"Rides"}
                    type={"number"}
                    onChange={event => setRidesYeux(parseFloat(event.target.value))}
                />
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <label style={{ marginRight: '24px' }}>Ptose</label>
                <input
                    style={{ width: '35px' }}
                    value={ptose}
                    name={"Rides"}
                    type={"number"}
                    onChange={event => setPtose(parseFloat(event.target.value))}
                />
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <label style={{ marginRight: '24px' }}>Fermeté</label>
                <input
                    style={{ width: '35px' }}
                    value={fermete}
                    name={"Rides"}
                    type={"number"}
                    onChange={event => setFermete(parseFloat(event.target.value))}
                />
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <label style={{ marginRight: '24px' }}>Cernes</label>
                <input
                    style={{ width: '35px' }}
                    value={cernes}
                    name={"Rides"}
                    type={"number"}
                    onChange={event => setCernes(parseFloat(event.target.value))}
                />
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <label style={{ marginRight: '24px' }}>Brillance</label>
                <input
                    style={{ width: '35px' }}
                    value={brillance}
                    name={"Rides"}
                    type={"number"}
                    onChange={event => setBrillance(parseFloat(event.target.value))}
                />
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <label style={{ marginRight: '24px' }}>Taches</label>
                <input
                    style={{ width: '35px' }}
                    value={taches}
                    name={"Rides"}
                    type={"number"}
                    onChange={event => setTaches(parseFloat(event.target.value))}
                />
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <label style={{ marginRight: '24px' }}>Imperfections</label>
                <input
                    style={{ width: '35px' }}
                    value={imperfections}
                    name={"Rides"}
                    type={"number"}
                    onChange={event => setImperfections(parseFloat(event.target.value))}
                />
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <label style={{ marginRight: '24px' }}>Eclat</label>
                <input
                    style={{ width: '35px' }}
                    value={eclat}
                    name={"Rides"}
                    type={"number"}
                    onChange={event => setEclat(parseFloat(event.target.value))}
                />
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <label style={{ marginRight: '24px' }}>Hétérogénéité</label>
                <input
                    style={{ width: '35px' }}
                    value={heterogeneite}
                    name={"Rides"}
                    type={"number"}
                    onChange={event => setHeterogeneite(parseFloat(event.target.value))}
                />
            </div>

            <button
                onClick={() => submit()}
            >Valider</button>

        </div>
    )
}

const AnalysesMakerComponent = () => {
    return <AnalysesMaker />
}
export default AnalysesMakerComponent