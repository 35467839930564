import { Answer } from './QuestsConstants'
import gouteIcon from '../Assets/Icons/Goutte.svg'
import fatigueIcon from '../Assets/Icons/Lune.svg'
import sensibiliteIcon from '../Assets/Icons/Plume.svg'
import poresIcon from "../Assets/Icons/PoresIcon.svg";

export class Concern {
    name: Answer;
    color: string;
    score: number;
    icon: any;

    constructor(_name: Answer, _analyses: any) {
        this.name = _name;
        this.color = this.setColor(this.name);
        this.score = this.setScore(_analyses);
        this.icon = null;

        switch (_name) {
            case Answer.PPdeshydratation:
                this.icon = gouteIcon
                break;
            case Answer.PPsensibilite:
                this.icon = sensibiliteIcon
                break;
            case Answer.PPsigneFatigue:
                this.icon = fatigueIcon
                break;
            case Answer.PPpores:
                this.icon = poresIcon
                break;
            case Answer.PPimperfections:
                if (!window.isQA) {
                    this.icon = poresIcon
                }
                break;
            default:
                break;
        }
    }

    setColor = (name: Answer): string => {
        switch (name) {
            case Answer.PPdeshydratation:
                return '#B0CDD1'
            case Answer.PPimperfections:
                return '#B6C992'
            case Answer.PPtaches:
            case Answer.PPpores:
                return '#B6C0D1'
            case Answer.PPbrillance:
                return '#B6C992'
            case Answer.PPgrainDePeau:
                return '#B6C992'
            case Answer.PPmanqueEclat:
                return '#B2658B'
            case Answer.PPperteFermete:
                return '#BAAA82'
            case Answer.CYrides:
            case Answer.PPrides:
                return '#BAAA82'
            case Answer.CYrelachement:
                return "#BAAA82"
            case Answer.PPsensibilite:
                return '#B0CDD1'
            case Answer.PPsigneFatigue:
                return '#B0CDD1'
            case Answer.PPtracesResiduelles:
                return '#B6C0D1'
            case Answer.CYcernes:
            case Answer.CYpoche:
            case Answer.CYridules:
                return "#B2658B"
            default:
                return '#7097AF'
        }
    }

    setScore = (analyses: any): number => {
        switch (this.name) {
            case Answer.CYcernes:
                return analyses.find((e: any) => e.name === "DARK_CIRCLES") ? analyses.find((e: any) => e.name === "DARK_CIRCLES").score : analyses.find((e: any) => e.name === "EYES_BAGS").score
            case Answer.CYpoche:
                return analyses.find((e: any) => e.name === "EYES_BAGS").score
            case Answer.CYrelachement:
                return analyses.find((e: any) => e.name === "LOSS_OF_FIRMNESS").score
            case Answer.CYridules:
            case Answer.CYrides:
                return analyses.find((e: any) => e.name === "WRINKLES").score
            case Answer.PPbrillance:
                return analyses.find((e: any) => e.name === "SHININESS").score
            case Answer.PPdeshydratation:
                return analyses.find((e: any) => e.name === "HYDRATION").score
            case Answer.PPgrainDePeau:
                return analyses.find((e: any) => e.name === "ROUGHNESS").score
            case Answer.PPimperfections:
                return analyses.find((e: any) => e.name === "ACNE").score
            case Answer.PPmanqueEclat:
                return analyses.find((e: any) => e.name === "RADIANCE").score
            case Answer.PPperteFermete:
                return analyses.find((e: any) => e.name === "LOSS_OF_FIRMNESS").score
            case Answer.PPrides:
                return analyses.find((e: any) => e.name === "WRINKLES").score
            case Answer.PPsigneFatigue:
                return analyses.find((e: any) => e.name === "WRINKLES_UNDER_EYE").score // pas utilisé
            case Answer.PPtaches:
                return analyses.find((e: any) => e.name === "DARK_SPOTS").score
            case Answer.PPtracesResiduelles:
                return analyses.find((e: any) => e.name === "DARK_SPOTS").score // Effet voulu.
            default:
                return 0
        }
    }
}